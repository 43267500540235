import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getUserProfile } from "../apis/user"

const Settings = () => {
  const { t, i18n } = useTranslation()
  const navigation = useNavigate()
  const [userInfo, setUserInfo] = useState({
    email: "testing1234@example.com",
    phoneNumber: "123456789",
    name: "testing1234",
  })
  const init = async () => {
    getUserProfile().then((result) => {
      setUserInfo(result.data.data)
    })
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "2vh",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <button
              onClick={() => {
                navigation("/profile")
              }}
              style={{
                border: "0",
                backgroundColor: "transparent",
                padding: "none",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/左尖角.svg"
              />
            </button>
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              {t("profile.settings")}
            </b>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            left: "0px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "1.8vh",
            color: "#3c464e",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              padding: "2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0.5vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("profile.email")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.65vh",
                }}
              >
                {userInfo.email}
              </div>
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              padding: "2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0.5vh",
              }}
            >
              {/* <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("profile.phoneNumber")}
              </div> */}
              {/* <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.65vh",
                }}
              >
                {userInfo.phoneNumber}
              </div> */}
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              padding: "2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0.5vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("profile.name")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.65vh",
                }}
              >
                {userInfo.name}
              </div>
            </div>
            <button
              onClick={() => {}}
              style={{
                border: "0",
                backgroundColor: "transparent",
                padding: "0",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "2vh",
                  right: "0",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </button>
          </div>
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              padding: "2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0.5vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("profile.password")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.65vh",
                }}
              >
                ********
              </div>
            </div>
            <button
              onClick={() => {
                navigation("/changePassword")
              }}
              style={{
                border: "0",
                backgroundColor: "transparent",
                padding: "0",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "2vh",
                  right: "0",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </button>
          </div>
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              padding: "2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "0.5vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("profile.paymentPassword")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.65vh",
                }}
              >
                ********
              </div>
            </div>
            <button
              onClick={() => {
                navigation("/change-payment-password")
              }}
              style={{
                border: "0",
                backgroundColor: "transparent",
                padding: "0",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "2vh",
                  right: "0",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </center>
  )
}

export default Settings
