import { t } from "i18next";

const IdVerification1 = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "779px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "18px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              {t("profile.idVerif")}
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "35px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "#fff",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#0E73F6",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              {t("profile.next")}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            textAlign: "left",
            fontSize: "14px",
            color: "#f2271c",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
              color: "#303940",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "0px 11px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆-灰.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                {t("profile.basicInfo")}
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                {t("profile.uploadID")}
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                {t("profile.submit")}
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            {t("profile.verify-1")}
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {t("profile.firstName")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder={t("profile.inputFirstName")}
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {t("profile.lastName")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder={t("profile.inputLastName")}
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {t("profile.dateOfBirth")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    border: "0",
                    backgroundColor: "transparent",
                  }}
                  placeholder="YYYY-MM-DD"
                ></input>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            {t("profile.verify-2")}
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdVerification1;
