const FreeMember = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "894px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#48535b",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontSize: "18px",
            color: "#303940",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "48px",
              height: "20px",
              flexShrink: "0",
            }}
            alt=""
            src="../左尖角.svg"
          />
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
              left: "-10px",
            }}
          >
            我的团队
          </b>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "48px",
            left: "calc(50% - 175.5px)",
            width: "351px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "16px",
            fontSize: "14px",
            color: "#0e73f6",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "100px",
                backgroundColor: "#d7edff",
                height: "39px",
                flexShrink: "0",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: "4px 24px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    fontWeight: "600",
                  }}
                >
                  提成收益
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#fff",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                height: "39px",
                flexShrink: "0",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: "4px 24px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    fontWeight: "600",
                  }}
                >
                  邀请好友
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "72px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "#0e73f6",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                fontFamily: "Inter",
                fontWeight: "400",
                color: "#000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              合伙人
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "6px",
              }}
            >
              <b style={{ position: "relative", letterSpacing: "-0.01em" }}>
                免费会员
              </b>
              <div
                style={{
                  position: "relative",
                  borderTop: "2px solid #0e73f6",
                  boxSizing: "border-box",
                  width: "66px",
                  height: "2px",
                  flexShrink: "0",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "113px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            textAlign: "left",
            fontSize: "14px",
            color: "#84919a",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              backgroundColor: "#f6f8f9",
              border: "1px solid #dee5ef",
              boxSizing: "border-box",
              width: "351px",
              height: "40px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "row",
              padding: "8px 12px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../放大镜.svg"
              />
              <input
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  border: "0",
                  width: "100%",
                  backgroundColor: "#f6f8f9",
                }}
                placeholder="Search"
              ></input>
            </div>
            <img
              style={{
                position: "relative",
                width: "16px",
                height: "16px",
                flexShrink: "0",
              }}
              alt=""
              src="../清空输入.svg"
            />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "169px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 8px rgba(91, 104, 113, 0.22)",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <img
                style={{
                  borderRadius: "10000px",
                  width: "44px",
                  height: "44px",
                  flexShrink: "0",
                  objectFit: "cover",
                }}
                alt=""
                src="../示例头像2.png"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    Mehmet Talay
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 5px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      已实名
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    letterSpacing: "-0.01em",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#5b6871",
                  }}
                >
                  asdasf@gmail.com
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  fontWeight: "600",
                }}
              >
                级别1
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "10px",
                  letterSpacing: "-0.01em",
                  fontWeight: "500",
                  color: "#84919a",
                }}
              >
                2022-01-03 12:00
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  borderRadius: "1000px",
                  height: "19px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
              </div>
              <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
                <span style={{ fontWeight: "500" }}>{`累积贡献提成: `}</span>
                <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                  200 SP
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3px",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日活跃度任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-蓝.svg"
                />
              </div>
              <div
                style={{
                  width: "108px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日健步任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-灰.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "467px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 8px rgba(91, 104, 113, 0.22)",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <img
                style={{
                  borderRadius: "10000px",
                  width: "44px",
                  height: "44px",
                  flexShrink: "0",
                  objectFit: "cover",
                }}
                alt=""
                src="../示例头像2.png"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    Mehmet Talay
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 5px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      已实名
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    letterSpacing: "-0.01em",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#5b6871",
                  }}
                >
                  asdasf@gmail.com
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  fontWeight: "600",
                }}
              >
                级别1
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "10px",
                  letterSpacing: "-0.01em",
                  fontWeight: "500",
                  color: "#84919a",
                }}
              >
                2022-01-03 12:00
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  borderRadius: "1000px",
                  height: "19px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
              </div>
              <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
                <span style={{ fontWeight: "500" }}>{`累积贡献提成: `}</span>
                <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                  200 SP
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3px",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日活跃度任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-蓝.svg"
                />
              </div>
              <div
                style={{
                  width: "108px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日健步任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-灰.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "318px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 8px rgba(91, 104, 113, 0.22)",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <img
                style={{
                  borderRadius: "10000px",
                  width: "44px",
                  height: "44px",
                  flexShrink: "0",
                  objectFit: "cover",
                }}
                alt=""
                src="../示例头像2.png"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    Mehmet Talay
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 5px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      已实名
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    letterSpacing: "-0.01em",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#5b6871",
                  }}
                >
                  asdasf@gmail.com
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  fontWeight: "600",
                }}
              >
                级别1
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "10px",
                  letterSpacing: "-0.01em",
                  fontWeight: "500",
                  color: "#84919a",
                }}
              >
                2022-01-03 12:00
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  borderRadius: "1000px",
                  height: "19px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
              </div>
              <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
                <span style={{ fontWeight: "500" }}>{`累积贡献提成: `}</span>
                <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                  200 SP
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3px",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日活跃度任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-蓝.svg"
                />
              </div>
              <div
                style={{
                  width: "108px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日健步任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-灰.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "616px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 8px rgba(91, 104, 113, 0.22)",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <img
                style={{
                  borderRadius: "10000px",
                  width: "44px",
                  height: "44px",
                  flexShrink: "0",
                  objectFit: "cover",
                }}
                alt=""
                src="../示例头像2.png"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    Mehmet Talay
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 5px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      已实名
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    letterSpacing: "-0.01em",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#5b6871",
                  }}
                >
                  asdasf@gmail.com
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "7px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  fontWeight: "600",
                }}
              >
                级别1
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "10px",
                  letterSpacing: "-0.01em",
                  fontWeight: "500",
                  color: "#84919a",
                }}
              >
                2022-01-03 12:00
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  borderRadius: "1000px",
                  height: "19px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../星星.svg"
                />
              </div>
              <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
                <span style={{ fontWeight: "500" }}>{`累积贡献提成: `}</span>
                <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                  200 SP
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3px",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日活跃度任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-蓝.svg"
                />
              </div>
              <div
                style={{
                  width: "108px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  今日健步任务
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../对号-灰.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default FreeMember;
