import React, { useEffect, useState, useRef } from 'react';
import { Button, Drawer, Input } from 'antd';
import { getComments, likeComment, postComment, unlikeComment } from '../service';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";

import defaultAva from '../../Card/assets/default-avatar.png';

import likeIcon from '../assets/like.svg';
import likedIcon from '../assets/like-liked.png';
import replyIcon from '../assets/reply.svg';

import './index.css';

const CommentItem = (props) => {
  const { content, createTime, dislikes, liked, likes, replies, user, _id } = props;

  const { userName, avatarUrl } = user;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleCommentLike = () => {
    if (liked) {
      likeComment(dispatch, _id);
    } else {
      unlikeComment(dispatch, _id);
    }
  };

  return (
    <div className='comment-item'>
      <div className='comment-header'>
        <div className='comment-item-avatar'>
          <img src={avatarUrl || defaultAva} alt="" />
        </div>
        <div className='comment-item-info'>
          <div className='comment-item-name'>
            {userName}
          </div>
          <div className='comment-item-time'>
            {moment(createTime).format("YYYY-MM-DD HH:mm")}
          </div>
        </div>
      </div>
      <div className='comment-item-content'>
        {content}
      </div>
      <div className='comment-item-bottom'>
        <div className='comment-like' onClick={handleCommentLike}>
          <img src={liked ? likedIcon : likeIcon} alt="" width={17} />
        </div>
        {/* <div className='comment-reply'>
          {t('read.reply')}
        </div> */}
      </div>
    </div>
  );
};

const CommentFooter = (props) => {
  const { id, type } = props;

  const [c, setC] = useState('');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  const handleSubmit = async () => {
    if (type === 'article') {
      await postComment(id, c);
      setC('');
      await getComments(dispatch, id, isLoggedIn, 1);
    }
  };

  return (
    <div className='comment-footer'>
      <Input
        placeholder={t('read.comment')}
        value={c}
        onChange={e => {
          setC(e.target.value);
        }}
        bordered={false}
        width={'70%'}
        className='comment-footer-input'
      />
      <div className='comment-footer-submit' onClick={handleSubmit}>
        <img src={replyIcon} alt="" />
      </div>
    </div>
  );
};

const Comment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const { comments, commentModal, page } = useSelector(state => state.article);

  const { articleId, visible, total } = commentModal;

  useEffect(() => {
    getComments(dispatch, articleId, isLoggedIn);
  }, []);

  const domRef = useRef(null);

  const handleOnScroll = () => {
    if (domRef.current) {
      const contentScrollTop = domRef.current.scrollTop; //滚动条距离顶部
      const clientHeight = domRef.current.clientHeight; //可视区域
      const scrollHeight = domRef.current.scrollHeight; //滚动条内容的总高度
      if (contentScrollTop + clientHeight >= scrollHeight) {
        getComments(dispatch, articleId, isLoggedIn, page);
      }
    }
  };

  const onClose = () => {
    dispatch({
      type: 'article/SET_COMMENT_MODAL',
      payload: {
        visible: false,
        articleId: null,
        total: 0,
      }
    });
  };

  return (
    <Drawer
      title={t('read.totalComments', { amount: total })}
      placement="bottom"
      onClose={onClose}
      open={visible}
      height={'80vh'}
    >
      <div
        className='comment-list'
        onScroll={handleOnScroll}
        ref={domRef}
      >
        {
          comments.map(item => {
            return <CommentItem key={item._id} {...item} />
          })
        }
        <div
          className='comment-anchor'
        />
      </div>

      <CommentFooter id={articleId} type="article" />
    </Drawer>
  );
};

export default Comment;
