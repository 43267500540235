export default function LoginTypeSelector({ mode, setMode }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#f5f5f5",
        borderRadius: "20px",
      }}
    >
      <div
        onClick={() => {
          setMode(0)
        }}
        style={
          mode === 0
            ? styles.modeSelectorOuter.selected
            : styles.modeSelectorOuter.unselected
        }
      >
        <span
          style={
            mode === 0 ? styles.modeText.selected : styles.modeText.unselected
          }
        >
          Phone
        </span>
      </div>
      <div
        onClick={() => {
          setMode(1)
        }}
        style={
          mode === 1
            ? styles.modeSelectorOuter.selected
            : styles.modeSelectorOuter.unselected
        }
      >
        <span
          style={
            mode === 1 ? styles.modeText.selected : styles.modeText.unselected
          }
        >
          Email
        </span>
      </div>
    </div>
  )
}

const styles = {
  modeSelectorOuter: {
    selected: {
      backgroundColor: "#0e73f6",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
    unselected: {
      backgroundColor: "transparent",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
  },
  modeText: {
    selected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "white",
    },
    unselected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "#5B6871",
    },
  },
}
