const SetArea = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "832px",
        overflow: "hidden",
        textAlign: "left",
        fontSize: "14px",
        color: "#1a2024",
        fontFamily: "Inter",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <center>
        <div
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            textAlign: "center",
            fontSize: "18px",
            color: "#303940",
            fontFamily: "'Work Sans'",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../返回.svg"
          />
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
            }}
          >
            选择国家和区域
          </b>
        </div>
        <div
          style={{
            position: "relative",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            padding: "16px 20px 0px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
            color: "#5b6871",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #dee5ef",
              boxSizing: "border-box",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "8px 7px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../放大镜.svg"
            />
            <input
              style={{
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "24px",
                width: "100%",
                border: "0",
              }}
              placeholder="搜索国家"
            ></input>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "16px",
            left: "0px",
            backgroundColor: "#f6f8f9",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            padding: "0px 20px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "24px",
            }}
          >
            A
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "30px",
            left: "20px",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "50px",
            left: "0px",
            backgroundColor: "#f6f8f9",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            padding: "0px 20px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "24px",
            }}
          >
            B
          </div>
        </div>

        <div
          style={{
            position: "relative",
            top: "65px",
            left: "20px",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../阿富汗.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                阿富汗
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  color: "#252c32",
                }}
              >
                +93
              </div>
            </div>
          </div>
        </div>
      </center>
      <div
        style={{
          position: "absolute",
          left: "calc(50% + 190px)",
          top: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          fontSize: "12px",
        }}
      >
        <div
          style={{
            borderRadius: "100px",
            backgroundColor: "#0e73f6",
            width: "19px",
            display: "flex",
            flexDirection: "column",
            padding: "2px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
            A
          </div>
        </div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>B</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>C</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>D</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>E</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>F</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>G</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>H</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>J</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>K</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>L</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>M</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>N</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>P</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>Q</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>R</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>S</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>T</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>W</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>Y</div>
        <div style={{ position: "relative", letterSpacing: "-0.01em" }}>Z</div>
      </div>
    </div>
  );
};

export default SetArea;
