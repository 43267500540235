export default function FunctionButton({ icon, title, onClick, style }) {
  return (
    <button
      onClick={onClick}
      style={{
        border: "0",
        backgroundColor: "transparent",
        borderRight: "1px solid #E5E9EB",
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <img
        src={icon}
        style={{ width: "20px", height: "20px", marginRight: "8px" }}
      />

      <span
        style={{
          fontWeight: "600",
          fontSize: "14px",
          color: "#1A2024",
        }}
      >
        {title}
      </span>
    </button>
  )
}
