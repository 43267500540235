import { useEffect, useRef, useState } from "react"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import PageHeader from "../components/PageHeader"
import PageWrapper from "../../../components/PageWrapper"
import { getWalletInfo, getConversionRate } from "../../../apis/wallet"
import { formatBalance } from "../../../utlis/numbers"
import RegularTextInput from "../../../components/RegularTextInput"
import { convertCurrency } from "../../../apis/wallet"
const fscIcon = require("../asset/fscIcon.png")
const bnbIcon = require("../asset/bnbIcon.png")
const usdtIcon = require("../asset/usdtIcon.png")
const switchIcon = require("../asset/switchIcon.png")
const VALID_CURRENCY = ["FSC", "USDT", "BNB"]
export default function ExchangeCurrencyScreen() {
  const [fromCurrency, setFromCurrency] = useState("FSC")
  const [toCurrency, setToCurrency] = useState("USDT")
  const [fromCurrencyAmount, setFromCurrencyAmount] = useState(null)
  const [fscBalance, setFscBalance] = useState(0)
  const [usdtBalance, setUsdtBalance] = useState(0)
  const [bnbBalance, setBnbBalance] = useState(0)
  const [FSCToUSDTRatio, setFSCToUSDTRatio] = useState(0)
  const [FSCToBNBRatio, setFSCToBNBRatio] = useState(0)
  const [fscConvertFee, setFSCConvertFee] = useState(0)
  const exchangeConfig = {
    FSC: {
      balance: fscBalance,
      USDT: FSCToUSDTRatio,
      BNB: FSCToBNBRatio,
    },
    USDT: {
      balance: usdtBalance,
      FSC: FSCToUSDTRatio > 0 ? 1 / FSCToUSDTRatio : 0,
    },
    BNB: {
      balance: bnbBalance,
      FSC: FSCToBNBRatio > 0 ? 1 / FSCToBNBRatio : 0,
    },
  }
  const navigate = useNavigate()
  const RenderInfoRow = ({ title, info, hideSeparator = false }) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: hideSeparator ? "none" : "1px solid #EEF0F2",
          padding: "18px 0px",
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "14px" }}>{title}</span>
        <span style={{ fontWeight: "500", fontSize: "14px" }}>{info}</span>
      </div>
    )
  }
  const RenderCurrencyBlock = ({
    title,
    currency,
    balance,
    value,
    hasInput,
  }) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          backgroundColor: "#F6F8F9",
          padding: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "transparent",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "6px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "12px",
              color: "#303940",
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: "#303940",
            }}
          >
            {currency}余额: {balance}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor: "transparent",
            marginTop: "12px",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={currencyIcon[currency]}
              style={{ width: "35px", height: "35px" }}
            />
            <span
              style={{
                fontWeight: "600",
                fontSize: "18px",
                marginLeft: "8px",
                color: "#1A2024",
              }}
            >
              {currency}
            </span>
          </div>
          {hasInput && (
            <RegularTextInput
              value={fromCurrencyAmount}
              setValue={setFromCurrencyAmount}
            />
          )}

          <span
            style={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#9AA6AC",
            }}
          >
            {value}
          </span>
        </div>
      </div>
    )
  }
  const tryConfirm = async () => {
    try {
      await convertCurrency(
        fromCurrency,
        toCurrency,
        Number(fromCurrencyAmount)
      )
      console.log("success")
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    const part1 = location.search.split("&")[0]
    const part2 = location.search.split("&")[1]
    if (
      !VALID_CURRENCY.includes(part1.split("from=")[1]) ||
      !VALID_CURRENCY.includes(part2.split("to=")[1])
    ) {
      navigate(-1)
    } else {
      setFromCurrency(part1.split("from=")[1])
      setToCurrency(part2.split("to=")[1])
    }
  }, [])
  useEffect(() => {
    getWalletInfo()
      .then((result) => {
        let wallet = result.data.data
        setFscBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "FSC")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
        setUsdtBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "USDT")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
        setBnbBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "BNB")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
      })
      .catch((e) => console.log(e))

    getConversionRate()
      .then((result) => {
        setFSCToUSDTRatio(result.data.data.FSCtoUSDTRatio)
        setFSCToBNBRatio(result.data.data.FSCtoBNBRatio)
        setFSCConvertFee(result.data.data.FSCtoUSDTFee)
      })
      .catch((e) => console.log(e))
  }, [])
  return (
    <PageWrapper>
      <PageHeader title={t("wallet.convert")} />
      <div style={styles.bodyWrapper}>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            gap: "16px",
            borderRadius: "10px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "6px",
              backgroundColor: "#F6F8F9",
              padding: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "6px",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#303940",
                }}
              >
                {t("wallet.from")}
              </span>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#303940",
                }}
              >
                {fromCurrency}余额:{" "}
                {formatBalance(exchangeConfig[fromCurrency].balance)}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                marginTop: "12px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={currencyIcon[fromCurrency]}
                  style={{ width: "35px", height: "35px" }}
                />
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    marginLeft: "8px",
                    color: "#1A2024",
                  }}
                >
                  {fromCurrency}
                </span>
              </div>
              <RegularTextInput
                placeHolder={"0.00"}
                value={fromCurrencyAmount}
                setValue={setFromCurrencyAmount}
                wrapperStyle={{
                  border: "none",
                }}
                inputStyle={{
                  padding: "0px",
                  textAlign: "end",
                  backgroundColor: "transparent",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#9AA6AC",
                }}
              />
            </div>
          </div>
          <button
            onClick={() => {
              setFromCurrencyAmount(null)
              const temp = fromCurrency
              setFromCurrency(toCurrency)
              setToCurrency(temp)
            }}
            style={{
              padding: "0px",
              border: "none",
              position: "absolute",
              display: "flex",
              borderRadius: "999px",
            }}
          >
            <img src={switchIcon} style={{ width: "32px", height: "32px" }} />
          </button>
          <RenderCurrencyBlock
            title={t("wallet.to")}
            currency={toCurrency}
            balance={formatBalance(exchangeConfig[toCurrency].balance)}
            value={formatBalance(
              exchangeConfig[fromCurrency][toCurrency] * fromCurrencyAmount,
              100000
            )}
            hasInput={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            padding: "10px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        >
          <RenderInfoRow title={t("wallet.exchangeChain")} info={"BSC"} />
          <RenderInfoRow
            title={t("wallet.conversionRate")}
            info={`1 ${fromCurrency} ≈ ${formatBalance(
              exchangeConfig[fromCurrency][toCurrency],
              100000
            )} ${toCurrency}`}
          />
          <RenderInfoRow
            title={t("wallet.exchangeAmount")}
            info={fromCurrencyAmount ?? 0}
          />
          <RenderInfoRow
            title={t("wallet.convertedAmount")}
            info={formatBalance(
              exchangeConfig[fromCurrency][toCurrency] * fromCurrencyAmount,
              100000
            )}
          />
          <RenderInfoRow
            title={t("wallet.processFee")}
            info={"0"}
            hideSeparator={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "0px 12px",
            position: "absolute",
            bottom: "80px",
            gap: "10px",
          }}
        >
          <button
            onClick={tryConfirm}
            style={{
              backgroundColor: "#0E73F6",
              borderRadius: "100px",
              padding: "11px 0px",
              border: "none",
            }}
          >
            <span
              style={{ color: "white", fontWeight: "600", fontSize: "16px" }}
            >
              {t("wallet.confirm")}
            </span>
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              borderRadius: "100px",
              padding: "11px 0px",
              border: "none",
            }}
          >
            <span
              style={{ color: "#0E73F6", fontWeight: "600", fontSize: "16px" }}
            >
              {t("wallet.cancel")}
            </span>
          </button>
        </div>
      </div>
    </PageWrapper>
  )
}
const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
    alignItems: "center",
  },
}

const currencyIcon = {
  FSC: fscIcon,
  BNB: bnbIcon,
  USDT: usdtIcon,
}
