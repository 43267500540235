import { useState } from "react";
import PasswordInput from "../../components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { backendChangePassword } from "../../apis/auth";

const ChangePassword = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const onConfirmPressed = async () => {
    try {
      const result = await backendChangePassword(
        currentPassword,
        newPassword,
        repeatPassword
      );
      navigation("/settings");
      // Toast.show({ type: "info", text1: "支付密码修改成功" });
    } catch (err) {
      console.log(err);
      // switch (err.response.status) {
      //   case 401:
      //     Toast.show({ type: "error", text1: "未设置支付密码" });
      //     break;
      //   case 402:
      //     Toast.show({ type: "error", text1: "必填项为空" });
      //     break;
      //   case 403:
      //     Toast.show({ type: "error", text1: "当前支付密码错误" });
      //     break;
      //   case 403:
      //     Toast.show({ type: "error", text1: "新付款密码不匹配" });
      //     break;
      // }
    }
  };
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "2vh",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 3vh",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "1.6vh 2vh",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <button
                onClick={() => {
                  navigation("/settings");
                }}
                style={{
                  border: "0",
                  backgroundColor: "transparent",
                  padding: "0",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    height: "2vh",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/左尖角.svg"
                />
              </button>
              <b
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                }}
              >
                {t("profile.resetPassword")}
              </b>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "1.65vh",
              color: "#252c32",
              fontFamily: "Inter",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "1.5vh",
                width: "100%",
                padding: "0 2vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.5vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "130%",
                  }}
                >
                  {t("profile.currentPass")}
                </div>
                <PasswordInput
                  value={currentPassword}
                  setValue={setCurrentPassword}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.5vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "130%",
                  }}
                >
                  {t("profile.newPass")}
                </div>
                <PasswordInput value={newPassword} setValue={setNewPassword} />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.5vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "130%",
                  }}
                >
                  {t("profile.repeatPass")}
                </div>
                <PasswordInput
                  value={repeatPassword}
                  setValue={setRepeatPassword}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "20vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "1.8vh",
            gap: "1vh",
            color: "#fff",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#0e73f6",
              width: "50%",
              display: "flex",
              flexDirection: "row",
              padding: "1vh 3vh",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={onConfirmPressed}
              style={{
                border: "0",
                backgroundColor: "transparent",
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
                color: "inherit",
              }}
            >
              {t("profile.confirm")}
            </button>
          </div>
          <button
            onClick={() => {
              navigation("/settings");
            }}
            style={{
              border: "0",
              backgroundColor: "transparent",
              position: "relative",
              lineHeight: "140%",
              fontWeight: "500",
              color: "#0e73f6",
            }}
          >
            {t("profile.cancel")}
          </button>
        </div>
      </div>
    </center>
  );
};

export default ChangePassword;
