import request from "../../utlis/request";
import { notification } from "antd";
import axios from "axios";

export const getArticle = async (dispatch, articleId, loggedIn) => {
  try {
    const { data } = await request.get(
      `/api/article/id/${articleId}/${!loggedIn ? "guest" : ""}`
    );

    dispatch({
      type: "article/PUT_CONTENT",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const readArticle = async (message) => {
  try {
    await request.post("/api/article/read", { message });
  } catch (e) {
    console.log(e);
  }
};

export const likeArticle = async (dispatch, articleId) => {
  try {
    await request.put(`/api/article/like/${articleId}`);

    notification.success("like success");
  } catch (e) {
    console.log(e);
  }
};

export const unlikeArticle = async (articleId) => {
  return await axios.put(`/api/article/unlike/${articleId}`);
};

export const getComments = async (dispatch, articleId, loggedIn, page = 1) => {
  try {
    const { data } = await request.get(
      `/api/comment/article/${articleId}` +
      (loggedIn ? "?" : "/guest?") +
      `page=${page}`
    );

    dispatch({
      type: "article/GET_COMMENT",
      payload: {
        comments: data,
        page,
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const likeComment = async (dispatch, commentId) => {
  try {
    await request.put(`/api/comment/like/${commentId}`);
  } catch (e) {
    console.log(e);
  }
};

export const unlikeComment = async (dispatch, commentId) => {
  try {
    await request.put(`/api/comment/like/${commentId}`);
  } catch (e) {
    console.log(e);
  }
};

export const likeReply = async (commentId) => {
  return await axios.put(`/api/comment/like-reply/${commentId}`);
};

export const unlikeReply = async (commentId) => {
  return await axios.put(`/api/comment/unlike-reply/${commentId}`);
};

export const postComment = async (articleId, content) => {
  try {
    await request.post(`/api/comment/article/${articleId}`, { content });

    notification.success({ message: 'success' });
  } catch (e) {
    console.log(e);
  }
};

export const postReply = async (commentId, content) => {
  return await axios.post(`/api/comment/reply/${commentId}`, { content });
};
