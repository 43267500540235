import React from 'react';
import { useTranslation } from "react-i18next";

import './index.css';
import User from './components/User';
import Data from './components/Data';
import Jump from './components/Jump';
import ObtainCard from './components/ObtainCard';

import Menu from '../../components/Menu';

const splash = require('./assets/splash.png');

const Card = () => {
  const { t } = useTranslation();

  return (
    <div className='card-layout'>
      <div className='card-cover'>
        <img src={splash} alt="" />
      </div>
      <div className='card-header'>
        <div>{t('card.title')}</div>
      </div>
      <User />
      <Data />
      <Jump />
      <ObtainCard />
      <Menu select={'card'} />
    </div>
  )
}

export default Card;
