const IdVerificationFailedPopup = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "779px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontSize: "18px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="/左尖角.svg"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "689px",
            left: "20px",
            borderRadius: "100px",
            backgroundColor: "#f2271c",
            width: "335px",
            display: "flex",
            flexDirection: "row",
            padding: "11px 32px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            color: "#fff",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              position: "relative",
              lineHeight: "130%",
              fontWeight: "600",
            }}
          >
            Log Out
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "96px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "40px",
            fontSize: "26px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  borderRadius: "100px",
                  width: "80px",
                  height: "80px",
                  flexShrink: "0",
                  backgroundImage: "url('/示例头像2.png')",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "56.5px",
                    borderRadius: "718.75px",
                    backgroundColor: "#84919a",
                    display: "flex",
                    flexDirection: "row",
                    padding: "2.875px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "17.25px",
                      height: "17.25px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/认证账户角标.svg"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "120%",
                    fontWeight: "600",
                  }}
                >
                  David Doe
                </div>
                <div
                  style={{
                    position: "relative",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#3c464e",
                    display: "inline-block",
                    width: "90px",
                  }}
                >
                  Edit Profile
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "16px",
              color: "#3c464e",
              fontFamily: "Inter",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "28px",
                    height: "28px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/账户设置.svg"
                />
                <div
                  style={{
                    position: "relative",
                    fontWeight: "600",
                    display: "inline-block",
                    width: "82px",
                    flexShrink: "0",
                  }}
                >
                  Account
                </div>
              </div>
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "28px",
                    height: "28px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/语言选择.svg"
                />
                <div
                  style={{
                    position: "relative",
                    fontWeight: "600",
                    display: "inline-block",
                    width: "82px",
                    flexShrink: "0",
                  }}
                >
                  Language
                </div>
              </div>
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "28px",
                    height: "28px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/联系我们.svg"
                />
                <div
                  style={{
                    position: "relative",
                    fontWeight: "600",
                    display: "inline-block",
                    width: "209px",
                    flexShrink: "0",
                  }}
                >
                  Contact Us
                </div>
              </div>
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/右尖角.svg"
              />
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "28px",
                    height: "28px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/身份认证.svg"
                />
                <div
                  style={{
                    position: "relative",
                    fontWeight: "600",
                    display: "inline-block",
                    width: "120px",
                    flexShrink: "0",
                  }}
                >
                  ID Verification
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  fontWeight: "600",
                  color: "#ff8f0b",
                  textAlign: "right",
                  display: "inline-block",
                  width: "100px",
                  flexShrink: "0",
                }}
              >
                In Process
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "-9px",
            left: "-495px",
            backgroundColor: "#1a2024",
            width: "1366px",
            height: "841px",
            overflow: "hidden",
            opacity: "0.5",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "304px",
            left: "20px",
            borderRadius: "6px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
            width: "335px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
            color: "#252c32",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "600",
                    }}
                  >{`ID Verification Failed `}</div>
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/关闭.svg"
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "14px",
                    letterSpacing: "-0.01em",
                    lineHeight: "20px",
                    color: "#5b6871",
                  }}
                >{`Sorry, we cannot verify your ID for {**** Reason}, please try again.`}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
                textAlign: "center",
                fontSize: "14px",
                color: "#0e73f6",
              }}
            >
              <div
                style={{
                  borderRadius: "100px",
                  border: "1px solid #0e73f6",
                  boxSizing: "border-box",
                  height: "39px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Later
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0e73f6",
                  height: "39px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#f6f8f9",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Verify ID
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdVerificationFailedPopup;
