const rightButton = require("../images/rightButton.png")

export default function SettingRow({ title, content, onClick = null }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        padding: "20px",
        borderBottom: "2px solid #EEF0F2",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <span style={{ fontSize: "14px", color: "#3C464E", fontWeight: "600" }}>
          {title}
        </span>
        <span style={{ fontSize: 16, color: "#3C464E" }}>{content}</span>
      </div>
      {onClick && (
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={onClick}
        >
          <img src={rightButton} style={{ width: "20px", height: "20px" }} />
        </button>
      )}
    </div>
  )
}
