import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './index.css';
import { getHomepageVideo, recordVideoView } from './service';
import ReactHlsPlayer from 'react-hls-player';
import CryptoAES from 'crypto-js/aes';

import configs from '../../configs';

const Video = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  const { list, index } = useSelector(state => state.video);

  const playerRef = useRef();

  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const id = location.search.split('=')[1];
    if (id) {
      getHomepageVideo(dispatch, { language: i18n.language, videoId: id });
    } else {
      getHomepageVideo(dispatch, { language: i18n.language });
    }
  }, []);

  const handleBack = () => {
    navigation('/');
  };

  const videoId = list.length > 0 ? list[index].videoHLS[1080].url : '';

  return (
    <div className='video-layout'>
      <div
        className='video-countdown'
      >
        {duration}
      </div>

      <ReactHlsPlayer
        src={videoId}
        autoPlay={true}
        //controls={true}
        width="100%"
        playerRef={playerRef}
        onEnded={async () => {
          if (videoId) {
            const watchTime = duration;

            const message = {
              videoId: list[index]._id,
              watchTime,
            };

            const cipher = CryptoAES.encrypt(
              JSON.stringify(message),
              configs.ARTICLE_READ_KEY,
            ).toString();

            await recordVideoView(dispatch, cipher);

            if (list.length > 1) {
              dispatch({
                type: 'video/PLAY_NEXT',
                payload: (index + 1) % list.length,
              });
            }
          }
        }}
        onLoadedMetadata={() => {
          setDuration(playerRef.current.duration);
        }}
      />
    </div>
  )
}

export default Video;
