const initialState = {
  selectArticles: [],
  banners: [],
  articles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'info/PUT_SELECT_ARTICLES':
      return {
        ...state,
        selectArticles: action.payload,
      };
    case 'info/PUT_BANNERS':
      return {
        ...state,
        banners: action.payload,
      };
    case 'info/PUT_ARTICLES':
      return {
        ...state,
        articles: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
