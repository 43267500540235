const amazonCognitoIdentity = require("amazon-cognito-identity-js")
import configs from "../configs"
const poolData = {
  UserPoolId: configs.AWS_USER_POOL_ID,
  ClientId: configs.AWS_CLIENT_ID,
}
const userPool = new amazonCognitoIdentity.CognitoUserPool(poolData)

export const userLogin = async (
  email,
  password,
  onLoginSuccess,
  onLoginFailure,
  firstTimeLogin = false
) => {
  let USERPOOL = userPool
  const authenticationDetails = new amazonCognitoIdentity.AuthenticationDetails(
    {
      Username: email,
      Password: password,
    }
  )
  const userDetails = {
    Username: email,
    Pool: USERPOOL,
  }
  const cognitoUser = new amazonCognitoIdentity.CognitoUser(userDetails)
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: async (data) => {
      onLoginSuccess(data)
      1
    },
    onFailure: async (err) => {
      console.log(err.code)
      if (err && err.code) {
        onLoginFailure(err)
      } else {
        console.log(
          "An error has occurred. Please check your parameters and try again"
        )
      }
    },
  })
}

export const forgetPassword = async (
  email,
  onSendCodeSucess,
  onSendCodeFail
) => {
  //give code
  let USERPOOL = userPool
  const userDetails = {
    Username: email,
    Pool: USERPOOL,
  }
  const cognitoUser = new amazonCognitoIdentity.CognitoUser(userDetails)
  cognitoUser.forgotPassword({
    onSuccess: async (data) => {
      onSendCodeSucess()
    },
    onFailure: (err) => {
      console.log(err)
      onSendCodeFail(err)
    },
  })
}

export const resetPassword = async (
  email,
  code,
  password,
  onConfirmSuccess,
  onConfirmFailed
) => {
  //changepassword
  let USERPOOL = userPool

  const userDetails = {
    Username: email,
    Pool: USERPOOL,
  }
  const cognitoUser = new amazonCognitoIdentity.CognitoUser(userDetails)
  cognitoUser.confirmPassword(code, password, {
    onSuccess: async () => {
      onConfirmSuccess()
      // console.log('password reset success');
    },
    onFailure: async (err) => {
      console.log(err)
      onConfirmFailed(err)
    },
  })
}
