export default function RegularTextInput({
  value,
  setValue,
  placeHolder,
  errorMessage = "",
  wrapperStyle,
  inputStyle,
  disabled = false,
}) {
  return (
    <div
      style={{
        position: "relative",
        alignSelf: "stretch",
        borderRadius: "6px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #dde2e4",
        display: "flex",
        flexDirection: "row",
        padding: "2px 4px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "16px",
        color: "#1a2024",
        borderColor: errorMessage.length > 0 ? "#f76659" : " #dde2e4",
        ...wrapperStyle,
      }}
    >
      <input
        disabled={disabled}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        style={{
          alignSelf: "stretch",
          borderRadius: "6px",
          backgroundColor: "#f5f5f5",
          border: "1px solid #dde2e4",
          display: "flex",
          flexDirection: "row",
          padding: "2px 4px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "16px",
          color: "#1a2024",
          padding: "8px 16px",
          border: "0",
          flex: 1,
          ...inputStyle,
        }}
        placeholder={placeHolder}
      ></input>
      {errorMessage.length > 0 && (
        <text
          style={{
            position: "absolute",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#f2271c",
            left: 0,
            bottom: -16,
          }}
        >
          {errorMessage}
        </text>
      )}
    </div>
  )
}
