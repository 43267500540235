import request from '../../utlis/request';
import { notification } from 'antd';
import axios from 'axios';

export const getHomepageVideo = async (dispatch, { language, videoId }) => {
  try {
    const URL = '/api/video?' +
      (language ? `language=${language}&` : '') +
      (videoId ? `videoId=${videoId}&` : '');

    const { data } = await request.get(URL);

    dispatch({
      type: 'video/PUT_VIDEO_LIST',
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const recordVideoView = async (dispatch, message) => {
  try {
    const URL = '/api/video/view';

    await request.post(URL, { message });
  } catch (e) {
    console.log(e);
  }
};

export const recordBannerView = async (message) => {
  return axios.post('/api/banner/view', { message });
}
