import { useEffect } from "react"
import { getAppVersion } from "../apis/version"
import { useNavigate } from "react-router-dom"
import configs from "../configs"
const Cover = () => {
  const navigate = useNavigate()
  const init = async () => {
    const { data } = await getAppVersion()
    if (data.data.version === configs.APP_VERSION) {
      navigate("/")
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "667px",
        overflow: "hidden",
        textAlign: "left",
        fontSize: "16px",
        color: "#84919a",
        fontFamily: "Inter",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "85%",
          lineHeight: "140%",
        }}
      >
        <center>The APP helps you to earn anytime</center>
      </div>
      <center>
        <img
          style={{
            position: "relative",
            top: "165px",
            width: "164px",
            height: "184px",
            objectFit: "cover",
          }}
          alt=""
          src="../logo.png"
        />
      </center>
    </div>
  )
}

export default Cover
