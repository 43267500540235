const Income1 = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "894px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "48px",
              height: "20px",
              flexShrink: "0",
            }}
            alt=""
            src="../左尖角.svg"
          />
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
            }}
          >
            提成收益
          </b>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "12px",
            fontSize: "16px",
            color: "#0e73f6",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ position: "relative", fontWeight: "500" }}>
              11月(本月)收益数据
            </div>
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../展开选项-蓝.svg"
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "14px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "223px",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../邀请好友收益.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        邀请好友收益
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          flexShrink: "0",
                          overflow: "hidden",
                          display: "none",
                        }}
                        alt=""
                        src="../duplicate.svg"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213 SP
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "223px",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../邀请好友收益.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        邀请好友收益
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          flexShrink: "0",
                          overflow: "hidden",
                          display: "none",
                        }}
                        alt=""
                        src="../duplicate.svg"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213 SP
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "223px",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../邀请好友收益.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        邀请好友收益
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          flexShrink: "0",
                          overflow: "hidden",
                          display: "none",
                        }}
                        alt=""
                        src="../duplicate.svg"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213 SP
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "223px",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../邀请好友收益.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        邀请好友收益
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "20px",
                          height: "20px",
                          flexShrink: "0",
                          overflow: "hidden",
                          display: "none",
                        }}
                        alt=""
                        src="../duplicate.svg"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213 SP
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Income1;
