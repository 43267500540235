import { message, notification } from 'antd';
import axios from './axios';
import { eraseCookie } from "../cookie";

import configs from "../configs";

axios.defaults.baseURL = configs.API_BASE_URL;

const getConfig = () => {
  return {
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': '*',
      sessionid: localStorage.getItem('sessionid'),
    }
  };
};

const errorHandler = (e) => {
  const message = e.response.data.message;
  notification.error({
    message,
  });

  if (message === 'jwt expired' || message === 'Token Expired') {
    notification.error({
      message: '登录过期，请重新登录',
    });

    localStorage.clear();
    eraseCookie();
    window.open('/', '_self');
  }
};

const get = async (url) => {
  try {
    const result = await axios.get(url, getConfig());
    return result.data;
  } catch (e) {
    errorHandler(e);
  }
};

const post = async (url, body) => {
  try {
    const result = await axios.post(url, body, getConfig());
    return result.data;
  } catch (e) {
    errorHandler(e);
  }
};

const put = async (url, body) => {
  try {
    const result = await axios.put(url, body, getConfig());
    return result.data;
  } catch (e) {
    errorHandler(e);
  }
};

const remove = async (url) => {
  try {
    const result = await axios.delete(url, getConfig());
    return result.data;
  } catch (e) {
    errorHandler(e);
  }
};

export default { get, post, put, remove };
