import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Dropdown from "react-dropdown"
import PasswordInput from "../components/PasswordInput"
import LoginTypeSelector from "../components/LoginTypeSelector"
import VerifyCodeInput from "../components/VerifyCodeInput"
import { checkUserStatus } from "../apis/auth"
import { forgetPassword, resetPassword } from "../cognito"
import { supportedLanguage, supportedAreaCode } from "../constant"
import { backendSendCode, backendResetPassword } from "../apis/auth"
const ForgetPassword1 = () => {
  const navigate = useNavigate()
  const [selectedAreaCode, setSelectedAreaCode] = useState(supportedAreaCode[0])
  const [mode, setMode] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [countdown, setCountdown] = useState(0)
  const cognitoResetPassword = async () => {
    await resetPassword(
      mode === 0 ? selectedAreaCode + phoneNumber : email,
      code,
      password,
      () => {
        console.log("cognito reset password success")
      },
      () => {
        console.log("cognito reset password failed")
      }
    )
  }
  const resetCognitoPassword = async () => {
    await forgetPassword(
      mode === 0 ? selectedAreaCode + phoneNumber : email,
      () => {},
      () => {}
    )
  }
  const onSendCodeClicked = async () => {
    try {
      await backendSendCode(
        mode === 0 ? null : email,
        mode === 1 ? null : selectedAreaCode + phoneNumber,
        "resetPassword"
      )
    } catch (err) {
      console.log(err)
    }
  }
  const onResetPasswordClicked = async () => {
    try {
      const { data } = await checkUserStatus(
        mode === 0 ? selectedAreaCode + phoneNumber : email
      )
      const userMigrated = data.data.authExist
      if (!userMigrated) {
        await cognitoResetPassword()
      } else {
        await backendResetPassword(
          mode === 0 ? null : email,
          mode === 1 ? null : selectedAreaCode + phoneNumber,
          code,
          password,
          repeatPassword
        )
        navigate(-1)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [countdown])
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "'Work Sans'",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "0px",
          left: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 32px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <div
              onClick={() => {
                navigate(-1)
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../返回.svg"
              />
            </div>

            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              Forgot Password
            </b>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "28px",
            color: "#000",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Forgot your password?
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "500",
              }}
            >
              Don’t worry, let’s reset your password now!
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "14px",
              color: "#252c32",
            }}
          >
            <LoginTypeSelector mode={mode} setMode={setMode} />
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {mode === 0 ? "Phone Number" : "Email"}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "2px 4px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  color: "#1a2024",
                  height: "50px",
                }}
              >
                {mode === 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Dropdown
                        options={supportedAreaCode}
                        onChange={setSelectedAreaCode}
                        value={selectedAreaCode}
                        placeholder={supportedAreaCode[0]}
                      />
                    </div>
                    <input
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value)
                      }}
                      style={{
                        alignSelf: "stretch",
                        borderRadius: "6px",
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #dde2e4",
                        display: "flex",
                        flexDirection: "row",
                        padding: "2px 4px",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "16px",
                        color: "#1a2024",
                        border: "0",
                        flex: 1,
                      }}
                      placeholder="Phone Number"
                    ></input>
                  </>
                ) : (
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "6px",
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #dde2e4",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 4px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "16px",
                      color: "#1a2024",
                      border: "0",
                      flex: 1,
                    }}
                    placeholder="Email"
                  ></input>
                )}
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Verification Code
              </div>
              <VerifyCodeInput
                value={code}
                setValue={setCode}
                onSendCodeClicked={onSendCodeClicked}
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <PasswordInput
                placeHolder={"Password"}
                value={password}
                setValue={setPassword}
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Repeat Password
              </div>
              <PasswordInput
                placeHolder={"Repeat Password"}
                value={repeatPassword}
                setValue={setRepeatPassword}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "16px",
          fontSize: "16px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            borderRadius: "100px",
            backgroundColor: "#0e73f6",
            width: "335px",
            display: "flex",
            flexDirection: "row",
            padding: "11px 32px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
              display: "none",
            }}
            alt=""
            src="../group-add-fill0-wght400-grad0-opsz48-1-23.svg"
          />
          <div
            onClick={onResetPasswordClicked}
            style={{
              position: "relative",
              lineHeight: "130%",
              fontWeight: "600",
            }}
          >
            Reset Password
          </div>
        </div>
        <div
          onClick={() => {
            navigate(-1)
          }}
          style={{
            position: "relative",
            lineHeight: "140%",
            fontWeight: "500",
            color: "#0e73f6",
            textAlign: "right",
          }}
        >
          Cancel
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword1

const styles = {
  modeSelectorOuter: {
    selected: {
      backgroundColor: "#0e73f6",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
    unselected: {
      backgroundColor: "transparent",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
  },
  modeText: {
    selected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "white",
    },
    unselected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "#5B6871",
    },
  },
}
