export default function Card(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "100%",
        borderRadius: "12px",
        padding: "18px",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}
