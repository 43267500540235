export default {
  bottomBar: {
    Home: 'Home',
    Card: 'Card',
    Friend1: 'Friends',
    Wallet: 'Wallet',
    Friend: 'Members',
    Task: 'Tasks',
    Profile: 'Profile',
    Read: 'Star News',
  },
  home: {
    login: 'Login to Earn',
    starNews: 'Star News',
    starSelected: 'Star Selected',
    watchAds: 'Watch Ads To Earn',
    moveToEarn: 'Move To Earn',
    moveToEarnTitle: 'Move To Earn Is Coming Soon!',
    moveToEarnDescription:
      'We are working hard on developing move to earn function so you can earn more!',
    verify: 'Verify your ID to secure your account and income.',
    doItNow: 'Do It Now',
  },
  search: {
    title: 'Search',
    search: 'Search',
    trending: 'TRENDING ON FUTURE STAR',
    notFound: 'No articles found',
    notFound1: 'No Results Found',
    notFound2:
      'We did not find anything you searched, try search something else.',
  },
  read: {
    ads: 'Ads',
    duration: '{{ duration }} min read',
    totalComments: 'Responses ({{ amount }})',
    reply: 'Reply',
    comment: 'What are your thoughts?',
    replying: 'Replying to',
    noComment1: 'No Comment Yet',
    noComment2: 'Be the first person who leaves the comments!',
    headlines: 'Star Headlines',
    error1: 'Unable to like! Please try again',
    error2: 'Unable to unlike! Please try again',
    error3: 'Unable to retrieve comments',
    error4: 'Unable to comment! Please try again',
  },
  card: {
    refreshSuccess:
      'Wallet refreshed successfully, your wallet balance will be refreshed within minutes.',
    title: 'Star Card',
    cardList: 'Card List',
    myCards: 'My Cards',
    remaining: '{{ remaining }}/{{ total }} Left',
    expire: '{{ days }} days left',
    valid: 'Expires in {{ days }} days',
    potential: 'Total Earnable: ',
    earned: 'Earned: ',
    cost: 'Cost',
    obtain: 'Obtain',
    task: 'Task',
    step: 'Step',
    combo: 'Old',
    comboCard: 'Old Card',
    stepCard: 'Step Card',
    taskCard: 'Task Card',

    cardDesc_zero: 'New {{ type }} Star Card',
    cardDesc: '{{ star }}-Star {{ type }} Card',
    dailyProd: '({{ dailyProd }} FSC / Day)',
    required: 'REQUIRED FOR CARD',
    totalEarning: 'TOTAL EARNING',
    until: '(UNTIL {{ date }})',
    memberReq: '{{ users }} {{ star }} Star Member',
    obtainCard: 'Obtain Card',
    cardReq: 'CARD REQUIREMENTS',
    noFunds: 'Funds not enough',
    buyCoin: 'Buy Future Star Coin',
    memberReq1: '{{ users }} {{ star }}-star member{{ plural }}',
    memberReq2:
      '{{ users }} {{ star }}-star member{{ plural }}, in {{ users }} separate relationship lines.',
    noMembers: 'No members found',
    invite: 'Invite Friends',
    confirm: 'Confirm And Start Card',
    historyTitle: 'Star Card History',
    sort1: 'Newest First',
    sort2: 'Oldest First',
    sort3: 'Card Star High to Low',
    sort4: 'Card Star Low to High',
    sort5: 'Total Earning High to Low',
    sort6: 'Total Earning Low to High',
    purchased: 'Sucessfully purchased',
    purchasedDesc: 'You have received this star card! Start earning now',
    noCards: 'No Cards Found',
    noHistory: 'No Card History Found',
    noCards2: 'Get more cards to start earning!',
    totalCards: 'Total Cards',
    cardSingle: ' Card',
    cardPlural: ' Cards',
    revenueCap: 'Earning Limit',
    cumulativeIncome: 'Total Income',
    'member-new': 'New Member',
    'member-0': 'New Star Member',
    'member-1': '1 Star Member',
    'member-2': '2 Star Member',
    'member-3': '3 Star Member',
    'member-4': '4 Star Member',
    'member-5': '5 Star Member',
    'member-6': '6 Star Member',
    rules: 'Rules',
    get: 'Obtain Star Card',
    starLeveDesc:
      'Your member level is determined by the highest level Star Card you hold',
    myCards2: 'View My Star Cards',
    spend: 'Spend',
    confirmBuy: 'Do you want to spend {{ cost }} FSC to obtain this star card?',
    obtainedSuccess: 'Obtained Successfully!',
    insufficientFSC: 'Insufficient FSC',
    insufficientMember: 'Insufficient team member qualification',
    confirmBuyDesc:
      'This star card will consume {{ cost }} FSC and you can earn {{ earning }} SP accumulatively in {{ days }} days',
    obtainedSuccessDesc:
      'You have received this star card! Now start making money',
    insufficientFSCDesc:
      'This star card will consume {{ cost }} FSC. The balance of FSC in the wallet is insufficient. Please top-up or complete more tasks to obtain FSC',
    insufficientMemberDesc:
      'To obtain this star card, you need {{ member }} in the team, and you do not have {{ member }}. Invite more friends to develop your team to receive a higher star card!',
    yes: 'Yes',
    okay: 'Okay',
    topUp: 'Top-Up',
    inviteFriends: 'Invite friends',
    cancel: 'Cancel',
    noStarCard: "You don't have a star card yet",
    noStarCardDetail:
      "You haven't owned the star card yet. You can complete the task to get more rewards only after you have the star card! Don't miss the benefits, go and get the star card now!",
    noQualifyCardTitle: "You can't swap FSC yet",
    noQualifyCardContent:
      'You are currently a new star partner and cannot apply for SP swap to FSC. Only users with one-star and above activity star cards can apply for swapping FSC. Upgrade your star card to convert your income now!',
    upgradeCard: 'Upgrade Star Card',
    noQualifyStepCardTitle: "You can't buy a Star card yet",
    noQualifyStepCardContent:
      'You do not currently have the same or higher level of task star card, so you cannot purchase this level of step star card. Please upgrade your task star card now!',
    'rules-0-1': 'Star Level',
    'rules-0-2': 'Max. Quantity',
    'rules-0-3': 'SP Needed',
    'rules-0-4': 'Total Production',
    'rules-0-5': 'Term of Validity',
    'rules-0-6': 'New Star',
    'rules-0-7': '1 Star',
    'rules-0-8': '2 Star',
    'rules-0-9': '3 Star',
    'rules-0-10': '4 Star',
    'rules-0-11': '5 Star',
    'rules-0-12': '6 Star',
    'rules-0-13': 'Free',
    'rules-0-14': '{{ days }} Days',
    'rules-0-15': 'Daily Production',
    'rules-0-16': 'Processing Fee For Swapping SP to SP',
    'rules-0-17': 'Promotion Requirements',

    'rules-1-1':
      'Star cards are divided into step star cards and activity star cards. After receiving the star cards and completing corresponding tasks, you can get rewards.',
    'rules-1-2':
      'Each level of star card can be received unlimitedly, but only one star card can be purchased and held at a time.',
    'rules-1-3':
      'After completing the 30-day task of the star card, you can choose to continue to purchase the star card of the same level or upgrade to a higher-level star card, but the level cannot be lowered after the promotion, and you can only keep the current star level or continue to be promoted. All star cards will be expired after 30 days.',
    'rules-1-4':
      'Star cards are divided into different levels. Each star card is valid for 30 days after receiving it. Counting from the day of collection, the star card will become invalid after 30 days, and the corresponding rewards will not be obtained for completing tasks.',
    'rules-1-5':
      "Any star card level from one star to six stars, when the star card expired, and no new star card is obtained, the team's income will be stopped. But when you purchased new the star card at any time, the team's income will be restored from the same day.",
    'rules-1-6':
      'You only need to consume the corresponding star coins when receiving the new star, one-star, and two-star cards, and you need to meet the promotion assessment when you receive three-star and above star cards.',

    error1: 'The server is busy, please try again later.',
  },
  friend: {
    title: 'Members',
    partner: 'Partner',
    members: 'Members',
    all: 'All Tiers',
    tier: 'Tier {{ tier }}',
    sortBy: 'Sort By',
    totalSharing: 'Total Sharing:',
    dailyTask: 'Today activity task',
    dailyStep: 'Today step task',
    invitaedActivityCommision: ' Level Activity Commission',
    invitaedStepCommision: ' Level Step Commission',
    year: ' Year ',
    month: ' Month',
    incomeData: ' revenue data',
    commisionIncome: 'Commission Income',
    chooseDate: 'Select Date',
    cannotShare:
      'You cannot share benefits from this friend anymore, because you do not have card with same star!',
    invite: 'Invite Member',
    invite2: 'Invite Members',
    earnTogether: 'Earn Together and Earn More',
    earnDesc:
      'Invite more members and help them start the earning, while you can share their benefits when they finish the card!',
    inviteCode: 'Your Invite Code:',
    share: 'Share With Friend',
    noMembers1: 'No Members Found',
    noMembers2:
      'Invite more members and help them start the earning, while you can share their benefits when they get benefits!',
    showingUsers: 'Showing {{ count }} User{{plural}}',

    sort1: 'Card Star High to Low',
    sort2: 'Card Star Low to High',
    sort3: 'Total Sharing High to Low',
    sort4: 'Total Sharing Low to High',

    info1:
      '1. In order to attract more users to join the Futurestar ecosystem, Futurestar users can get up to 10 levels of invitation rewards',
    info2:
      '2. Every time you directly invite a user to complete the registration inviter, you can get a reward of 100 stars.',
    info3: '3. Each newly registered user can get a reward of 100 stars.',
    info4:
      '4. After the invitee completes the task every day, according to the number of star coins obtained, the inviter can get a certain percentage of star coins as rewards.',
    info5: 'The specific algorithm is as follows:',
    info6: 'New Star: Tire 1: 10%',
    info7: '\u2022 1 Star: Tire 1: 10%; Tier 2: 5%',
    info8: '\u2022 2 Star: Tire 1: 10%; Tier 2: 5%, Tier 3: 5%',
    info9: '\u2022 3 Star: Tire 1: 10%; Tier 2: 5%; Tier 3: 5%; Tier 4: 5%',
    info10:
      '\u2022 4 Star: Tire 1: 10%; Tier 2: 5%; Tier 3: 5%; Tier 4: 5%; Tier 5: 3%; Tier 6: 3%',
    info11:
      '\u2022 5 Star: Tire 1: 10%; Tier 2: 5%; Tier 3: 5%; Tier 4: 5%; Tier 5: 3%; Tier 6: 3%; Tier 7: 3%; Tier 8: 1%',
    info12:
      '\u2022 6 Star: Tire 1: 10%; Tier 2: 5%; Tier 3: 5%; Tier 4: 5%; Tier 5: 3%; Tier 6: 3%; Tier 7: 3%; Tier 8: 1%; Tier 9: 1%; Tier 10: 1%',
    info13:
      '5. In order to get the invitation reward, the inviter must have the corresponding star rating. If there is no star rating, the invitation reward cannot be obtained.',
  },
  wallet: {
    spDescription:
      'Star Point (SP) can be exchanged into FSC at 1:1, and FSC can be exchanged into USDT and BNB through the flash exchange function',
    chooseNetwork: 'Choose main network',
    title: 'My Wallet',
    fscBalance: 'FSC Balance',
    usdtBalance: 'USDT Balance',
    bnbBalance: 'BNB Balance',
    view: 'View',
    convertFSC: 'Convert FSC',
    buyFSC: 'Buy FSC',
    history: 'History',
    historyEarn_zero: 'Earning from New Star Card',
    historyEarn: 'Earning from {{ star }}-Star Card',
    historyConvert: 'Convert to USDT',
    historyConvertFrom: 'Convert from FSC',
    historyPurchase: 'Purchase Card',
    withdraw: 'Withdraw',
    withdrawTitle: 'Withdraw USDT',
    convertTitle: 'Convert FSC',
    toWallet: 'To Your Wallet',
    inputAddress: 'Please input receiving address',
    from: 'From',
    to: 'To',
    fscBalance2: 'FSC Balance: {{ balance }}',
    usdtBalance2: 'USDT Balance: {{ balance }}',
    bnbBalance2: 'BNB Balance: {{ balance }}',
    fsc: 'FSC',
    usdt: 'USDT',
    processFee: 'Processing Fee',
    gasFee: 'Gas Fee',
    remainingUSDT: 'Remaining USDT',
    remainingFSC: 'Remaining FSC',
    remaining: 'Remaining {{ currency }}',
    confirm: 'Confirm',
    cancel: 'Cancel',
    gotIt: 'Got It',
    withdrawingUSDT: 'Withdrawing USDT',
    withdrawingUSDTDesc:
      "We are converting USDT for you, you will receive USDT right away once it's done.",
    withdrawing: 'Withdrawing {{ currency }}',
    withdrawingDesc:
      "We are withdrawing {{ currency }} for you, you will receive {{ currency }} right away once it's done.",
    convertingFSC: 'Converting to {{ currency }}',
    convertingFSCDesc:
      "We are converting {{ currency1 }} for you, you will receive {{ currency2 }} right away once it's done.",
    registerBonus: 'Register Bonus',
    invitationBonus: 'Invitation Bonus',
    usdtWithdraw: 'USDT Withdraw',
    reminder:
      'Please be aware that the minumum withdraw amount for {{ currency }} is {{ minimum }}',
    minimum:
      'The minimum withdraw amount for {{ currency }} is {{ minimum }} {{ currency }}.',
    inviteIncome: 'Invitation Income',
    taskIncome: 'Task Income',
    stepIncome: 'Move To Earn Income',
    convert: 'Convert',
    topUp: 'Top Up',
    selectCrypto: 'Select Crypto',
    tether: 'Tether (USDT)',
    binance: 'Binance Coin (BNB)',
    topUpTitle: 'Top Up {{ currency }}',
    topUpDesc:
      'Scan to pay the amount you want to top up, the transaction usually takes around 10 mins. If you do not receive your fund, please contact with our ',
    customerService: 'Customer Service',
    address: 'Address',
    scanTitle: 'Scan QR Code',
    currencyWithdraw: '{{ currency }} Withdraw',
    currencyConvertTo: 'Convert to {{ currencyTo }}',
    currencyConvertFrom: 'Convert from {{ currencyFrom }}',
    enableWithdraw: 'Verify your account to enable withdraw.',
    totalAssets: 'Total Assets',
    topUpWarning:
      'This address is Binance Smart Chain (BSC). Only transfer BSC/BEP20 assets to this address, and you will be personally responsible for the loss of funds caused by wrong transfers.',
    assets: 'Assets List',
    starPoints: 'Star Points',
    exchangeChain: 'Exchange Chain',
    conversionRate: 'Conversion Rate',
    exchangeAmount: 'Exchange Amount',
    convertedAmount: 'Converted Amount',
    paymentAddress: 'Payment Address',
    mainNetwork: 'Main Network',
    withdrawWarningBIANCE:
      'This network is a BSC network. Please ensure that your coin withdrawing address supports the BNB Smart Chain (BEP20) network. Any capital loss caused by incorrect transfer will be borne by you personally.',
    withdrawWarningETHEREUM:
      'This network is a Ethereum network. Please ensure that your coin withdrawing address supports the Ethereum (ERC20) network. Any capital loss caused by incorrect transfer will be borne by you personally.',
    withdrawWarningTRON:
      'This network is a TRON (TRC20) network. Please ensure that your coin withdrawing address supports the TRON (TRC20) network. Any capital loss caused by incorrect transfer will be borne by you personally.',
    withdrawalAmount: 'Withdrawal Amount',
    receivingAmount: 'Receiving Amount',
    withdrawalFee: 'Withdrawal Fee',
    withdrawalArrive:
      'Withdrawals will usually arrive within 10 minutes, if the amount has not arrived within 10 minutes, please contact our customer service.',
    withdraw2: 'Withdraw',
    minimumInput: 'Minimum value: ',
    remainingBalance: 'Remaining balance: ',
    all: 'All',
    totalIncome: 'Total Income',
    myStarPoints: 'My Star Points',
    todayTotalIncome: 'Total Income Today: ',
    exchangeFSCP: 'Exchange to FSC',
    seeMore: 'See More',
    starPointHistory: 'Star Point History',
    month: '{{ month }} Records',
    totalStarPointIncome: 'Total Star Point Income',
    totalExchanged: 'Total Exchanged SP',
    income: 'Income',
    expense: 'Expenses',
    thisMonth: 'This Month',
    selectedMonth: 'Select Month',
    starPointRedeem: 'Redeem Star Points',
    redeemAmount: 'Redeem Amount',
    inputStarPoint: 'Input Star Point',
    availableStarPoint: 'Available Balance',
    minStarPointRequest: 'Min Convert Amount:500SP',
    fscObtained: 'FSC Obtained',
    fscpConvertStatus:
      'You are currently a {{ member }} and the exchange fee for Star Points is {{ fee }}%',
    feeRules1: 'Upgrading your member level can reduce your fee. See ',
    feeRules2: 'Fee Rules',
    confirmExchange: 'Confirm Exchange',
    fscp: 'SP',
    exchangeComplete: 'SP Conversion Success!',
    exchangeCompleteDesc1:
      'We will audit within 24 hours. Please be patient. After the audit is passed, ',
    exchangeCompleteDesc2: ' will be deposited into your wallet.',
    goToWallet: 'Go to Wallet',
    understood: 'Understood',
    convertDetails: 'Convert Details',
    successConvert: 'Conversion Successful',
    currencyConverted: 'Currency Converted',
    convertAmount: 'Convert Amount',
    currencyReceived: 'Currency Received',
    receivedAmount: 'Received Amount',
    conversionDate: 'Conversion Date',
    transactionDetails: 'Transaction Details',
    successWithdraw: 'Withdraw Successful',
    sendingAddress: 'Sending Address',
    receivingAddress: 'Receiving Address',
    transactionHash: 'Transaction Hash',
    transactionDate: 'Transaction Date',
    spConversionRule:
      'SP converting will be processed manually within 24 hours，If it is not received for a long time, you can contact the customer service for processing.',
    spPending: ' (Pending)',
    spFailed: ' (Declined)',
    spSuccess: ' (Success)',
    spError: ' (Error)',
    spSendFailed: ' (Failed)',
    error1: 'Record unavailable',
  },
  profile: {
    commissionUnlocked: 'Unlocked commission income',
    popupDetail1:
      'You have successfully unlocked commission income SP, your current team power is',
    popupDetail2: 'of the total commission income',
    popupDetail3:
      'Your unlocked commission will be automatically credited to your asset wallet.',
    power: 'Power',
    locked: 'Locked',
    unlocked: 'Unlocked',
    commissionEarned: 'Commission income',
    totalCommissionEarned: 'Total commission income',
    totalUnlockableCommission: 'Total unlockable commission income',
    unlockedCommission: 'Unlocked commission',
    currentTeamPower: 'Current team power',
    teamPower: 'Team power value',
    toUnlockRatio: 'Corresponding unlock ratio',
    unlockDetails:
      'Unlock ratio each time: depends on the team power of the invited user. The higher the team power, the higher the unlock ratio each time.',
    teamUserSP: 'Lower-level user star rating',
    unlockTimeDescribe:
      'Each invitation to a one-star partner can obtain one unlock opportunity',
    powerIncreasable: 'Power value increasable',
    oneStar: 'One star',
    twoStar: 'Two stars',
    threeStar: 'Three stars',
    fourStar: 'Four stars',
    fiveStar: 'Five stars',
    sixStar: 'Six stars',
    powerToUnlockRatio: 'Unlock ratio corresponding to team power value',
    includes: 'Includes',
    above: 'Above',
    unlockSP: 'Unlock',
    unlockTimeLeft: 'Remaining unlock times',
    taskActivityCommission: 'Task activity commission',
    stepActivityCommission: 'Step activity commission',
    spUnlock: 'SP unlock',
    userLoginPassword: 'User login password',
    resetPaymentPassword: 'Retrieve payment password',
    repeatNewPaymentPassword: 'Repeat new payment password',
    newPaymentPassword: 'New payment password',
    currentPaymentPassword: 'Current payment password',
    changePaymentPassword: 'Modify payment password',
    paymentPassword: 'Payment password',
    aboutUs: 'About Us',
    companyIntro:
      'FUTURE STAR GROUP is a company that builds an open metaverse through NFT and innovative media ecology. Future Star was founded in Silicon Valley in the United States. At the same time, it has more than 400 R&D and operation teams in Singapore, Japan, Switzerland and other places. More than 70% of the team members graduated from the famous Ivy League schools in the United States. Future Star has established the company as one of the world\'s leading technology groups in blockchain and gaming by pioneering and innovating "decentralized" in-game rewards and monetization of video games. \r\n\nFuture Star is based on the overall framework of X to earn: through the mechanism of running check-in management, it advocates a new way of green life with move to earn, and achieves the goal of global carbon neutrality; watch and engage to earn, pay attention to realization, media paradigm innovation, trend The new ecology of web3 metaverse media has perfectly realized the transformation from traditional media to digital media. With the corporate brand concept of "committing to every brand to be remembered by the world", the "300 million fans drainage plan" was launched, and a community fan group was established to share industry benefits globally. Future Star focuses on building a Web3.0 meta-universe advertising ecology, and realizes a closed loop from strategic design to user data, marketing tools, and creative communication, thereby inspiring the infinite future of the advertising industry. \r\n\nFuture Star has received 140 million US dollars in financing from well-known institutions such as Metaverse Tech, INK, Sequoia India and MW9. The company has more than 100 core patents in the media field, as well as the Dubai digital currency exchange license and the US MSB digital currency. core qualifications such as trading licenses.',
    editProfile: 'Edit Profile',
    account: 'Account',
    language: 'Language',
    logOut: 'Log Out',
    changeLanguage: 'Change Language',
    changPassword: 'Change password success',
    resetPassword: 'Reset Password',
    resetPassDesc: 'Please reset your password',
    currentPass: 'Current Password',
    newPass: 'New Password',
    repeatPass: 'Repeat Password',
    email: 'Email',
    name: 'Name',
    password: 'Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    changePhoto: 'Change Photo',
    yourUsername: 'Your User Name',
    save: 'Save',
    phoneNumber: 'Phone Number',
    contactUs: 'Contact Us',
    contactTelegram: 'Contact Us On Telegram',
    joinTelegram: 'Or Join Telegram Group',
    contactWhatsApp: 'Contact Us On WhatsApp',
    joinWhatsApp: 'Or Join WhatsApp Group',
    idVerif: 'ID Verification',
    confirmInfo: 'Confirm Information',
    confirmInfoDesc:
      'The name and date of birth below must match your government-issued ID. Please double check the information below before continuing the verification process.',
    firstName: 'First Name',
    lastName: 'Last Name',
    dateOfBirth: 'Date of Birth',
    inputFirstName: 'Input First Name',
    inputLastName: 'Input Last Name',
    chooseCountry: 'Choose issuing country/region',
    selectIdType: 'Select ID Type',
    inputIdNumber: 'Input ID Number',
    inputNumber: 'Input Your Number Here',
    uploadPhoto: 'Upload ID Photo',
    uploadFile: 'Upload File',
    redo: 'Redo',
    upload: 'Upload',
    idSubmitted: 'ID Verification Submitted!',
    weWillReview:
      'We will review your ID as soon as we have it. Please wait patiently, and we will let you know if it is approved!',
    next: 'Next',
    close: 'Close',
    idCard: 'ID Card',
    passport: 'Passport',
    driver: "Driver's License",
    inProgress: 'In Progress',
    idVerified: 'ID Verified',
    idUnVerified: 'ID Unverified',
    failed: 'Failed',
    failedTitle: 'ID Verification Failed',
    failedDesc:
      'Sorry, we cannot verify your ID because {{ reason }}. Please try again.',
    verifyId: 'Verify ID',
    later: 'Later',
    personalCenter: 'Personal Center',
    checkedIn: 'Checked In',
    checkIn: 'Check In',
    currentRating: 'Current: ',
    viewStarCard: 'View Star Card',
    wallet: 'My Wallet',
    details: 'Details',
    totalEarning: 'Total Earning',
    totalBalance: 'Total Balance',
    members: 'My Members',
    totalPeople: 'Total People',
    addedToday: 'Invited Today',
    withdraw: 'Withdraw',
    settings: 'Settings',
    verified: 'Verified',
    basicInfo: 'Basic Info',
    uploadID: 'Upload ID',
    submit: 'Submit',
    'verify-1':
      'Please ensure that the real identity is used, otherwise the authentication will fail. The information you submitted is only used for Future Star authentication, and we will encrypt your information.',
    'verify-2':
      '* The information must be consistent with the information on the certificate',
    uploadPhoto2: 'Upload personal signature and ID front photo',
    uploadID2: 'Upload ID',
    uploadFront: "Upload ID's Front",
    uploadBack: "Upload ID's Back",
    uploadSignature: 'Upload personal signature and ID front photo',
    'front-desc':
      'During shooting, ensure that the certificate frame is complete, the font is clear and the brightness is uniform.',
    'back-desc':
      'During shooting, ensure that the certificate frame is complete, the font is clear and the brightness is uniform.',
    'sign-desc':
      'Please upload a photo of yourself holding the "front of the certificate" and "personal signature". The content of the personal signature needs to include "Future Star" and the date of the day. Please ensure that the content of the photo is clearly visible.',
    standard: 'Standard',
    lostEdge: 'Lost Edge',
    blurry: 'Blurry',
    overExposure: 'Over Exposure',
    skip: 'Skip',
    prev: 'Previous',
    next2: 'Next',
    understood: 'Understood',
    uploadFront2: 'Upload Front',
    uploadBack2: 'Upload Back',
    uploadPhoto3: 'Upload Photo',

    error1: 'This ID has been used for verification, please use another one.',
    error2: 'The server is busy, please try again later.',
  },
  auth: {
    inputPaymentPassword: 'Please enter payment password',
    success1: 'The payment password is set successfully',
    setPayPasswordDiscribe:
      'In order to better protect your assets, please set up a payment password for payment, transfer, and cash withdrawal.',
    setPayPassword: 'Set Payment Password',
    forgotPassword: 'Forgot Password',
    forgotTitle: 'Forgot Your Password?',
    forgotDesc: "Don't worry, let's reset your password now!",
    email: 'Email Address',
    verificationCode: 'Verification Code',
    resetTitle: 'Reset Password',
    resetDesc: 'Please reset your password',
    password: 'Password',
    repeatPass: 'Repeat Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    resetPassword: 'Reset Password',
    loginTitle: 'Welcome Back',
    loginDesc: 'Come back and restart your earning today!',
    forgotQ: 'Forgot Password?',
    signup: 'Sign Up',
    signUpTitle: 'Set Your Login Info',
    signUpDesc: 'Please give us your login info first',
    inputEmail: 'Input Email Address',
    inputPass: 'Input Password',
    inputVerification: 'Input Verification Code',
    inputInvite: 'Input Invitation Code',
    inputUsername: 'Input User Name',
    sendCode: 'Send Code',
    readAgree: 'I have read carefully and agree with ',
    userAgree: 'User Agreement ',
    and: 'and ',
    privacyPolicy: 'Privacy Policy',
    haveAccount: 'I already have an account',
    noAccount: 'I do not have an account',
    personalTitle: 'Your Personal Info',
    personalDesc: 'Please verify email for your security',
    username: 'Your User Name',
    invitation: 'Invitation Code',
    letsEarn: "Let's Earn!",
    login: 'Log In',
    passChangeSuccess: 'Password has been changed',
    phone: 'Phone',
    phone2: 'Phone Number',
    inputPhone: 'Input Phone Number',
    email2: 'Email',
    codeTitle: 'Confirm Your\nVerification Code',
    codeDescPhone: 'We sent a code to your phone number. Please verify.',
    codeDescEmail: 'We sent a code to your email. Please verify.',
    resendCode: 'Resend Code',
    captcha: 'Please answer CAPTCHA',
    bannedTitle: 'Something is wrong with your account',
    bannedDesc:
      'Sorry, we found that your acocunt is abnormal. Account has been temporarily banned. If you have any objection, please contact customer service',
    bannedConfirm: 'Contact Customer Service',
    understood: 'Understood',
    continue: 'Continue',
    signupComplete: 'You Are Registered!',
    pleaseLogin:
      'Your phone number has been successfully registered, please log in to your account to continue',
    okay: 'Okay',

    error1: 'User does not exist',
    error2:
      'Your password was wrong or this account has not been registered. Please try again.',
    error3: "Password don't match",
    error4: 'Must be at least 8 characters',
    error5: 'User already exists',
    error6: 'Incorrect code',
    error7: 'The server is busy, please try again later.',
    error8: 'Invalid code',
    error9: 'Authentication Error! Please login again.',
    error10: 'This phone number has been registered. Please login',
    error11: 'This email has been registered. Please login',
    error12: 'Please input',
    error13: 'Incorrect CAPTCHA',
    error14: 'User has not completed sign up. Please go sign up.',
    error15: 'This account has not been registered. Please go sign up.',
    error16: 'Your password was wrong. Please try again.',
  },
  activity: {
    title: 'Activity',
    subTitle: "Finish task to earn today's benefits",
    youCanEarn: 'You Can Earn',
    yourStarCard: 'With your star cards',
    viewCard: 'View Cards',
    calculateionMethod: 'Activity Calculation Methods',
    method1:
      'Browsing articles can get 2 activity points per minute. (Unlimited)',
    method2:
      'Like 1 article to get 5 activity points. (The upper limit of activity is 30)',
    method3:
      'Browse 1 advertisement and get 15 activity. (The upper limit of activity points is 45)',
    method4:
      'Comment or Reply article to get 10 activity points. (The upper limit of activity points is 30)',
    method5:
      'Browsing videos can get 10 activity per minute. (The upper limit of activity is 30)',
    complete25: '25 Activity points earned! Hurry Up!',
    complete50: '50 Activity points earned! Half Way!',
    complete75: '75 Activity points earned! Almost There!',
    completeTitle: 'Congrats! You have earned!',
    completeDesc: 'You have reached 100 activity points, your earning is:',
    completeFooter: 'Your earning will be sent to your wallet within 10 mins',
    noCard:
      'You do not have any star cards yet, you have to get a star card to gain activity points.',
    getCard: 'Get Star Cards Now',
  },
  language: {
    set: 'Set Your Language',
    ready: "I'm Ready",
  },
  word: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    share: 'Share',
    copy: 'Copy',
    Zero: 'Zero',
    One: 'One',
    Two: 'Two',
    Three: 'Three',
    Four: 'Four',
    Five: 'Five',
    Six: 'Six',
    Seven: 'Seven',
    Eight: 'Eight',
    Nine: 'Nine',
  },
  videoAds: {
    next: 'Next',
    finish: 'Finish',
    goBackTitle: 'Are you sure to go back?',
    goBackContent: 'You will lose the reward for unfinished video.',
  },
  oldVersion: {
    text1: 'We found that you are using an old version of the Future Star app.',
    text2: 'You can download the latest version at:',
    text3: 'New version discovered!',
    text4: 'Update',
    text5: 'APK download has started',
    text6:
      'Download Complete. Pleased check your downloads and install the new version of the Future Star app.',
  },
  fitness: {
    modalGoogle:
      'Allow Future Star to access Activity For "Move To Earn" function!',
    modalGoogle2: 'You must have Google Fit App installed first!',
    modalApple:
      'Allow Future Star to access Core Motion For "Move To Earn" function!',
    modalDesc:
      'We need to track your steps so that you can earn benefits from our Move To Earn Function!',
    confirm: 'Yes',
    deny: 'No, thanks',
    title: 'Move To Earn',
    steps: 'Steps',
    keepMoving: 'Keep moving to earn {{ amount }} SP',
    complete: 'Congrats! You have earned {{ amount }} SP',
    tired: 'Getting Tired? Watch Ads to keep earning',
    viewHistory: 'View Move To Earn History',
    finished: 'Finished',
    warning:
      'We will not count your steps if you close this app. Minimizing is allowed.',
    authorizeTitle: 'Allow Future Star Access Your Data',
    authorizeDesc:
      'In order to better help you complete your daily walking task, you need to authorize Future Star to access the Motion and Fitness permissions of your mobile phone, which can be authorized in ',
    authorizeIOS: 'Phone Settings > Privacy & Security > Motion and Fitness',
    authorizeAndroid: 'Phone Settings > Apps > Future Star > Permissions',
    authorizeGo: 'Go Authorize',
    skip: 'Skip',
  },
  countries: {
    select: 'Select Country',

    china: 'China',
    us: 'United States',
    canada: 'Canada',
    dominican: 'Dominican Republic',
    costa: 'Costa Rica',
    honduras: 'Honduras',
    nicaragua: 'Nicaragua',
    mexico: 'Mexico',
    austria: 'Austria',
    belgium: 'Belgium',
    bulgaria: 'Bulgaria',
    croatia: 'Croatia',
    czech: 'Czech Republic',
    denmark: 'Denmark',
    germany: 'Germany',
    estonia: 'Estonia',
    finland: 'Finland',
    france: 'France',
    greece: 'Greece',
    hungary: 'Hungary',
    iceland: 'Iceland',
    ireland: 'Ireland',
    italy: 'Italy',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    latvia: 'Latvia',
    malta: 'Malta',
    netherlands: 'Netherlands',
    norway: 'Norway',
    poland: 'Poland',
    portugal: 'Portugal',
    romania: 'Romania',
    spain: 'Spain',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    uk: 'United Kingdom',
    brazil: 'Brazil',
    venezuela: 'Venezuela',
    malaysia: 'Malaysia',
    singapore: 'Singapore',
    indonesia: 'Indonesia',
    vietnam: 'Vietnam',
    turkey: 'Turkey',

    anguilla: 'Anguilla',
    antiguaBarbuda: 'Antigua and Barbuda',
    aruba: 'Aruba',
    ascension: 'Ascension',
    bahamas: 'Bahamas',
    barbados: 'Barbados',
    belize: 'Belize',
    bermuda: 'Bermuda',
    caymanIslands: 'Cayman Islands',
    costaRica: 'Costa Rica',
    cuba: 'Cuba',
    curacao: 'Curaçao',
    dominica: 'Dominica',
    dominicanRepublic: 'Dominican Republic',
    elSalvador: 'El Salvador',
    greenland: 'Greenland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guatemala: 'Guatemala',
    haiti: 'Haiti',
    jamaica: 'Jamaica',
    martinique: 'Martinique',
    montserrat: 'Montserrat',
    panama: 'Panama',
    peurtoRico: 'Peurto Rico',
    kittsNevis: 'Saint Kitts and Nevis',
    stLucia: 'Saint Lucia',
    pierreMiquelon: 'Saint Pierre and Miquelon',
    vincentGrenadines: 'Saint Vincent and the Grenadines',
    trinidadTobago: 'Trinidad and Tobago',
    turksCaicos: 'Turks and Caicos Islands',
    virginBritish: 'Virgin Islands, British',
    virginUS: 'Virgin Islands, U.S.',
    afghanistan: 'Afghanistan',
    armenia: 'Armenia',
    azerbaijan: 'Azerbaijan',
    bahrain: 'Bahrain',
    bangladesh: 'Bangladesh',
    bhutan: 'Bhutan',
    brunei: 'Brunei Darussalam',
    cambodia: 'Cambodia',
    timor: 'Timor-Leste',
    hongKong: 'Hong Kong',
    india: 'India',
    iran: 'Iran',
    iraq: 'Iraq',
    israel: 'Israel',
    japan: 'Japan',
    jordan: 'Jordan',
    northKorea: 'North Korea',
    southKorea: 'South Korea',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    laos: 'Laos',
    lebanon: 'Lebanon',
    macao: 'Macao',
    maldives: 'Maldives',
    mongolia: 'Mongolia',
    myanmar: 'Myanmar',
    nepal: 'Nepal',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palestine: 'Palestine, State of',
    philippines: 'Philippines',
    qatar: 'Qatar',
    russia: 'Russia',
    saudiArabia: 'Saudi Arabia',
    sriLanka: 'Sri Lanka',
    syria: 'Syria',
    taiwan: 'Taiwan',
    tajikistan: 'Tajikistan',
    thailand: 'Thailand',
    turkiye: 'Türkiye',
    turkmenistan: 'Turkmenistan',
    unitedArab: 'United Arab Emirates',
    uzbekistan: 'Uzbekistan',
    yemen: 'Yemen',
    albania: 'Albania',
    andorra: 'Andorra',
    belarus: 'Belarus',
    bosnia: 'Bosnia and Herzegovina',
    canary: 'Canary Islands',
    cyprus: 'Cyprus',
    faroe: 'Faroe Islands',
    gibraltar: 'Gibraltar',
    kosovo: 'Kosovo',
    liechtenstein: 'Liechtenstein',
    moldova: 'Moldova',
    monaco: 'Monaco',
    montenegro: 'Montenegro',
    macedonia: 'Republic of North Macedonia',
    sanMarino: 'San Marino',
    serbia: 'Serbia',
    slokavia: 'Slokavia',
    slovenia: 'Slovenia',
    ukraine: 'Ukraine',
    vatican: 'Vatican City',
    argentina: 'Argentina',
    bolivia: 'Bolivia',
    chile: 'Chile',
    colombia: 'Colombia',
    ecuador: 'Ecuador',
    falkland: 'Falkland Islands',
    frenchGuiana: 'French Guiana',
    guyana: 'Guyana',
    paraguay: 'Paraguay',
    peru: 'Peru',
    suriname: 'Suriname',
    algeria: 'Algeria',
    angola: 'Angola',
    benin: 'Benin',
    botswana: 'Botswana',
    burkinaFaso: 'Burkina Faso',
    burundi: 'Burundi',
    cameroon: 'Cameroon',
    capeVerde: 'Cape Verde',
    centralAfrica: 'Central Africa',
    chad: 'Chad',
    comoros: 'Comoros',
    congo: 'Congo',
    congoDem: 'Congo, Democratic Republic of the',
    djibouti: 'Djibouti',
    egypt: 'Egypt',
    equatorialGuinea: 'Equatorial Guinea',
    eritrea: 'Eritrea',
    ethiopia: 'Ethiopia',
    gabon: 'Gabon',
    gambia: 'Gambia',
    ghana: 'Ghana',
    guinea: 'Guinea',
    guineaBissau: 'Guinea-Bissau',
    ivoryCoast: 'Ivory Coast',
    kenya: 'Kenya',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    mali: 'Mali',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    morocco: 'Morocco/Western Sahara',
    mozambique: 'Mozambique',
    namibia: 'Namibia',
    niger: 'Niger',
    nigeria: 'Nigeria',
    mayotte: 'Mayotte',
    rwanda: 'Rwanda',
    saoTome: 'Sao Tome and Principe',
    seychelles: 'Seychelles',
    sierraLeone: 'Sierra Leone',
    somalia: 'Somalia',
    southAfrica: 'South Africa',
    southSudan: 'South Sudan',
    sudan: 'Sudan',
    swaziland: 'Swaziland',
    tanzania: 'Tanzania',
    togo: 'Togo',
    tunisia: 'Tunisia',
    uganda: 'Uganda',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe',
    americanSamoa: 'American Samoa',
    australia: 'Australia',
    cookIslands: 'Cook Islands',
    fiji: 'Fiji',
    frenchPolynesia: 'French Polynesia',
    guam: 'Guam',
    kiribati: 'Kiribati',
    marshallIslands: 'Marshall Islands',
    micronesia: 'Micronesia',
    newCaledonia: 'New Caledonia',
    newZealand: 'New Zealand',
    niue: 'Niue',
    norfolk: 'Norfolk Island',
    marianaIslands: 'Northern Mariana Islands',
    palau: 'Palau',
    papua: 'Papua New Guinea',
    samoa: 'Samoa',
    solomon: 'Solomon Islands',
    tonga: 'Tonga',
    tuvalu: 'Tuvalu',
    vanuatu: 'Vanuatu',
    wallisFutuna: 'Wallis and Futuna',
    georgia: 'Georgia',
    uruguay: 'Uruguay',
    senegal: 'Senegal',
  },
  reason: {
    alreadyExists: 'your ID already exists in our system',
    doesNotMatch: 'your information does not match the picture',
    invalidPicture: 'your uploaded picture could not be recognized',
  },
  homeV1: {
    upgradeStar: 'Obtain a star card to earn rewards',
    activityTitle: 'Activity Task',
    activityDesc: 'Reach 100 activity points to earn {{ FSC }} SP',
    moveToEarn: 'Steps Task',
    moveToEarnDesc: 'Walk 4000 steps to earn {{ FSC }} SP',
    checkIn: 'Check In',
    headlines: 'Headlines',
    wheel: 'Wheel',
    lottery: 'Lottery',
    pet: 'Star Pets',
    recommended: 'Suggest For You',
    inviteFriends: 'Invite Friends To Earn!',
    inviteFriendsDesc: 'Get USDT by inviting new users',
    watchNews: 'Read to Earn',
    watchNewsDesc: 'To know the world',
    watchAds: 'Watch to Earn',
    watchAdsDesc: 'Everything you like is here',
    walking: 'Move to Earn',
    walkingDesc: 'Exercise never stops',
    starPet: 'Star Pets',
    starPetDesc: 'Adopt your NFT pet',
    welcome: 'Welcome to Future Star!',
    welcomeDesc:
      'You can get benefits only after you log in and get your star card!',
    loginFirst: 'Please Login to Future Star First!',
    loginFirstDesc:
      'Log in to Future Star, enjoy your membership and open more functions. Receive star card and share millions of income!',
    loginNow: 'Login Now',
    comingSoon: 'This Function is Under Development',
    comingSoonDesc:
      'We are working hard to develop this function. Please keep an eye on Future Star and you can experience this function soon!',
  },
  tasks: {
    myTasks: 'My Tasks',
    totalCurrentIncome: 'Total Income Today',
    totalEarning: 'The upper limit of current income is {{ FSC }} SP',
    upgradeStar: 'Upgrade star card to get higher rewards',
    activityTaskIncome: 'Activity Task Income',
    walkToEarnIncome: 'Steps Task Income',
    dailyCheckin: 'Daily Check-In',
    checkInRules: 'Rules',
    day1: 'Day 1',
    day2: 'Day 2',
    day3: 'Day 3',
    day4: 'Day 4',
    day5: 'Day 5',
    day6: 'Day 6',
    day7: 'Day 7',
    consecutiveDays1: 'You have checked-in for ',
    consecutiveDays2: ' day{{ plural }}.',
    cumulativeEarning: 'Total earned ',
    signInNow: 'Check In Now',
    beginnerTask: 'New User Tasks',
    earn: 'Earn {{ FSC }} SP!',
    checkOut: 'Read new user tutorial article',
    viewIntro: 'Watch Future Star introduction video',
    completeVerification: 'Complete ID verification',
    receiveStarCard: 'Obtain a Star Card',
    commissionIncome: 'Commission income transfer',
    completeActivity: 'First time reach 100 activity points',
    completeWalk: 'First time finish 4000 steps',
    completeInvite: 'First time invite new user to sign up',
    completeConvert: 'First time swap FSC to USDT',
    completeWithdraw: 'First time withdraw USDT',
    topUp: 'First time top-up funds',
    reward: 'Reward',
    completed: 'Completed',
    toFinish: 'Go',
    congratulations: 'Congratulations',
    signinSuccess: 'Sign in successful! You have received your reward.',
    getStarCard: 'Please Get Star Card First',

    getStarCardDesc: 'You can only get benefits after receiving a star card!',
    getItNow: 'Get It Now',
    noTaskCardTitle: 'You don’t have activity cards yet',
    noStepCardTitle: 'You don’t have step cards yet',

    noCardSubTitle: 'Obtain your star card to gain benefits!',

    dailyRule1:
      '1. Each round of daily check-in is up to 7 days and corresponding rewards can be obtained after successfully checking in every day.',
    dailyRule2:
      '2. You need to check in consecutively to get all the rewards. If you miss a day, your streak will restart.',
    dailyRule3:
      '3. After 7 consecutive days of check-in is completed, daily check-in will start from the first day again.',
    dailyRule4:
      'Check-in reward: {{ 0 }} SP on the first day, {{ 1 }} SP on the second day, {{ 2 }} on the third day, {{ 3 }} on the fourth day, {{ 4 }} on the fifth day, {{ 5 }} on the sixth day, and {{ 6 }} on the seventh day.',
    cardRule:
      'The upper limit of daily SP earning is determined by the number of and level of your star cards. If you want to earn more, please go to the Star Card page and get more star cards',
  },
  axios: {
    error1:
      'You have network issues. Please use the app in a strong network environment.',
    error2: 'Session expired, please log in again.',
    error3: 'The server is busy, please try again later.',
    error4: 'Old password wrong.',
    error5: 'Two new passwords not same.',
    error6: 'New password can not be empty.',
  },
  invite: {
    january: 'Jan',
    february: 'Feb',
    march: 'Mar',
    april: 'Apr',
    may: 'May',
    june: 'Jun',
    july: 'Jul',
    august: 'Aug',
    september: 'Sep',
    october: 'Oct',
    november: 'Nov',
    december: 'Dec',
    title: 'Invite Friends',
    catchphrase: 'Download and Register to Earn 28 USDT',
    inviteFriend: 'Invite Friend',
    inviteCode: 'Invitation Code',
    myInvite: 'My Invitation',
    person: 'Person',
    people: 'People',
    teamMembers: 'Team Members',
    totalIncome: 'Total Invitation Income',
    totalCommission: 'Total Commision',
    lostIncome: 'Lost Income',
    todayData: "Today's Data",
    todayInvite: "Today's Invitation",
    inviteRewards: 'Invitation Rewards',
    todayCommission: "Today's Commission",
    todayLost: "Today's Lost",
    viewInviteRank: 'View Invitation Rank',
    threeSteps: 'Three Steps To Invite Friend',
    step1: 'Share invitation poster',
    step2: 'Sign up to Future Star',
    step3: 'Finish ID verification',
    howToEarn: 'How to Earn',
    part1: 'Part I',
    'part1-desc-1':
      'After inviting friends to finish ID verification, you can immediately get ',
    'part1-desc-2': '',
    'part1-desc-3': '',
    'part1-desc-4': ' rewards',
    part2: 'Part II',
    'part2-desc-1': 'After a friend completes the ',
    dailyActivityTask: 'Daily Activity Task',
    'part2-desc-2':
      ', you can get a commission reward. The amount of reward is determined by the friend level and your star card level. For details, you can view the earning strategy',
    part3: 'Part III',
    'part3-desc-1': 'After a friend completes the ',
    dailyWalkingTask: 'Daily Walking Task',
    'part3-desc-2':
      ', you can get a commission reward. The amount of reward is determined by the friend level and your star card level. For details, you can view the earning strategy',
    howToInvite: 'How To Invite',
    inviteStep1:
      'After your friends login to Future Star App via the link/QR code you shared, they need to complete ID verification to be counted as a successful invite, otherwise it is regards as an invalid invitation and the invitation rewards will not be obtained.',
    inviteStep2:
      'Your friend needs to fill in your invitation code when registering to establish an invitation relationship',
    inviteStep3:
      'Share with family, friends, classmates and colleagues and the succress rate of invitation is higher',
    lostIncomeDesc:
      "If your lower tier partner's star card level is higher than you, you can only get the commission award according to your star card level. The exceed part of the commission award will be discarded. If you want to get all the commission income, please try to keep your partner's level higher than or equal to all your lower level partners",
    rules: 'Rules',
    close: 'Close',
    rulesTitle: 'Rules for inviting friends',
    'rules-0-1':
      'Invitation relationships can be up to 10 levels in the Future Star App.',
    'rules-0-2':
      'Each new user can get 100 SP reward after completing the ID verification.',
    'rules-0-3':
      'After the invitee completes the task every day, according to the amount of  SP they get, the inviter can get a certain percentage of star coins as rewards. The reward is issued at Coordinated Universal Time (UTC) at 06:00 AM every day.',
    'rules-0-4':
      'After the invitee completes the task every day, according to the amount of  SP they get, the inviter can get a certain percentage of star coins as rewards. The reward is issued at Coordinated Universal Time (UTC) at 06:00 AM every day.',
    'rules-1-1': 'Star Level',
    'rules-1-2': "Percentage that you share from your friend's earning",
    'rules-1-3-0': 'New Star Partner',
    'rules-1-3-1': 'Tier 1: 10%',
    'rules-1-4-0': '1 Star Partner',
    'rules-1-4-1': 'Tier 1: 10%、Tier 2: 5%',
    'rules-1-5-0': '2 Star Partner',
    'rules-1-5-1': 'Tier 1: 10%、Tier 2: 5%、Tier 3: 5%',
    'rules-1-6-0': '3 Star Partner',
    'rules-1-6-1': 'Tier 1: 10%、Tier 2: 5%、Tier 3: 5%、Tier 4: 5%',
    'rules-1-7-0': '4 Star Partner',
    'rules-1-7-1':
      'Tier 1: 10%、Tier 2: 5%、Tier 3: 5%、Tier 4: 5%、Tier 5: 3%、Tier 6: 3%',
    'rules-1-8-0': '5 Star Partner',
    'rules-1-8-1':
      'Tier 1: 10%、Tier 2: 5%、Tier 3: 5%、Tier 4: 5%、Tier 5: 3%、Tier 6: 3%、Tier 7: 3%、Tier 8: 1%',
    'rules-1-9-0': '6 Star Partner',
    'rules-1-9-1':
      'Tier 1: 10%、Tier 2: 5%、Tier 3: 5%、Tier 4: 5%、Tier 5: 3%、Tier 6: 3%、Tier 7: 3%、Tier 8: 1%、Tier 9: 1%、Tier 10: 1%',
    'rules-2-1':
      'Note: The distribution ratio and level of invitation rewards depends on your current partner level. If your lower-level partner level is higher than yours, you can only get rewards based on your partner level, and excess rewards will be discarded. To get more invitation rewards, please invite more friends to upgrade your partner level.',
    myTeam: 'My Team',
    totalTeamMember: 'Total Team Members',
    todayIncrease: "Today's Increase",
    users: 'User{{ plural }}',
    invitationIncome: 'Invitation Income',
    todayShare: "Today's Share",
    todayIncome: "Today's Income",
    partners: 'Partners',
    freeMembers: 'Free Members',
    viewAll: 'View All',
    'summary-0': ' member{{ plural }} have completed activity task, ',
    'summary-1': ' member{{ plural }} have completed walking task, ',
    'summary-2': ' member{{ plural }} are resting~',
    invite: 'Invite',
    inviteFriends: 'Invite Friends',
    incomeDetails: 'Income Details',
    invitationRanking: 'Invitation Ranking',
    dailyRank: 'Daily Rank',
    totalRank: 'Total Rank',
    shared: 'Shared: ',
    invited: 'Invited: ',
    totalInvited: 'Total Invited: ',
    'member-new': 'New Member',
    'member-0': 'New Star Member',
    'member-1': '1 Star Partner',
    'member-2': '2 Star Partner',
    'member-3': '3 Star Partner',
    'member-4': '4 Star Partner',
    'member-5': '5 Star Partner',
    'member-6': '6 Star Partner',
    noToday: 'No rankings yet today',
    download: 'Download',
    share: 'Share',
    downloadComplete:
      'Invitation poster is saved to your gallery, share with your friends now!',
    shareComplete: 'Invitation link is copied, share with your friends now!',
  },
};
