import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCardSummary } from '../service';

const Data = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { cardSummary } = useSelector(state => state.card);

  const { cardNumber, totalEarning, totalSupply } = cardSummary;

  useEffect(() => {
    getCardSummary(dispatch);
  }, []);

  return (
    <div className='card-data'>
      <div className='card-data-cell'>
        <div style={{ color: '#A23FEE' }}>{cardNumber}</div>
        <div>{t('card.totalCards')}</div>
      </div>
      <div className='card-data-divider' />
      <div className='card-data-cell'>
        <div style={{ color: '#A23FEE' }}>{totalSupply} SP</div>
        <div>{t('card.revenueCap')}</div>
      </div>
      <div className='card-data-divider' />
      <div className='card-data-cell'>
        <div style={{ color: '#22C348' }}>{totalEarning} SP</div>
        <div>{t('card.cumulativeIncome')}</div>
      </div>
    </div>
  )
}

export default Data