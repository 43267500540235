const initialState = {
  content: {
    content: [],
  },
  comments: [],
  commentModal: {
    visible: false,
    articleId: null,
    total: 0,
  },
  page: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "article/PUT_CONTENT":
      return {
        ...state,
        content: action.payload,
      };

    case "article/GET_COMMENT": {
      const { page, comments } = action.payload;
      if (page === 1) {
        return {
          ...state,
          comments,
          page: page + 1,
        };
      } else {
        return {
          ...state,
          comments: [...state.comments, ...comments],
          page: page + 1,
        };
      }
    }
    case 'article/SET_COMMENT_MODAL':
      return {
        ...state,
        commentModal: action.payload,
        comments: [],
      };
    default:
      return state;
  }
};

export default reducer;
