const StarPointConvertUnderReview = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "831px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "14px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "71px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              height: "139px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "351px",
                height: "139px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="/登陆领取背景图.png"
              />
              <div
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    width: "315px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "144px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "140%",
                          fontWeight: "600",
                          display: "inline-block",
                          width: "188px",
                          flexShrink: "0",
                        }}
                      >
                        我的星值
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "24px",
                        letterSpacing: "0.06em",
                        fontWeight: "600",
                        display: "inline-block",
                        width: "315px",
                      }}
                    >
                      112412.98
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "140%",
                      fontWeight: "400",
                      display: "inline-block",
                      width: "188px",
                    }}
                  >
                    今日获得星值：2184
                  </div>
                </div>
                <div
                  style={{
                    width: "204px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "4px 24px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      兑换成 SP
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "12px",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/历史记录.svg"
                />
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "0.02em",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  历史记录
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  lineHeight: "16px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                }}
              >
                查看更多
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "17px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "17px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "17px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#FFFEEB",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/兑换sp-审核中.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        兑换 SP(审核中)
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "17px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      asdasf@gmail.com
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#D29404",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    -200
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      color: "#828282",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "1px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "18px",
            color: "#303940",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              星值
            </b>
          </div>
        </div>
      </div>
    </center>
  );
};

export default StarPointConvertUnderReview;
