import Menu from "../../components/Menu"
import defaultAvatar from "./images/default-avatar.png"
import calenderIcon from "./images/calenderIcon.png"
import rightArror from "./images/rightArror.png"
import Card from "./components/card"
import walletIcon from "./images/walletIcon.png"
import greyRightIcon from "./images/greyRightIcon.png"
import headerImage from "./images/headerImage.png"
import widthdrawIcon from "./images/widthdrawIcon.png"
import contactUsIcon from "./images/contactUsIcon.png"
import multiLanguage from "./images/multiLanguageIcon.png"
import idVerify from "./images/idVerifyIcon.png"
import settingIcon from "./images/settingIcon.png"
import aboutUsIcon from "./images/aboutUsIcon.png"
import commisionIcon from "./images/commisionIcon.svg"
import membersIcon from "./images/members.png"
import lockedIcon from "./images/lockedIcon.png"
import unlockedIcon from "./images/unlockedIcon.png"
import { formatBalance } from "../../utlis/numbers"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getUserProfile } from "../../apis/user"
import { getWalletSummary } from "../../apis/user"
import { getInviteSummary } from "../../apis/user"
import { backendLogout } from "../../apis/auth"
import { eraseCookie } from "../../cookie"
import { useTranslation } from "react-i18next"
import { getSPStoreInfo } from "../../apis/spStore"
export default function Profile() {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const [userInfo, setUserInfo] = useState({})
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalEarning, setTotalEarning] = useState(0)
  const [totalInvited, setTotalInvited] = useState(0)
  const [invitedToday, setInvitedToday] = useState(0)
  const [spLocked, setSpLocked] = useState(true)
  const [spStoreBalance, setSPStoreBalance] = useState(0)

  const navigate = useNavigate()

  const FIRST_MENU = [
    {
      icon: widthdrawIcon,
      title: t("profile.withdraw"),
      action: () => navigate("/wallet"),
    },
    {
      icon: contactUsIcon,
      title: t("profile.contactUs"),
      action: () => window.open("https://chat.futurestargroup.com/", "_blank"),
    },
    {
      icon: multiLanguage,
      title: t("profile.changeLanguage"),
      action: () => {
        navigate("/changeLanguage")
      },
    },
  ]
  const SECOND_MENU = [
    {
      icon: idVerify,
      title: t("profile.idVerif"),
      action: () => {
        navigate("/Id-verification1")
      },
    },
    {
      icon: settingIcon,
      title: t("profile.settings"),
      action: () => {
        navigate("/account-setting")
      },
    },
    {
      icon: aboutUsIcon,
      title: t("profile.aboutUs"),
      action: () => {
        navigate("/about-us")
      },
    },
  ]
  const RenderMenuItem = ({ item }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={item.icon} style={{ width: "18px" }} />
          <span
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#1A2024",
              marginLeft: "8px",
            }}
          >
            {item.title}
          </span>
        </div>
        <button
          onClick={item.action}
          style={{
            background: "none",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={greyRightIcon} style={{ width: "8px", height: "14px" }} />
        </button>
      </div>
    )
  }
  const init = async () => {
    getUserProfile().then((result) => {
      setUserInfo(result.data.data)
    })
    getWalletSummary()
      .then((result) => {
        console.log(
          result.data.data.totalBalanceUSDT,
          result.data.data.totalIncomeUSDT
        )
        setTotalBalance(result.data.data.totalBalanceUSDT)
        setTotalEarning(result.data.data.totalIncomeUSDT)
        //  setWalletLoading(false)
      })
      .catch((e) => console.log(e))
    getInviteSummary()
      .then((result) => {
        setTotalInvited(result.data.data.totalMember)
        setInvitedToday(result.data.data.todayMember)
        //  setInviteLoading(false)
      })
      .catch((e) => console.log(e))
    getSPStoreInfo()
      .then((result) => {
        setSPStoreBalance(result.data.data.totalEarned)
        setSpLocked(
          result.data.data.balance <= 0 ||
            result.data.data.unlockInfo.unlockTime <= 0
        )
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "10px",
        paddingBottom: "100px",
        gap: "30px",
        zIndex: 0,
      }}
    >
      <span
        style={{
          zIndex: 20,
          fontSize: 18,
          fontWeight: "700",
          color: "white",
          marginTop: "20px",
        }}
      >
        {t("profile.personalCenter")}
      </span>
      <Card style={{ padding: "0px", zIndex: 20 }}>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 12px",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={userInfo.avatarUrl ?? defaultAvatar}
              style={{ width: "62px", height: "62px", borderRadius: "50%" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "8px",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {userInfo.userName}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "5px",
                }}
              >
                {userInfo.email ?? userInfo.phoneNumber}
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              navigate("/tasks")
            }}
            style={{
              border: "1px solid #4094F7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20vw",
              padding: "0px 10px",
              background: "none",
              padding: "5px 10px",
            }}
          >
            <img src={calenderIcon} style={{ width: "20px", height: "20px" }} />
            <span
              style={{ fontSize: "14px", fontWeight: "500", color: "#4094F7" }}
            >
              {t("profile.checkIn")}
            </span>
          </button>
        </div>
        <div
          style={{
            backgroundColor: "#0E73F6",
            padding: "10px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: "14px", fontWeight: "500", color: "white" }}>
            {t("profile.currentRating")}
            {userInfo.starLevel >= 0
              ? t(`card.member-${userInfo.starLevel}`)
              : t("card.member-new")}
          </span>
          <button
            onClick={() => {
              navigate("/card")
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0",
              background: "none",
            }}
          >
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "white" }}
            >
              {t("profile.viewStarCard")}
            </span>
            <img
              src={rightArror}
              style={{ width: "8px", height: "14px", marginLeft: "5px" }}
            />
          </button>
        </div>
      </Card>
      <Card style={{ gap: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={walletIcon} style={{ width: "19px" }} />
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {t("profile.wallet")}
            </span>
          </div>
          <button
            onClick={() => {
              navigate("/wallet")
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0",
              background: "none",
              color: "black",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {t("profile.details")}
            </span>
            <img
              src={greyRightIcon}
              style={{ width: "8px", height: "14px", marginLeft: "5px" }}
            />
          </button>
        </div>
        <div style={{ display: "flex", marginTop: "21px" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#6E7C87" }}
            >
              {t("profile.totalEarning")}
            </span>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              ≈ {formatBalance(totalEarning)} USDT
            </span>
          </div>
          <div
            style={{
              flex: 1,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#6E7C87" }}
            >
              {t("profile.totalBalance")}
            </span>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              ≈ {formatBalance(totalBalance)} USDT
            </span>
          </div>
        </div>
      </Card>
      <Card style={{ gap: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={commisionIcon} style={{ width: "19px" }} />
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {spLocked
                ? t("profile.totalCommissionEarned")
                : t("profile.totalUnlockableCommissionEarned")}
            </span>
          </div>
          <button
            onClick={() => {
              navigate(`/commission?locked=${spLocked}`)
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0",
              background: "none",
              color: "black",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {t("profile.details")}
            </span>
            <img
              src={greyRightIcon}
              style={{ width: "8px", height: "14px", marginLeft: "5px" }}
            />
          </button>
        </div>
        <div style={{ display: "flex", marginTop: "21px" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#6E7C87" }}
            >
              {t("profile.commissionEarned")}
            </span>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              ≈ {spStoreBalance} SP
            </span>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "right",
              paddingRight: "5px",
            }}
          >
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              {spLocked ? (
                <div>
                  <img src={lockedIcon} style={{ width: "25px" }} />
                  {t("profile.locked")}
                </div>
              ) : (
                <div>
                  <img src={unlockedIcon} style={{ width: "25px" }} />
                  {t("profile.unlocked")}
                </div>
              )}
            </span>
          </div>
        </div>
      </Card>
      <Card style={{ gap: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={membersIcon} style={{ width: "19px" }} />
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {t("profile.members")}
            </span>
          </div>
          <button
            onClick={() => {
              navigate("/mymember")
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "0",
              background: "none",
              color: "black",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {t("profile.details")}
            </span>
            <img
              src={greyRightIcon}
              style={{ width: "8px", height: "14px", marginLeft: "5px" }}
            />
          </button>
        </div>
        <div style={{ display: "flex", marginTop: "21px" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#6E7C87" }}
            >
              {t("profile.totalPeople")}
            </span>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              {totalInvited}
            </span>
          </div>
          <div
            style={{
              flex: 1,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#6E7C87" }}
            >
              {t("profile.addedToday")}
            </span>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "#1A2024" }}
            >
              {invitedToday}
            </span>
          </div>
        </div>
      </Card>
      <Card style={{ gap: "24px" }}>
        {FIRST_MENU.map((each, index) => {
          return <RenderMenuItem item={each} key={index} />
        })}
      </Card>
      <Card style={{ gap: "24px" }}>
        {SECOND_MENU.map((each, index) => {
          return <RenderMenuItem item={each} key={index} />
        })}
      </Card>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0px 20px",
        }}
      >
        <button
          onClick={async () => {
            await backendLogout()
            eraseCookie()
            // removeToken()
            navigate("/")
            dispatch({
              type: "user/SET_USER",
              payload: {
                username: "",
                starLevel: -1,
                avatarUrl: null,
                inviteCode: null,
                isLoggedIn: false,
              },
            })
          }}
          style={{
            border: "0",
            width: "100%",
            backgroundColor: "red",
            textAlign: "center",
            borderRadius: "10px",
            padding: "11px 0px",
            borderRadius: "100px",
          }}
        >
          <span style={{ color: "white" }}>{t("profile.logOut")}</span>
        </button>
      </div>
      <span style={{ alignSelf: "flex-end", marginTop: "20px" }}>v1.0.39</span>
      <Menu select={"profile"} />
      <img
        src={headerImage}
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          zIndex: 10,
          height: "15%",
        }}
      />
    </div>
  )
}
