import React, { useEffect, useState } from 'react';
import { getArticle, likeArticle, readArticle } from './service';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import left from '../Card/assets/left.svg';
import defaultAva from '../Card/assets/default-avatar.png';

import './index.css';

import likeIcon from './assets/like.svg';
import commentIcon from './assets/comment.svg';

import Menu from '../../components/Menu';

import Comment from './components/Comment';

import CryptoAES from 'crypto-js/aes';

import configs from '../../configs';

const Article = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const { content, commentModal } = useSelector(state => state.article);

  const id = location.search.split('=')[1];

  const [readTime, setReadTime] = useState(0);

  useEffect(() => {
    getArticle(dispatch, id, isLoggedIn);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const timer = setInterval(() => {
        const message = {
          articleId: id,
          readTime: 30,
        };

        const cipher = CryptoAES.encrypt(
          JSON.stringify(message),
          configs.ARTICLE_READ_KEY,
        ).toString();

        setReadTime(readTime + 30);

        readArticle(cipher);
      }, 30 * 1000);

      return () => {
        clearInterval(timer);
      }
    }
  }, [isLoggedIn, readTime]);

  const handleBack = () => {
    navigation('/info');
  };

  const handleOpenComment = () => {
    dispatch({
      type: 'article/SET_COMMENT_MODAL',
      payload: {
        visible: true,
        articleId: id,
        total: content?.comments,
      }
    });
  };

  return (
    <div className='article'>
      <div className='article-top'>
        <div style={{ cursor: 'pointer' }} onClick={handleBack}>
          <img src={left} alt="" />
        </div>
      </div>
      <div className='article-header'>
        <div className='article-left'>
          <img src={defaultAva} />
        </div>
        <div className='article-right'>
          <div className='article-author'>
            {content?.author}
          </div>
          <div className='article-time'>
            <span>{moment(content?.createTime).format('MM DD, YYYY')}</span>
            <span>{` · ${t('read.duration', { duration: readTime / 60 })}`}</span>
          </div>
        </div>
      </div>
      <div className='article-title'>
        {content?.title}
      </div>
      <div className='article-body'>
        {
          content?.content.map((item, index) => {
            const { type } = item;
            if (type === 'text') {
              return <div className='article-text'>{item.content}</div>
            }

            if (type === 'image') {
              return <div className='article-image'>
                <img src={item.content} alt="" />
              </div>
            }
          })
        }
      </div>
      {
        (isLoggedIn && content?.title)
        &&
        <div className='article-fix'>
          <div className='article-fix-like'
            onClick={async () => {
              await likeArticle(dispatch, content?._id);
            }}
          >
            <img alt="" src={likeIcon} />
            {content?.likes}
          </div>
          <div className='article-fix-divider'></div>
          <div className='article-fix-comment' onClick={handleOpenComment}>
            <img alt="" src={commentIcon} />
            {content?.comments}
          </div>
        </div>
      }
      <Menu select={'news'} />
      {commentModal.visible && <Comment />}
    </div>
  );
};

export default Article;
