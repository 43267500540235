const initialState = {
  isLoading: false,
  cardList: [],
  myStepCardList: [],
  myTaskCardList: [],
  cardListLoaded: false,
  selectedType: "task",
  myCardType: "step",
  cardSummary: {
    cardNumber: 0,
    currentLevel: 0,
    totalEarning: 0,
    totalSupply: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "card/PUT_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "card/PUT_CARD_LIST":
      return {
        ...state,
        cardList: action.payload,
      };
    case "card/PUT_MY_STEP_CARD_LIST":
      return {
        ...state,
        myStepCardList: action.payload,
      };
    case "card/PUT_MY_TASK_CARD_LIST":
      return {
        ...state,
        myTaskCardList: action.payload,
      };
    case "card/SWTICH_MYCARD_SELECT_TYPE":
      return {
        ...state,
        myCardType: action.payload,
      };
    case "card/SWTICH_SELECT_TYPE":
      return {
        ...state,
        cardList: [],
        selectedType: action.payload,
      };
    case "card/PUT_CARD_SUMMARY":
      return {
        ...state,
        cardSummary: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
