const ForgetpaymentPassword = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "699px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 32px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "16px 20px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/左尖角.svg"
              />
              <b
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  left: "-2px",
                }}
              >
                找回付款密码
              </b>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "14px",
              color: "#252c32",
              fontFamily: "Inter",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    width: "335px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                    }}
                  >
                    Phone Number
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "6px",
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #dde2e4",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px 16px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "16px",
                      color: "#999",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <input
                        style={{
                          position: "relative",
                          letterSpacing: "-0.01em",
                          lineHeight: "24px",
                          width: "40px",
                          border: "0",
                          backgroundColor: "transparent",
                        }}
                        placeholder="+1"
                      ></input>
                    </div>
                    <input
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        width: "100%",
                        border: "0",
                        backgroundColor: "transparent",
                      }}
                      placeholder="6476091868"
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    width: "335px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                    }}
                  >
                    Verification Code
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      borderRadius: "6px",
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #dde2e4",
                      boxSizing: "border-box",
                      height: "40px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      style={{
                        position: "absolute",
                        width: "calc(100% - 117px)",
                        top: "8px",
                        left: "16px",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        display: "inline-block",
                        width: "100%",
                        border: "0",
                        backgroundColor: "transparent",
                      }}
                      placeholder="51126"
                    ></input>
                    <div
                      style={{
                        position: "absolute",
                        width: "calc(100% - 224px)",
                        top: "8px",
                        left: "215px",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        fontWeight: "500",
                        color: "#0e73f6",
                        textAlign: "right",
                        display: "inline-block",
                      }}
                    >
                      30s
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  borderTop: "1px solid #dbdbdb",
                  boxSizing: "border-box",
                  height: "1px",
                  flexShrink: "0",
                }}
              />
              <div
                style={{
                  width: "335px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                  }}
                >
                  新付款密码
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #dde2e4",
                    display: "flex",
                    flexDirection: "row",
                    padding: "8px 16px",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      width: "100%",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    placeholder="futurestar123456"
                  ></input>
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/可见.svg"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "335px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                  }}
                >
                  重复新密码
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #dde2e4",
                    display: "flex",
                    flexDirection: "row",
                    padding: "8px 16px",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      width: "100%",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    placeholder="***********"
                  ></input>
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/不可见.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "28px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "16px",
            color: "#fff",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#0e73f6",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              确认
            </div>
          </div>
          <div
            style={{
              position: "relative",
              lineHeight: "140%",
              fontWeight: "500",
              color: "#0e73f6",
              textAlign: "right",
            }}
          >
            取消
          </div>
        </div>
      </div>
    </center>
  );
};

export default ForgetpaymentPassword;
