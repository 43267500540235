export default function WalletButton({
  icon,
  title,
  currencyBalance,
  usdtBalance,
  onClick,
}) {
  return (
    <button
      onClick={onClick}
      style={{
        border: "none",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        backgroundColor: "white",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px 8px",
        marginTop: "12px",
      }}
    >
      <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
        <img src={icon} style={{ width: "44px", height: "44px" }} />
        <span
          style={{
            fontWeight: "600",
            fontSize: "14px",
            color: "#1A2024",
            marginLeft: "12px",
          }}
        >
          {title}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "16px",
            color: "#252C32",
          }}
        >
          {currencyBalance}
        </span>
        <span
          style={{
            fontWeight: "400",
            fontSize: "12px",
            color: "#828282",
            marginTop: "4px",
          }}
        >
          ≈ {usdtBalance} USDT
        </span>
      </div>
    </button>
  )
}
