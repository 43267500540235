import { useNavigate } from "react-router-dom";
import "./Commission.css";
import historyIcon from "./images/history.png";
import incomeImage from "./images/sp-income.png";
import convertSuccessImage from "./images/convert-success.png";
import convertFailedImage from "./images/convert-failed.png";
import spUnlockIcon from "./images/sp_unlock.png";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getSPStoreInfo, unlockSPStore } from "../../apis/spStore";
import { getTransactionRecord } from "../../apis/wallet";
import moment from "moment";

const Commission = () => {
  const locked = location.search.split("=")[1].toLowerCase() === "true";
  const [history, setHistory] = useState([]);
  const [totalSPEarned, setTotalSPEarned] = useState(0);
  const [totalSPUnlocked, setTotalSPUnlocked] = useState(0);
  const [totalTeamPower, setTotalTeamPower] = useState(0);
  const [unlockRate, setUnlockRate] = useState(0);
  const [unlockTimeLeft, setUnlockTimeLeft] = useState(0);
  const [unlockedAmount, setUnlockedAmount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const fetchInfo = () => {
    getSPStoreInfo().then((result) => {
      setTotalSPEarned(result.data.data.balance);
      setTotalSPUnlocked(
        result.data.data.totalEarned - result.data.data.balance
      );
      setTotalTeamPower(result.data.data.unlockInfo.unlockPower);
      setUnlockRate(result.data.data.unlockRate);
      setUnlockTimeLeft(result.data.data.unlockInfo.unlockTime);
    });
    getTransactionRecord({ currency: "SP_STORE" })
      .then((result) => {
        setHistory(result.data.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    fetchInfo();
  }, []);

  const historyComponents = {
    task_invite_income: {
      icon: incomeImage,
      title: t("profile.taskActivityCommission"),
      color: "#0E73F6",
    },
    step_invite_income: {
      icon: incomeImage,
      title: t("profile.stepActivityCommission"),
      color: "#0E73F6",
    },
    unlock_sp_store: {
      icon: spUnlockIcon,
      title: t("profile.spUnlock"),
      negative: true,
      color: "#119C2B",
    },
  };

  const getDisplayInfo = (item) => {
    if (
      item.type === "task_invite_income" ||
      item.type === "step_invite_income"
    ) {
      return item.relatedUser;
    } else if (item.type === "unlock_sp_store" && item.extra) {
      const data = JSON.parse(item.extra);
      return `${data.unlockRate * 100}% • ${data.unlockPower}${t(
        "profile.power"
      )}`;
    }
  };

  const [tooltip1, setTooltip1] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const [tooltip3, setTooltip3] = useState(false);

  const navigation = useNavigate();

  return (
    <div className="div">
      <div className="summary-parent">
        <div className="summary">
          <img className="summary-bg" alt="" src="/登陆领取背景图.png" />
          <div className="summary-frame">
            <div className="frame-group">
              <div className="frame-wrapper">
                <div className="frame-container">
                  <div className="data-parent">
                    <div className="data1">
                      {Math.round(totalSPEarned * 100) / 100}
                    </div>
                    <div className="data2">
                      {t("profile.totalUnlockableCommission")}
                    </div>
                  </div>
                  <div className="frame-child" />
                  <div className="data-parent">
                    <div className="data1">
                      {Math.round(totalSPUnlocked * 100) / 100}
                    </div>
                    <div className="data2">
                      {t("profile.unlockedCommission")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary2-parent">
                <div className="summary2">
                  {t("profile.currentTeamPower")} : {totalTeamPower} (
                  {t("profile.unlocked")}
                  {unlockRate * 100}%)
                </div>
                <button
                  onClick={() => {
                    setTooltip1(!tooltip1);
                  }}
                  className="button-transparent"
                >
                  <img
                    className="info-icon"
                    alt=""
                    src="../info-fill0-wght400-grad0-opsz48-12.svg"
                  />
                  <div style={{ display: tooltip1 ? "flex" : "none" }}>
                    <img className="info-icon" alt="" src="../上尖角.svg" />
                  </div>
                </button>
              </div>
            </div>
            <div className="button-div">
              <div className="button-wrapper">
                <button
                  className="button"
                  onClick={async () => {
                    setShowPopup(true);
                    const result = await unlockSPStore();
                    setUnlockedAmount(result.data.data.amount);
                    setShowPopup(true);
                  }}
                  disabled={locked}
                >
                  <div
                    className="button-title"
                    style={{ color: locked ? "#84919A" : "#0E73F6" }}
                  >
                    {t("profile.unlockSP")} SP
                  </div>
                </button>
              </div>
              <div className="button-info-parent">
                <div className="button-info">
                  {t("profile.unlockTimeLeft")}: {unlockTimeLeft}
                </div>
                <button
                  onClick={() => {
                    setTooltip2(!tooltip2);
                  }}
                  className="button-transparent"
                >
                  <img
                    className="info-icon"
                    alt=""
                    src="../info-fill0-wght400-grad0-opsz48-12.svg"
                  />
                  <div style={{ display: tooltip2 ? "flex" : "none" }}>
                    <img className="info-icon" alt="" src="../上尖角.svg" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="history-parent">
          <div className="history-parent2">
            <div className="history-icon-parent">
              <img className="history-icon" alt="" src={historyIcon} />
              <div className="div6">{t("wallet.history")}</div>
            </div>
            <div className="details">{t("profile.details")}</div>
          </div>
          {history.map((item) => {
            const typeExists = !!historyComponents[item.type];

            let icon = typeExists ? historyComponents[item.type].icon : null;
            let title = typeExists
              ? t(historyComponents[item.type].title, {
                  currency: t("wallet.fscp"),
                  currencyTo:
                    historyComponents[item.type].convertTo === "SP"
                      ? t("wallet.fscp")
                      : historyComponents[item.type].convertTo,
                  currencyFrom:
                    historyComponents[item.type].convertFrom === "SP"
                      ? t("wallet.fscp")
                      : historyComponents[item.type].convertFrom,
                })
              : "";
            let color = typeExists
              ? historyComponents[item.type].color
              : "#0E73F6";

            if (item.type === "SP convert FSC") {
              if (item.status === 0) {
                icon = convertSuccessImage;
                title += t("wallet.spSuccess");
                color = "#119C2B";
              } else if (item.status !== 1) {
                icon = convertFailedImage;
                title += t("wallet.spFailed");
                color = "#CC0905";
              } else {
                title += t("wallet.spPending");
              }
            }
            return (
              <div className="history">
                <div className="history-parent3">
                  <div className="history-parent4">
                    <div className="commission-icon-wrapper">
                      <img className="commission-icon" alt="" src={icon} />
                    </div>
                    <div className="history-parent5">
                      <div className="">
                        <div className="history-title">{title}</div>
                      </div>
                      <div className="history-email">
                        {getDisplayInfo(item)}
                      </div>
                    </div>
                  </div>
                  <div className="history-data">
                    <div className="history-title" style={{ color: color }}>
                      {typeExists && historyComponents[item.type].negative
                        ? "-"
                        : "+"}
                      {Math.round(item.amount * 100) / 100}
                    </div>
                    <div className="history-date">
                      {moment(item.createTime).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="header">
        <div className="back-parent">
          <button
            className="button-transparent"
            onClick={() => {
              navigation("/profile");
            }}
          >
            <img className="back-icon" alt="" src="/返回.svg" />
          </button>
          <b className="title">{t("profile.commissionEarned")}</b>
        </div>
      </div>
      <div
        className="tooltip1-parent"
        style={{ display: tooltip1 || tooltip3 ? "flex" : "none" }}
      >
        <div className="tooltip1">
          <div className="tooltip1-1">{t("profile.unlockDetails")}</div>
          <div className="tooltip1-2">
            <div className="tooltip1-3">
              <div className="tooltip1-4">{t("profile.teamUserSP")}</div>
              <div className="tooltip1-5">{t("profile.oneStar")}</div>
              <div className="tooltip1-5">{t("profile.twoStar")}</div>
              <div className="tooltip1-5">{t("profile.threeStar")}</div>
              <div className="tooltip1-5">{t("profile.fourStar")}</div>
              <div className="tooltip1-5">{t("profile.fiveStar")}</div>
              <div className="tooltip1-5">{t("profile.sixStar")}</div>
            </div>
            <div className="tooltip1-3">
              <div className="tooltip1-6">{t("profile.powerIncreasable")}</div>
              <div className="tooltip1-5">10</div>
              <div className="tooltip1-5">20</div>
              <div className="tooltip1-5">100</div>
              <div className="tooltip1-5">200</div>
              <div className="tooltip1-5">1000</div>
              <div className="tooltip1-5">2000</div>
            </div>
          </div>
          <div className="tooltip1-1">{t("profile.powerToUnlockRatio")}</div>
          <div className="tooltip1-2">
            <div className="tooltip1-3">
              <div className="tooltip1-4">{t("profile.teamPower")}</div>
              <div className="tooltip1-5">0-100（{t("profile.includes")}）</div>
              <div className="tooltip1-5">
                100-500（{t("profile.includes")}）
              </div>
              <div className="tooltip1-5">
                500-1000（{t("profile.includes")}）
              </div>
              <div className="tooltip1-5">
                1000-2000（{t("profile.includes")}）
              </div>
              <div className="tooltip1-5">2000 {t("profile.above")}</div>
            </div>
            <div className="tooltip1-3">
              <div className="tooltip1-6">{t("profile.toUnlockRatio")}</div>
              <div className="tooltip1-5">0.1%</div>
              <div className="tooltip1-5">0.3%</div>
              <div className="tooltip1-5">0.5%</div>
              <div className="tooltip1-5">1%</div>
              <div className="tooltip1-5">2%</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tooltip2-parent"
        style={{ display: tooltip2 ? "flex" : "none" }}
      >
        <div className="tooltip2">
          <div className="tooltip1-1">{t("profile.unlockTimeDescribe")}</div>
        </div>
      </div>
      <div className="tooltip3" style={{ display: tooltip3 ? "flex" : "none" }}>
        <div className="tooltip3-1">{t("profile.unlockDetails")}</div>
        <div className="tooltip3-2">
          <div className="tooltip3-3">
            <div className="tooltip3-4">{t("profile.teamUserSP")}</div>
            <div className="tooltip3-5">{t("profile.oneStar")}</div>
            <div className="tooltip3-5">{t("profile.twoStar")}</div>
            <div className="tooltip3-5">{t("profile.threeStar")}</div>
            <div className="tooltip3-5">{t("profile.fourStar")}</div>
            <div className="tooltip3-5">{t("profile.fiveStar")}</div>
            <div className="tooltip3-5">{t("profile.sixStar")}</div>
          </div>
          <div className="tooltip3-3">
            <div className="tooltip3-6">{t("profile.powerIncreasable")}</div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
            <div className="tooltip3-5">
              {t("card.rules-0-14", { days: 30 })}
            </div>
          </div>
        </div>
        <div className="tooltip3-2">
          <div className="tooltip3-3">
            <div className="tooltip3-14">{t("card.rules-0-1")}</div>
            <div className="tooltip3-5">{t("card.rules-0-6")}</div>
            <div className="tooltip3-5">{t("card.rules-0-7")}</div>
            <div className="tooltip3-5">{t("card.rules-0-8")}</div>
            <div className="tooltip3-5">{t("card.rules-0-9")}</div>
            <div className="tooltip3-15">{t("card.rules-0-10")}</div>
            <div className="tooltip3-16">{t("card.rules-0-11")}</div>
            <div className="tooltip3-15">{t("card.rules-0-12")}</div>
          </div>
          <div className="tooltip3-3">
            <div className="tooltip3-17">
              <p className="tooltip3-6">{t("card.rules-0-15")}</p>
            </div>
            <div className="tooltip3-5">60</div>
            <div className="tooltip3-5">215</div>
            <div className="tooltip3-5">450</div>
            <div className="tooltip3-5">2200</div>
            <div className="tooltip3-18">4500</div>
            <div className="tooltip3-18">22500</div>
            <div className="tooltip3-19">46000</div>
          </div>
          <div className="tooltip3-3">
            <div className="tooltip3-6">
              <p className="tooltip3-6">{t("card.rules-0-16")}</p>
              <p></p>
            </div>
            <div className="tooltip3-5">50%</div>
            <div className="tooltip3-5">9%</div>
            <div className="tooltip3-5">9%</div>
            <div className="tooltip3-5">8%</div>
            <div className="tooltip3-7">7%</div>
            <div className="tooltip3-8">6%</div>
            <div className="tooltip3-9">6%</div>
          </div>
          <div className="tooltip3-10">
            <div className="tooltip3-11">
              <p className="tooltip3-6">{t("card.rules-0-17")}</p>
            </div>
            <div className="tooltip3-12">{` `}</div>
            <div className="tooltip3-5">
              {t("card.memberReq1", { users: 1, star: 2, plural: "" })}
            </div>
            <div className="tooltip3-13">
              <p className="tooltip3-6">
                {t("card.memberReq2", { users: 2, star: 3, plural: "s" })}
              </p>
            </div>
            <div className="tooltip3-13">
              <p className="tooltip3-6">
                {t("card.memberReq2", { users: 2, star: 4, plural: "s" })}
              </p>
            </div>
            <div className="tooltip3-13">
              <p className="tooltip3-6">
                {t("card.memberReq2", { users: 3, star: 5, plural: "s" })}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: showPopup ? "flex" : "none" }}>
        <div className="popup-background" />
        <div className="popup">
          <div className="popup-content">
            <div className="popup-frame-wrapper">
              <div className="popup-title">
                <div className="popup-title1">
                  <b className="popup-title2">
                    {t("profile.commissionUnlocked")}
                  </b>
                </div>
                <div className="popup-description">
                  {t("profile.popupDetail1")} {totalTeamPower},{" "}
                  {t("profile.unlocked")}{" "}
                  {Math.round(unlockedAmount * 100) / 100} SP({unlockRate * 100}
                  % {t("profile.popupDetail2")}
                  ). {t("profile.popupDetail3")}
                </div>
              </div>
            </div>
            <div className="popup-button">
              <button
                className="popup-button1"
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                <div className="popup-button2">
                  <div className="popup-button3">{t("card.okay")}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
