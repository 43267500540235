const IdUpload2 = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "779px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "18px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              实名认证
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "35px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "#fff",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#9bcffd",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              Next
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            textAlign: "left",
            fontSize: "14px",
            color: "#f2271c",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
              color: "#303940",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "0px 11px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆-灰.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                基本信息
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传证件
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                提交认证
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            请确保使用的是真实身份，否则认证将无法通过。您提交的信息仅用于Future
            Star认证使用，我们将会对您的信息进行加密处理。
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                名字
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder="Input First Name"
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                姓氏
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder="Input Last Name"
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                出生日期
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    border: "0",
                    backgroundColor: "transparent",
                  }}
                  placeholder="YYYY-MM-DD"
                ></input>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            *信息需与证件上的信息一致
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "375px",
            height: "854px",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "193px",
            left: "0px",
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px -4px 8px rgba(186, 186, 186, 0.24)",
            width: "375px",
            height: "586px",
            overflow: "hidden",
            color: "#1a2024",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "600",
                left: "120px",
              }}
            >
              上传证件照
            </div>
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/关闭.svg"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "54px",
              left: "0px",
              backgroundColor: "#4094f7",
              width: "375px",
              height: "233px",
              overflow: "hidden",
              textAlign: "left",
              fontSize: "14px",
              color: "#fff",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "375px",
                height: "233px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="/登陆领取背景图.png"
              />

              <div
                style={{
                  position: "absolute",
                  top: "28px",
                  left: "35px",
                  borderRadius: "16.14px",
                  backgroundColor: "#fff",
                  width: "305px",
                  height: "176.71px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "151px",
                    left: "50px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8.7px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.67px",
                      height: "10.67px",
                      flexShrink: "0",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "14px",
                    left: "91px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8.41px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "#b3bfca",
                      width: "10.31px",
                      height: "10.31px",
                      flexShrink: "0",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "32px",
                    left: "71px",
                    width: "162px",
                    height: "17px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "10.41px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      position: "relative",
                      backgroundColor: "#b3bfca",
                    }}
                  />
                </div>
                <img
                  style={{
                    position: "absolute",
                    top: "62px",
                    left: "115px",
                    width: "75px",
                    height: "75px",
                  }}
                  alt=""
                  src="/灰色实心圆.svg"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "309px",
              left: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              textAlign: "left",
              fontSize: "18px",
              color: "#303940",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                width: "351px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传证件反面照片
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontSize: "24px",
                  color: "#0e73f6",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }}
              >
                <span>2</span>
                <span style={{ fontSize: "18px", color: "#303940" }}>/3</span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                fontSize: "14px",
                lineHeight: "130%",
                fontFamily: "Inter",
                color: "#1a2024",
                display: "inline-block",
                width: "351px",
              }}
            >
              拍摄时确保证件边框完整，字体清晰，亮度均匀。
            </div>
            <img
              style={{
                position: "relative",
                width: "350px",
                height: "103px",
                flexShrink: "0",
                objectFit: "cover",
              }}
              alt=""
              src="/证件照要求.png"
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "31px",
              left: "calc(50% - 175.5px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#0e73f6",
            }}
          >
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#ebf7ff",
                width: "167px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                上一步
              </div>
            </div>
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                width: "167px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                color: "#fff",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                下一步
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdUpload2;
