export default {
  bottomBar: {
    Home: 'Startseite',
    Card: 'Karte',
    Friend1: 'Freunde',
    Wallet: 'Geldbörse',
    Friend: 'Mitglieder',
    Task: 'Aufgaben',
    Profile: 'Profil',
    Read: 'Nachrichten lesen',
  },
  home: {
    login: 'Anmelden, um zu verdienen',
    starNews: 'Star-Nachrichten',
    starSelected: 'Star ausgewählt',
    watchAds: 'Schauen Sie Anzeigen, um zu verdienen',
    moveToEarn: 'Wechseln Sie, um zu verdienen',
    moveToEarnTitle: 'Bald verfügbar: Wechseln Sie, um zu verdienen!',
    moveToEarnDescription:
      'Wir arbeiten hart an der Entwicklung der "Wechseln, um zu verdienen"-Funktion, damit Sie mehr verdienen können!',
    verify: 'Verifizieren Sie Ihre ID, um Ihr Konto und Einkommen zu sichern.',
    doItNow: 'Jetzt verifizieren',
  },
  search: {
    title: 'Suche',
    search: 'Suchen',
    trending: 'TRENDING BEI FUTURE STAR',
    notFound: 'Keine Artikel gefunden',
    notFound1: 'Keine Ergebnisse gefunden',
    notFound2:
      'Wir haben nichts gefunden, wonach Sie gesucht haben. Versuchen Sie es mit einer anderen Suche.',
  },
  read: {
    ads: 'Anzeigen',
    duration: '{{ duration }} Min. Lesezeit',
    totalComments: 'Antworten ({{ amount }})',
    reply: 'Antworten',
    comment: 'Was denkst du?',
    replying: 'Antworten auf',
    noComment1: 'Noch keine Kommentare',
    noComment2: 'Sei die/der Erste, die/der einen Kommentar hinterlässt!',
    headlines: 'Star-Schlagzeilen',
    error1: 'Gefällt mir nicht möglich! Bitte versuche es erneut',
    error2: 'Gefällt mir nicht mehr möglich! Bitte versuche es erneut',
    error3: 'Kommentare können nicht abgerufen werden',
    error4: 'Kommentieren nicht möglich! Bitte versuche es erneut',
  },
  card: {
    refreshSuccess:
      'Wallet erfolgreich aktualisiert, Ihr Kontostand wird innerhalb von Minuten aktualisiert.',
    title: 'Star-Karte',
    cardList: 'Kartenliste',
    myCards: 'Meine Karten',
    remaining: '{{ remaining }}/{{ total }} übrig',
    expire: '{{ days }} Tage übrig',
    valid: 'Läuft in {{ days }} Tagen ab',
    potential: 'Gesamtpotenzial: ',
    earned: 'Verdient: ',
    cost: 'Kosten',
    obtain: 'Erwerben',
    task: 'Aufgabe',
    step: 'Schritt',
    combo: 'Alt',
    comboCard: 'Alte Karte',
    stepCard: 'Schrittkarte',
    taskCard: 'Aufgabenkarte',

    cardDesc_zero: 'Neue {{ type }}-Star-Karte',
    cardDesc: '{{ star }}-Star-{{ type }}-Karte',
    dailyProd: '({{ dailyProd }} FSC / Tag)',
    required: 'ERFORDERLICH FÜR KARTE',
    totalEarning: 'GESAMTEINNAHMEN',
    until: '(BIS {{ date }})',
    memberReq: '{{ users }} {{ star }}-Star-Mitglied',
    obtainCard: 'Karte erwerben',
    cardReq: 'KARTENANFORDERUNGEN',
    noFunds: 'Nicht genügend Guthaben',
    buyCoin: 'Future Star Coin kaufen',
    memberReq1: '{{ users }} {{ star }}-Star-Mitglied{{ plural }}',
    memberReq2:
      '{{ users }} {{ star }}-Star-Mitglied{{ plural }}, in {{ users }} separaten Beziehungsleitungen.',
    noMembers: 'Keine Mitglieder gefunden',
    invite: 'Freunde einladen',
    confirm: 'Bestätigen und Karte starten',
    historyTitle: 'Star-Karten-Verlauf',
    sort1: 'Neueste zuerst',
    sort2: 'Älteste zuerst',
    sort3: 'Kartenstar hoch bis niedrig',
    sort4: 'Kartenstar niedrig bis hoch',
    sort5: 'Gesamteinnahmen hoch bis niedrig',
    sort6: 'Gesamteinnahmen niedrig bis hoch',
    purchased: 'Kauf erfolgreich abgeschlossen',
    purchasedDesc:
      'Sie haben diese Star-Karte erhalten! Fangen Sie jetzt an, Geld zu verdienen',
    noCards: 'Keine Karten gefunden',
    noHistory: 'Kein Kartenverlauf gefunden',
    noCards2: 'Holen Sie sich mehr Karten, um zu verdienen!',
    totalCards: 'Gesamtzahl an Karten',
    cardSingle: ' Karte',
    cardPlural: ' Karten',
    revenueCap: 'Verdienstgrenze',
    cumulativeIncome: 'Gesamteinkommen',

    'member-new': 'Neues Mitglied',
    'member-0': 'Neues Star-Mitglied',
    'member-1': '1-Sterne-Mitglied',
    'member-2': '2-Sterne-Mitglied',
    'member-3': '3-Sterne-Mitglied',
    'member-4': '4-Sterne-Mitglied',
    'member-5': '5-Sterne-Mitglied',
    'member-6': '6-Sterne-Mitglied',
    rules: 'Regeln',
    get: 'Star-Karte erwerben',
    starLeveDesc:
      'Ihr Mitgliedslevel wird durch die höchste Star-Karte bestimmt, die Sie besitzen.',
    myCards2: 'Meine Star-Karten ansehen',
    spend: 'Ausgeben',
    confirmBuy:
      'Möchten Sie {{ cost }} FSC ausgeben, um diese Star-Karte zu erhalten?',
    obtainedSuccess: 'Erfolgreich erworben!',
    insufficientFSC: 'Unzureichender FSC',
    insufficientMember: 'Unzureichende Teammitgliederqualifikation',
    confirmBuyDesc:
      'Diese Star-Karte wird {{ cost }} FSC verbrauchen, und Sie können in {{ days }} Tagen kumulativ {{ earning }} SP verdienen.',
    obtainedSuccessDesc:
      'Sie haben diese Star-Karte erhalten! Starten Sie jetzt mit dem Geldverdienen!',
    insufficientFSCDesc:
      'Diese Star-Karte wird {{ cost }} FSC verbrauchen. Das FSC-Guthaben in der Geldbörse ist unzureichend. Bitte führen Sie eine Aufladung durch oder erledigen Sie weitere Aufgaben, um FSC zu erhalten.',
    insufficientMemberDesc:
      'Um diese Star-Karte zu erhalten, benötigen Sie {{ member }} im Team, und Sie haben nur {{ member }}. Laden Sie mehr Freunde ein, um Ihr Team zu erweitern und eine höhere Star-Karte zu erhalten!',
    yes: 'Ja',
    okay: 'Okay',
    topUp: 'Aufladen',
    inviteFriends: 'Freunde einladen',
    cancel: 'Abbrechen',
    noStarCard: 'Sie haben noch keine Star-Karte',
    noStarCardDetail:
      'Sie besitzen noch keine Star-Karte. Sie können erst nach dem Erwerb der Star-Karte Aufgaben erledigen, um mehr Belohnungen zu erhalten! Verpassen Sie nicht die Vorteile und holen Sie sich jetzt die Star-Karte!',
    noQualifyCardTitle: 'Sie können noch keine FSC tauschen',
    noQualifyCardContent:
      'Sie sind derzeit ein neuer Star-Partner und können keinen SP gegen FSC tauschen. Nur Benutzer mit einer Aktivitätsstar-Karte mit einem Stern oder höher können FSC-Austausch beantragen. Upgrade Ihrer Star-Karte, um Ihr Einkommen jetzt zu konvertieren!',
    upgradeCard: 'Star-Karte upgraden',
    noQualifyStepCardTitle: 'Sie können noch keine Star-Karte kaufen',
    noQualifyStepCardContent:
      'Sie besitzen derzeit keine Step-Star-Karte auf demselben oder höheren Niveau, daher können Sie keine Step-Star-Karte auf diesem Level kaufen. Bitte upgraden Sie Ihre Task-Star-Karte jetzt!',

    'rules-0-1': 'Star-Level',
    'rules-0-2': 'Maximale Anzahl',
    'rules-0-3': 'SP Benötigt',
    'rules-0-4': 'Gesamtproduktion',
    'rules-0-5': 'Gültigkeitsdauer',
    'rules-0-6': 'Neuer Star',
    'rules-0-7': '1 Stern',
    'rules-0-8': '2 Sterne',
    'rules-0-9': '3 Sterne',
    'rules-0-10': '4 Sterne',
    'rules-0-11': '5 Sterne',
    'rules-0-12': '6 Sterne',
    'rules-0-13': 'Kostenlos',
    'rules-0-14': '{{ days }} Tage',
    'rules-0-15': 'Tägliche Produktion',
    'rules-0-16': 'Bearbeitungsgebühr für den Tausch von SP zu SP',
    'rules-0-17': 'Promotionsanforderungen',

    'rules-1-1':
      'Star-Karten sind in Step-Star-Karten und Aktivitäts-Star-Karten unterteilt. Nach Erhalt der Star-Karten und Abschluss der entsprechenden Aufgaben können Sie Belohnungen erhalten.',
    'rules-1-2':
      'Jedes Level der Star-Karte kann unbegrenzt empfangen werden, aber es kann nur eine Star-Karte auf einmal erworben und gehalten werden.',
    'rules-1-3':
      'Nach Abschluss der 30-tägigen Aufgabe der Star-Karte können Sie sich entscheiden, die Star-Karte auf demselben Level weiter zu erwerben oder auf eine höherwertige Star-Karte zu upgraden. Das Level kann nach der Beförderung nicht gesenkt werden, und Sie können nur das aktuelle Star-Level beibehalten oder weiter befördert werden. Alle Star-Karten laufen nach 30 Tagen ab.',
    'rules-1-4':
      'Star-Karten sind in verschiedene Level unterteilt. Jede Star-Karte ist nach Erhalt 30 Tage lang gültig. Ab dem Tag der Sammlung wird die Star-Karte nach 30 Tagen ungültig, und die entsprechenden Belohnungen werden nicht für das Abschließen von Aufgaben erhalten.',
    'rules-1-5':
      'Jedes Star-Karten-Level von einem Stern bis sechs Sternen, wenn die Star-Karte abgelaufen ist und keine neue Star-Karte erworben wird, wird das Einkommen des Teams gestoppt. Aber wenn Sie jederzeit eine neue Star-Karte erwerben, wird das Einkommen des Teams ab demselben Tag wiederhergestellt.',
    'rules-1-6':
      'Sie müssen nur die entsprechenden Star-Münzen beim Empfangen der neuen Star-, Ein-Stern- und Zwei-Sterne-Karten verbrauchen, und Sie müssen bei Empfang von Drei-Sterne- und höheren Star-Karten die Beförderungsbeurteilung erfüllen.',

    error1: 'Der Server ist beschäftigt, bitte versuchen Sie es später erneut.',
  },
  friend: {
    title: 'Mitglieder',
    partner: 'Partner',
    members: 'Mitglieder',
    all: 'Alle Ebenen',
    tier: 'Ebene {{ tier }}',
    sortBy: 'Sortieren nach',
    totalSharing: 'Gesamte Vorteilsteilung:',
    dailyTask: 'Aktivitätsaufgabe heute',
    dailyStep: 'Schrittaufgabe heute',
    invitaedActivityCommision: ' Ebene Aktivitätskommission',
    invitaedStepCommision: ' Ebene Schrittkommission',
    year: ' Jahr ',
    month: ' Monat',
    incomeData: ' Einkommensdaten',
    commisionIncome: 'Provisions-Einkommen',
    chooseDate: 'Datum auswählen',
    cannotShare:
      'Sie können keine Vorteile mehr von diesem Freund teilen, da Sie keine Karte mit demselben Stern haben!',
    invite: 'Mitglied einladen',
    invite2: 'Mitglieder einladen',
    earnTogether: 'Gemeinsam verdienen und mehr verdienen',
    earnDesc:
      'Laden Sie mehr Mitglieder ein und helfen Sie ihnen, mit dem Verdienen zu beginnen, während Sie ihre Vorteile teilen können, wenn sie die Karte abschließen!',
    inviteCode: 'Ihr Einladungscode:',
    share: 'Mit Freund teilen',
    noMembers1: 'Keine Mitglieder gefunden',
    noMembers2:
      'Laden Sie mehr Mitglieder ein und helfen Sie ihnen, mit dem Verdienen zu beginnen, während Sie ihre Vorteile teilen können, wenn sie die Karte abschließen!',
    showingUsers: '{{ count }} Benutzer{{plural}} werden angezeigt',

    sort1: 'Karte Stern hoch bis niedrig',
    sort2: 'Karte Stern niedrig bis hoch',
    sort3: 'Gesamte Teilungen Hoch bis Niedrig',
    sort4: 'Gesamte Teilungen Niedrig bis Hoch',
    info1:
      '1. Um mehr Benutzer anzuziehen, um dem Futurestar-Ökosystem beizutreten, können Futurestar-Benutzer bis zu 10 Ebenen an Einladungsprämien erhalten',
    info2:
      '2. Jedes Mal, wenn Sie einen Benutzer direkt einladen, die Registrierung abzuschließen, können Sie eine Belohnung von 100 Sternen erhalten.',
    info3:
      '3. Jeder neu registrierte Benutzer kann eine Belohnung von 100 Sternen erhalten.',
    info4:
      '4. Nachdem der Eingeladene die tägliche Aufgabe abgeschlossen hat, kann der Einlader je nach Anzahl der erhaltenen Sternmünzen einen bestimmten Prozentsatz der Sternmünzen als Belohnung erhalten.',
    info5: 'Der spezifische Algorithmus lautet wie folgt:',
    info6: 'Neuer Stern: Stufe 1: 10%',
    info7: '\u2022 1 Stern: Stufe 1: 10%; Stufe 2: 5%',
    info8: '\u2022 2 Sterne: Stufe 1: 10%; Stufe 2: 5%, Stufe 3: 5%',
    info9:
      '\u2022 3 Sterne: Stufe 1: 10%; Stufe 2: 5%; Stufe 3: 5%; Stufe 4: 5%',
    info10:
      '\u2022 4 Sterne: Stufe 1: 10%; Stufe 2: 5%; Stufe 3: 5%; Stufe 4: 5%; Stufe 5: 3%; Stufe 6: 3%',
    info11:
      '\u2022 5 Sterne: Stufe 1: 10%; Stufe 2: 5%; Stufe 3: 5%; Stufe 4: 5%; Stufe 5: 3%; Stufe 6: 3%; Stufe 7: 3%; Stufe 8: 1%',
    info12:
      '\u2022 6 Sterne: Stufe 1: 10%; Stufe 2: 5%; Stufe 3: 5%; Stufe 4: 5%; Stufe 5: 3%; Stufe 6: 3%; Stufe 7: 3%; Stufe 8: 1%; Stufe 9: 1%; Stufe 10: 1%',
    info13:
      '5. Um die Einladungsprämie zu erhalten, muss der Einlader die entsprechende Sternbewertung haben. Wenn keine Sternbewertung vorliegt, kann die Einladungsprämie nicht erhalten werden.',
  },
  wallet: {
    spDescription:
      'Star Point (SP) kann im Verhältnis 1:1 in FSC umgetauscht werden. FSC kann durch die Flash-Austauschfunktion in USDT und BNB umgetauscht werden.',
    chooseNetwork: 'Hauptnetzwerk auswählen',
    title: 'Mein Wallet',
    fscBalance: 'FSC-Guthaben',
    usdtBalance: 'USDT-Guthaben',
    bnbBalance: 'BNB-Guthaben',
    view: 'Anzeigen',
    convertFSC: 'FSC umtauschen',
    buyFSC: 'FSC kaufen',
    history: 'Verlauf',
    historyEarn_zero: 'Einnahmen aus neuer Star-Karte',
    historyEarn: 'Einnahmen aus {{ star }}-Star-Karte',
    historyConvert: 'In USDT umtauschen',
    historyConvertFrom: 'Von FSC umtauschen',
    historyPurchase: 'Karte kaufen',
    withdraw: 'Abheben',
    withdrawTitle: 'USDT abheben',
    convertTitle: 'FSC umtauschen',
    toWallet: 'Auf Ihr Wallet',
    inputAddress: 'Bitte Empfängeradresse eingeben',
    from: 'Von',
    to: 'Zu',
    fscBalance2: 'FSC-Guthaben: {{ balance }}',
    usdtBalance2: 'USDT-Guthaben: {{ balance }}',
    bnbBalance2: 'BNB-Guthaben: {{ balance }}',
    fsc: 'FSC',
    usdt: 'USDT',
    processFee: 'Bearbeitungsgebühr',
    gasFee: 'Gasgebühr',
    remainingUSDT: 'Verbleibende USDT',
    remainingFSC: 'Verbleibende FSC',
    remaining: 'Verbleibende {{ currency }}',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    gotIt: 'Verstanden',
    withdrawingUSDT: 'USDT wird abgehoben',
    withdrawingUSDTDesc:
      'Wir tauschen USDT für Sie um. Sobald der Umtausch abgeschlossen ist, erhalten Sie USDT.',
    withdrawing: '{{ currency }} wird abgehoben',
    withdrawingDesc:
      'Wir heben {{ currency }} für Sie ab. Sobald die Abhebung abgeschlossen ist, erhalten Sie {{ currency }}.',
    convertingFSC: 'Wird zu {{ currency }} umgetauscht',
    convertingFSCDesc:
      'Wir tauschen {{ currency1 }} für Sie um. Sobald der Umtausch abgeschlossen ist, erhalten Sie {{ currency2 }}.',
    registerBonus: 'Registrierungsbonus',
    invitationBonus: 'Einladungsbonus',
    usdtWithdraw: 'USDT abheben',
    reminder:
      'Bitte beachten Sie, dass der Mindestabhebungsbetrag für {{ currency }} {{ minimum }} beträgt.',
    minimum:
      'Der Mindestabhebungsbetrag für {{ currency }} beträgt {{ minimum }} {{ currency }}.',

    inviteIncome: 'Einladungseinkommen',
    taskIncome: 'Aufgabeneinkommen',
    stepIncome: 'Einkommen durch Schritte',
    convert: 'Konvertieren',
    topUp: 'Aufladen',
    selectCrypto: 'Kryptowährung auswählen',
    tether: 'Tether (USDT)',
    binance: 'Binance Coin (BNB)',
    topUpTitle: '{{ currency }} aufladen',
    topUpDesc:
      'Scannen Sie den QR-Code, um den gewünschten Betrag aufzuladen. Die Transaktion dauert in der Regel etwa 10 Minuten. Wenn Sie Ihr Geld nicht erhalten, wenden Sie sich bitte an unseren ',
    customerService: 'Kundendienst',
    address: 'Adresse',
    scanTitle: 'QR-Code scannen',
    currencyWithdraw: '{{ currency }} auszahlen',
    currencyConvertTo: 'In {{ currencyTo }} konvertieren',
    currencyConvertFrom: 'Von {{ currencyFrom }} konvertieren',
    enableWithdraw: 'Überprüfen Sie Ihr Konto, um Auszahlungen zu ermöglichen.',
    totalAssets: 'Gesamtvermögen',
    topUpWarning:
      'Diese Adresse ist das Binance Smart Chain (BSC). Überweisen Sie nur BSC/BEP20-Vermögenswerte auf diese Adresse. Sie tragen persönlich die Verantwortung für den Verlust von Vermögenswerten aufgrund falscher Überweisungen.',
    assets: 'Vermögenswerte',
    starPoints: 'Sternpunkte',
    exchangeChain: 'Exchange-Kette',
    conversionRate: 'Wechselkurs',
    exchangeAmount: 'Austauschmenge',
    convertedAmount: 'Konvertierte Menge',
    paymentAddress: 'Zahlungsadresse',
    mainNetwork: 'Hauptnetzwerk',
    withdrawWarningBIANCE:
      'Dieses Netzwerk ist ein BSC-Netzwerk. Bitte stellen Sie sicher, dass Ihre Münzauszahlungsadresse das BNB Smart Chain (BEP20)-Netzwerk unterstützt. Jeder Kapitalverlust aufgrund falscher Überweisungen wird von Ihnen persönlich getragen.',
    withdrawWarningETHEREUM:
      'Dieses Netzwerk ist ein Ethereum-Netzwerk. Bitte stellen Sie sicher, dass Ihre Münzauszahlungsadresse das Ethereum (ERC20)-Netzwerk unterstützt. Jeder Kapitalverlust aufgrund falscher Überweisungen wird von Ihnen persönlich getragen.',
    withdrawWarningTRON:
      'Dieses Netzwerk ist ein TRON (TRC20)-Netzwerk. Bitte stellen Sie sicher, dass Ihre Münzauszahlungsadresse das TRON (TRC20)-Netzwerk unterstützt. Jeder Kapitalverlust aufgrund falscher Überweisungen wird von Ihnen persönlich getragen.',
    withdrawalAmount: 'Auszahlungsbetrag',
    receivingAmount: 'Empfangsbetrag',
    withdrawalFee: 'Auszahlungsgebühr',
    withdrawalArrive:
      'Auszahlungen treffen normalerweise innerhalb von 10 Minuten ein. Wenn der Betrag innerhalb von 10 Minuten nicht angekommen ist, wenden Sie sich bitte an unseren Kundendienst.',
    withdraw2: 'Auszahlung',
    minimumInput: 'Minimalwert: ',
    remainingBalance: 'Verbleibendes Guthaben: ',

    alle: 'Alle',
    totalIncome: 'Gesamteinkommen',
    myStarPoints: 'Meine Sternpunkte',
    todayTotalIncome: 'Gesamteinkommen heute:',
    exchangeFSCP: 'In FSC umtauschen',
    seeMore: 'Mehr anzeigen',
    starPointHistory: 'Sternpunkte-Verlauf',
    month: '{{ month }} Aufzeichnungen',
    totalStarPointIncome: 'Gesamte Sternpunkte-Einnahmen',
    totalExchanged: 'Insgesamt umgetauscht SP',
    income: 'Einkommen',
    expense: 'Ausgaben',
    thisMonth: 'Diesen Monat',
    selectedMonth: 'Monat auswählen',
    starPointRedeem: 'Sternpunkte einlösen',
    redeemAmount: 'Einlösebetrag',
    inputStarPoint: 'Sternpunkte eingeben',
    availableStarPoint: 'Verfügbares Guthaben',
    minStarPointRequest: 'Min. Umtauschmenge: 500 SP',
    fscObtained: 'Erhaltene FSC',
    fscpConvertStatus:
      'Sie sind derzeit ein/e {{ member }} und die Umtauschgebühr für Sternpunkte beträgt {{ fee }} %',
    feeRules1:
      'Eine Erhöhung Ihres Mitgliedslevels kann Ihre Gebühren senken. Siehe ',
    feeRules2: 'Gebührenregeln',
    confirmExchange: 'Umtausch bestätigen',
    fscp: 'SP',
    exchangeComplete: 'Umtausch von SP erfolgreich!',
    exchangeCompleteDesc1:
      'Wir werden innerhalb von 24 Stunden prüfen. Bitte haben Sie Geduld. Nach erfolgreicher Prüfung werden ',
    exchangeCompleteDesc2: ' in Ihre Brieftasche eingezahlt.',
    goToWallet: 'Zur Geldbörse gehen',
    understood: 'Verstanden',
    convertDetails: 'Umtauschdetails',
    successConvert: 'Umtausch erfolgreich',
    currencyConverted: 'Währung umgetauscht',
    convertAmount: 'Umtauschmenge',
    currencyReceived: 'Währung erhalten',
    receivedAmount: 'Erhaltener Betrag',
    conversionDate: 'Umtauschdatum',
    transactionDetails: 'Transaktionsdetails',
    successWithdraw: 'Auszahlung erfolgreich',
    sendingAddress: 'Sendeadresse',
    receivingAddress: 'Empfangsadresse',
    transactionHash: 'Transaktionshash',
    transactionDate: 'Transaktionsdatum',
    spConversionRule:
      'Die Umwandlung von SP wird manuell innerhalb von 24 Stunden bearbeitet. Wenn die Umwandlung länger als erwartet dauert, wenden Sie sich an den Kundenservice.',
    spPending: ' (Ausstehend)',
    spFailed: ' (Fehlgeschlagen)',
    spSuccess: ' (Erfolgreich)',
    spError: ' (Error)',
    spSendFailed: ' (Failed)',
    error1: 'Datensatz nicht verfügbar',
  },
  profile: {
    commissionUnlocked: 'Freigeschaltete Provisionseinnahmen',
    popupDetail1:
      'Sie haben die Provisionseinnahmen SP erfolgreich freigeschaltet. Ihre derzeitige Teamleistung beträgt',
    popupDetail2: 'der insgesamt freigeschalteten Provisionseinnahmen',
    popupDetail3:
      'Ihre freigeschaltete Provision wird automatisch auf Ihr Vermögensportemonnaie gebucht.',
    power: 'Leistung',
    locked: 'Gesperrt',
    unlocked: 'Entsperrt',
    commissionEarned: 'Provisions-Einkommen',
    totalCommissionEarned: 'Gesamtes Provisions-Einkommen',
    totalUnlockableCommission: 'Gesamte entsperrbare Provisionseinnahmen',
    unlockedCommission: 'Freigeschaltete Provision',
    currentTeamPower: 'Aktuelle Teamstärke',
    teamPower: 'Teamstärkewert',
    toUnlockRatio: 'Entsprechende Freischaltungsrate',
    unlockDetails:
      'Freischaltungsrate pro Mal: Abhängig von der Teamstärke des eingeladenen Benutzers. Je höher die Teamstärke, desto höher die Freischaltungsrate pro Mal.',
    teamUserSP: 'Sternbewertung des unteren Benutzers',
    unlockTimeDescribe:
      'Jede Einladung zu einem Ein-Sterne-Partner kann eine Entsperrungsmöglichkeit erhalten',
    powerIncreasable: 'Erhöhbarer Leistungswert',
    oneStar: 'Ein Stern',
    twoStar: 'Zwei Sterne',
    threeStar: 'Drei Sterne',
    fourStar: 'Vier Sterne',
    fiveStar: 'Fünf Sterne',
    sixStar: 'Sechs Sterne',
    powerToUnlockRatio: 'Freischaltungsrate, die dem Teamstärkewert entspricht',
    includes: 'Enthält',
    above: 'Über',
    unlockSP: 'Entsperren',
    unlockTimeLeft: 'Verbleibende Freischaltungszeiten',
    taskActivityCommission: 'Aktivitätsprovision für Aufgaben',
    stepActivityCommission: 'Aktivitätsprovision für Schritte',
    spUnlock: 'SP entsperren',
    userLoginPassword: 'Benutzeranmeldepasswort',
    resetPaymentPassword: 'Zahlungspasswort zurücksetzen',
    repeatNewPaymentPassword: 'Neues Zahlungspasswort wiederholen',
    newPaymentPassword: 'Neues Zahlungspasswort',
    currentPaymentPassword: 'Aktuelles Zahlungspasswort',
    changePaymentPassword: 'Zahlungspasswort ändern',
    paymentPassword: 'Zahlungspasswort',
    aboutUs: 'Über uns',
    companyIntro:
      'FUTURE STAR GROUP ist ein Unternehmen, das durch NFT und innovative Medienökologie eine offene Metaverse aufbaut. Future Star wurde im Silicon Valley in den USA gegründet. Gleichzeitig verfügt es über mehr als 400 Forschungs- und Entwicklungsteams und Betriebsteams in Singapur, Japan, der Schweiz und anderen Orten. Über 70% der Teammitglieder haben an den bekannten Ivy-League-Schulen in den USA studiert. Future Star hat das Unternehmen durch Pionierarbeit und Innovationen bei "dezentralisierten" In-Game-Belohnungen und Monetarisierung von Videospielen als eine der weltweit führenden Technologiegruppen in Blockchain und Gaming etabliert. \r\n\nFuture Star basiert auf dem umfassenden Rahmen von X to Earn: Durch den Mechanismus der Check-in-Verwaltung wird ein neuer Weg des grünen Lebens mit Move to Earn befürwortet und das Ziel der globalen Kohlenstoffneutralität erreicht; Watch and Engage to Earn, konzentriert sich auf Realisierung, Medienparadigma-Innovation, Trend-Web3-Metaverse-Medienökologie Die neue Ökologie hat die Transformation von traditionellen Medien in digitale Medien perfekt realisiert. Mit dem Unternehmensmarkenkonzept "sich jedem Unternehmen verpflichten, von der Welt erinnert zu werden" wurde der "300-Millionen-Fans-Drainage-Plan" gestartet und eine Community-Fan-Gruppe gegründet, um globale Branchenvorteile zu teilen. Future Star konzentriert sich darauf, eine Web3.0-Meta-Universums-Werbungsebene aufzubauen und einen geschlossenen Kreislauf von strategischem Design bis hin zu Benutzerdaten, Marketing-Tools und kreativer Kommunikation zu realisieren, um die unendliche Zukunft der Werbebranche zu inspirieren. \r\n\nFuture Star hat 140 Millionen US-Dollar von bekannten Institutionen wie Metaverse Tech, INK, Sequoia India und MW9 erhalten. Das Unternehmen hat mehr als 100 Kernpatente im Medienbereich sowie die Dubai Digital Currency Exchange-Lizenz und die US MSB Digital Currency. Kernqualifikationen wie Handelslizenzen.',
    editProfile: 'Profil bearbeiten',
    account: 'Konto',
    language: 'Sprache',
    logOut: 'Abmelden',
    changeLanguage: 'Sprache ändern',
    changPassword: 'Passwort erfolgreich geändert.',
    resetPassword: 'Passwort zurücksetzen',
    resetPassDesc: 'Bitte setzen Sie Ihr Passwort zurück',
    currentPass: 'Aktuelles Passwort',
    newPass: 'Neues Passwort',
    repeatPass: 'Passwort wiederholen',
    email: 'E-Mail',
    name: 'Name',
    password: 'Passwort',
    confirm: 'Bestätigen',

    Abbrechen: 'Abbrechen',
    changePhoto: 'Foto ändern',
    yourUsername: 'Ihr Benutzername',
    save: 'Speichern',
    phoneNumber: 'Telefonnummer',
    contactUs: 'Kontaktiere uns',
    contactTelegram: 'Kontaktiere uns auf Telegram',
    joinTelegram: 'Oder trete der Telegram-Gruppe bei',
    contactWhatsApp: 'Kontaktiere uns auf WhatsApp',
    joinWhatsApp: 'Oder trete der WhatsApp-Gruppe bei',
    idVerif: 'Ausweisverifizierung',
    confirmInfo: 'Informationen bestätigen',
    confirmInfoDesc:
      'Der Vorname und das Geburtsdatum unten müssen mit Ihrem amtlichen Ausweis übereinstimmen. Bitte überprüfen Sie die Informationen unten, bevor Sie den Verifizierungsprozess fortsetzen.',
    firstName: 'Vorname',
    lastName: 'Nachname',
    dateOfBirth: 'Geburtsdatum',
    inputFirstName: 'Vorname eingeben',
    inputLastName: 'Nachname eingeben',
    chooseCountry: 'Ausstellungsland/Region auswählen',
    selectIdType: 'Ausweisart auswählen',
    inputIdNumber: 'Ausweisnummer eingeben',
    inputNumber: 'Geben Sie hier Ihre Nummer ein',
    uploadPhoto: 'Ausweisfoto hochladen',
    uploadFile: 'Datei hochladen',
    redo: 'Wiederholen',
    upload: 'Hochladen',
    idSubmitted: 'Ausweisverifizierung eingereicht!',

    weWillReview:
      'Wir werden Ihre ID so schnell wie möglich überprüfen. Bitte haben Sie Geduld, wir werden Sie informieren, wenn sie genehmigt ist!',
    next: 'Nächstes',
    close: 'Schließen',
    idCard: 'Ausweis',
    passport: 'Reisepass',
    driver: 'Führerschein',
    inProgress: 'In Bearbeitung',
    idVerified: 'ID verifiziert',
    idUnVerified: 'ID nicht verifiziert',
    failed: 'Fehlgeschlagen',
    failedTitle: 'ID-Verifizierung fehlgeschlagen',
    failedDesc:
      'Es tut uns leid, wir können Ihre ID nicht verifizieren, weil {{ reason }}. Bitte versuchen Sie es erneut.',
    verifyId: 'ID verifizieren',
    later: 'Später',
    personalCenter: 'Persönliches Zentrum',
    checkedIn: 'Eingecheckt',
    checkIn: 'Einchecken',
    currentRating: 'Aktuell: ',
    viewStarCard: 'Star-Karte ansehen',
    wallet: 'Mein Wallet',
    details: 'Details',
    totalEarning: 'Gesamteinnahmen',
    totalBalance: 'Gesamtguthaben',
    members: 'Meine Mitglieder',
    totalPeople: 'Gesamtzahl der Personen',
    addedToday: 'Heute hinzugefügt',
    withdraw: 'Abheben',
    settings: 'Einstellungen',
    verified: 'Verifiziert',
    basicInfo: 'Grundlegende Informationen',
    uploadID: 'ID hochladen',
    submit: 'Absenden',
    'verify-1':
      'Bitte stellen Sie sicher, dass die echte Identität verwendet wird, sonst wird die Authentifizierung fehlschlagen. Die von Ihnen eingereichten Informationen werden nur für die Future Star-Authentifizierung verwendet, und wir werden Ihre Informationen verschlüsseln.',
    'verify-2':
      '* Die Informationen müssen mit den Informationen auf dem Zertifikat übereinstimmen',
    uploadPhoto2: 'Hochladen von persönlicher Unterschrift und ID-Frontfoto',
    uploadID2: 'ID hochladen',
    uploadFront: 'ID-Front hochladen',
    uploadBack: 'ID-Rückseite hochladen',
    uploadSignature: 'Persönliche Unterschrift und ID-Frontfoto hochladen',
    'front-desc':
      'Stellen Sie während des Fotografierens sicher, dass der Zertifikatrahmen vollständig ist, die Schriftart klar und die Helligkeit gleichmäßig ist.',
    'back-desc':
      'Stellen Sie während des Fotografierens sicher, dass der Zertifikatrahmen vollständig ist, die Schriftart klar und die Helligkeit gleichmäßig ist.',
    'sign-desc':
      'Bitte laden Sie ein Foto von sich hoch, auf dem Sie den "Vorderseite des Zertifikats" und die "persönliche Unterschrift" halten. Der Inhalt der persönlichen Unterschrift muss "Future Star" und das Datum des Tages enthalten. Stellen Sie sicher, dass der Inhalt des Fotos klar sichtbar ist.',
    standard: 'Standard',
    lostEdge: 'Kanten verloren',
    blurry: 'Unscharf',
    overExposure: 'Überbelichtet',
    skip: 'Überspringen',
    prev: 'Zurück',

    next2: 'Weiter',
    understood: 'Verstanden',
    uploadFront2: 'Vorderseite hochladen',
    uploadBack2: 'Rückseite hochladen',
    uploadPhoto3: 'Foto hochladen',
    error1:
      'Dieses Ausweisdokument wurde bereits für die Überprüfung verwendet, bitte verwenden Sie ein anderes.',
    error2: 'Der Server ist beschäftigt, bitte versuchen Sie es später erneut.',
  },
  auth: {
    inputPaymentPassword: 'Bitte geben Sie das Zahlungspasswort ein',
    success1: 'Das Zahlungspasswort wurde erfolgreich eingerichtet',
    setPayPasswordDiscribe:
      'Um Ihre Vermögenswerte besser zu schützen, richten Sie bitte ein Zahlungspasswort für Zahlungen, Überweisungen und Bargeldabhebungen ein.',
    setPayPassword: 'Zahlungspasswort einrichten',
    forgotPassword: 'Passwort vergessen',
    forgotTitle: 'Passwort vergessen?',
    forgotDesc: 'Keine Sorge, setzen Sie Ihr Passwort jetzt zurück!',
    email: 'E-Mail-Adresse',
    verificationCode: 'Verifizierungscode',
    resetTitle: 'Passwort zurücksetzen',
    resetDesc: 'Bitte setzen Sie Ihr Passwort zurück',
    password: 'Passwort',
    repeatPass: 'Passwort wiederholen',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    resetPassword: 'Passwort zurücksetzen',
    loginTitle: 'Willkommen zurück',
    loginDesc:
      'Kommen Sie zurück und starten Sie heute wieder mit dem Geldverdienen!',
    forgotQ: 'Passwort vergessen?',

    signup: 'Registrieren',
    signUpTitle: 'Legen Sie Ihre Anmeldeinformationen fest',
    signUpDesc: 'Bitte geben Sie uns zuerst Ihre Anmeldeinformationen',
    inputEmail: 'E-Mail-Adresse eingeben',
    inputPass: 'Passwort eingeben',
    inputVerification: 'Verifizierungscode eingeben',
    inputInvite: 'Einladungscode eingeben',
    inputUsername: 'Benutzername eingeben',
    sendCode: 'Code senden',
    readAgree: 'Ich habe sorgfältig gelesen und stimme zu ',
    userAgree: 'Nutzungsbedingungen ',
    and: 'und ',
    privacyPolicy: 'Datenschutzerklärung',
    haveAccount: 'Ich habe bereits ein Konto',
    noAccount: 'Ich habe kein Konto',
    personalTitle: 'Ihre persönlichen Informationen',
    personalDesc:
      'Bitte überprüfen Sie Ihre E-Mail-Adresse für Ihre Sicherheit',
    username: 'Ihr Benutzername',
    invitation: 'Einladungscode',
    letsEarn: 'Verdienen wir Geld!',
    login: 'Einloggen',
    passChangeSuccess: 'Das Passwort wurde geändert',
    phone: 'Telefon',
    phone2: 'Telefonnummer',
    inputPhone: 'Telefonnummer eingeben',
    email2: 'E-Mail',
    codeTitle: 'Bestätigen Sie Ihren\nVerifizierungscode',
    codeDescPhone:
      'Wir haben einen Code an Ihre Telefonnummer gesendet. Bitte bestätigen Sie.',
    codeDescEmail:
      'Wir haben einen Code an Ihre E-Mail-Adresse gesendet. Bitte bestätigen Sie.',
    resendCode: 'Code erneut senden',
    captcha: 'Bitte beantworten Sie CAPTCHA',
    bannedTitle: 'Etwas stimmt nicht mit Ihrem Konto',
    bannedDesc:
      'Es tut uns leid, wir haben festgestellt, dass Ihr Konto abnorm ist. Das Konto wurde vorübergehend gesperrt. Wenn Sie Einwände haben, kontaktieren Sie bitte den Kundenservice',
    bannedConfirm: 'Kundendienst kontaktieren',
    understood: 'Verstanden',
    continue: 'Fortsetzen',
    signupComplete: 'Sie sind registriert!',
    pleaseLogin:
      'Ihre Telefonnummer wurde erfolgreich registriert. Bitte loggen Sie sich in Ihr Konto ein, um fortzufahren',
    okay: 'Okay',

    error1: 'Benutzer existiert nicht',
    error2:
      'Dein Passwort war falsch oder dieser Account wurde nicht registriert. Bitte versuche es erneut.',
    error3: 'Passwörter stimmen nicht überein',
    error4: 'Muss mindestens 8 Zeichen lang sein',
    error5: 'Benutzer existiert bereits',
    error6: 'Falscher Code',
    error7: 'Der Server ist beschäftigt, bitte versuchen Sie es später erneut.',
    error8: 'Ungültiger Code',
    error9: 'Authentifizierungsfehler! Bitte erneut einloggen.',
    error10:
      'Diese Telefonnummer wurde bereits registriert. Bitte logge dich ein.',
    error11:
      'Diese E-Mail-Adresse wurde bereits registriert. Bitte logge dich ein.',
    error12: 'Bitte gib ein',
    error13: 'Falsches CAPTCHA',
    error14:
      'Benutzer hat die Anmeldung nicht abgeschlossen. Bitte gehe zur Anmeldung.',
    error15:
      'Dieser Account wurde nicht registriert. Bitte gehe zur Anmeldung.',
    error16: 'Dein Passwort war falsch. Bitte versuche es erneut.',
  },
  activity: {
    Titel: 'Aktivität',
    subTitle: 'Erledigen Sie Aufgaben, um die Vorteile von heute zu verdienen',
    youCanEarn: 'Sie können verdienen',
    yourStarCard: 'Mit Ihren Star-Karten',
    viewCard: 'Karten ansehen',
    calculateionMethod: 'Aktivitätsberechnungsmethoden',
    method1:
      'Beim Durchsuchen von Artikeln erhalten Sie 2 Aktivitätspunkte pro Minute. (Unbegrenzt)',
    method2:
      'Liked einen Artikel, um 5 Aktivitätspunkte zu erhalten. (Die Obergrenze der Aktivität beträgt 30)',
    method3:
      'Durchsuchen Sie eine Anzeige und erhalten Sie 15 Aktivitätspunkte. (Die Obergrenze der Aktivitätspunkte beträgt 45)',
    method4:
      'Kommentieren oder Antworten auf Artikel, um 10 Aktivitätspunkte zu erhalten. (Die Obergrenze der Aktivitätspunkte beträgt 30)',
    method5:
      'Beim Durchsuchen von Videos erhalten Sie 10 Aktivitätspunkte pro Minute. (Die Obergrenze der Aktivität beträgt 30)',
    complete25: '25 Aktivitätspunkte verdient! Beeilen Sie sich!',
    complete50: '50 Aktivitätspunkte verdient! Halbzeit!',
    complete75: '75 Aktivitätspunkte verdient! Fast da!',
    completeTitle: 'Glückwunsch! Sie haben verdient!',
    completeDesc:
      'Sie haben 100 Aktivitätspunkte erreicht, Ihr Verdienst beträgt:',
    completeFooter:
      'Ihr Verdienst wird innerhalb von 10 Minuten an Ihre Brieftasche gesendet',
    noCard:
      'Sie haben noch keine Star-Karten, Sie müssen eine Star-Karte erhalten, um Aktivitätspunkte zu sammeln.',
    getCard: 'Jetzt Star-Karten erhalten',
  },
  language: {
    set: 'Sprache einstellen',
    ready: 'Ich bin bereit',
  },
  word: {
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    share: 'Teilen',
    copy: 'Kopieren',
    Zero: 'Null',
    One: 'Eins',
    Two: 'Zwei',
    Three: 'Drei',
    Four: 'Vier',
    Five: 'Fünf',
    Six: 'Sechs',
    Seven: 'Sieben',
    Eight: 'Acht',
    Nine: 'Neun',
  },
  videoAds: {
    next: 'Weiter',
    finish: 'Beenden',
    goBackTitle: 'Bist du sicher, dass du zurückgehen möchtest?',
    goBackContent:
      'Du wirst die Belohnung für das unvollendete Video verlieren.',
  },
  oldVersion: {
    text1:
      'Wir haben festgestellt, dass Sie eine alte Version der Future Star-App verwenden.',
    text2: 'Sie können die neueste Version unter folgendem Link herunterladen:',
    text3: 'Neue Version entdeckt!',
    text4: 'Aktualisieren',
    text5: 'APK-Download wurde gestartet',
    text6:
      'Download abgeschlossen. Bitte überprüfen Sie Ihre Downloads und installieren Sie die neue Version der Future Star-App.',
  },
  fitness: {
    modalGoogle:
      'Erlaube Future Star Zugriff auf Aktivität für "Move to Earn" Funktion!',
    modalGoogle2: 'Du musst zuerst die Google Fit App installieren!',
    modalApple:
      'Erlaube Future Star Zugriff auf Core Motion für "Move to Earn" Funktion!',
    modalDesc:
      'Wir müssen deine Schritte verfolgen, damit du von unserer "Move to Earn" Funktion profitieren kannst!',
    confirm: 'Ja',
    deny: 'Nein, danke',
    title: 'Move To Earn',
    steps: 'Schritte',
    keepMoving: 'Bewege dich weiter, um {{ amount }} SP zu verdienen',
    complete: 'Herzlichen Glückwunsch! Du hast {{ amount }} SP verdient',
    tired: 'Müde geworden? Sieh dir Anzeigen an, um weiterhin zu verdienen',
    viewHistory: 'Zeige Move To Earn-Verlauf an',
    finished: 'Fertiggestellt',
    warning:
      'Wir zählen deine Schritte nicht, wenn du diese App schließt. Minimieren ist erlaubt.',
    authorizeTitle: 'Erlaube Future Star den Zugriff auf deine Daten',
    authorizeDesc:
      'Um dir bei der täglichen Gehaufgabe besser helfen zu können, musst du Future Star den Zugriff auf Motion- und Fitnessberechtigungen deines Mobiltelefons ermöglichen, die in den ',
    authorizeIOS:
      'Telefon-Einstellungen > Datenschutz & Sicherheit > Motion und Fitness',
    authorizeAndroid:
      'Telefon-Einstellungen > Apps > Future Star > Berechtigungen',
    authorizeGo: 'Zur Autorisierung gehen',
    skip: 'Überspringen',
  },
  countries: {
    select: 'Land auswählen',
    china: 'China',
    us: 'Vereinigte Staaten',
    canada: 'Kanada',
    dominican: 'Dominikanische Republik',
    costa: 'Costa Rica',
    honduras: 'Honduras',
    nicaragua: 'Nicaragua',
    mexico: 'Mexiko',
    austria: 'Österreich',
    belgium: 'Belgien',
    bulgaria: 'Bulgarien',
    croatia: 'Kroatien',
    czech: 'Tschechische Republik',
    denmark: 'Dänemark',
    germany: 'Deutschland',
    estonia: 'Estland',
    finland: 'Finnland',
    france: 'Frankreich',
    greece: 'Griechenland',
    hungary: 'Ungarn',
    iceland: 'Island',
    ireland: 'Irland',
    italy: 'Italien',
    lithuania: 'Litauen',
    luxembourg: 'Luxemburg',
    latvia: 'Lettland',
    malta: 'Malta',
    netherlands: 'Niederlande',
    norway: 'Norwegen',
    poland: 'Polen',
    portugal: 'Portugal',
    romania: 'Rumänien',
    spain: 'Spanien',
    sweden: 'Schweden',
    switzerland: 'Schweiz',
    uk: 'Vereinigtes Königreich',
    brazil: 'Brasilien',
    venezuela: 'Venezuela',
    malaysia: 'Malaysia',
    singapore: 'Singapur',
    indonesia: 'Indonesien',
    vietnam: 'Vietnam',
    turkey: 'Türkei',

    anguilla: 'Anguilla',
    antiguaBarbuda: 'Antigua und Barbuda',
    aruba: 'Aruba',
    ascension: 'Ascension',
    bahamas: 'Bahamas',
    barbados: 'Barbados',
    belize: 'Belize',
    bermuda: 'Bermuda',
    caymanIslands: 'Kaimaninseln',
    costaRica: 'Costa Rica',
    cuba: 'Kuba',
    curacao: 'Curaçao',
    dominica: 'Dominica',
    dominicanRepublic: 'Dominikanische Republik',
    elSalvador: 'El Salvador',
    greenland: 'Grönland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guatemala: 'Guatemala',
    haiti: 'Haiti',
    jamaica: 'Jamaika',
    martinique: 'Martinique',
    montserrat: 'Montserrat',
    panama: 'Panama',
    peurtoRico: 'Puerto Rico',
    kittsNevis: 'St. Kitts und Nevis',
    stLucia: 'St. Lucia',
    pierreMiquelon: 'St. Pierre und Miquelon',
    vincentGrenadines: 'St. Vincent und die Grenadinen',
    trinidadTobago: 'Trinidad und Tobago',
    turksCaicos: 'Turks- und Caicosinseln',
    virginBritish: 'Britische Jungferninseln',
    virginUS: 'Amerikanische Jungferninseln',
    afghanistan: 'Afghanistan',
    armenia: 'Armenien',
    azerbaijan: 'Aserbaidschan',
    bahrain: 'Bahrain',
    bangladesh: 'Bangladesch',
    bhutan: 'Bhutan',
    brunei: 'Brunei Darussalam',
    cambodia: 'Kambodscha',
    timor: 'Timor-Leste',
    hongKong: 'Hongkong',
    india: 'Indien',
    iran: 'Iran',
    iraq: 'Irak',
    israel: 'Israel',
    japan: 'Japan',
    jordan: 'Jordanien',
    northKorea: 'Nordkorea',
    southKorea: 'Südkorea',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kirgisistan',
    laos: 'Laos',
    lebanon: 'Libanon',
    macao: 'Macao',
    maldives: 'Malediven',
    mongolia: 'Mongolei',
    myanmar: 'Myanmar',
    nepal: 'Nepal',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palestine: 'Palästina',
    philippines: 'Philippinen',
    qatar: 'Katar',
    russia: 'Russland',
    saudiArabia: 'Saudi-Arabien',
    sriLanka: 'Sri Lanka',
    syria: 'Syrien',
    taiwan: 'Taiwan',
    tajikistan: 'Tadschikistan',
    thailand: 'Thailand',
    turkiye: 'Türkei',
    turkmenistan: 'Turkmenistan',
    unitedArab: 'Vereinigte Arabische Emirate',
    uzbekistan: 'Usbekistan',

    yemen: 'Jemen',
    albania: 'Albanien',
    andorra: 'Andorra',
    belarus: 'Weißrussland',
    bosnia: 'Bosnien und Herzegowina',
    canary: 'Kanarische Inseln',
    cyprus: 'Zypern',
    faroe: 'Färöer',
    gibraltar: 'Gibraltar',
    kosovo: 'Kosovo',
    liechtenstein: 'Liechtenstein',
    moldova: 'Republik Moldau',
    monaco: 'Monaco',
    montenegro: 'Montenegro',
    macedonia: 'Republik Nordmazedonien',
    sanMarino: 'San Marino',
    serbia: 'Serbien',
    slokavia: 'Slowakei',
    slovenia: 'Slowenien',
    ukraine: 'Ukraine',
    vatican: 'Vatikanstadt',
    argentina: 'Argentinien',
    bolivia: 'Bolivien',
    chile: 'Chile',
    colombia: 'Kolumbien',
    ecuador: 'Ecuador',
    falkland: 'Falklandinseln',
    frenchGuiana: 'Französisch-Guayana',
    guyana: 'Guyana',
    paraguay: 'Paraguay',
    peru: 'Peru',
    suriname: 'Suriname',
    algeria: 'Algerien',
    angola: 'Angola',
    benin: 'Benin',
    botswana: 'Botswana',
    burkinaFaso: 'Burkina Faso',
    burundi: 'Burundi',
    cameroon: 'Kamerun',
    capeVerde: 'Kap Verde',
    centralAfrica: 'Zentralafrikanische Republik',
    chad: 'Tschad',
    comoros: 'Komoren',
    congo: 'Kongo',
    congoDem: 'Demokratische Republik Kongo',
    djibouti: 'Dschibuti',
    egypt: 'Ägypten',
    equatorialGuinea: 'Äquatorialguinea',
    eritrea: 'Eritrea',
    ethiopia: 'Äthiopien',
    gabon: 'Gabun',
    gambia: 'Gambia',
    ghana: 'Ghana',
    guinea: 'Guinea',

    guineaBissau: 'Guinea-Bissau',
    ivoryCoast: 'Elfenbeinküste',
    kenya: 'Kenia',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libyen',
    madagascar: 'Madagaskar',
    malawi: 'Malawi',
    mali: 'Mali',
    mauritania: 'Mauretanien',
    mauritius: 'Mauritius',
    morocco: 'Marokko/Westsahara',
    mozambique: 'Mosambik',
    namibia: 'Namibia',
    niger: 'Niger',
    nigeria: 'Nigeria',
    mayotte: 'Mayotte',
    rwanda: 'Ruanda',
    saoTome: 'São Tomé und Príncipe',
    seychelles: 'Seychellen',
    sierraLeone: 'Sierra Leone',
    somalia: 'Somalia',
    southAfrica: 'Südafrika',
    southSudan: 'Südsudan',
    sudan: 'Sudan',
    swaziland: 'Eswatini',
    tanzania: 'Tansania',
    togo: 'Togo',
    tunisia: 'Tunesien',
    uganda: 'Uganda',
    zambia: 'Sambia',
    zimbabwe: 'Simbabwe',
    americanSamoa: 'Amerikanisch-Samoa',
    australia: 'Australien',
    cookIslands: 'Cookinseln',
    fiji: 'Fidschi',
    frenchPolynesia: 'Französisch-Polynesien',
    guam: 'Guam',
    kiribati: 'Kiribati',
    marshallIslands: 'Marshallinseln',
    micronesia: 'Mikronesien',
    newCaledonia: 'Neukaledonien',
    newZealand: 'Neuseeland',
    niue: 'Niue',
    norfolk: 'Norfolkinsel',
    marianaIslands: 'Nördliche Marianen',
    palau: 'Palau',
    papua: 'Papua-Neuguinea',
    samoa: 'Samoa',
    solomon: 'Salomonen',
    tonga: 'Tonga',
    tuvalu: 'Tuvalu',
    vanuatu: 'Vanuatu',
    wallisFutuna: 'Wallis und Futuna',
    georgia: 'Georgien',
    uruguay: 'Uruguay',
    senegal: 'Senegal',
  },
  Grund: {
    alreadyExists: 'Ihre ID existiert bereits in unserem System',
    doesNotMatch: 'Ihre Informationen stimmen nicht mit dem Bild überein',
    invalidPicture: 'Ihr hochgeladenes Bild konnte nicht erkannt werden',
  },
  homeV1: {
    upgradeStar: 'Holen Sie sich eine Star-Karte, um Belohnungen zu verdienen',
    activityTitle: 'Aktivitätsaufgabe',
    activityDesc:
      'Erreichen Sie 100 Aktivitätspunkte, um {{FSC}} SP zu verdienen',
    moveToEarn: 'Schrittzähler-Aufgabe',
    moveToEarnDesc: 'Gehen Sie 4000 Schritte, um {{FSC}} SP zu verdienen',
    checkIn: 'Einchecken',
    headlines: 'Schlagzeilen',
    wheel: 'Rad',
    lottery: 'Lotterie',
    pet: 'Star-Haustiere',
    recommended: 'Empfohlen für Sie',
    inviteFriends: 'Freunde einladen und verdienen!',
    inviteFriendsDesc: 'Verdienen Sie USDT, indem Sie neue Benutzer einladen',
    watchNews: 'Lesen und verdienen',
    watchNewsDesc: 'Um die Welt kennen zu lernen',
    watchAds: 'Ansehen und verdienen',
    watchAdsDesc: 'Alles, was Sie mögen, ist hier',
    walking: 'Bewegen und verdienen',
    walkingDesc: 'Bewegung hört nie auf',
    starPet: 'Star-Haustiere',
    starPetDesc: 'Adoptiere dein NFT-Haustier',
    welcome: 'Willkommen bei Future Star!',
    welcomeDesc:
      'Sie können nur Vorteile genießen, nachdem Sie sich angemeldet und Ihre Star-Karte erhalten haben!',
    loginFirst: 'Bitte loggen Sie sich zuerst bei Future Star ein!',
    loginFirstDesc:
      'Melden Sie sich bei Future Star an, genießen Sie Ihre Mitgliedschaft und öffnen Sie mehr Funktionen. Erhalten Sie eine Star-Karte und teilen Sie Millionen von Einnahmen!',
    loginNow: 'Jetzt anmelden',
    comingSoon: 'Diese Funktion wird entwickelt',
    comingSoonDesc:
      'Wir arbeiten hart an der Entwicklung dieser Funktion. Bitte halten Sie Ausschau nach Future Star und Sie können bald diese Funktion erleben!',
  },
  tasks: {
    myTasks: 'Meine Aufgaben',
    totalCurrentIncome: 'Gesamteinkommen heute',
    totalEarning:
      'Die Obergrenze des aktuellen Einkommens beträgt {{ FSC }} SP',
    upgradeStar: 'Star Card aufrüsten, um höhere Belohnungen zu erhalten',
    activityTaskIncome: 'Einkommen aus Aktivitätsaufgaben',
    walkToEarnIncome: 'Einkommen aus Schrittaufgaben',
    dailyCheckin: 'Täglicher Check-In',
    checkInRules: 'Regeln',
    day1: 'Tag 1',
    day2: 'Tag 2',
    day3: 'Tag 3',
    day4: 'Tag 4',
    day5: 'Tag 5',
    day6: 'Tag 6',
    day7: 'Tag 7',
    consecutiveDays1: 'Sie haben für ',
    consecutiveDays2: ' Tag{{ plural }} eingecheckt.',
    cumulativeEarning: 'Insgesamt verdient ',
    signInNow: 'Jetzt einchecken',
    beginnerTask: 'Aufgaben für neue Benutzer',
    earn: '{{ FSC }} SP verdienen!',
    checkOut: 'Lesen Sie den Artikel zum Benutzer-Tutorial',
    viewIntro: 'Schauen Sie sich die Einführung in Future Star an',
    completeVerification: 'Identitätsprüfung abschließen',
    receiveStarCard: 'Eine Star Card erhalten',
    commissionIncome: 'Übertragung von Provisionseinnahmen',
    completeActivity: 'Erreichen Sie zum ersten Mal 100 Aktivitätspunkte',
    completeWalk: 'Erreichen Sie zum ersten Mal 4000 Schritte',
    completeInvite: 'Laden Sie zum ersten Mal neue Benutzer zur Anmeldung ein',
    completeConvert: 'Tauschen Sie zum ersten Mal FSC in USDT um',
    completeWithdraw: 'Heben Sie zum ersten Mal USDT ab',
    topUp: 'Laden Sie zum ersten Mal Guthaben auf',
    reward: 'Belohnung',
    completed: 'Abgeschlossen',
    toFinish: 'Los gehts',
    congratulations: 'Herzlichen Glückwunsch',
    signinSuccess: 'Erfolgreich eingeloggt! Sie haben Ihre Belohnung erhalten.',
    getStarCard: 'Bitte holen Sie sich zuerst Ihre Star Card',

    getStarCardDesc:
      'Sie können nur Vorteile erhalten, nachdem Sie eine Star-Karte erhalten haben!',
    getItNow: 'Jetzt erhalten',
    noTaskCardTitle: 'Sie haben noch keine Aktivitätskarten',
    noStepCardTitle: 'Sie haben noch keine Schrittkarten',
    noCardSubTitle: 'Erhalten Sie Ihre Star-Karte, um Vorteile zu erlangen!',
    dailyRule1:
      '1. Jede Runde des täglichen Check-Ins dauert bis zu 7 Tage und entsprechende Belohnungen können nach erfolgreichem täglichen Check-In erhalten werden.',
    dailyRule2:
      '2. Sie müssen aufeinanderfolgend einchecken, um alle Belohnungen zu erhalten. Wenn Sie einen Tag verpassen, beginnt Ihre Streifenfolge von neuem.',
    dailyRule3:
      '3. Nach Abschluss von 7 aufeinanderfolgenden Check-Ins beginnt der tägliche Check-In wieder am ersten Tag.',
    dailyRule4:
      'Check-in-Belohnung: {{ 0 }} SP am ersten Tag, {{ 1 }} SP am zweiten Tag, {{ 2 }} am dritten Tag, {{ 3 }} am vierten Tag, {{ 4 }} am fünften Tag, {{ 5 }} am sechsten Tag und {{ 6 }} am siebten Tag.',
    cardRule:
      'Die Obergrenze des täglichen SP-Verdienstes wird durch die Anzahl und das Level Ihrer Star-Karten bestimmt. Wenn Sie mehr verdienen möchten, gehen Sie bitte zur Star-Karte-Seite und holen Sie sich weitere Star-Karten',
  },
  axios: {
    error1:
      'Sie haben Netzwerkprobleme. Bitte verwenden Sie die App in einer stabilen Netzwerkumgebung.',
    error2: 'Sitzung abgelaufen, bitte melden Sie sich erneut an.',
    error3: 'Der Server ist beschäftigt, bitte versuchen Sie es später erneut.',
    error4: 'Altes Passwort falsch.',
    error5: 'Die beiden neuen Passwörter stimmen nicht überein.',
    error6: 'Das neue Passwort darf nicht leer sein.',
  },
  invite: {
    january: 'Jan',
    february: 'Feb',
    march: 'März',
    april: 'Apr',
    may: 'Mai',
    june: 'Jun',
    july: 'Jul',
    august: 'Aug',
    september: 'Sep',
    october: 'Okt',
    november: 'Nov',
    december: 'Dez',
    title: 'Freunde einladen',
    catchphrase:
      'Laden Sie herunter und registrieren Sie sich, um 28 USDT zu verdienen',
    inviteFriend: 'Freund einladen',
    inviteCode: 'Einladungscode',
    myInvite: 'Meine Einladung',
    person: 'Person',
    people: 'Personen',
    teamMembers: 'Teammitglieder',
    totalIncome: 'Gesamte Einladungseinkommen',
    totalCommission: 'Gesamte Provision',
    lostIncome: 'Verlorenes Einkommen',
    todayData: 'Heutige Daten',
    todayInvite: 'Heutige Einladung',
    inviteRewards: 'Einladungsprämien',
    todayCommission: 'Heutige Provision',
    todayLost: 'Heutiger Verlust',
    viewInviteRank: 'Einladungsrang anzeigen',
    threeSteps: 'Drei Schritte, um einen Freund einzuladen',
    step1: 'Einladungsposter teilen',
    step2: 'Bei Future Star anmelden',
    step3: 'Identitätsüberprüfung abschließen',
    howToEarn: 'Wie man verdient',
    part1: 'Teil I',
    'part1-desc-1':
      'Nachdem Sie Freunde dazu eingeladen haben, die Identitätsüberprüfung abzuschließen, erhalten Sie sofort ',
    'part1-desc-2': '',
    'part1-desc-3': '',
    'part1-desc-4': ' Belohnungen',
    part2: 'Teil II',
    'part2-desc-1': 'Nachdem ein Freund die tägliche ',
    dailyActivityTask: 'Aktivitätsaufgabe',
    'part2-desc-2':
      ' abgeschlossen hat, erhalten Sie eine Provisionsbelohnung. Die Höhe der Belohnung wird durch den Freundesrang und den Sternkartenrang bestimmt. Details finden Sie in der Verdienststrategie',
    part3: 'Teil III',
    'part3-desc-1': 'Nachdem ein Freund die tägliche ',
    dailyWalkingTask: 'Laufaufgabe',
    'part3-desc-2':
      ' abgeschlossen hat, erhalten Sie eine Provisionsbelohnung. Die Höhe der Belohnung wird durch den Freundesrang und den Sternkartenrang bestimmt. Details finden Sie in der Verdienststrategie',
    howToInvite: 'Wie man einlädt',
    inviteStep1:
      'Nachdem Ihre Freunde sich über den von Ihnen geteilten Link/QR-Code in der Future Star-App angemeldet haben, müssen sie die Identitätsüberprüfung abschließen, um als erfolgreiche Einladung gezählt zu werden. Andernfalls wird die Einladungsbelohnung nicht erhalten.',
    inviteStep2:
      'Ihr Freund muss bei der Registrierung Ihren Einladungscode eingeben, um eine Einladungsbeziehung aufzubauen',

    inviteStep3:
      'Teilen Sie es mit Familie, Freunden, Klassenkameraden und Kollegen, um die Einladungserfolgsquote zu erhöhen.',
    lostIncomeDesc:
      'Wenn das Star-Kartenlevel Ihres unteren Partner-Levels höher ist als Ihres, können Sie nur die Kommissionsbelohnung gemäß Ihrem Star-Kartenlevel erhalten. Der überschüssige Teil der Kommissionsbelohnung wird verworfen. Wenn Sie das gesamte Kommissionsinkommen erhalten möchten, versuchen Sie bitte, das Partner-Level Ihres Partners höher als oder gleich allen Ihren unteren Level-Partnern zu halten.',
    rules: 'Regeln',
    close: 'Schließen',
    rulesTitle: 'Regeln für die Einladung von Freunden',
    'rules-0-1':
      'Einladungsbeziehungen können in der Future Star-App bis zu 10 Ebenen betragen.',
    'rules-0-2':
      'Jeder neue Benutzer kann nach Abschluss der ID-Überprüfung eine Belohnung von 100 SP erhalten.',
    'rules-0-3':
      'Nachdem der Eingeladene jeden Tag die Aufgabe abgeschlossen hat, kann der Einlader je nach Höhe der von ihm erhaltenen SP eine bestimmte Anzahl von Starcoins als Belohnung erhalten. Die Belohnung wird jeden Tag um 06:00 Uhr Coordinated Universal Time (UTC) ausgegeben.',
    'rules-1-1': 'Star-Level',
    'rules-1-2': 'Prozentsatz Ihres Freundesertrags, den Sie teilen',
    'rules-1-3-0': 'Neuer Star-Partner',
    'rules-1-3-1': 'Ebene 1: 10%',
    'rules-1-4-0': '1-Stern-Partner',
    'rules-1-4-1': 'Ebene 1: 10%、Ebene 2: 5%',
    'rules-1-5-0': '2-Stern-Partner',
    'rules-1-5-1': 'Ebene 1: 10%、Ebene 2: 5%、Ebene 3: 5%',
    'rules-1-6-0': '3-Stern-Partner',
    'rules-1-6-1': 'Ebene 1: 10%、Ebene 2: 5%、Ebene 3: 5%、Ebene 4: 5%',
    'rules-1-7-0': '4-Stern-Partner',
    'rules-1-7-1':
      'Ebene 1: 10%、Ebene 2: 5%、Ebene 3: 5%、Ebene 4: 5%、Ebene 5: 3%、Ebene 6: 3%',
    'rules-1-8-0': '5-Stern-Partner',

    'rules-1-8-1':
      'Stufe 1: 10%, Stufe 2: 5%, Stufe 3: 5%, Stufe 4: 5%, Stufe 5: 3%, Stufe 6: 3%, Stufe 7: 3%, Stufe 8: 1%',
    'rules-1-9-0': '6-Sterne-Partner',
    'rules-1-9-1':
      'Stufe 1: 10%, Stufe 2: 5%, Stufe 3: 5%, Stufe 4: 5%, Stufe 5: 3%, Stufe 6: 3%, Stufe 7: 3%, Stufe 8: 1%, Stufe 9: 1%, Stufe 10: 1%',
    'rules-2-1':
      'Hinweis: Die Verteilungsrate und die Ebene der Einladungsprämien hängen von Ihrer aktuellen Partner-Ebene ab. Wenn Ihr Partner einer niedrigeren Ebene höher ist als Sie, erhalten Sie nur Belohnungen basierend auf seiner Ebene, und überschüssige Belohnungen werden verworfen. Um mehr Einladungsprämien zu erhalten, laden Sie bitte mehr Freunde ein, um Ihre Partner-Ebene zu erhöhen.',
    myTeam: 'Mein Team',
    totalTeamMember: 'Gesamtmitglieder im Team',
    todayIncrease: 'Heutige Zunahme',
    users: 'Benutzer{{ plural }}',
    invitationIncome: 'Einladungseinkommen',
    todayShare: 'Heutige Beteiligung',
    todayIncome: 'Heutiges Einkommen',
    partners: 'Partner',
    freeMembers: 'Kostenlose Mitglieder',
    viewAll: 'Alle anzeigen',
    'summary-0': '{{ plural }} Mitglieder haben die Aktivität abgeschlossen, ',
    'summary-1': '{{ plural }} Mitglieder haben die Gehaufgabe abgeschlossen, ',
    'summary-2': '{{ plural }} Mitglieder ruhen sich aus~',
    invite: 'Einladen',
    inviteFriends: 'Freunde einladen',
    incomeDetails: 'Einkommensdetails',
    invitationRanking: 'Einladungsrangliste',
    dailyRank: 'Täglicher Rang',
    totalRank: 'Gesamtrang',
    shared: 'Geteilt: ',
    invited: 'Eingeladen: ',
    totalInvited: 'Insgesamt eingeladen: ',
    'member-new': 'Neues Mitglied',
    'member-0': 'Neuer Stern-Mitglied',
    'member-1': '1-Sterne-Partner',
    'member-2': '2-Sterne-Partner',
    'member-3': '3-Sterne-Partner',
    'member-4': '4-Sterne-Partner',
    'member-5': '5-Sterne-Partner',
    'member-6': '6-Sterne-Partner',
    noToday: 'Heute noch keine Rankings',
    download: 'Herunterladen',
    share: 'Teilen',
    downloadComplete:
      'Die Einladungsposter wurden in Ihrer Galerie gespeichert, teilen Sie sie jetzt mit Ihren Freunden!',
    shareComplete:
      'Der Einladungslink wurde kopiert, teilen Sie ihn jetzt mit Ihren Freunden!',
  },
};
