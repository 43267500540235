const AccountBlocked = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "831px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "14px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "71px",
            left: "20px",
            width: "335px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
            color: "#fff",
          }}
        >
          <div
            style={{
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              width: "335px",
              height: "139px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "335px",
                height: "139px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="/登陆领取背景图.png"
              />
              <div
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    width: "315px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "144px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "140%",
                          fontWeight: "600",
                          display: "inline-block",
                          width: "188px",
                          flexShrink: "0",
                        }}
                      >
                        我的星值
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "24px",
                        letterSpacing: "0.06em",
                        fontWeight: "600",
                        display: "inline-block",
                        width: "315px",
                      }}
                    >
                      112412.98
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "140%",
                      fontWeight: "400",
                      display: "inline-block",
                      width: "188px",
                    }}
                  >
                    今日获得星值：2184
                  </div>
                </div>
                <div
                  style={{
                    width: "204px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "4px 24px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      兑换成 FSC
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
                fontSize: "12px",
                color: "#1a2024",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/历史记录.svg"
              />
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  lineHeight: "16px",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                历史记录
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        兑换为 SP
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#f2271c",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    -213
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "1px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "18px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              星值
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "375px",
            height: "831px",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "287px",
            left: "20px",
            borderRadius: "6px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
            width: "335px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            color: "#252c32",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <b
                    style={{
                      flex: "1",
                      position: "relative",
                      lineHeight: "140%",
                    }}
                  >
                    您的账户出现异常
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "14px",
                    letterSpacing: "-0.01em",
                    lineHeight: "20px",
                    color: "#5b6871",
                  }}
                >
                  <span>
                    非常抱歉，我们发现您的账号异常。账户已被暂时封禁。
                    如果您有任何异议请联系客服。
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "12px",
                fontSize: "14px",
                color: "#f6f8f9",
              }}
            >
              <div
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0e73f6",
                  width: "303px",
                  height: "39px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    联系客服
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                  color: "#5b6871",
                }}
              >
                知道了
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default AccountBlocked;
