import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from '../service';

import './index.css';
import { Carousel } from 'antd';

const Banners = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { banners } = useSelector(state => state.info);

  useEffect(() => {
    getBanner(dispatch, { language: i18n.language });
  }, []);

  const handleClick = (item) => {
    const { type, link, videoHLS } = item;
    if (type === 'article') {
      window.open(`/article?id=${link}`, link);
    }

    if (type === 'video') {
      window.open(link, '_blank');
    }
  };

  return (
    <div className='info-banner'>
      <Carousel
        autoplay
      >
        {
          banners.map(item => {
            return (
              <div key={item._id} className="caro-item" onClick={() => handleClick(item)}>
                <img src={item.img} alt="" />
              </div>
            );
          })
        }
      </Carousel>
    </div>
  );
};

export default Banners;
