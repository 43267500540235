const Read = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "1012px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "16px",
        color: "#1a2024",
        fontFamily: "Inter",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "0px",
          left: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "28px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              border: "none",
              padding: "0",
              backgroundColor: "transparent",
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                position: "relative",
                height: "79.79%",
                width: "44.58%",
                top: "10.1%",
                right: "28.33%",
                bottom: "10.1%",
                left: "27.08%",
                maxWidth: "100%",
                overflow: "hidden",
                maxHeight: "100%",
              }}
              alt=""
              src="../左箭头.svg"
            />
          </button>
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../分享.svg"
          />
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: "0px 20px",
            alignItems: "left",
            justifyContent: "flex-start",
            gap: "12px",
            width: "375px",
          }}
        >
          <img
            style={{
              position: "relative",
              borderRadius: "100px",
              width: "48px",
              height: "48px",
              flexShrink: "0",
              objectFit: "cover",
            }}
            alt=""
            src="../示例头像.png"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "600",
              }}
            >
              John Papa
            </div>
            <div
              style={{
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "24px",
                color: "#6e7c87",
              }}
            >{`Apr 10, 2022 • 7 Min Read `}</div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            height: "806px",
            flexShrink: "0",
            display: "flex",
            flexDirection: "column",
            padding: "0px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <b
              style={{
                position: "relative",
                lineHeight: "24px",
                display: "inline-block",
                width: "335px",
              }}
            >
              7 Passive Income Apps To Earn Money Without Any Investment!
            </b>
            <div
              style={{
                position: "relative",
                fontSize: "16px",
                lineHeight: "140%",
                fontWeight: "500",
                display: "inline-block",
                width: "335px",
              }}
            >
              Earn more money with the web3 Apps.
            </div>
          </div>
          <img
            style={{
              position: "relative",
              maxWidth: "335px",
              overflow: "hidden",
              height: "222.67px",
              flexShrink: "0",
              objectFit: "cover",
            }}
            alt=""
            src="../文章插图.png"
          />
          <div
            style={{
              position: "relative",
              fontSize: "inherit",
              lineHeight: "140%",
              fontFamily: "inherit",
              color: "#48535b",
              width: "335px",
            }}
          >
            <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
              Indeed, technology has revolutionized the way we work and live. In
              fact, through advancements in technology, a stream of passive
              income can now be established without the need to invest any
              capital! There are many ways of doing so, such as by answering
              paid surveys, blogging, availing crypto faucet sites and so on.
              While these avenues may appear to offer free money, they entail a
              certain degree of effort and time — much valuable resources in
              itself.
            </p>
            <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
              &nbsp;
            </p>
            <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
              As time is indeed the ultimate currency, the ideal passive income
              stream is one which is profitable with the minimum effort
              possible, isn’t it? In this article, we shall explore the best
              options in this regard to earn a steady and respectable stream of
              effortless passive income, using the devices you already have!
              These platforms can be broadly categorized into the following:
            </p>
            <ul style={{ margin: "0", paddingLeft: "19px" }}>
              <li style={{ marginBottom: "0px" }}>
                Internet-sharing platforms: Honeygain, Peer2Profit, IPRoyal
                Pawns, EarnApp
              </li>
              <li style={{ marginBottom: "0px" }}>
                Nodes: Nodle Cash, Mysterium Network
              </li>
              <li>Move-To-Earn: Sweatcoin</li>
            </ul>
          </div>
        </div>
      </div>
      <center>
        <div
          style={{
            position: "relative",
            bottom: "72px",
            left: "0px",
            borderRadius: "100px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24)",
            display: "flex",
            flexDirection: "row",
            padding: "8px 16px",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "14px",
            width: "154px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../鼓掌.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "500",
              }}
            >
              200
            </div>
          </div>
          <div
            style={{
              position: "relative",
              borderRight: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "1px",
              height: "16px",
              flexShrink: "0",
            }}
          />
          <div
            style={{
              width: "49px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../聊天框.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "500",
              }}
            >
              20
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Read;
