import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { purchaseCard } from '../service';
import request from '../../../utlis/request';

const starIcon = require('../assets/star.png');
const starGreyIcon = require('../assets/star-grey.png');
const cover = require('../assets/card-splash.png');

const pluralMap = {
  4: 2,
  5: 2,
  6: 3,
}

const DefaultCard = ({ level, type, total_supply = 0, cost, effective_days = 30, requirement }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onConfirm = async () => {
    purchaseCard(dispatch, t, level, type);
  };

  const handleObtain = () => {
    dispatch({
      type: 'user/SWITCH_CONFIRM',
      payload: {
        confirmVisible: true,
        confirmInfo: {
          title: t('card.confirmBuy', { cost }),
          content: t('card.confirmBuyDesc', { cost, earning: total_supply, days: effective_days }),
          type: 'confirm',
          onConfirm,
          onCancel: null,
        }
      },
    });
  };

  return (
    <div className='default-card'>
      <img src={cover} alt="" className='default-card-cover' />
      <div className='default-card-header'>
        <div className='card-title'>
          {t('card.cardDesc', { star: level, type })}
        </div>
        <div className='card-star'>
          {
            level === 0 ?
              <img src={starGreyIcon} style={{ height: 13, width: 14 }} />
              : null
          }
          {
            Array.from(Array(level).keys()).map(s =>
              <img key={s} src={starIcon} style={{ height: 13, width: 14, marginLeft: 6 }} />
            )
          }
        </div>
      </div>
      <div className='default-card-body'>
        <div className='card-expire'>{t('card.valid', { days: effective_days })}</div>
        <div className='card-income'>
          {t('card.potential')} {total_supply} SP
        </div>
      </div>
      <div className='default-card-bottom'>
        <div className='card-cost'>
          {
            requirement
            &&
            `${t('card.memberReq', {
              users: requirement.number,
              star: requirement.level,
              plural: pluralMap[requirement.level],
            })} | `
          }
          {t('card.spend')} {cost} SP
        </div>
        <div className='card-get-button' onClick={() => handleObtain()}>
          {t('card.obtain')}
        </div>
      </div>
    </div>
  );
};

export default DefaultCard;

