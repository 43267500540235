const Convert = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "684px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "16px 20px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/左尖角.svg"
              />
              <b
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  left: "-2px",
                }}
              >
                闪兑
              </b>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "36px",
            left: "calc(50% - 175.5px)",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "100px",
              backgroundColor: "#0e73f6",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              确定闪兑
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "72px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "16px",
            textAlign: "right",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              height: "222px",
              flexShrink: "0",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#fff",
                width: "351px",
                display: "flex",
                flexDirection: "column",
                padding: "12px",
                boxSizing: "border-box",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f6f8f9",
                  border: "1px solid #eef0f2",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      fontWeight: "600",
                    }}
                  >
                    发送
                  </div>
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                    }}
                  >
                    余额: 1.2636
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "left",
                    fontSize: "18px",
                    color: "#1a2024",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "100px",
                        backgroundColor: "#fff",
                        boxShadow:
                          "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 1px 2px rgba(91, 104, 113, 0.32)",
                        border: "0.5px solid #4094f7",
                        boxSizing: "border-box",
                        width: "35px",
                        height: "35px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          height: "57.03%",
                          width: "62.06%",
                          top: "21.88%",
                          right: "22.32%",
                          bottom: "21.1%",
                          left: "15.63%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt=""
                        src="/FSC.png"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ position: "relative", fontWeight: "600" }}>
                        FSC
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          overflow: "hidden",
                        }}
                        alt=""
                        src="/展开选项.svg"
                      />
                    </div>
                  </div>
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                      color: "#9aa6ac",
                      border: "0",
                      backgroundColor: "transparent",
                      width: "75px",
                    }}
                    placeholder="转出数量"
                  ></input>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f6f8f9",
                  border: "1px solid #eef0f2",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      fontWeight: "600",
                    }}
                  >
                    接收
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "left",
                    fontSize: "18px",
                    color: "#1a2024",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "100px",
                        backgroundColor: "#fff",
                        border: "1px solid #50af95",
                        boxSizing: "border-box",
                        width: "36px",
                        height: "36px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: "6px",
                          left: "5px",
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                        }}
                        alt=""
                        src="/USDT.png"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ position: "relative", fontWeight: "600" }}>
                        USDT
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          overflow: "hidden",
                        }}
                        alt=""
                        src="/展开选项.svg"
                      />
                    </div>
                  </div>
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                      color: "#9aa6ac",
                      border: "0",
                      backgroundColor: "transparent",
                      width: "75px",
                    }}
                    placeholder="接收数量"
                  ></input>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 20px)",
                left: "calc(50% - 19.5px)",
                borderRadius: "50px",
                backgroundColor: "#f6f8f9",
                border: "4px solid #fff",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "32px",
                  height: "32px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/上下调换.svg"
              />
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "8px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "16px 12px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "12px",
              textAlign: "left",
              fontSize: "14px",
              color: "#000",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "none",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
                fontFamily: "Poppins",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  display: "inline-block",
                  width: "96px",
                  flexShrink: "0",
                }}
              >
                From
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                  fontSize: "16px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "500",
                    display: "inline-block",
                    height: "43px",
                    flexShrink: "0",
                  }}
                >
                  0x30a6322b55d7e4fc0317b8ec1b75b4bb6df2308f
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontFamily: "Inter",
                  }}
                >
                  (Rocket Video)
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
                display: "none",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "none",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                fontFamily: "Poppins",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  display: "inline-block",
                  width: "104px",
                  flexShrink: "0",
                }}
              >
                To
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "right",
                  fontSize: "12px",
                  fontFamily: "Inter",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    fontSize: "16px",
                    letterSpacing: "-0.01em",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    display: "inline-block",
                    width: "219px",
                    height: "43px",
                    flexShrink: "0",
                  }}
                >
                  0x30a6322b55d7e4fc0317b8ec1b75b4bb6df2308f
                </div>
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    textAlign: "left",
                  }}
                >
                  (Current Account)
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                  }}
                >
                  (Rocket Video)
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
                display: "none",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                兑换公链
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "500",
                  }}
                >
                  　BSC
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #48535b",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
                display: "none",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "none",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
                color: "#f4f4f4",
                fontFamily: "Poppins",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Final Receiving Amount
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                  fontSize: "16px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "500",
                  }}
                >
                  0
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                兑换价格
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  1 FSC ≈ 0.001 bnb
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                发送数量
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  10 FSC
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                接收数量
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  0.1 usdt
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                borderTop: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "312px",
                height: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                手续费
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "right",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  10 FSC
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Convert;
