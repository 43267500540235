import request from "../../utlis/request";

export const getInviteSummary = async (dispatch) => {
  try {
    const URL = "/api/invite/summary/v2";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "invite/GET_INVITE_SUMMARY",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getRankingTotal = async (dispatch) => {
  try {
    const URL = "/api/invite/ranking/total";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "invite/GET_RANKING_TOTAL",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getRankingToday = async (dispatch) => {
  try {
    const URL = "/api/invite/ranking/today";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "invite/GET_RANKING_TODAY",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};
