export default {
  bottomBar: {
    Home: "首页",
    Card: "卡片",
    Friend1: "好友",
    Wallet: "钱包",
    Friend: "会员",
    Task: "任务",
    Profile: "我的",
    Read: "头条",
  },
  home: {
    login: "登录赚钱",
    starNews: "星新闻",
    starSelected: "星精选",
    watchAds: "看广告赚钱",
    moveToEarn: "移动赚钱",
    moveToEarnTitle: "移动赚钱即将到来！",
    moveToEarnDescription: "我们正在努力开发移动赚钱功能，以便您可以赚取更多！",
    verify: "验证您的 ID 以保护您的帐户和收入。",
    doItNow: "现在做",
  },
  search: {
    title: "搜索",
    search: "搜索",
    trending: "未来星趋势",
    notFound: "没有找到文章",
    notFound1: "未找到结果",
    notFound2: "我们没有找到您搜索的任何内容，请尝试搜索其他内容。",
  },
  read: {
    ads: "广告",
    duration: "{{ duration }} 分钟阅读",
    totalComments: "回复 ({{ amount }})",
    reply: "回复",
    comment: "你有什么想法？",
    replying: "回复给",
    noComment1: "暂无评论",
    noComment2: "成为第一个留下评论的人！",
    headlines: "星头条",
    error1: "无法喜欢!请再试一次",
    error2: "无法像!请再试一次",
    error3: "无法检索到评论",
    error4: "无法评论!请再试一次",
  },
  card: {
    refreshSuccess: "刷新成功，您的钱包余额将在三分钟内刷新",
    title: "星卡",
    cardList: "卡片列表",
    myCards: "我的星卡",
    remaining: "{{remaining}}/{{total}} 剩余",
    expire: "{{ days }} 剩余天数",
    valid: "有效期{{ days }}天",
    potential: "累计可获得",
    earned: "累计已获得 ",
    cost: "消耗",
    obtain: "领取",
    stepCard: "健步星卡",
    taskCard: "活跃度星卡",
    comboCard: "旧星卡",
    cardDesc_zero: "新{{ type }}星卡",
    task: "活跃度",
    step: "健步",
    combo: "旧",
    cardDesc: "{{ star }}星{{ type }}星卡",
    dailyProd: "({{ dailyProd }} FSC / 天)",
    required: "星卡要求",
    totalEarning: "总收入",
    until: "(直到 {{date}})",
    memberReq: "{{ users }}名{{ star }}星会员",
    obtainCard: "获取星卡",
    cardReq: "星卡要求",
    noFunds: "资金不足",
    buyCoin: "购买未来星硬币",
    memberReq1: "{{users}}名{{star}}星合伙人",
    memberReq2: "团队有{{users}}名{{star}}星分布在{{users}}条关系线",
    noMembers: "没有找到会员",
    invite: "邀请好友",
    confirm: "确认并启动卡片",
    historyTitle: "星卡历史",
    sort1: "最晚的优先",
    sort2: "最早的优先",
    sort3: "卡片星级从高到低",
    sort4: "卡片星级从低到高",
    sort5: "收入总额从高到低",
    sort6: "收入总额从低到高",
    purchased: "购买成功",
    purchasedDesc: "你已领取此星卡！现在开始赚钱吧",
    noCards: "未找到卡",
    noHistory: "未找到卡历史记录",
    noCards2: "获得更多卡以开始赚钱！",
    totalCards: "星卡总数",
    cardSingle: " 张",
    cardPlural: " 张",
    revenueCap: "收益上限",
    cumulativeIncome: "累计收益",
    member: "{{ star }}星合伙人",
    "member-new": "新成员",
    "member-0": "新星卡合伙人",
    "member-1": "一星合伙人",
    "member-2": "二星合伙人",
    "member-3": "三星合伙人",
    "member-4": "四星合伙人",
    "member-5": "五星合伙人",
    "member-6": "六星合伙人",
    rules: "规则说明",
    get: "领取星卡",
    starLeveDesc: "当前合伙人级别由您持有的最高级别的星卡决定",
    myCards2: "查看我的星卡",
    spend: "消耗",
    confirmBuy: "你想花费{{ cost }}FSC领取此星卡吗",
    obtainedSuccess: "领取成功！",
    insufficientFSC: "星值余额不足",
    insufficientMember: "团队成员资格证不足",
    confirmBuyDesc:
      "此星卡将消耗{{ cost }}FSC, 有效期{{ days }}天共可累计可赚取{{ earning }}星值 ",
    obtainedSuccessDesc: "你已领取此星卡！现在开始赚钱吧",
    insufficientFSCDesc:
      "此星卡将消耗 {{ cost }} FSC, 钱包中FSC余额不足，请兑换或完成更多任务获取FSC.",
    insufficientMemberDesc:
      "领取此星卡需有{{ member }}在团队中，您还未有任{{ member }}。邀请好友，发展您的团队以领取更高星卡！",
    yes: "是的",
    okay: "好的",
    topUp: "充值",
    inviteFriends: "邀请好友",
    cancel: "取消",
    noStarCard: "您还未拥有星卡",
    noStarCardDetail:
      "您还未领取星卡，只有在拥有星卡后才能完成任务以获取更多奖励！不要错失收益，快去领取星卡吧",
    noQualifyCardTitle: "您还无法兑换FSC",
    noQualifyCardContent:
      "你当前为新星合伙人无法申请SP兑换FSC，持有一星及以上活跃度星卡的用户才能申请SP兑换FSC。立即升级星卡转换您的收益！",
    upgradeCard: "升级星卡",
    noQualifyStepCardTitle: "您还无法购买健步星卡",
    noQualifyStepCardContent:
      "您当前还未持有同等级或更高等级活跃度星卡，因此您无法购买此等级健步星卡。请升级您的活跃度星卡！",

    "rules-0-1": "星级",
    "rules-0-2": "最大领取数量",
    "rules-0-3": "兑换所需",
    "rules-0-4": "总产量",
    "rules-0-5": "有效期",
    "rules-0-6": "新星",
    "rules-0-7": "一星",
    "rules-0-8": "二星",
    "rules-0-9": "三星",
    "rules-0-10": "四星",
    "rules-0-11": "五星",
    "rules-0-12": "六星",
    "rules-0-13": "免费",
    "rules-0-14": "{{ days }}天",
    "rules-0-15": "每日产量",
    "rules-0-16": "SP兑换FSC手续费",
    "rules-0-17": "晋级考核",

    "rules-1-1":
      "星卡分为健步星卡和活跃度星卡，领取星卡后完成相应任务后即可获得奖励。",
    "rules-1-2": "每等级星卡可无限领取，但每次只能购买并持有一张星卡。",
    "rules-1-3":
      "星卡做完30天任务后，可选择继续购买同级星卡或晋升为更高级星卡，但晋升后级别不能回降，只能保留现有的星级或继续晋升。所有星卡30天为一轮，都可无限轮充值领取。",
    "rules-1-4":
      "星卡分为不同等级，每个星卡领取后有效期皆为30天，从领取当天开始算起，30天结束后星卡失效，完成任务也无法获得对应奖励。",
    "rules-1-5":
      "一星至六星任一星级，当做完30天任务后未及时领取新卡时，即停止团队收益。但当任何时候再充值领取星卡时，当天起即恢复团队收益。",
    "rules-1-6":
      "领取新星、一星、二星星卡时消耗相应星币即可，领取三星及以上星卡时需要满足晋级考核。",

    error1: "服务器繁忙，请稍后重试。",
  },
  friend: {
    title: "会员",
    partner: "合伙人",
    members: "免费会员",
    all: "全部",
    tier: "级别 {{ tier }}",
    sortBy: "排序方式",
    totalSharing: "累积贡献提成：",
    dailyTask: "今日活跃度任务",
    dailyStep: "今日健步任务",
    invitaedActivityCommision: "级好友活跃度提成",
    invitaedStepCommision: "级好友健步提成",
    year: "年",
    month: "月",
    incomeData: "收益数据",
    commisionIncome: "提成收益",
    chooseDate: "选择日期",

    cannotShare: "你不能再从这个朋友那里分享利益，因为你没有相同星级的卡片",
    invite: "邀请会员",
    invite2: "邀请会员",
    earnTogether: "一起赚钱，赚得更多",
    earnDesc:
      "邀请更多的会员，帮助他们开始赚钱，而你可以在他们完成卡片后分享他们的好处",
    inviteCode: "您的邀请码：",
    share: "与朋友分享",
    noMembers1: "未找到会员",
    noMembers2:
      "邀请更多会员，帮助他们开始赚钱，当他们得到好处时，你可以分享他们的好处！",
    showingUsers: "显示 {{ count }} 个用户",

    sort1: "卡片星级从高到低",
    sort2: "卡片星级从低到高",
    sort3: "分享总额从高到低",
    sort4: "分享总数从低到高",

    info1:
      "1.为了吸引更多的用户加入Futurestar生态系统，Futurestar用户可以获得高达10级的邀请奖励",
    info2: "2.每当你直接邀请一个用户完成注册邀请者，你可以得到100星币的奖励。",
    info3: "3.每个新注册的用户可以得到100星币的奖励。",
    info4:
      "4.被邀请者每天完成任务后，根据获得的星币数量，邀请者可以获得一定比例的星币作为奖励。",
    info5: "具体算法如下。",
    info6: "新星。一级: 10%",
    info7: "\u2022 一星级：一级：10%；二级：5%。",
    info8: "\u2022 二星级：一级：10%；二级：5%，三级：5%。",
    info9: "\u2022 三星级：一级：10%；二级：5%；三级：5%；四级：5%。",
    info10:
      "\u2022 四星级：一级：10%；二级：5%；三级：5%；四级：5%；五级：3%；六级：3%。",
    info11:
      "\u2022 五星级：一级：10%；二级：5%；三级：5%；四级：5%；五级：3%；六级：3%；七级：3%；八级：1%。",
    info12:
      "\u2022 六星级：一级：10%；二级：5%；三级：5%；四级：5%；五级：3%；六级：3%；七级：3%；八级：1%；九级：1%；十级：1%",
    info13:
      "5.为了获得邀请奖励，邀请者必须有相应的星级评价。如果没有星级，则不能获得邀请奖励。",
  },
  wallet: {
    spDescription:
      "星值（SP）可以1:1兑换成FSC，FSC可以通过闪兑功能兑换成USDT和BNB",
    chooseNetwork: "选择主网络",
    title: "我的钱包",
    fscBalance: "FSC余额",
    usdtBalance: "USDT余额",
    bnbBalance: "BNB余额",
    view: "查看",
    convertFSC: "转换FSC",
    buyFSC: "购买FSC",
    history: "历史记录",
    historyEarn_zero: "从新星卡赚取收入",
    historyEarn: "从{{star}}-星级卡中赚取收入",
    historyConvert: "转换为USDT",
    historyConvertFrom: "从FSC转换",
    historyPurchase: "购买卡",
    withdraw: "提现",
    withdrawTitle: "提取USDT",
    convertTitle: "转换FSC",
    toWallet: "为你的钱包干杯",
    inputAddress: "请输入收货地址",
    from: "来自",
    to: "接收",
    fscBalance2: "FSC余额：{{balance}}",
    usdtBalance2: "USDT余额：{{balance}}",
    bnbBalance2: "BNB余额：{{ balance }}",
    fsc: "FSC",
    usdt: "USDT",
    processFee: "手续费",
    gasFee: "矿工费用",
    remainingUSDT: "剩余的USDT",
    remainingFSC: "剩余的FSC",
    remaining: "剩余的{{ currency }}",
    confirm: "确认",
    cancel: "取消",
    gotIt: "知道了",
    withdrawingUSDT: "提取USDT",
    withdrawingUSDTDesc:
      "我们正在为您转换USDT，一旦转换完成，您将立即收到USDT。",
    withdrawing: "提取{{ currency }}",
    withdrawingDesc:
      "我们正在为您提取{{ currency }}，一旦转换完成，您将立即收到{{ currency }}。",
    convertingFSC: "兑换成{{ currency }}",
    convertingFSCDesc:
      "我们正在为您转换{{ currency2 }}，一旦完成，您将立即收到{{ currency2 }}。",
    registerBonus: "注册奖金",
    invitationBonus: "邀请奖金",
    usdtWithdraw: "USDT提现",
    reminder: "请注意，{{ currency }}的最低提款金额为 {{ minimum }}",
    minimum: "{{ currency }}的最低提款金额为{{ minimum }}{{ currency }}。",
    inviteIncome: "邀请收益",
    taskIncome: "任务收益",
    stepIncome: "移动赚钱收益",
    convert: "兑换",
    topUp: "充值",
    selectCrypto: "选择加密货币",
    tether: "Tether (USDT)",
    binance: "币安币 (BNB)",
    topUpTitle: "充值{{ currency }}",
    topUpDesc:
      "扫描支付您要充值的金额，交易通常需要10分钟左右。 如果您没有收到您的资金，请联系我们的",
    customerService: "客户服务",
    address: "地址",
    scanTitle: "扫描二维码",
    currencyWithdraw: "{{ currency }}提现{{status}}",
    currencyConvertTo: "转换为{{ currencyTo }}",
    currencyConvertFrom: "从{{ currencyFrom }}转换",
    totalAssets: "总资产",
    topUpWarningBIANCE:
      "该地址为币安智能链(BSC)，仅向该地址转入BSC/BEP20资产，因错误转账导致的资金损失将由您个人承担。",
    topUpWarningETHEREUM:
      "该地址为Ethereum (ERC20)，仅向该地址转入ERC20资产，因错误转账导致的资金损失将由您个人承担。",
    topUpWarningTRON:
      "该地址为TRON (TRC20)，仅向该地址转入TRC20资产，因错误转账导致的资金损失将由您个人承担。",
    assets: "资产列表",
    starPoints: "星值",
    exchangeChain: "兑换公链",
    conversionRate: "兑换价格",
    exchangeAmount: "发送数量",
    convertedAmount: "接收数量",
    paymentAddress: "收款地址",
    mainNetwork: "主网络",
    withdrawWarningBIANCE:
      "该网络是币安智能链(BSC)网络，请确保您的提币地址支持 BNB Smart Chain（BEP20）网络，因错误转账导致的资金损失将由您个人承担。",
    withdrawWarningETHEREUM:
      "该网络是Ethereum (ERC20)网络，请确保您的提币地址支持 Ethereum (ERC20)网络，因错误转账导致的资金损失将由您个人承担。",
    withdrawWarningTRON:
      "该网络是TRON (TRC20)网络，请确保您的提币地址支持TRON (TRC20)网络，因错误转账导致的资金损失将由您个人承担。",
    withdrawalAmount: "提现金额",
    receivingAmount: "到账数量",
    withdrawalFee: "提现网络手续费",
    withdrawalArrive:
      "提现通常会在10分钟内到账，若超过10分钟未到账请联系我们的客户服务",
    withdraw2: "申请提现",
    minimumInput: "最小值 ",
    remainingBalance: "可用余额 ",
    all: "全部",
    totalIncome: "我的星值",
    myStarPoints: "我的星值",
    todayTotalIncome: "今日获得星值：",
    exchangeFSCP: "兑换成FSC",
    seeMore: "查看更多",
    starPointHistory: "星值记录",
    month: "{{ month }}星值数据",
    totalStarPointIncome: "累计收益星值",
    totalExchanged: "累计兑换星值数量",
    income: "收入",
    expense: "支出",
    thisMonth: "本月",
    selectedMonth: "选择日期",
    starPointRedeem: "星值兑换",
    redeemAmount: "兑换星值数量",
    inputStarPoint: "输入要兑换的星值数量",
    availableStarPoint: "可用余额",
    minStarPointRequest: "最小转换数量:500SP",
    fscObtained: "获得的FSC数量",
    fscpConvertStatus: "您当前为{{ member }}，星值兑换手续费比例为{{ fee }}%",
    feeRules1: "提升星级合伙人等级，可以降低手续费比例，查看",
    feeRules2: "手续费规则",
    confirmExchange: "确认兑换",
    fscp: "星值",
    exchangeComplete: "SP兑换成功",
    exchangeCompleteDesc1: "我们会在24小时内审核，请耐心等待。 审核通过后 ",
    exchangeCompleteDesc2: " 将存入你的钱包！",
    goToWallet: "去钱包查看",
    understood: "知道了",
    convertDetails: "闪兑详情",
    successConvert: "兑换成功",
    currencyConverted: "转出币种",
    convertAmount: "转出数量",
    currencyReceived: "接收币种",
    receivedAmount: "接收数量",
    conversionDate: "兑换时间",
    transactionDetails: "交易详情",
    successWithdraw: "转账成功",
    sendingAddress: "发款方",
    receivingAddress: "收款方",
    transactionHash: "哈希值",
    transactionDate: "交易时间",
    spConversionRule:
      "星值兑换将在24小时内进行人工审核，如果长时间未到账可联系客服进行处理。",
    spPending: " (打款中)",
    spFailed: " (已拒绝)",
    spSuccess: " (成功)",
    spError: " (错误)",
    spSendFailed: " (失败)",

    error1: "详情不详",
  },
  profile: {
    commissionUnlocked: "解锁提成收益",
    popupDetail1: "您已成功解锁提成收益SP,您的当前团队战力值为",
    popupDetail2: "总提成收益的",
    popupDetail3: "您的解锁的提成将自动打入您的资产钱包。",
    power: "战力",
    locked: "已锁定",
    unlocked: "可解锁",
    commissionEarned: "提成收益",
    totalCommissionEarned: "总提成收益",
    totalUnlockableCommission: "可解锁提成收益",
    unlockedCommission: "已解锁收益",
    currentTeamPower: "当前团队战力",
    teamPower: "团队战力值",
    toUnlockRatio: "对应解锁比例",
    unlockDetails:
      "每次解锁比例：取决于当前查接邀请用户的团队战力值，战力值越高每次解锁比例越高",
    teamUserSP: "下级用户星级",
    unlockTimeDescribe: "每邀请一个一星合伙人可以获得一次解锁机会",
    powerIncreasable: "可增加战力值",
    oneStar: "一星",
    twoStar: "二星",
    threeStar: "三星",
    fourStar: "四星",
    fiveStar: "五星",
    sixStar: "六星",
    powerToUnlockRatio: "战力值对应的解锁比例",
    includes: "含",
    above: "以上",
    unlockSP: "解锁",
    unlockTimeLeft: "剩余解锁次数",
    taskActivityCommission: "活跃度提成收益",
    stepActivityCommission: "健步提成收益",
    spUnlock: "SP解锁",
    userLoginPassword: "用户登录密码",
    resetPaymentPassword: "找回付款密码",
    repeatNewPaymentPassword: "重复新付款密码",
    newPaymentPassword: "新付款密码",
    currentPaymentPassword: "当前支付密码",
    changePaymentPassword: "修改支付密码",
    paymentPassword: "支付密码",
    aboutUs: "关于我们",
    companyIntro:
      "FUTURE STAR GROUP是一家通过NFT和创新媒体生态构建开放元宇宙的公司。 未来之星创立于美国硅谷。 同时在新加坡、日本、瑞士等地拥有超过400人的研发和运营团队。 70%以上的团队成员毕业于美国著名的常春藤盟校。 Future Star 通过开拓和创新“去中心化”游戏内奖励和视频游戏货币化，将公司打造为全球领先的区块链和游戏技术集团之一。\r\n\nFuture Star基于X赚取的整体架构：通过运行签到管理机制，倡导一种移动赚取的绿色生活新方式，实现全球碳中和的目标； 看即赚，关注变现，媒体范式创新，趋势 web3元界媒体新生态，完美实现了传统媒体向数字媒体的转变。 以“致力于每一个品牌都被世界记住”为企业品牌理念，启动“3亿粉丝引流计划”，建立社区粉丝团，在全球共享行业利益。 \r\n\n未来之星专注于构建Web3.0元宇宙广告生态，实现从战略设计到用户数据、营销工具、创意传播的闭环，激发广告业的无限未来。未来之星已获得元界科技、INK、红杉印度、MW9等知名机构1.4亿美元融资。 公司拥有100多项媒体领域核心专利，以及迪拜数字货币兑换牌照和美国MSB数字货币。 交易执照等核心资格。",
    editProfile: "编辑资料",
    account: "帐户",
    language: "语言",
    logOut: "登出",
    changeLanguage: "语言切换",
    resetPassword: "重置密码",
    changPassword: "密码修改成功",
    resetPassDesc: "请重置您的密码",
    currentPass: "当前密码",
    newPass: "新密码",
    repeatPass: "重复密码",
    email: "电子邮件",
    name: "编辑资料",
    password: "密码",
    confirm: "确认",
    cancel: "取消",
    changePhoto: "更改照片",
    yourUsername: "您的用户名",
    save: "保存",
    phoneNumber: "电话号码",
    contactUs: "联系我们",
    contactTelegram: "通过电报联系我们",
    joinTelegram: "或加入电报群",
    contactWhatsApp: "在 WhatsApp 上联系我们",
    joinWhatsApp: "在 WhatsApp 上联系我们",
    idVerif: "实名认证",
    confirmInfo: "确认信息",
    confirmInfoDesc:
      "下面的姓名和出生日期必须与您的政府签发的身份证件相符。 在继续验证过程之前，请仔细检查以下信息。",
    firstName: "名",
    lastName: "姓",
    dateOfBirth: "出生日期",
    inputFirstName: "输入名字",
    inputLastName: "输入姓氏",
    chooseCountry: "证件签发国家",
    selectIdType: "选择政府签发的有效证件",
    inputIdNumber: "输入证件号码",
    inputNumber: "在此处输入您的号码",
    uploadPhoto: "上传证件照片",
    uploadFile: "上传文件",
    redo: "重做",
    upload: "上传",
    idSubmitted: "身份验证已提交！",
    weWillReview:
      "我们将在收到您的 ID 后立即对其进行审核。 请耐心等待，如果审核通过，我们会通知您！",
    next: "下一个",
    close: "关",
    idCard: "身份证",
    passport: "护照",
    driver: "驾驶执照",
    inProgress: "进行中",
    idVerified: "身份验证",
    idUnVerified: "未实名",
    failed: "失败的",
    failedTitle: "身份验证失败",
    failedDesc: "抱歉，我们无法验证您的 ID，因为 {{ reason }}。 请再试一次。",
    verifyId: "验证身份证",
    later: "之后",
    personalCenter: "个人中心",
    checkedIn: "已签到",
    checkIn: "去签到",
    currentRating: "当前星级：",
    viewStarCard: "查看星卡",
    wallet: "我的钱包",
    details: "查看详情",
    totalEarning: "总收益",
    totalBalance: "总资产",
    members: "我的团队",
    totalPeople: "总人数",
    addedToday: "今日新增",
    withdraw: "申请提现",
    settings: "设置",
    verified: "已实名",
    basicInfo: "基本信息",
    uploadID: "上传证件",
    submit: "提交认证",
    "verify-1":
      "请确保使用的是真实身份，否则认证将无法通过。您提交的信息仅用于Future Star认证使用，我们将会对您的信息进行加密处理。",
    "verify-2": "* 信息需与证件上的信息一致",
    uploadPhoto2: "上传个人签字和证件正面照片",
    uploadID2: "上传证件照",
    uploadFront: "上传证件正面照片",
    uploadBack: "上传证件反面照片",
    uploadSignature: "上传个人签字和证件正面照片",
    "front-desc": "拍摄时确保证件边框完整，字体清晰，亮度均匀。",
    "back-desc": "拍摄时确保证件边框完整，字体清晰，亮度均匀。",
    "sign-desc":
      "请上传一张本人持有“证件正面”和“个人签字”的照片，个人签字内容需要含 “Future Star”和当日日期，请确保照片内容清晰可见。",
    standard: "标准",
    lostEdge: "边框缺失",
    blurry: "边框模糊",
    overExposure: "闪光强烈",
    skip: "跳过",
    prev: "上一步",
    next2: "下一步",
    understood: "知道了",
    uploadFront2: "上传证件正面",
    uploadBack2: "上传证件反面",
    uploadPhoto3: "上传照片",

    error1: "此ID已用于验证，请使用另一个ID。",
  },
  auth: {
    inputPaymentPassword: "请输入支付密码",
    success1: "支付密码设置成功",
    setPayPasswordDiscribe:
      "为了更好的保护您的资产，请设用来支付,转账,提现时使用的支付密码。",
    setPayPassword: "请设置支付密码",
    forgotPassword: "忘记密码",
    forgotTitle: "忘记密码？",
    forgotDesc: "别担心，我们现在就来重设你的密码吧!",
    email: "电子邮件地址",
    verificationCode: "验证码",
    resetTitle: "重置密码",
    resetDesc: "请重置您的密码",
    password: "密码",
    repeatPass: "重复密码",
    confirm: "确认",
    cancel: "取消",
    resetPassword: "重置密码",
    loginTitle: "欢迎回来",
    loginDesc: "今天就回来重新开始挣钱吧!",
    forgotQ: "忘记密码？",
    signup: "注册",
    signUpTitle: "设置您的登录信息",
    signUpDesc: "请先给我们您的登录信息",
    inputEmail: "输入电子邮件地址",
    inputPass: "输入密码",
    inputVerification: "输入验证码",
    inputInvite: "输入邀请码",
    inputUsername: "输入用户名",
    sendCode: "发送代码",
    readAgree: "我已仔细阅读并同意",
    userAgree: "用户协议",
    and: "和",
    privacyPolicy: "隐私政策",
    haveAccount: "我已经有了一个账户",
    noAccount: "我没有账户",
    personalTitle: "您的个人信息",
    personalDesc: "为了您的安全，请验证电子邮件",
    username: "您的用户名",
    invitation: "邀请码",
    letsEarn: "让我们赚取!",
    login: "登录",
    passChangeSuccess: "密码已被更改",
    phone: "电话",
    phone2: "电话号码",
    inputPhone: "输入电话号码",
    email2: "电子邮件",
    codeTitle: "确认您的验证码",
    codeDescPhone: "我们向您的电话号码发送了一个代码。 请核实。",
    codeDescEmail: "我们向您的电子邮件发送了一个代码。 请核实。",
    resendCode: "重新发送验证码",
    captcha: "请回答验证码",
    bannedTitle: "您的账户出现异常",
    bannedDesc:
      "非常抱歉，我们发现您的账号异常。账户已被暂时封禁。 如果您有任何异议请联系客服。",
    bannedConfirm: "联系客服",
    understood: "知道了",
    continue: "继续",
    signupComplete: "注册成功",
    pleaseLogin: "您的手机号已经成功注册请登录账号以继续",
    okay: "好的",

    error1: "用户不存在",
    error2: "您的密码错误或该帐户尚未注册。 请再试一次。",
    error3: "密码不匹配",
    error4: "必须至少有8个字符",
    error5: "用户已经存在",
    error6: "邀请码错误",
    error7: "服务器繁忙，请稍后重试。",
    error8: "邀请码无效",
    error9: "授权错误！ 请重新登录。",
    error10: "此电话号码已注册。请登录。",
    error11: "此电子邮件已注册。请登录。",
    error12: "请输入",
    error13: "验证码不正确",
    error14: "用户尚未完成注册。 请去报名。",
    error15: "此帐户尚未注册。 请去报名。",
    error16: "您的密码错误。 请再试一次。",
  },
  activity: {
    title: "活跃度",
    subTitle: "完成任务即可获得今日福利",
    youCanEarn: "你可以赚到",
    yourStarCard: "用你的星卡",
    viewCard: "查看卡片",
    calculateionMethod: "活跃度计算方法",
    method1: "浏览文章每分钟可获得2个活跃度。（不限次数）",
    method2: "点赞1篇获得5个活跃度（活跃度上限30）",
    method3: "浏览1个广告，获得15个活跃度（活跃度上限为45）",
    method4: "评论或回复文章可获得10个活跃度（活跃度上限为30）",
    method5: "浏览视频每分钟可获得10个活跃度。（活跃度上限为30）",
    complete25: "获得 25 活跃度！ 赶快！",
    complete50: "获得 50 活跃度！ 半途而废！",
    complete75: "获得 75 活跃度！ 差不多好了！",
    completeTitle: "恭喜！ 你赚了！",
    completeDesc: "您已达到 100 活跃度，您的收入为：",
    completeFooter: "您的收入将在 10 分钟内发送到您的钱包",
    noCard: "您还没有星卡，需要获得星卡才能获得活动积分。",
    getCard: "立即获取星卡",
  },
  language: {
    set: "设置您的语言",
    ready: "我准备好了",
  },
  word: {
    cancel: "取消",
    confirm: "确认",
    share: "分享",
    copy: "复制",
    Zero: "零",
    One: "一",
    Two: "二",
    Three: "三",
    Four: "四",
    Five: "五",
    Six: "六",
    Seven: "七",
    Eight: "八",
    Nine: "九",
  },
  videoAds: {
    next: "下一个",
    finish: "结束",
    goBackTitle: "你确定要回去吗",
    goBackContent: "您将失去未完成视频的奖励。",
  },
  oldVersion: {
    text1: "我们发现您使用的是旧版 Future Star 应用程序。",
    text2: "您可以在以下位置下载最新版本：",
    text3: "发现新版本！",
    text4: "立即更新",
    text5: "APK 下载已开始",
    text6: "下载完毕。 很高兴检查您的下载并安装新版本的 Future Star 应用程序。",
  },
  fitness: {
    modalGoogle: "允许 Future Star 访问 Activity 的“移动赚钱”功能！",
    modalGoogle2: "您必须先安装 Google Fit 应用程序！",
    modalApple: "允许未来之星访问 Core Motion 以获得“Move To Earn”功能！",
    modalDesc:
      "我们需要跟踪您的步数，以便您可以从我们的“Move To Earn”功能中获益！",
    confirm: "是的",
    deny: "不，谢谢",
    title: "移动赚钱",
    steps: "脚步",
    keepMoving: "继续前进以赚取 {{ amount }} 星值",
    complete: "恭喜！ 您已获得 {{ amount }} 星值",
    tired: "累了？ 观看广告以保持收入",
    viewHistory: "查看移动赚取历史",
    finished: "完成的",
    warning: "如果您关闭此应用程序，我们将不计算您的步数。 允许最小化。",
    authorizeTitle: "未来星权限设置",
    authorizeDesc:
      "为了更好的帮助您完成每日健步任务，需要您授权 Future Star 访问手机的运动与健身权限，可在手机设置>权限管理>运动与健身中进行授权",
    authorizeIOS: "可在手机设置 > 权限管理 > 运动与健身中进行授权",
    authorizeAndroid: "可在手机设置 > 应用程序 > 未来之星 > 权限",
    authorizeGo: "去授权",
    skip: "跳过",
  },
  countries: {
    select: "选择国家和区域",

    china: "中国",
    us: "美国",
    canada: "加拿大",
    dominican: "多米尼加共和国",
    costa: "哥斯达黎加",
    honduras: "洪都拉斯",
    nicaragua: "尼加拉瓜",
    mexico: "墨西哥",
    austria: "奥地利",
    belgium: "比利时",
    bulgaria: "保加利亚",
    croatia: "克罗地亚",
    czech: "捷克共和国",
    denmark: "丹麦",
    germany: "德国",
    estonia: "爱沙尼亚",
    finland: "芬兰",
    france: "法国",
    greece: "希腊",
    hungary: "匈牙利",
    iceland: "冰岛",
    ireland: "爱尔兰",
    italy: "意大利",
    lithuania: "立陶宛",
    luxembourg: "卢森堡",
    latvia: "拉脱维亚",
    malta: "马耳他",
    netherlands: "荷兰",
    norway: "挪威",
    poland: "波兰",
    portugal: "葡萄牙",
    romania: "罗马尼亚",
    spain: "西班牙",
    sweden: "瑞典",
    switzerland: "瑞士",
    uk: "英国",
    brazil: "巴西",
    venezuela: "委内瑞拉",
    malaysia: "马拉西亚",
    singapore: "新加坡",
    indonesia: "印尼",
    vietnam: "越南",
    turkey: "土耳其",
    anguilla: "安圭拉",
    antiguaBarbuda: "安提瓜和巴布达",
    aruba: "阿鲁巴",
    ascension: "提升",
    bahamas: "巴哈马",
    barbados: "巴巴多斯",
    belize: "伯利兹",
    bermuda: "百慕大",
    caymanIslands: "开曼群岛",
    costaRica: "哥斯达黎加",
    cuba: "古巴",
    curacao: "库拉索",
    dominica: "多米尼克",
    dominicanRepublic: "多米尼加共和国",
    elSalvador: "萨尔瓦多",
    greenland: "格陵兰",
    grenada: "格林纳达",
    guadeloupe: "瓜德罗普",
    guatemala: "危地马拉",
    haiti: "海地",
    jamaica: "牙买加",
    martinique: "马提尼克",
    montserrat: "蒙特塞拉特",
    panama: "巴拿马",
    peurtoRico: "波多黎各",
    kittsNevis: "圣基茨和尼维斯",
    stLucia: "圣卢西亚",
    pierreMiquelon: "圣皮埃尔和密克隆",
    vincentGrenadines: "圣文森特和格林纳丁斯",
    trinidadTobago: "特立尼达和多巴哥",
    turksCaicos: "特克斯和凯科斯群岛",
    virginBritish: "英属维尔京群岛",
    virginUS: "美国维尔京群岛",
    afghanistan: "阿富汗",
    armenia: "亚美尼亚",
    azerbaijan: "阿塞拜疆",
    bahrain: "巴林",
    bangladesh: "孟加拉国",
    bhutan: "不丹",
    brunei: "文莱达鲁萨兰国",
    cambodia: "柬埔寨",
    timor: "东帝汶",
    hongKong: "香港",
    india: "印度",
    iran: "伊朗",
    iraq: "伊拉克",
    israel: "以色列",
    japan: "日本",
    jordan: "乔丹",
    northKorea: "朝鲜",
    southKorea: "韩国",
    kuwait: "科威特",
    kyrgyzstan: "吉尔吉斯斯坦",
    laos: "老挝",
    lebanon: "黎巴嫩",
    macao: "澳门",
    maldives: "马尔代夫",
    mongolia: "蒙古",
    myanmar: "缅甸",
    nepal: "尼泊尔",
    oman: "阿曼",
    pakistan: "巴基斯坦",
    palestine: "巴勒斯坦，国家",
    philippines: "菲律宾",
    qatar: "卡塔尔",
    russia: "俄罗斯",
    saudiArabia: "沙特阿拉伯",
    sriLanka: "斯里兰卡",
    syria: "叙利亚",
    taiwan: "台湾",
    tajikistan: "塔吉克斯坦",
    thailand: "泰国",
    turkiye: "土耳其人",
    turkmenistan: "土库曼斯坦",
    unitedArab: "阿拉伯联合酋长国",
    uzbekistan: "乌兹别克斯坦",
    yemen: "也门",
    albania: "阿尔巴尼亚",
    andorra: "安道尔",
    belarus: "白俄罗斯",
    bosnia: "波斯尼亚和黑塞哥维那",
    canary: "加那利群岛",
    cyprus: "塞浦路斯",
    faroe: "法罗群岛",
    gibraltar: "直布罗陀",
    kosovo: "直布罗陀",
    liechtenstein: "列支敦士登",
    moldova: "摩尔多瓦",
    monaco: "摩纳哥",
    montenegro: "黑山",
    macedonia: "北马其顿共和国",
    sanMarino: "圣马力诺",
    serbia: "塞尔维亚",
    slokavia: "斯洛卡维亚",
    slovenia: "斯洛文尼亚",
    ukraine: "乌克兰",
    vatican: "梵蒂冈城",
    argentina: "阿根廷",
    bolivia: "玻利维亚",
    chile: "智利",
    colombia: "哥伦比亚",
    ecuador: "厄瓜多尔",
    falkland: "福克兰群岛",
    frenchGuiana: "法属圭亚那",
    guyana: "圭亚那",
    paraguay: "巴拉圭",
    peru: "秘鲁",
    suriname: "苏里南",
    algeria: "阿尔及利亚",
    angola: "安哥拉",
    benin: "贝宁",
    botswana: "博茨瓦纳",
    burkinaFaso: "布基纳法索",
    burundi: "布隆迪",
    cameroon: "喀麦隆",
    capeVerde: "佛得角",
    centralAfrica: "中非",
    chad: "乍得",
    comoros: "科摩罗",
    congo: "刚果",
    congoDem: "刚果民主共和国",
    djibouti: "吉布提",
    egypt: "埃及",
    equatorialGuinea: "赤道几内亚",
    eritrea: "厄立特里亚",
    ethiopia: "埃塞俄比亚",
    gabon: "加蓬",
    gambia: "冈比亚",
    ghana: "加纳",
    guinea: "几内亚",
    guineaBissau: "几内亚比绍",
    ivoryCoast: "象牙海岸",
    kenya: "肯尼亚",
    lesotho: "莱索托",
    liberia: "利比里亚",
    libya: "利比亚",
    madagascar: "马达加斯加",
    malawi: "马拉维",
    mali: "马里",
    mauritania: "毛里塔尼亚",
    mauritius: "毛里求斯",
    morocco: "摩洛哥/西撒哈拉",
    mozambique: "莫桑比克",
    namibia: "纳米比亚",
    niger: "尼日尔",
    nigeria: "尼日利亚",
    mayotte: "马约特",
    rwanda: "卢旺达",
    saoTome: "圣多美和普林西比",
    seychelles: "塞舌尔",
    sierraLeone: "塞拉利昂",
    somalia: "索马里",
    southAfrica: "南非",
    southSudan: "南苏丹",
    sudan: "苏丹",
    swaziland: "斯威士兰",
    tanzania: "坦桑尼亚",
    togo: "多哥",
    tunisia: "突尼斯",
    uganda: "乌干达",
    zambia: "赞比亚",
    zimbabwe: "津巴布韦",
    americanSamoa: "美属萨摩亚",
    australia: "澳大利亚",
    cookIslands: "库克群岛",
    fiji: "斐济",
    frenchPolynesia: "法属波利尼西亚",
    guam: "关岛",
    kiribati: "基里巴斯",
    marshallIslands: "马绍尔群岛",
    micronesia: "密克罗尼西亚",
    newCaledonia: "新喀里多尼亚",
    newZealand: "新西兰",
    niue: "纽埃",
    norfolk: "诺福克岛",
    marianaIslands: "北马里亚纳群岛",
    palau: "帕劳",
    papua: "巴布亚新几内亚",
    samoa: "萨摩亚",
    solomon: "所罗门群岛",
    tonga: "汤加",
    tuvalu: "图瓦卢",
    vanuatu: "瓦努阿图",
    wallisFutuna: "瓦利斯和富图纳",
    georgia: "乔治亚州",
    uruguay: "乌拉圭",
    senegal: "塞内加尔",
  },
  reason: {
    alreadyExists: "您的 ID 已存在于我们的系统中",
    doesNotMatch: "您的信息与图片不符",
    invalidPicture: "无法识别您上传的图片",
  },
  homeV1: {
    upgradeStar: "升级星卡获得更高奖励",
    activityTitle: "活跃度任务",
    activityDesc: "达成100活跃度领取{{ FSC }} 星值",
    moveToEarn: "健步任务",
    moveToEarnDesc: "完成4000步领取{{ FSC }} 星值",
    checkIn: "每日签到",
    headlines: "每日头条",
    wheel: "幸运转盘",
    lottery: "星星彩票",
    pet: "星星宠物",
    recommended: "为你推荐",
    inviteFriends: "邀好友 送现金",
    inviteFriendsDesc: "邀请新用户必得USDT",
    watchNews: "看资讯，赚星币",
    watchNewsDesc: "快速了解世界大事",
    watchAds: "看广告赚星币",
    watchAdsDesc: "你爱看的都在这",
    walking: "健步赚星币",
    walkingDesc: "运动永不止步",
    starPet: "星星宠物",
    starPetDesc: "领养你的NFT宠物",
    welcome: "欢迎来到未来星！",
    welcomeDesc: "登陆领取星卡后才能获得收益！",
    loginFirst: "请先登陆未来星！",
    loginFirstDesc: "登陆领取星卡后才能获得收益！",
    loginNow: "现在登陆",
    comingSoon: "该功能正在开发中",
    comingSoonDesc: "我们正在努力开发此功能，请持续关注未来星及时体验此功能",
  },
  tasks: {
    myTasks: "我的任务",
    totalCurrentIncome: "今日累计收益",
    totalEarning: "当前收益上限为{{ FSC }}星值",
    upgradeStar: "升级星卡获得更高奖励",
    activityTaskIncome: "活跃度任务收益",
    walkToEarnIncome: "健步任务收益",
    dailyCheckin: "每日签到",
    checkInRules: "签到规则",
    day1: "第一天",
    day2: "第二天",
    day3: "第三天",
    day4: "第四天",
    day5: "第五天",
    day6: "第六天",
    day7: "第七天",
    consecutiveDays1: "已连续签到 ",
    consecutiveDays2: " 天",
    cumulativeEarning: "累计获得 ",
    signInNow: "立即签到",
    beginnerTask: "新手任务",
    earn: "赚取 {{ FSC }} 星值!",
    checkOut: "查看未来星新用户教学文章",
    viewIntro: "查看未来星介绍视频",
    completeVerification: "完成实名认证",
    receiveStarCard: "首次领取星卡",
    commissionIncome: "提成收益转入",
    completeActivity: "首次完成活跃度达到100",
    completeWalk: "首次完成4000步健步任务",
    completeInvite: "首次邀请用户注册未来星",
    completeConvert: "首次完成FSC兑换USDT",
    completeWithdraw: "首次完成提现USDT",
    topUp: "首次完成充值",
    reward: "奖励",
    completed: "已完成",
    toFinish: "去完成",
    congratulations: "恭喜",
    signinSuccess: "签到成功！奖励已到账",
    getStarCard: "请先领取星卡",
    getStarCardDesc: "领取星卡后才能获得收益！",
    getItNow: "立即领取",
    noTaskCardTitle: "您尚未拥有活跃度星卡",
    noStepCardTitle: "您尚未拥有健步星卡",

    noCardSubTitle: "领取星卡后才能获得收益!",

    dailyRule1: "1. 每轮签到最多为7天，每天签到成功后可获得对应奖励。",
    dailyRule2:
      "2. 需连续签到才能获得全部奖励，漏签后不可补签，将从第1天开始重新签到。",
    dailyRule3: "3. 连续签到7天完成后，则重新从第1天开始签到。",
    dailyRule4:
      "签到奖励：第一天{{ 0 }}星值 第二天{{ 1 }}星值 第三天{{ 2 }} 第四天{{ 3 }} 第五天{{ 4 }} 第六天{{ 5 }} 第七天{{ 6 }}",
    cardRule:
      "您当前持有的星卡数量和等级决定了您每日可以获得的星币上限数量，如果想获得更多星币请前往星卡页面获取更多星卡。",
  },
  axios: {
    error1: "网络连接速度受限，请检查网络设置。",
    error2: "身份过期，请重新登录。",
    error3: "服务器繁忙，请稍后重试。",
    error4: "原始密码错误。",
    error5: "新密码不一致。",
    error6: "新密码为空。",
  },
  invite: {
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
    title: "邀请好友",
    catchphrase: "下载注册即可领取\n28美金",
    inviteFriend: "立即邀请",
    inviteCode: "邀请码",
    myInvite: "我的邀请",
    person: "人",
    people: "人",
    teamMembers: "团队总人数",
    totalIncome: "累计邀请奖励",
    totalCommission: "任务提成收益",
    lostIncome: "损失的收益",
    todayData: "今日数据",
    todayInvite: "今日邀请总数",
    inviteRewards: "今日邀请奖励",
    todayCommission: "今日提成奖励",
    todayLost: "今日损失",
    viewInviteRank: "查看邀请榜单",
    threeSteps: "邀请好友，只需三步",
    step1: "点击立即邀请发送邀请海报",
    step2: "好友打开链接下载并登陆",
    step3: "好友完成实名认证",
    howToEarn: "如何赚钱",
    part1: "第一部分",
    "part1-desc-1": "邀请好友完成",
    "part1-desc-2": "实名认证",
    "part1-desc-3": "后，您可立即获得",
    "part1-desc-4": "奖励",
    part2: "第二部分",
    "part2-desc-1": "好友完成每日",
    dailyActivityTask: "活跃度任务",
    "part2-desc-2":
      "后，您可获得提成奖励，奖励金额数量由好友层级和您的星卡等级决定，详情可查看赚钱攻略",
    part3: "第三部分",
    "part3-desc-1": "好友完成每",
    dailyWalkingTask: "日健步任务",
    "part3-desc-2":
      "后，您可获得提成奖励，奖励金额数量由好友层级和您的星卡等级决定，详情可查看赚钱攻略",
    howToInvite: "如何邀请",
    inviteStep1:
      "好友通过您分享的链接/二维码下载登陆Future Star App后，需要完成实名认证，才算邀请成功，否则视为无效邀请，无法获得邀请奖励以及提成奖励。",
    inviteStep2: "好友在注册时需要填写您的邀请码，邀请关系方才成立",
    inviteStep3: "分享给家人、朋友、同学、同事，邀请成功率更高。",
    lostIncomeDesc:
      "如果你下级的合伙人等级高于你，则只能按照你的等级领取提成奖励，高出部分的提成奖励将被丢弃，如果想获得全部提成收益，请尽可能保持你的合伙人等级始终高于或等于你所有的下级",
    rules: "活\n动\n规\n则",
    close: "关闭",
    rulesTitle: "邀请好友活动规则",
    "rules-0-1": "在Future Star App中邀请关系最多可达10层。",
    "rules-0-2": "每个新注册的用户完成实名认证后即可获得100 SP奖励。",
    "rules-0-3":
      "被邀请人每天完成任务后根据其获得的星币数量，邀请人可以获得一定比例的星币奖励，奖励发放时间为Coordinated Universal Time (UTC) 每日AM 06:00。",
    "rules-0-4":
      "被邀请人每天完成任务后根据其获得的星币数量，邀请人可以获得一定比例的星币奖励，奖励发放时间为Coordinated Universal Time (UTC) 每日AM 06:00",
    "rules-1-1": "星级",
    "rules-1-2": "你可获得好友每日赚取星币的比例",
    "rules-1-3-0": "新星合伙人",
    "rules-1-3-1": "一层10%",
    "rules-1-4-0": "一星合伙人",
    "rules-1-4-1": "一层10%、二层5%",
    "rules-1-5-0": "二星合伙人",
    "rules-1-5-1": "一层10%、二层5%、三层5%",
    "rules-1-6-0": "三星合伙人",
    "rules-1-6-1": "一层10%、二层5%、三层5%、四层5%",
    "rules-1-7-0": "四星合伙人",
    "rules-1-7-1": "一层10%、二层5%、三层5%、四层5%、五层3%、六层3%",
    "rules-1-8-0": "五星合伙人",
    "rules-1-8-1":
      "一层10%、二层5%、三层5%、四层5%、五层3%、六层3%、七层3%、八层1%",
    "rules-1-9-0": "六星合伙人",
    "rules-1-9-1":
      "一层10%、二层5%、三层5%、四层5%、五层3%、六层3%、七层3%、八层1%、九层1%、十层1%",
    "rules-2-1":
      "注：邀请奖励的发放比例和层级取决于你当前的合伙人等级，如果你的下级合伙人等级高于你，只能按照你的合伙人等级获得奖励，多余的奖励将被废弃。想要获得更多的邀请奖励，请升级你的合伙人等级。",
    myTeam: "我的团队",
    totalTeamMember: "团队总人数",
    todayIncrease: "今日新增",
    users: "人",
    invitationIncome: "累计邀请收益",
    todayShare: "今日收益",
    todayIncome: "今日收益",
    partners: "合伙人",
    freeMembers: "免费会员",
    viewAll: "查看全部",
    "summary-0": "人已完成活跃度任务，",
    "summary-1": "人已完成健步任务，",
    "summary-2": "人在休息中～",
    invite: "邀请",
    inviteFriends: "邀请好友",
    incomeDetails: "收益详情",
    invitationRanking: "邀请排行榜",
    dailyRank: "日榜",
    totalRank: "总榜",
    shared: "收益: ",
    invited: "邀请: ",
    totalInvited: "邀请人数: ",
    "member-new": "新成员",
    "member-0": "新星卡合伙人",
    "member-1": "一星合伙人",
    "member-2": "二星合伙人",
    "member-3": "三星合伙人",
    "member-4": "四星合伙人",
    "member-5": "五星合伙人",
    "member-6": "六星合伙人",
    noToday: "今天还没有排名",
    download: "下载",
    share: "分享",
    downloadComplete: "邀请海报已下载至手机相册，赶快分享给好友吧",
    shareComplete: "邀请链接已复制，赶快分享给好友吧",
  },
}
