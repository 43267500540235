import "bootstrap/dist/css/bootstrap.min.css"
import { useState } from "react"

import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import PasswordInput from "../components/PasswordInput"
import VerifyCodeInput from "../components/VerifyCodeInput"
import { backendUserSignUp } from "../apis/auth"
import { backendVerifyCode } from "../apis/auth"
import RegularTextInput from "../components/RegularTextInput"
import PhoneNumberInput from "../components/PhoneNumberInput"
import LoginTypeSelector from "../components/LoginTypeSelector"
const LoginInfo1 = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [selectedAreaCode, setSelectedAreaCode] = useState("")

  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const [mode, setMode] = useState(0)
  const [repeatPassword, setRepeatPassword] = useState("")
  const onSendCodeClicked = async () => {
    try {
      await backendUserSignUp(
        mode === 0 ? null : email,
        mode === 1 ? null : selectedAreaCode + phoneNumber,
        password,
        repeatPassword
      )
    } catch (err) {
      console.log(err)
    }
  }
  const onClickSignedUp = async () => {
    try {
      await backendVerifyCode(
        mode === 0 ? null : email,
        mode === 1 ? null : selectedAreaCode + phoneNumber,
        code
      )
      navigate("/onBoard")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "797px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "Inter",
      }}
    >
      <center>
        <div
          style={{
            position: "relative",
            top: "0px",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            onClick={() => {
              navigate(-1)
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../关闭.svg"
            />
          </div>

          <div
            style={{
              position: "relative",
              borderRadius: "6px",
              backgroundColor: "#ebf7ff",
              display: "flex",
              flexDirection: "row",
              padding: "4px 8px",
              alignItems: "center",
              justifyContent: "flex-start",
              left: "265px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "28px",
                height: "28px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../客服.svg"
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "14px",
            color: "#000",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "600",
                color: "#0e73f6",
              }}
            >
              Sign Up 1/2
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "28px",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Set Your Login Info
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
              }}
            >
              Please give us your login info first
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <LoginTypeSelector mode={mode} setMode={setMode} />

            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {mode === 0 ? "Phone Number" : "Email"}
              </div>
              {mode === 0 ? (
                <PhoneNumberInput
                  areaCode={selectedAreaCode}
                  setAreaCode={setSelectedAreaCode}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                />
              ) : (
                <RegularTextInput
                  value={email}
                  setValue={setEmail}
                  placeHolder={"Email"}
                />
              )}
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>

              <PasswordInput
                placeHolder={"Password"}
                value={password}
                setValue={setPassword}
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Repeat Password
              </div>
              <PasswordInput
                placeHolder={"Repeat Password"}
                value={repeatPassword}
                setValue={setRepeatPassword}
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Verification Code
              </div>
              <VerifyCodeInput
                value={code}
                setValue={setCode}
                onSendCodeClicked={onSendCodeClicked}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "4px 0px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <Form.Check />
              <div style={{ flex: "1", position: "relative" }}>
                <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                  <span
                    style={{ lineHeight: "140%" }}
                  >{`I have read carefully and agree with `}</span>
                </p>
                <p style={{ margin: "0", color: "#0e73f6" }}>
                  <span
                    style={{
                      textDecoration: "underline",
                      lineHeight: "130%",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      color: "#0e73f6",
                    }}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "24px",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              onClick={onClickSignedUp}
              style={{
                borderRadius: "100px",
                backgroundColor: "#0E73F6",
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                Sign Up
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/login")
              }}
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "500",
                color: "#0e73f6",
                textAlign: "right",
              }}
            >
              I already had an account
            </div>
          </div>
        </div>
      </center>
    </div>
  )
}

export default LoginInfo1
