export default function BottomPopupMenu({ title, selections }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgb(26, 32, 36, 0.32)",
        display: "flex",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          backgroundColor: "white",
          alignSelf: "end",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <div
          style={{
            border: "1px solid #EEF0F2",
            textAlign: "center",
            padding: "16px 0px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>{title}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "10px 20px",
            marginBottom: "40px",
            gap: "10px",
          }}
        >
          {selections.map((item, index) => {
            if (item.selectionTitle) {
              return (
                <button
                  key={index}
                  onClick={() => {
                    item.clickAction(item.selectionTitle)
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    backgroundColor: "transparent",
                    borderRadius: "12px",
                    padding: "8px",
                    boxShadow: " 0px 1px 4px rgba(120, 120, 120, 0.25)",
                  }}
                >
                  {item.selectionIcon && (
                    <img
                      src={item.selectionIcon}
                      style={{ width: "36px", height: "36px" }}
                    />
                  )}
                  <span
                    style={{
                      marginLeft: "8px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {item.selectionTitle}
                  </span>
                </button>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
