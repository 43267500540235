import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { callOnboard } from "../apis/auth"
import RegularTextInput from "../components/RegularTextInput"
const PersonalInfo1 = () => {
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")
  const [invitationCode, setInvitationCode] = useState("")
  const onClicked = async () => {
    try {
      await callOnboard(userName, invitationCode)
      console.log("on board finished")
      navigate("/")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <center>
      <form
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "100%",
          height: "797px",
          overflow: "hidden",
        }}
      >
        <div
          onClick={() => {
            navigate("/login")
          }}
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../关闭.svg"
          />
        </div>
        <div
          style={{
            position: "relative",
            top: "120px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "32px",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "600",
                fontFamily: "Inter",
                color: "#0e73f6",
                textAlign: "left",
              }}
            >
              Sign Up 2/2
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "28px",
                lineHeight: "32px",
                textTransform: "capitalize",
                fontFamily: "Inter",
                color: "#000",
                textAlign: "left",
              }}
            >
              Your Personal Info
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontFamily: "Inter",
                color: "#000",
                textAlign: "left",
              }}
            >{`Please provide your personal info `}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontFamily: "Inter",
                  color: "#252c32",
                  textAlign: "left",
                }}
              >
                Your User Name
              </div>
              <RegularTextInput
                value={userName}
                setValue={setUserName}
                placeHolder={"Your User Name"}
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  fontFamily: "Inter",
                  color: "#252c32",
                  textAlign: "left",
                }}
              >
                Invitation Code
              </div>
              <RegularTextInput
                value={invitationCode}
                setValue={setInvitationCode}
                placeHolder={"Invitation Code"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            top: "300px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            onClick={onClicked}
            style={{
              borderRadius: "100px",
              backgroundColor: "#0e73f6",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                fontSize: "16px",
                lineHeight: "130%",
                fontWeight: "600",
                fontFamily: "Inter",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Let’s Earn!
            </div>
          </div>
        </div>
      </form>
    </center>
  )
}

export default PersonalInfo1
