import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStepData } from "../HomePage/service";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getDefaultLanguage } from "../../locale";
import { getHomepageVideo } from "../HomePage/service";

const Step = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const { stepData } = useSelector((state) => state.homePage);

  const dispatch = useDispatch();
  useEffect(() => {
    getStepData(dispatch);
  }, []);

  return (
    <center>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "2vh",
          fontFamily: "'Work Sans'",
          color: "#48535b",
        }}
      >
        <div
          style={{
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#303940",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "1.6vh 2vh",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                fontSize: "2.4vh",
              }}
            >
              {t("fitness.title")}
            </b>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            color: "#303940",
            padding: "5vh 3vh",
            textAlign: "center",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "10vh",
          }}
        >
          <div style={{ lineHeight: "130%" }}>{t("fitness.warning")}</div>
          <div
            style={{
              position: "relative",
              width: "30vh",
              height: "30vh",
              flexShrink: "0",
              fontSize: "1.4vh",
            }}
          >
            <CircularProgressbar
              value={Math.min(
                Math.round(
                  (stepData.current.steps / stepData.current.targetSteps) * 1000
                ) / 10,
                100
              )}
              text={`${Math.min(
                Math.round(
                  (stepData.current.steps / stepData.current.targetSteps) * 1000
                ) / 10,
                100
              )}%`}
              styles={buildStyles({
                pathColor: "#1371FF",
                textColor: "#48535b",
                trailColor: "#97CEFF",
                textSize: "2.6vh",
              })}
            />
            <div
              style={{
                fontSize: "2.8vh",
                lineHeight: "130%",
                padding: "2vh 0 0 0",
              }}
            >
              <b>
                {stepData.current.steps} / {stepData.current.targetSteps}{" "}
                {t("fitness.steps")}
              </b>
            </div>
            <div
              style={{
                fontSize: "2vh",
                lineHeight: "130%",
                color: "#0E73F6",
                fontFamily: "Inter",
                fontWeight: "500",
              }}
            >
              {t("fitness.keepMoving", {
                amount: stepData.current.targetIncome,
              })}
            </div>
          </div>
          <button
            onClick={async () => {
              const language = getDefaultLanguage();
              let filter = { language };
              const videos = await getHomepageVideo(filter);
              const index = Math.floor(Math.random() * videos.length);
              location.href = videos[index].link;
            }}
            style={{
              position: "relative",
              border: "0",
              top: "10vh",
              lineHeight: "130%",
              backgroundColor: "transparent",
            }}
          >
            <u>{t("fitness.tired")}</u>
          </button>
        </div>
      </div>
    </center>
  );
};

export default Step;
