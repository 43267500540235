import { t } from "i18next";

const MyMember = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "988px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontFamily: "Inter",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "40px",
              height: "20px",
              flexShrink: "0",
            }}
            alt=""
            src="../左尖角.svg"
          />
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
            }}
          >
            {t("invite.myTeam")}
          </b>
          <div
            style={{
              height: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
              fontSize: "14px",
              color: "#000",
              fontFamily: "Inter",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "16px",
                height: "16px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../info-fill0-wght400-grad0-opsz48-11.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "500",
              }}
            >
              {t("invite.rules")}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "312px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "20px",
            color: "#1a2024",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "75px",
                height: "23px",
                flexShrink: "0",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "0px",
                  borderRadius: "100px",
                  backgroundColor: "#97ceff",
                  width: "36px",
                  height: "10px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                {t("invite.myTeam")}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "28px",
              fontSize: "12px",
              color: "#48535b",
              fontFamily: "Inter",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "left",
                  fontSize: "16px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "500",
                    }}
                  >
                    {t("invite.partners")} (31){" "}
                    {t("invite.users", { plural: "s" })}
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "18px",
                      height: "18px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../info-fill0-wght400-grad0-opsz48-11.svg"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "327px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      自己
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      John...
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Arial
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Bens...
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 2px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Bill Ga..
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "327px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Phill..
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      John...
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Arial
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Bens...
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "12px",
                    backgroundColor: "#ebf7ff",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px 2px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "10px",
                    color: "#0e73f6",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../右箭头-蓝.svg"
                  />
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      textDecoration: "underline",
                      lineHeight: "16px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "47px",
                    }}
                  >
                    {t("invite.viewAll")}
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "1000px",
                  backgroundColor: "#f6f6f6",
                  display: "flex",
                  flexDirection: "row",
                  padding: "6px 18px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "10px",
                  color: "#f8a01b",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    lineHeight: "16px",
                  }}
                >
                  <b>13</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-0", { plural: "s" })}
                  </span>
                  <b>24</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-1", { plural: "s" })}
                  </span>
                  <b>34</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-2", { plural: "s" })}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "left",
                  fontSize: "16px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  {t("invite.freeMembers")}(3{" "}
                  {t("invite.users", { plural: "s" })})
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../info-fill0-wght400-grad0-opsz48-11.svg"
                />
              </div>
              <div
                style={{
                  width: "327px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      自己
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      John...
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10000px",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像2.png"
                  />
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 13px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      Arial
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "327px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "300",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10000px",
                      backgroundColor: "#f5f5f5",
                      border: "0.5px dashed #c9c9c9",
                      boxSizing: "border-box",
                      width: "51px",
                      height: "51px",
                      flexShrink: "0",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "32px",
                        height: "32px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../我的.svg"
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#f5f5f5",
                      width: "51px",
                      display: "flex",
                      flexDirection: "row",
                      padding: "2px 6px",
                      boxSizing: "border-box",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "15px",
                        height: "14px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../加号.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                      }}
                    >
                      {t("invite.invite")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "12px",
                    backgroundColor: "#ebf7ff",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px 2px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "10px",
                    color: "#0e73f6",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../右箭头-蓝.svg"
                  />
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      textDecoration: "underline",
                      lineHeight: "16px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "47px",
                    }}
                  >
                    {t("invite.viewAll")}
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "1000px",
                  backgroundColor: "#f6f6f6",
                  display: "flex",
                  flexDirection: "row",
                  padding: "6px 18px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "10px",
                  color: "#f8a01b",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    lineHeight: "16px",
                  }}
                >
                  <b>1</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-0", { plural: "s" })}
                  </span>
                  <b>2</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-1", { plural: "s" })}
                  </span>
                  <b>1</b>
                  <span style={{ fontWeight: "500", color: "#48535b" }}>
                    {t("invite.summary-2", { plural: "s" })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                height: "39px",
                flexShrink: "0",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: "4px 24px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                color: "#ffffff",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    fontWeight: "600",
                  }}
                >
                  {t("invite.inviteFriends")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "78px",
            left: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              width: "351px",
              height: "100px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "351px",
                height: "119px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="../登陆领取背景图.png"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "18px",
                left: "12px",
                width: "327px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      color: "#ffffff",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "2px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        {t("invite.teamMembers")}
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "20px",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        2
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "12px",
                          lineHeight: "130%",
                          color: "#eaeaea",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>
                          {t("invite.todayIncrease")} (10{" "}
                          {t("invite.users", { plural: "s" })})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              width: "351px",
              height: "100px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "351px",
                height: "119px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="../登陆领取背景图.png"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "18px",
                left: "12px",
                width: "327px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "2px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        {t("invite.totalCommission")}
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        <span>4500</span>
                        <span style={{ fontSize: "14px" }}>SP</span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "12px",
                          lineHeight: "130%",
                          color: "#eaeaea",
                          fontFamily: "Inter",
                          fontWeight: "400",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>
                          {t("invite.todayShare")}
                        </span>
                        <span style={{ fontWeight: "600" }}>{`300 `}</span>
                        <span style={{ fontWeight: "500" }}>SP</span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        borderRight: "1px solid #d5dadd",
                        boxSizing: "border-box",
                        width: "1px",
                        height: "27px",
                        flexShrink: "0",
                      }}
                    />
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "2px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            lineHeight: "130%",
                            fontWeight: "600",
                          }}
                        >
                          {t("invite.lostIncome")}
                        </div>
                        <img
                          style={{
                            position: "relative",
                            width: "18px",
                            height: "18px",
                            flexShrink: "0",
                            overflow: "hidden",
                          }}
                          alt=""
                          src="../info-fill0-wght400-grad0-opsz48-1.svg"
                        />
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        <span>500</span>
                        <span style={{ fontSize: "14px" }}>FSC</span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "12px",
                          lineHeight: "130%",
                          color: "#eaeaea",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>
                          {t("invite.todayLost")}
                        </span>
                        <span style={{ fontWeight: "600" }}>{`300 `}</span>
                        <span style={{ fontWeight: "500" }}>SP</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default MyMember;
