const H5Login = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#527efd",
          width: "375px",
          height: "1039px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "27px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "369px",
            left: "24px",
            width: "327px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <b
            style={{
              alignSelf: "stretch",
              position: "relative",
              letterSpacing: "2.2px",
              lineHeight: "38px",
              textTransform: "capitalize",
            }}
          >
            New users register can get up to 28 USDT reward.
          </b>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            Great Rewards are waiting for you
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "707px",
            left: "24px",
            width: "327px",
            height: "166px",
            fontSize: "20px",
          }}
        >
          <b
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              letterSpacing: "2.2px",
              textTransform: "capitalize",
              display: "inline-block",
              width: "327px",
            }}
          >
            The APP That brings You wealth
          </b>
          <div
            style={{
              position: "absolute",
              top: "66px",
              left: "0px",
              fontSize: "18px",
              fontWeight: "400",
              display: "inline-block",
              width: "327px",
            }}
          >
            Future Star is an X to Earn super application. It is a leading
            company in the field of web3 to help you realize your wealth dream
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "calc(50% - 187.5px)",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "12px 24px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "12px",
            color: "#527efd",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "129px",
              height: "40px",
              flexShrink: "0",
            }}
          />
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              padding: "4px 8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <b style={{ position: "relative" }}>Register Now</b>
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            top: "104px",
            left: "18px",
            width: "340px",
            height: "228px",
            objectFit: "cover",
          }}
          alt=""
          src="/h5登陆背景图.png"
        />
        <div
          style={{
            position: "absolute",
            top: "621px",
            left: "17px",
            width: "341px",
            height: "162px",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "895px",
            left: "0px",
            backgroundColor: "#2755db",
            width: "375px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            fontSize: "18px",
            color: "#527efd",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "100px",
              backgroundColor: "#fff",
              height: "48px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              Register Now
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "100px",
              backgroundColor: "#6f94ff",
              height: "48px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              color: "#fff",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              Download APP
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default H5Login;
