const initialState = {
  progress: {
    income: 0,
    maxPoint: 100,
    potentialEarn: 48,
    redeemed: false,
    total: 0,
    totalPoint: 0,
  },

  stepData: {
    current: {
      steps: 0,
      targetIncome: 48,
      targetSteps: 4000,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "homePage/GET_ACTIVITY":
      return {
        ...state,
        progress: action.payload,
      };

    case "homePage/GET_STEP_DATA":
      return {
        ...state,
        stepData: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
