import React from 'react';
import { useTranslation } from "react-i18next";

import home from '../../assets/images/MainTab/home.png';
import home_select from '../../assets/images/MainTab/home-selected.png';

import task from '../../assets/images/MainTab/task.png';
import task_select from '../../assets/images/MainTab/task-selected.png';

import card from '../../assets/images/MainTab/card-2.png';
import card_select from '../../assets/images/MainTab/card-2-selected.png';

import news from '../../assets/images/MainTab/news.png';
import news_select from '../../assets/images/MainTab/news-selected.png';

import profile from '../../assets/images/MainTab/profile.png';
import profile_select from '../../assets/images/MainTab/profile-selected.png';

import { useNavigate } from "react-router-dom";

const Menu = ({ select = 'home' }) => {
  const { t } = useTranslation();

  const navigation = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderTop: "1px solid #eef0f2",
          boxSizing: "border-box",
          width: "100%",
          maxWidth: 800,
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1.2vh",
          color: "#9aa6ac",
          height: "6.8vh",
          zIndex: 1000,
        }}
      >
        <button
          onClick={() => {
            navigation("/");
          }}
          style={{
            border: "0",
            backgroundColor: "transparent",
            color: "inherit",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.23vh",
            color: select === 'home' ? '#0e73f6' : "#9AA6AC",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "2.74vh",
              height: "2.74vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src={select === 'home' ? home_select : home}
          />
          <div style={{ position: "relative", lineHeight: "120%" }}>
            {t("bottomBar.Home")}
          </div>
        </button>
        <button
          onClick={() => {
            navigation("/tasks");
          }}
          style={{
            border: "0",
            backgroundColor: "transparent",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.23vh",
            color: select === 'task' ? '#0e73f6' : "#9AA6AC",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "2.74vh",
              height: "2.74vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src={select === 'task' ? task_select : task}
          />
          <div style={{ position: "relative", lineHeight: "120%" }}>
            {t("bottomBar.Task")}
          </div>
        </button>
        <button
          onClick={() => {
            navigation("/card");
          }}
          style={{
            border: "0",
            backgroundColor: "transparent",
            color: "inherit",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.23vh",
            color: select === 'card' ? '#0e73f6' : "#9AA6AC",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "2.74vh",
              height: "2.74vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src={select === 'card' ? card_select : card}
          />
          <div style={{ position: "relative", lineHeight: "120%" }}>
            {t("bottomBar.Card")}
          </div>
        </button>
        <button
          onClick={() => {
            navigation("/info");
          }}
          style={{
            border: "0",
            backgroundColor: "transparent",
            color: "inherit",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.23vh",
            color: select === 'news' ? '#0e73f6' : "#9AA6AC",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "2.74vh",
              height: "2.74vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src={select === 'news' ? news_select : news}
          />
          <div style={{ position: "relative", lineHeight: "120%" }}>
            {t("bottomBar.Read")}
          </div>
        </button>
        <button
          onClick={() => {
            navigation("/profile");
          }}
          style={{
            border: "0",
            backgroundColor: "transparent",
            color: "inherit",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.23vh",
            color: select === 'profile' ? '#0e73f6' : "#9AA6AC",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "2.74vh",
              height: "2.74vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src={select === 'profile' ? profile_select : profile}
          />
          <div
            style={{
              position: "relative",
              lineHeight: "120%",
            }}
          >
            {t("bottomBar.Profile")}
          </div>
        </button>
      </div>
    </div>
  )
}

export default Menu;
