import React from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const starIcon = require('../assets/star.png');
const starGreyIcon = require('../assets/star-grey.png');
const cover = require('../assets/card-splash.png');

const MyStarCard = (props) => {
  const { t } = useTranslation();

  const { level, totalEarned, type, effectiveStart, effectiveEnd, attributes } = props;

  return (
    <div className='default-card'>
      <img src={cover} alt="" className='default-card-cover' />
      <div className='default-card-header'>
        <div className='card-title'>
          {t('card.cardDesc', { star: level, type })}
        </div>
        <div className='card-star'>
          {
            level === 0 ?
              <img src={starGreyIcon} style={{ height: 13, width: 14 }} />
              : null
          }
          {
            Array.from(Array(level).keys()).map(s =>
              <img key={s} src={starIcon} style={{ height: 13, width: 14, marginLeft: 6 }} />
            )
          }
        </div>
      </div>
      <div className='my-card-body'>
        <div className='card-expire'>
          {t('card.valid', { days: attributes.effective_days })}
        </div>
        <div
          style={{
            color: '#D5DADD',
            fontSize: 12,
            marginLeft: 6
          }}
        >
          {`(${moment(effectiveStart).format('YYYY.MM.DD')}-${moment(effectiveEnd).format('YYYY.MM.DD')})`}
        </div>
      </div>
      <div className='my-card-bottom'>
        {t('card.earned')} <span style={{ color: '#67E48B' }}>{totalEarned}</span> / {attributes.total_supply} SP
      </div>
    </div>
  );
};

export default MyStarCard;

