import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

const SetLanguage = () => {
  return (
    <form
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "667px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "151px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "36px",
        }}
      >
        <b
          style={{
            position: "relative",
            fontSize: "26px",
            letterSpacing: "-0.01em",
            lineHeight: "120%",
            fontFamily: "'Work Sans'",
            color: "#303940",
            textAlign: "center",
          }}
        >
          <center>Set Your Language</center>
        </b>
        <Form.Select style={{ width: "321px" }}>
          <option>English</option>
        </Form.Select>
      </div>
      <div
        style={{
          position: "relative",
          top: "425px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "36px",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            border: "none",
            padding: "11px 32px",
            backgroundColor: "#0e73f6",
            position: "relative",
            borderRadius: "100px",
            width: "335px",
            display: "flex",
            flexDirection: "row",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <div
            style={{
              position: "relative",
              fontSize: "16px",
              lineHeight: "130%",
              fontWeight: "600",
              fontFamily: "Inter",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <center>I‘m Ready</center>
          </div>
        </button>
      </div>
    </form>
  );
};

export default SetLanguage;
