import React from 'react';
import ArticleList from './components/ArticleList';
import Banners from './components/Banners';
import SelectList from './components/SelectList';
import { useTranslation } from "react-i18next";
import './index.css';
import Menu from '../../components/Menu';
import { useSelector } from 'react-redux';

const Info = () => {
  const { t } = useTranslation();

  const { isLoggedIn } = useSelector(state => state.user);

  return (
    <div className='info-layout'>
      <div className='info-header'>
        {t('read.headlines')}
      </div>
      {isLoggedIn && <Banners />}
      {isLoggedIn && <SelectList />}
      <ArticleList />
      <Menu select={'news'} />
    </div>
  );
};

export default Info;
