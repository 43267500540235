import PageWrapper from "../../../components/PageWrapper"
import QRCode from "react-qr-code"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import { getTopUpAddress } from "../../../apis/wallet"
const alertIcon = require("../asset/alertIcon.png")
const whiteGobackIcon = require("../asset/whiteGobackIcon.png")
const shareIcon = require("../asset/shareIcon.png")
const copyIcon = require("../asset/copyIcon.png")
const logoIcon = require("../asset/logoIcon.png")
const VALID_CURRENCY = ["FSC", "USDT", "BNB"]
const VALID_NETWORK = ["BIANCE", "ETHEREUM", "TRON"]
const currencyConfig = {
  FSC: {
    color: "#4094F7",
  },
  USDT: {
    color: "#55B197",
  },
  BNB: {
    color: "#F4BC33",
  },
}
export default function TopupScreen() {
  const [currency, setCurrency] = useState("FSC")
  const [network, setNetwork] = useState("BIANCE")
  const [address, setAddress] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    const part1 = location.search.split("&")[0]
    const part2 = location.search.split("&")[1]
    if (
      !VALID_CURRENCY.includes(part1.split("=")[1]) ||
      !VALID_NETWORK.includes(part2.split("=")[1])
    ) {
      navigate(-1)
    } else {
      setCurrency(part1.split("=")[1])
      setNetwork(part2.split("=")[1])
    }
  }, [])

  useEffect(() => {
    getTopUpAddress(currency, network)
      .then((result) => {
        setAddress(result.data.data.address)
      })
      .catch((e) => console.log(e))
  }, [currency, network])
  return (
    <PageWrapper style={{ backgroundColor: currencyConfig[currency].color }}>
      <div style={styles.headerWrapper}>
        <button
          onClick={() => {
            navigate(-1)
          }}
          style={styles.goBackButtonWrapper}
        >
          <img
            src={whiteGobackIcon}
            style={{ width: "24px", height: "24px" }}
          />
        </button>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "white",
          }}
        >
          {t("wallet.topUpTitle", { currency })}
        </span>
      </div>
      <div style={styles.bodyWrapper}>
        <div style={styles.cardWrapper}>
          <div style={styles.topPartWrapper}>
            <div style={styles.warningWrapper}>
              <img src={alertIcon} style={{ width: "24px", height: "24px" }} />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#F2271C",
                  marginLeft: "20px",
                }}
              >
                {t(`wallet.topUpWarning${network}`)}
              </span>
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                backgroundColor: "white",
                marginTop: "32px",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "180px", width: "180px" }}
                value={address}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>

          <div style={styles.bottomPartWrapper}>
            <div style={styles.addressWrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#84919A",
                  }}
                >
                  {t("wallet.address")}
                </span>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#D7EDFF",
                    padding: "3px 5px",
                    marginLeft: "5px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "#0452C8",
                    }}
                  >
                    {network}
                  </span>
                </div>
              </div>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#252C32",
                }}
              >
                {address}
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginTop: "28px",
              }}
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  padding: "10px 0px",
                }}
              >
                <img
                  src={shareIcon}
                  style={{ width: "16px", height: "16px" }}
                />

                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#252C32",
                    marginLeft: "5px",
                  }}
                >
                  {t("word.share")}
                </span>
              </button>
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  padding: "10px 0px",
                }}
              >
                <img src={copyIcon} style={{ width: "16px", height: "16px" }} />

                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#252C32",
                    marginLeft: "5px",
                  }}
                >
                  {t("word.copy")}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: "10vh",
            alignSelf: "center",
          }}
        >
          <img
            src={logoIcon}
            style={{
              width: "158px",
              height: "48px",
            }}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
  },
  headerWrapper: {
    backgroundColor: "transparent",
    width: "100%",
    height: "5.8vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "17px 0px",
  },
  goBackButtonWrapper: {
    border: "0",
    backgroundColor: "transparent",
    position: "absolute",
    left: "12px",
  },
  cardWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
  },
  topPartWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F8F9",
    borderRadius: "16px 16px 0px 0px",
  },
  warningWrapper: {
    display: "flex",
    backgroundColor: "#FED6CD",
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomPartWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "0px 0px 16px 16px",
  },
  addressWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEF0F2",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 16px",
    gap: "8px",
    borderRadius: "6px",
  },
}
