import { useNavigate } from "react-router-dom"
const goBackIcon = require("../asset/gobackIcon.png")
export default function PageHeader({ title, style }) {
  const navigate = useNavigate()

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "5.8vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "17px 0px",
        ...style,
      }}
    >
      <button
        onClick={() => {
          navigate(-1)
        }}
        style={{
          border: "0",
          backgroundColor: "transparent",
          position: "absolute",
          left: "12px",
        }}
      >
        <img src={goBackIcon} style={{ width: "24px", height: "24px" }} />
      </button>
      <span
        style={{
          fontSize: "18px",
          fontWeight: "700",
          color: "#303940",
        }}
      >
        {title}
      </span>
    </div>
  )
}
