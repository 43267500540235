import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

const WithdrawUSDT = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "775px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "18px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "16px 20px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/左尖角.svg"
              />
              <b
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  left: "-2px",
                }}
              >
                提现USDT
              </b>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: "72px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "12px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "7px",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  收款地址
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 9px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "17px",
                  textAlign: "left",
                  fontSize: "14px",
                  color: "#252c32",
                }}
              >
                <input
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    border: "0",
                    backgroundColor: "#f5f5f5",
                  }}
                  placeholder="0xce2FD7544c9469...eBEf7bcB61328"
                ></input>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    backgroundColor: "#e5e9eb",
                    width: "1px",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "none",
                  }}
                />
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/扫描.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "7px",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  主网络
                </div>
              </div>
              <Form.Select style={{ alignSelf: "stretch" }}>
                <option>BNB Smart Chain (BEP20)</option>
                <option value="Ethereum (ERC20)">Ethereum (ERC20)</option>
                <option value="TRON (TRC20)">TRON (TRC20)</option>
              </Form.Select>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "8px",
                backgroundColor: "#fed6cd",
                display: "flex",
                flexDirection: "row",
                padding: "12px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
                fontSize: "14px",
                color: "#f2271c",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/提现提醒惊叹号.svg"
              />
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    该网络是币安智能链(BSC)网络，请确保您的提币地址支持 BNB
                    Smart
                    Chain（BEP20）网络，因错误转账导致的资金损失将由您个人承担。
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  提现金额
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #dde2e4",
                    display: "flex",
                    flexDirection: "row",
                    padding: "8px 9px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "12px",
                    fontSize: "14px",
                    color: "#b0babf",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        display: "inline-block",
                        width: "132.5px",
                        flexShrink: "0",
                        border: "0",
                        backgroundColor: "transparent",
                      }}
                      placeholder="最小值 10"
                    ></input>
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        fontWeight: "500",
                        color: "#48535b",
                        textAlign: "right",
                        display: "inline-block",
                        width: "132.5px",
                        flexShrink: "0",
                      }}
                    >
                      USDT
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "500",
                      color: "#0e73f6",
                      textAlign: "right",
                    }}
                  >
                    全部
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                    fontSize: "12px",
                    color: "#252c32",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      display: "inline-block",
                      width: "178px",
                      flexShrink: "0",
                    }}
                  >
                    可用余额 1203.01 USDT
                  </div>
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      color: "#1a2024",
                      textAlign: "right",
                    }}
                  >
                    提现最小值 10 USDT
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "600",
                  }}
                >
                  到账数量
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #dde2e4",
                    display: "flex",
                    flexDirection: "row",
                    padding: "8px 9px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "14px",
                    color: "#1a2024",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        display: "inline-block",
                        width: "132.5px",
                        flexShrink: "0",
                        border: "0",
                        backgroundColor: "transparent",
                      }}
                      placeholder="到账数量"
                    ></input>
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        fontWeight: "500",
                        color: "#48535b",
                        textAlign: "right",
                        display: "inline-block",
                        width: "132.5px",
                        flexShrink: "0",
                      }}
                    >
                      USDT
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
                fontSize: "12px",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  width: "311px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "2px",
                }}
              >
                <div style={{ position: "relative", letterSpacing: "-0.01em" }}>
                  <span style={{ fontWeight: "400" }}>提现网络手续费</span>
                  <span style={{ fontWeight: "600" }}> 2 USDT</span>
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/i-蓝.svg"
                />
              </div>
              <div
                style={{
                  width: "311px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontWeight: "400",
                  }}
                >
                  提现通常会在10分钟内到账，若超过10分钟未到账请联系我们的客户服务
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default WithdrawUSDT;
