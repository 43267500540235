import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getTaskProgress, getStepData } from "./service";
import { getDefaultLanguage } from "../../locale";
import { getHomepageVideo } from "./service";

import Menu from "../../components/Menu";
import { getMyCards } from "../Card/service";

const medal0Image = require("../../pages/Card/assets/medal-0.png");
const medal1Image = require("../../pages/Card/assets/medal-1.png");
const medal2Image = require("../../pages/Card/assets/medal-2.png");
const medal3Image = require("../../pages/Card/assets/medal-3.png");
const medal4Image = require("../../pages/Card/assets/medal-4.png");
const medal5Image = require("../../pages/Card/assets/medal-5.png");
const medal6Image = require("../../pages/Card/assets/medal-6.png");

const medals = [
  medal0Image,
  medal1Image,
  medal2Image,
  medal3Image,
  medal4Image,
  medal5Image,
  medal6Image,
];

const HomePage = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { isLoggedIn, starLevel } = useSelector((state) => state.user);
  const { progress, stepData } = useSelector((state) => state.homePage);
  const { myTaskCardList, myStepCardList } = useSelector((state) => state.card);

  const [commingSoonPopup, setCommingSoonPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    getTaskProgress(dispatch);
    getStepData(dispatch);
    getMyCards(dispatch, "task");
    getMyCards(dispatch, "step");
  }, []);

  return (
    <center>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "1.4vh",
          color: "#48535b",
          fontFamily: "Inter",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "100%",
            height: "6.8%",
            display: "flex",
            flexDirection: "row",
            padding: "1.4vh 5.33vh",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={medals[Math.max(starLevel, 0)]}
            alt=""
            style={{
              position: "relative",
              width: "3.5vh",
              height: "3.5vh",
              flexShrink: "0",
              overflow: "hidden",
            }}
          />

          <button
            onClick={() => {
              navigation("/card");
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "transparent",
              border: "0",
            }}
          >
            <div
              style={{
                position: "relative",
                textDecoration: "underline",
                lineHeight: "130%",
                color: "48535B",
                fontWeight: "300",
              }}
            >
              {t("homeV1.upgradeStar")}
            </div>
            <img
              style={{
                position: "relative",
                width: "2vh",
                height: "1.83vh",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../右箭头.svg"
            />
          </button>
        </div>
        <div
          style={{
            position: "relative",
            top: "2.3vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            gap: "1.83vh",
            padding: "0px 3vh",
            fontSize: "2vh",
            color: "#fff",
            height: "86.4%",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              height: "15vh",
              flexShrink: "0",
              overflow: "hidden",
              display: !isLoggedIn ? "flex" : "none",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="登陆领取背景图.png"
              />
              <div
                style={{
                  position: "relative",
                  top: "2.17vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1.37vh",
                  paddingBottom: "2.17vh",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.46vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "0.06em",
                      lineHeight: "140%",
                      fontWeight: "600",
                    }}
                  >
                    {t("homeV1.loginFirst")}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      fontSize: "1.4vh",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "500",
                        display: "inline-block",
                        width: "100%",
                        flexShrink: "0",
                      }}
                    >
                      {t("homeV1.loginFirstDesc")}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    navigation("/login");
                  }}
                  style={{
                    width: "23.31vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#0e73f6",
                    borderRadius: "100px",
                    border: "0",
                    backgroundColor: "#FFF",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.46vh 2.4vw",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      {t("homeV1.loginNow")}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              height: "14.06vh",
              flexShrink: "0",
              overflow: "hidden",
              display: isLoggedIn && starLevel < 0 ? "flex" : "none",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="登陆领取背景图.png"
              />
              <div
                style={{
                  position: "relative",
                  top: "2.17vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1.37vh",
                  paddingBottom: "2.17vh",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.46vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "0.06em",
                      lineHeight: "140%",
                      fontWeight: "600",
                    }}
                  >
                    {t("tasks.getStarCard")}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      fontSize: "1.4vh",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "500",
                        display: "inline-block",
                        width: "100%",
                        flexShrink: "0",
                      }}
                    >
                      {t("tasks.getStarCardDesc")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "23.31vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#0e73f6",
                    borderRadius: "100px",
                    border: "0",
                    backgroundColor: "#FFF",
                  }}
                >
                  <button
                    onClick={() => navigation("/card")}
                    style={{
                      border: "0",
                      color: "inherit",
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.46vh 2.4vw",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      {t("tasks.getItNow")}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              background: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
              height: "13.06vh",
              flexShrink: "0",
              overflow: "hidden",
              display:
                isLoggedIn && starLevel >= 0 && myTaskCardList.length <= 0
                  ? "flex"
                  : "none",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "1.67vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1.37vh",
                  paddingBottom: "2.17vh",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.46vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "0.06em",
                      lineHeight: "140%",
                      fontWeight: "600",
                    }}
                  >
                    {t("tasks.noTaskCardTitle")}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      fontSize: "1.4vh",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "500",
                        display: "inline-block",
                        width: "100%",
                        flexShrink: "0",
                      }}
                    >
                      {t("tasks.noCardSubTitle")}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    navigation("/card");
                  }}
                  style={{
                    width: "23.31vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#0e73f6",
                    borderRadius: "100px",
                    border: "0",
                    backgroundColor: "#FFF",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.46vh 2.4vw",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      {t("tasks.getItNow")}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              background: "linear-gradient(266.42deg, #03d4aa, #0ade6e)",
              height: "13.06vh",
              flexShrink: "0",
              overflow: "hidden",
              display:
                isLoggedIn && starLevel >= 0 && myStepCardList.length <= 0
                  ? "flex"
                  : "none",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "1.67vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1.37vh",
                  paddingBottom: "2.17vh",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.46vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      letterSpacing: "0.06em",
                      lineHeight: "140%",
                      fontWeight: "600",
                    }}
                  >
                    {t("tasks.noStepCardTitle")}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      fontSize: "1.4vh",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "500",
                        display: "inline-block",
                        width: "100%",
                        flexShrink: "0",
                      }}
                    >
                      {t("tasks.noCardSubTitle")}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    navigation("/card");
                  }}
                  style={{
                    width: "23.31vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#22c348",
                    borderRadius: "100px",
                    border: "0",
                    backgroundColor: "#FFF",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0.46vh 2.4vw",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      {t("tasks.getItNow")}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigation("/activity")}
            style={{
              border: "0",
              color: "inherit",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
              display:
                isLoggedIn && starLevel >= 0 && myTaskCardList.length > 0
                  ? "flex"
                  : "none",
              flexDirection: "row",
              padding: "2.2vh 4.2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "calc(0.7*width)",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.23vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "500",
                }}
              >
                {t("homeV1.activityTitle")}
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "1.4vh",
                  lineHeight: "130%",
                  fontWeight: "200",
                }}
              >
                {t("homeV1.activityDesc", { FSC: progress.potentialEarn })}
              </div>
            </div>
            <div
              style={{
                position: "relative",
                right: "10vh",
                width: "6.4vh",
                height: "6.4vh",
                flexShrink: "0",
                fontSize: "1.4vh",
              }}
            >
              <CircularProgressbar
                value={
                  Math.round((progress.total / progress.potentialEarn) * 1000) /
                  10
                }
                text={`${
                  Math.round((progress.total / progress.potentialEarn) * 1000) /
                  10
                }%`}
                styles={buildStyles({
                  pathColor: "#fff",
                  textColor: "#fff",
                  trailColor: "#006DFC",
                  textSize: "2.6vh",
                })}
              />
            </div>
          </button>
          <button
            onClick={() => navigation("/step")}
            style={{
              border: "0",
              color: "inherit",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "linear-gradient(266.42deg, #03d4aa, #0ade6e)",
              display:
                isLoggedIn && starLevel >= 0 && myStepCardList.length > 0
                  ? "flex"
                  : "none",
              flexDirection: "row",
              padding: "2.2vh 4.2vh",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "calc(0.7*width)",
            }}
          >
            <div
              style={{
                width: "100%",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.23vh",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "500",
                }}
              >
                {t("homeV1.moveToEarn")}
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "1.4vh",
                  lineHeight: "130%",
                  fontWeight: "200",
                }}
              >
                {t("homeV1.moveToEarnDesc", {
                  FSC: stepData.current.targetIncome,
                })}
              </div>
            </div>
            <div
              style={{
                position: "relative",
                right: "10vh",
                width: "6.4vh",
                height: "6.4vh",
                flexShrink: "0",
                fontSize: "1.4vh",
              }}
            >
              <CircularProgressbar
                value={Math.min(
                  Math.round(
                    (stepData.current.steps / stepData.current.targetSteps) *
                      1000
                  ) / 10,
                  100
                )}
                text={`${Math.min(
                  Math.round(
                    (stepData.current.steps / stepData.current.targetSteps) *
                      1000
                  ) / 10,
                  100
                )}%`}
                styles={buildStyles({
                  pathColor: "#fff",
                  textColor: "#fff",
                  trailColor: "#00C191",
                  textSize: "2.6vh",
                })}
              />
            </div>
          </button>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              padding: "1.83vh 2.13vw",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontSize: "1.4vh",
              color: "#303940",
              gap: "1vw",
            }}
          >
            <button
              onClick={() => {
                isLoggedIn ? navigation("/tasks") : setLoginPopup(true);
              }}
              style={{
                border: "0",
                backgroundColor: "transparent",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 0.53vw",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.92vh",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "5.5vh",
                  height: "5.5vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../每日签到.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                {t("homeV1.checkIn")}
              </div>
            </button>
            <button
              onClick={() => {
                navigation("/info");
              }}
              style={{
                border: "0",
                backgroundColor: "transparent",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 0.53vw",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.92vh",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "5.5vh",
                  height: "5.5vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../每日头条.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                {t("homeV1.headlines")}
              </div>
            </button>
            <button
              onClick={() => {
                setCommingSoonPopup(true);
              }}
              style={{
                gap: "0.92vh",
                border: "0",
                backgroundColor: "transparent",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 0.53vw",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "5.5vh",
                  height: "5.5vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../幸运转盘.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                {t("homeV1.wheel")}
              </div>
            </button>
            <button
              onClick={() => {
                setCommingSoonPopup(true);
              }}
              style={{
                gap: "0.92vh",
                border: "0",
                backgroundColor: "transparent",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 0.53vw",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "5.5vh",
                  height: "5.5vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../星星彩票.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                {t("homeV1.lottery")}
              </div>
            </button>
            <button
              onClick={() => {
                setCommingSoonPopup(true);
              }}
              style={{
                gap: "0.92vh",
                border: "0",
                backgroundColor: "transparent",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 0.53vw",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "1000000px",
                  width: "5.5vh",
                  height: "5.5vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../星星宠物.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                {t("homeV1.pet")}
              </div>
            </button>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.4vh",
              fontSize: "2.2vh",
              color: "#1a2024",
            }}
          >
            <div
              style={{
                position: "relative",
                fontSize: "2vh",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              {t("homeV1.recommended")}
            </div>
            <button
              onClick={() => {
                isLoggedIn ? navigation("/invite") : setLoginPopup(true);
              }}
              style={{
                border: "0",
                alignSelf: "stretch",
                position: "relative",
                borderRadius: "12px",
                backgroundColor: "#fff",
                height: "11vh",
                flexShrink: "0",
                overflow: "hidden",
                textAlign: "left",
                fontSize: "2.6vh",
                color: "#fff",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "12px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="../邀好友送现金背景图.png"
              />
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "4.2vw",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="../礼盒.png"
              />
              <div
                style={{
                  position: "relative",
                  left: "2.2vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "1vh",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "0.21em",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    display: "inline-block",
                    fontSize: "2.2vh",
                  }}
                >
                  {t("homeV1.inviteFriends")}
                </div>
                <div
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.43vh 0.92vh",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "0.02em",
                      lineHeight: "16px",
                      textTransform: "uppercase",
                      fontWeight: "300",
                      fontSize: "1.4vh",
                    }}
                  >
                    {t("homeV1.inviteFriendsDesc")}
                  </div>
                </div>
              </div>
              <img
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "4.2vw",
                  height: "42.7%",
                  objectFit: "cover",
                }}
                alt=""
                src="../绿色钱币.png"
              />
            </button>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3.2vw",
                fontSize: "1.65vh",
              }}
            >
              <button
                onClick={() => {
                  navigation("/info");
                }}
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(68.87deg, #cce1ff, #b0c5ff)",
                  height: "10.63vh",
                  overflow: "hidden",
                  border: "0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "3.3vh",
                    left: "2.4vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    {t("homeV1.watchNews")}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                    }}
                  >
                    {t("homeV1.watchNewsDesc")}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    height: "100%",
                    opacity: "0.54",
                    bottom: "0",
                  }}
                >
                  <img
                    style={{
                      position: "relative",

                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../看资讯赚星币.png"
                  />
                </div>
              </button>
              <button
                onClick={() => {
                  navigation("/step");
                }}
                style={{
                  border: "0",
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(248.34deg, #ffd689, #ffc03e)",
                  height: "10.63vh",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "1.4vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    {t("homeV1.walking")}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                    }}
                  >
                    {t("homeV1.walkingDesc")}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    height: "100%",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",

                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../健步赚星币.png"
                  />
                </div>
              </button>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "3.2vw",
                fontSize: "1.65vh",
              }}
            >
              <button
                onClick={() => {
                  navigation("/video");
                }}
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(248.16deg, #ffaea9, #ffdad8)",
                  height: "10.63vh",
                  overflow: "hidden",
                  border: "0",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "1.4vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    {t("homeV1.watchAds")}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                    }}
                  >
                    {t("homeV1.watchAdsDesc")}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    height: "100%",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../看广告赚星币.png"
                  />
                </div>
              </button>
              <button
                onClick={() => {
                  setCommingSoonPopup(true);
                }}
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(249deg, #c891ff, #e7ceff)",
                  height: "10.63vh",
                  overflow: "hidden",
                  border: "0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    height: "100%",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../星星宠物赚星币.png"
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    left: "1.4vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    {t("homeV1.starPet")}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                    }}
                  >
                    {t("homeV1.starPetDesc")}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <Menu select={"home"} />
        <div style={{ display: commingSoonPopup ? "flex" : "none" }}>
          <button
            onClick={() => {
              setCommingSoonPopup(false);
            }}
            style={{
              position: "absolute",
              top: "0",
              backgroundColor: "rgba(26, 32, 36, 0.32)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 105px)",
              left: "calc(50% - 167.5px)",
              borderRadius: "6px",
              backgroundColor: "#fff",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              width: "335px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              boxSizing: "border-box",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontSize: "18px",
              color: "#252c32",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "600",
                      }}
                    >
                      {t("homeV1.comingSoon")}
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "1.65vh",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#5b6871",
                    }}
                  >
                    {t("homeV1.comingSoonDesc")}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  setCommingSoonPopup(false);
                }}
                style={{
                  border: "0",
                  borderRadius: "100px",
                  backgroundColor: "#0e73f6",
                  width: "303px",
                  height: "39px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.65vh",
                  color: "#f6f8f9",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    {t("auth.understood")}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: loginPopup ? "flex" : "none" }}>
          <div
            style={{
              position: "absolute",
              top: "0",
              backgroundColor: "rgba(26, 32, 36, 0.32)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 105px)",
              left: "calc(50% - 167.5px)",
              borderRadius: "6px",
              backgroundColor: "#fff",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              width: "335px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              boxSizing: "border-box",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontSize: "18px",
              color: "#252c32",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.83vh",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1.37vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "600",
                      }}
                    >
                      {t("homeV1.loginFirst")}
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "1.65vh",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#5b6871",
                    }}
                  >
                    {t("homeV1.loginFirstDesc")}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.91vh",
                  fontSize: "1.65vh",
                  color: "#f6f8f9",
                }}
              >
                <button
                  onClick={() => {
                    navigation("/login1");
                  }}
                  style={{
                    border: "0",
                    borderRadius: "100px",
                    backgroundColor: "#0e73f6",
                    width: "303px",
                    height: "39px",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    padding: "4px 24px",
                    boxSizing: "border-box",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFF",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                        fontWeight: "600",
                      }}
                    >
                      {t("homeV1.loginNow")}
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => setLoginPopup(false)}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    fontWeight: "400",
                    color: "#1a2024",
                  }}
                >
                  {t("profile.later")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default HomePage;
