import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { languageMap, getDefaultLanguage, DEFAULT_LANG } from "./locale/index"
import { Provider } from "react-redux"
import store from "./store/store"

import Confirm from "./components/Confirm"

import "./index.css"

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: languageMap,
  lng: getDefaultLanguage(),
  fallbackLng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
})

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
    <Confirm />
  </Provider>
);

reportWebVitals()
