import { useEffect, useState } from "react"
import { t } from "i18next"
import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../../Wallet/components/PageHeader"
export default function ResetPasswordScreen() {
  return (
    <PageWrapper>
      <PageHeader title={"重置密码"} />
    </PageWrapper>
  )
}
