import request from "../../utlis/request";

export const getTaskProgress = async (dispatch) => {
  try {
    const URL = "/api/user/progress";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "homePage/GET_ACTIVITY",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getStepData = async (dispatch) => {
  try {
    const URL = "/api/step/current";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "homePage/GET_STEP_DATA",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getHomepageVideo = async ({ language, videoId }) => {
  const URL =
    "/api/video?" +
    (language ? `language=${language}&` : "") +
    (videoId ? `videoId=${videoId}&` : "");
  const { data, message } = await request.get(URL);
  return data;
};
