import axios from "axios"
import { getCookie } from "../cookie"
import configs from "../configs"
export const updateUserAvatar = async (img) => {
  return await axios.post("/api/user/updateAvatar", { img })
}

export const getUserProfile = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/user/profile`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const updateUserName = async (userName) => {
  return await axios.post("/api/user/updateUserName", { userName })
}

export const getTaskProgress = async () => {
  return await axios.get("/api/user/progress")
}

export const getUserMigrationStatus = async (userName) => {
  return await axios.get(`/api/user/migrated/${userName}`)
}

export const migrateUser = async (regType, userName, password, token) => {
  return await axios.post(
    "/api/user/migration",
    { regType, userName, password },
    {
      headers: {
        authorization: token,
      },
    }
  )
}

export const getWalletSummary = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/wallet/summary`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const getInviteSummary = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/invite/summary`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const getSPTransHistory = async (page, year, month) => {
  return await axios.get(
    `/api/wallet/transaction?currency=SP&type=4&page=${page}&size=20&date=${year} ${month}`
  )
}

export const getUserWalletPasswordSetted = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/payPassword/exist`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const setUserWalletPassword = async (password, passwordConfirm) => {
  return await axios.post(`/api/payPassword/`, { password, passwordConfirm })
}
export const checkUserWalletPassword = async (password) => {
  return await axios.post(`/api/payPassword/check`, { password })
}
export const changeUserWalletPassword = async (
  original,
  password,
  passwordConfirm
) => {
  return await axios.put(`/api/payPassword/`, {
    original,
    password,
    passwordConfirm,
  })
}

export const getResetWalletPasswordCode = async () => {
  return await axios.get(`/api/payPassword/sms`)
}

export const resetUserWalletPassword = async (
  verifyCode,
  password,
  passwordConfirm
) => {
  return await axios.post(`/api/payPassword/reset`, {
    verifyCode,
    password,
    passwordConfirm,
  })
}
