import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInviteSummary } from "./service";
import Menu from "../../components/Menu";

const Invite = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  const { inviteSummary } = useSelector((state) => state.invite);

  const dispatch = useDispatch();
  useEffect(() => {
    getInviteSummary(dispatch);
  }, []);

  const [lostTooltip, setLostTooltip] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [rulePopup, setRulePopup] = useState(false);
  return (
    <center>
      <div
        style={{
          position: "relative",
          background: "linear-gradient(180deg, #126de9, #5997ec 91.28%)",
          width: "100%",
          height: "200vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "2vh",
          color: "#fff",
          fontFamily: "Inter",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "3.46vh",
            left: "0px",
            width: "100%",
            height: "200vh",
            overflow: "hidden",
          }}
        >
          <img
            style={{
              position: "absolute",
              botton: "0vh",
              left: "0vh",
              width: "100%",
              height: "58vh",
              objectFit: "cover",
            }}
            alt=""
            src="../邀请好友背景图4.png"
          />
          <img
            style={{
              position: "absolute",
              top: "5vh",
              right: "0",
              objectFit: "cover",
            }}
            alt=""
            src="../邀请好友背景图2.png"
          />
          <img
            style={{
              position: "absolute",
              left: "0px",
              objectFit: "cover",
            }}
            alt=""
            src="../邀请好友背景图3.png"
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "rgba(255, 255, 255, 0)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "'Work Sans'",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "1.6vh 2vh",
              boxSizing: "border-box",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              {t("invite.title")}
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "8.3vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.8vh",
            color: "#1a2024",
            padding: "0 2vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "2.4vh",
              fontSize: "3vh",
              color: "#fff",
              fontFamily: "'Work Sans'",
            }}
          >
            <b
              style={{
                position: "relative",
                lineHeight: "4vh",
                display: "flex",
                alignItems: "center",
                height: "8vh",
                flexShrink: "0",
              }}
            >
              {t("invite.catchphrase")}
            </b>
            <img
              style={{
                position: "relative",
                height: "30.4vh",
                flexShrink: "0",
                objectFit: "cover",
              }}
              alt=""
              src="../邀请好友背景图1.png"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.6vh",
                fontSize: "1.8vh",
                color: "#0e73f6",
                fontFamily: "Inter",
              }}
            >
              <button
                onClick={() => {
                  setInvitePopup(true);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  border: "0",
                  background: "none",
                  color: "inherit",
                }}
              >
                <div
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#fff",
                    width: "50vh",
                    height: "4vh",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "600",
                      }}
                    >
                      {t("invite.inviteFriend")}
                    </div>
                  </div>
                </div>
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.8vh",
                  fontSize: "1.8vh",
                  color: "#fff",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "140%",
                    fontWeight: "500",
                  }}
                >
                  {t("invite.inviteCode")}:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.4vh",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "500",
                    }}
                  >
                    {inviteSummary.code}
                  </div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(inviteSummary.code);
                    }}
                    style={{ border: "0", backgroundColor: "transparent" }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "18px",
                        height: "18px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="../复制.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "1.6vh 1.2vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.2vh",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                {t("invite.myInvite")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1.3vh",
                  fontSize: "2.3vh",
                  color: "#0e73f6",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2.3vh",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "600",
                      }}
                    >
                      <span>{inviteSummary.total.member}</span>
                      <span style={{ fontSize: "14px", color: "#303940" }}>
                        {inviteSummary.total.member > 1
                          ? t("invite.people")
                          : t("invite.person")}
                      </span>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        fontSize: "1.4vh",
                        lineHeight: "130%",
                        fontWeight: "500",
                        color: "#6e7c87",
                      }}
                    >
                      {t("invite.teamMembers")}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      borderRight: "1px solid #d5dadd",
                      boxSizing: "border-box",
                      height: "2.7vh",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "600",
                      }}
                    >
                      <span>{inviteSummary.total.register_invite_income}</span>
                      <span style={{ fontSize: "14px", color: "#303940" }}>
                        {t("wallet.fscp")}
                      </span>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        fontSize: "1.4vh",
                        lineHeight: "130%",
                        fontWeight: "500",
                        color: "#6e7c87",
                      }}
                    >
                      {t("invite.totalIncome")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2.3vh",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "600",
                      }}
                    >
                      <span>{inviteSummary.total.total_invite_income}</span>
                      <span style={{ fontSize: "14px", color: "#303940" }}>
                        {t("wallet.fscp")}
                      </span>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        fontSize: "1.4vh",
                        lineHeight: "130%",
                        fontWeight: "500",
                        color: "#6e7c87",
                      }}
                    >
                      {t("invite.totalCommission")}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      borderRight: "1px solid #d5dadd",
                      boxSizing: "border-box",
                      height: "2.7vh",
                      flexShrink: "0",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "600",
                      }}
                    >
                      <span>{inviteSummary.total.invite_loss}</span>
                      <span style={{ fontSize: "14px", color: "#303940" }}>
                        {t("wallet.fscp")}
                      </span>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        fontSize: "1.4vh",
                        lineHeight: "130%",
                        fontWeight: "500",
                        color: "#6e7c87",
                      }}
                    >
                      {t("invite.lostIncome")}
                    </div>
                    <button
                      style={{ border: "0", backgroundColor: "transparent" }}
                    >
                      <img
                        onClick={() => {
                          setLostTooltip(!lostTooltip);
                        }}
                        style={{
                          position: "absolute",
                          top: "1.3vh",
                          right: "8vh",
                          width: "2vh",
                          height: "2vh",
                          overflow: "hidden",
                        }}
                        alt=""
                        src="../info-md-blue.svg"
                      />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1.3vh",
                    fontSize: "2.3vh",
                    color: "#0e73f6",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    border: "2px dashed #0e73f6",
                    boxSizing: "border-box",
                    height: "14vh",
                    fontSize: "1.65vh",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      top: "-0.5vh",
                      borderRadius: "100px",
                      backgroundColor: "#0e73f6",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4px 8px",
                      color: "#fff",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "600",
                      }}
                    >
                      {t("invite.todayData")}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      top: "-2vh",
                      left: "0px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "2.3vh",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        <span>{inviteSummary.today.member}</span>
                        <span style={{ fontSize: "14px", color: "#303940" }}>
                          {inviteSummary.today.member > 1
                            ? t("invite.people")
                            : t("invite.person")}
                        </span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "1.4vh",
                          lineHeight: "130%",
                          fontWeight: "500",
                          color: "#6e7c87",
                        }}
                      >
                        {t("invite.todayInvite")}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        borderRight: "1px solid #d5dadd",
                        boxSizing: "border-box",
                        height: "2.7vh",
                        flexShrink: "0",
                      }}
                    />
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          {inviteSummary.today.register_invite_income}
                        </span>
                        <span style={{ fontSize: "14px", color: "#303940" }}>
                          {t("wallet.fscp")}
                        </span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "1.4vh",
                          lineHeight: "130%",
                          fontWeight: "500",
                          color: "#6e7c87",
                        }}
                      >
                        {t("invite.todayIncome")}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      top: "-2vh",
                      left: "0px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "2.3vh",
                      color: "#0e73f6",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        <span>{inviteSummary.today.total_invite_income}</span>
                        <span style={{ fontSize: "14px", color: "#303940" }}>
                          {t("wallet.fscp")}
                        </span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "1.4vh",
                          lineHeight: "130%",
                          fontWeight: "500",
                          color: "#6e7c87",
                        }}
                      >
                        {t("invite.todayCommission")}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        borderRight: "1px solid #d5dadd",
                        boxSizing: "border-box",
                        height: "2.7vh",
                        flexShrink: "0",
                      }}
                    />
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          lineHeight: "130%",
                          fontWeight: "600",
                        }}
                      >
                        <span>{inviteSummary.today.invite_loss}</span>
                        <span style={{ fontSize: "14px", color: "#303940" }}>
                          {t("wallet.fscp")}
                        </span>
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          fontSize: "1.4vh",
                          lineHeight: "130%",
                          fontWeight: "500",
                          color: "#6e7c87",
                        }}
                      >
                        {t("invite.todayLost")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              navigation("/invite/ranking");
            }}
            style={{
              border: "0",
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "1.6vh 1.2vh",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "1.8vh",
              color: "#0e73f6",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.6vh",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                {t("invite.viewInviteRank")}
              </div>
              <img
                style={{
                  position: "relative",
                  width: "1.8vh",
                  height: "1.8vh",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../右尖角.svg"
              />
            </div>
          </button>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "1.6vh 1.2vh",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.6vh",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                {t("invite.threeSteps")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: ".8vh",
                  textAlign: "left",
                  fontSize: "2vh",
                  color: "#0e73f6",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      border: "0.5px solid #0e73f6",
                      boxSizing: "border-box",
                      width: "3.6vh",
                      height: "3.6vh",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: ".6vh",
                        left: ".6vh",
                        fontWeight: "500",
                      }}
                    >
                      01
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      borderTop: "1px solid #0e73f6",
                      boxSizing: "border-box",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      border: "0.5px solid #0e73f6",
                      boxSizing: "border-box",
                      width: "3.6vh",
                      height: "3.6vh",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: ".6vh",
                        left: ".55vh",
                        fontWeight: "500",
                      }}
                    >
                      02
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      borderTop: "1px solid #0e73f6",
                      boxSizing: "border-box",
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "100px",
                      backgroundColor: "#fff",
                      border: "0.5px solid #0e73f6",
                      boxSizing: "border-box",
                      width: "3.6vh",
                      height: "3.6vh",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: ".6vh",
                        left: ".5vh",
                        fontWeight: "500",
                      }}
                    >
                      03
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1.6vh",
                    textAlign: "center",
                    fontSize: "1.4vh",
                    color: "#252c32",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "500",
                      }}
                    >
                      {t("invite.step1")}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "500",
                      }}
                    >
                      {t("invite.step2")}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        lineHeight: "130%",
                        fontWeight: "500",
                      }}
                    >
                      {t("invite.step3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              padding: "1.6vh 1.2vh",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.6vh",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
                display: "inline-block",
                width: "100%",
              }}
            >
              {t("invite.howToEarn")}
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.6vh",
                textAlign: "left",
                color: "#0e73f6",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: ".8vh",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "#989898",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "none",
                  }}
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: ".6vh",
                  }}
                >
                  <div style={{ position: "relative", fontWeight: "600" }}>
                    {t("invite.part1")}
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                      color: "#252c32",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part1-desc-1")}
                    </span>
                    <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                      {t("invite.part1-desc-2")}
                    </span>
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part1-desc-3")}
                    </span>
                    <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                      {"100 "}
                      {t("wallet.fscp")}
                      {" ≈ 1 "}
                      {t("wallet.usdt")}
                    </span>
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part1-desc-4")}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: ".8vh",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "#989898",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "none",
                  }}
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: ".6vh",
                  }}
                >
                  <div style={{ position: "relative", fontWeight: "600" }}>
                    {t("invite.part2")}
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                      color: "#252c32",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part2-desc-1")}
                    </span>
                    <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                      {t("invite.dailyActivityTask")}
                    </span>
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part2-desc-2")}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: ".8vh",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "#989898",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "none",
                  }}
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: ".6vh",
                  }}
                >
                  <div style={{ position: "relative", fontWeight: "600" }}>
                    {t("invite.part2")}
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "1.4vh",
                      lineHeight: "130%",
                      color: "#252c32",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part3-desc-1")}
                    </span>
                    <span style={{ fontWeight: "600", color: "#0e73f6" }}>
                      {t("invite.dailyWalkingTask")}
                    </span>
                    <span style={{ fontWeight: "500" }}>
                      {t("invite.part3-desc-2")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "1.6vh 0px 0px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
                display: "inline-block",
                width: "100%",
              }}
            >
              {t("invite.howToInvite")}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                textAlign: "left",
                fontSize: "2.8vh",
                color: "#b0babf",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px 12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1.2vh",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "4vh",
                    flexShrink: "0",
                  }}
                >
                  01
                </b>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#48535b",
                  }}
                >
                  {t("invite.inviteStep1")}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px 12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1.2vh",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "4vh",
                    flexShrink: "0",
                  }}
                >
                  02
                </b>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#48535b",
                  }}
                >
                  {t("invite.inviteStep2")}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px 12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1.2vh",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "4vh",
                    flexShrink: "0",
                  }}
                >
                  03
                </b>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "1.65vh",
                    color: "#48535b",
                  }}
                >
                  {t("invite.inviteStep3")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setRulePopup(true);
          }}
          style={{
            border: "0",
            color: "inherit",
            position: "fixed",
            top: "16vh",
            right: "0",
            borderRadius: "8px 0px 0px 8px",
            backgroundColor: "#4094f7",
            display: "flex",
            flexDirection: "column",
            padding: "0.5vh 0.5vh",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "2vh",
          }}
        >
          <div
            style={{
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "2vh",
              fontWeight: "600",
            }}
          >
            {t("invite.rules")}
          </div>
        </button>
        <Menu select={"task"} />
        <div style={{ display: rulePopup ? "flex" : "none" }}>
          <button
            onClick={() => {
              setRulePopup(false);
            }}
            style={{
              border: "0",
              position: "fixed",
              top: "0px",
              left: "0px",
              backgroundColor: "rgba(26, 32, 36, 0.32)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              position: "fixed",
              top: "20%",
              left: "15%",
              borderRadius: "6px",
              background: "#fff",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              width: "70%",
              height: "50%",
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              padding: "1.6vh",
              boxSizing: "border-box",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              color: "#1A2024",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.6vh",
                width: "90%",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1.2vh",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      textAlign: "center",
                      color: "#252c32",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        position: "relative",
                        lineHeight: "140%",
                        fontWeight: "600",
                      }}
                    >
                      {t("invite.rulesTitle")}
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "inherit",
                      letterSpacing: "-0.01em",
                      lineHeight: "150%",
                      fontFamily: "inherit",
                      fontSize: "1.65vh",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    <ol style={{ margin: "0", paddingLeft: "2vh" }}>
                      <li>{t("invite.rules-0-1")}</li>
                      <li>{t("invite.rules-0-2")}</li>
                      <li>{t("invite.rules-0-4")}</li>
                    </ol>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "5vh",
                      fontSize: "1.4vh",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: ".8vh",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "600",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          width: "100%",
                          padding: "0px 20px",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-1")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-2")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-3-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-3-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-4-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-4-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-5-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-5-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-6-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-6-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-7-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-7-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-8-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-8-1")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "140%",
                          fontWeight: "400",
                          alignSelf: "stretch",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "left",
                          width: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <span style={{ lineBreak: "anywhere", width: "25%" }}>
                          {t("invite.rules-1-9-0")}
                        </span>
                        <span style={{ lineBreak: "anywhere", width: "75%" }}>
                          {t("invite.rules-1-9-1")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "1.4vh",
                  lineHeight: "1.65vh",
                }}
              >
                {t("invite.rules-2-1")}
              </div>
              <button
                onClick={() => setRulePopup(false)}
                style={{
                  border: "0",
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  fontSize: "1.65vh",
                  color: "#f6f8f9",
                }}
              >
                <div
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#0e73f6",
                    width: "303px",
                    height: "4vh",
                    flexShrink: "0",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    padding: ".4vh 2.4vh",
                    boxSizing: "border-box",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "140%",
                        fontWeight: "600",
                      }}
                    >
                      {t("invite.close")}
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: invitePopup ? "flex" : "none" }}>
          <button
            onClick={() => {
              setInvitePopup(false);
            }}
            style={{
              border: "0",
              position: "fixed",
              top: "0px",
              left: "0px",
              backgroundColor: "rgba(26, 32, 36, 0.32)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              position: "fixed",
              top: "calc(50% - 300px)",
              left: "calc(50% - 200px)",
              borderRadius: "12px",
              backgroundColor: "#fff",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              height: "567px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 16px",
              boxSizing: "border-box",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#0e73f6",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                height: "503px",
                flexShrink: "0",
                overflow: "hidden",
                backgroundImage: "url('frame-48095749@3x.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "335px",
                  height: "503px",
                  objectFit: "cover",
                }}
                alt=""
                src="../邀请好友海报.png"
              />
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../下载.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  <a href="/邀请好友海报.png" download>
                    <button
                      onClick={() => {
                        setInvitePopup(false);
                        alert(t("invite.downloadComplete"));
                      }}
                      style={{
                        border: "0",
                        backgroundColor: "transparent",
                        color: "inherit",
                      }}
                    >
                      {t("invite.download")}
                    </button>
                  </a>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  borderRight: "1px solid #e5e9eb",
                  boxSizing: "border-box",
                  width: "1px",
                  height: "25px",
                  flexShrink: "0",
                }}
              />
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../分享链接.svg"
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(inviteSummary.code);
                    setInvitePopup(false);
                    alert(t("invite.shareComplete"));
                  }}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  {t("invite.share")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "77vh",
            right: "3.7vh",
            width: "50%",
            display: lostTooltip ? "flex" : "none",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "1.4vh",
            color: "#f6f8f9",
          }}
        >
          <img
            style={{
              position: "relative",
              maxWidth: "100%",
              overflow: "hidden",
              height: ".5vh",
              flexShrink: "0",
              right: "10vw",
            }}
            alt=""
            src="../上尖角.svg"
          />
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "4px",
              backgroundColor: "#303940",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              padding: "1.2vh",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{ flex: "1", position: "relative", lineHeight: "130%" }}
            >
              {t("invite.lostIncomeDesc")}
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Invite;
