const StarNews = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "900px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "10px",
          color: "#9aa6ac",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "12px",
            borderRadius: "8px",
            width: "351px",
            height: "191px",
            overflow: "hidden",
            backgroundImage: "url('星头条背景图.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "287px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
            fontSize: "14px",
            color: "#3c464e",
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "#eef0f2",
              width: "375px",
              height: "8px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 12px 0px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#303940",
                  fontFamily: "'Work Sans'",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "120%",
                  }}
                >
                  Star Selected
                </b>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../放大镜.svg"
                />
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "8px",
                    height: "8px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../ellipse-5.svg"
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "600",
                    }}
                  >
                    How to choose the right customer for your photo business?
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "8px",
                    height: "8px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../ellipse-5.svg"
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "600",
                    }}
                  >
                    How to choose the right customer for your photo business?
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eef0f2",
                  boxSizing: "border-box",
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "8px",
                    height: "8px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../ellipse-5.svg"
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "600",
                    }}
                  >
                    How to choose the right customer for your photo business?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              backgroundColor: "#eef0f2",
              width: "375px",
              height: "8px",
              flexShrink: "0",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              backgroundColor: "#fff",
              width: "375px",
              height: "772px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 20px",
              boxSizing: "border-box",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "center",
              fontSize: "12px",
              color: "#48535b",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      borderRadius: "100px",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像.png"
                  />
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "500",
                    }}
                  >
                    John Papa
                  </div>
                </div>
                <b
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "16px",
                    color: "#3c464e",
                    textAlign: "left",
                  }}
                >
                  How to choose the right customer for your photo business?
                </b>
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    color: "#84919a",
                  }}
                >{`Apr 10, 2022 • 7 Min Read `}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "82px",
                    height: "52px",
                    flexShrink: "0",
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                  alt=""
                  src="../文章缩略插图.png"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      borderRadius: "100px",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../示例头像.png"
                  />
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "500",
                    }}
                  >
                    John Papa
                  </div>
                </div>
                <b
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "16px",
                    color: "#3c464e",
                    textAlign: "left",
                  }}
                >
                  How to choose the right customer for your photo business?
                </b>
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    color: "#84919a",
                  }}
                >{`Apr 10, 2022 • 7 Min Read `}</div>
              </div>
              <img
                style={{
                  position: "relative",
                  width: "82px",
                  height: "52px",
                  flexShrink: "0",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                alt=""
                src="../文章缩略插图.png"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderTop: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "10px 12px",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../首页.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>首页</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../任务.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>任务</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../星卡.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>星卡</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
              color: "#0e73f6",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../头条-蓝.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>头条</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../我的.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "120%",
                fontWeight: "600",
              }}
            >
              我的
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            height: "58px",
            display: "flex",
            flexDirection: "row",
            padding: "12px 20px",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "12px",
            color: "#fff",
          }}
        >
          <div
            style={{
              position: "relative",
              fontSize: "18px",
              lineHeight: "130%",
              left: "140px",
              fontWeight: "600",
              color: "#48535b",
            }}
          >
            星头条
          </div>
        </div>
      </div>
    </center>
  );
};

export default StarNews;
