import request from "../../utlis/request";

export const getSummary = async (dispatch) => {
  try {
    const URL = "/api/user/todaySummary";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "task/GET_SUMMARY",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getDailySignIn = async (dispatch) => {
  try {
    const URL = "/api/attend";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "task/GET_DAILY_SIGN_IN",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const todaySignIn = async (dispatch) => {
  try {
    const URL = "/api/attend";

    const { data, message } = await request.post(URL);

    dispatch({
      type: "task/POST_DAILY_SIGN_IN",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getBeginnerTasks = async (dispatch) => {
  try {
    const URL = "/api/starter/task";

    const { data, message } = await request.get(URL);

    dispatch({
      type: "task/GET_BEGINNER_TASKS",
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};
