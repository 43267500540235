import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
const ForgetPassword1 = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "699px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "'Work Sans'",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "0px",
          left: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px 32px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../返回.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              Forgot Password
            </b>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "28px",
            color: "#000",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Reset Password
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "500",
              }}
            >
              Please reset your password
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "14px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  placeholder="futurestar123456"
                ></input>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../可见.svg"
                />
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  placeholder="***********"
                ></input>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../不可见.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          top: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "16px",
          fontSize: "16px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            borderRadius: "100px",
            backgroundColor: "#0e73f6",
            width: "335px",
            display: "flex",
            flexDirection: "row",
            padding: "11px 32px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
              display: "none",
            }}
            alt=""
            src="../group-add-fill0-wght400-grad0-opsz48-1-23.svg"
          />
          <div
            style={{
              position: "relative",
              lineHeight: "130%",
              fontWeight: "600",
            }}
          >
            Confirm
          </div>
        </div>
        <div
          style={{
            position: "relative",
            lineHeight: "140%",
            fontWeight: "500",
            color: "#0e73f6",
            textAlign: "right",
          }}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword1;
