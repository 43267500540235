import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../components/PageHeader"
import RegularTextInput from "../../../components/RegularTextInput"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { t } from "i18next"
const redWarning = require("../asset/redWarning.png")
const blueWarning = require("../asset/blueWarning.png")
const VALID_CURRENCY = ["FSC", "USDT", "BNB"]
const VALID_NETWORK = ["BIANCE", "ETHEREUM", "TRON"]
export default function CurrencyWithdrawScreen() {
  const navigate = useNavigate()
  const [currency, setCurrency] = useState("FSC")
  const [network, setNetwork] = useState("BIANCE")
  const [receiverAddress, setReceiverAddress] = useState("")
  const [withdrawAmount, setWithdrawAmount] = useState("")
  const [receiveAmount, setReceiveAmount] = useState(0)
  useEffect(() => {
    const part1 = location.search.split("&")[0]
    const part2 = location.search.split("&")[1]
    if (
      !VALID_CURRENCY.includes(part1.split("currency=")[1]) ||
      !VALID_NETWORK.includes(part2.split("network=")[1])
    ) {
      navigate(-1)
    } else {
      setCurrency(part1.split("currency=")[1])
      setNetwork(part2.split("network=")[1])
    }
  }, [])
  return (
    <PageWrapper>
      <PageHeader title={t("wallet.withdraw")} />
      <div style={styles.bodyWrapper}>
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            borderRadius: "12px",
          }}
        >
          <span
            style={{ fontWeight: "600", fontSize: "16px", marginBottom: "7px" }}
          >
            {t("wallet.paymentAddress")}
          </span>
          <RegularTextInput
            value={receiverAddress}
            setValue={setReceiverAddress}
            placeHolder={t("wallet.inputAddress")}
          />
          <span
            style={{
              fontWeight: "600",
              fontSize: "16px",
              marginTop: "16px",
              marginBottom: "7px",
            }}
          >
            {t("wallet.mainNetwork")}
          </span>
          <RegularTextInput value={networkMapping[network]} disabled={true} />
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#FED6CD",
              padding: "10px",
              alignItems: "center",
              margin: "16px 0px",
              borderRadius: "8px",
            }}
          >
            <img src={redWarning} style={{ width: "24px", height: "24px" }} />
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#F2271C",
                marginLeft: "10px",
              }}
            >
              {t(`wallet.withdrawWarning${network}`)}
            </span>
          </div>
          <span
            style={{
              fontWeight: "600",
              fontSize: "16px",
              marginTop: "16px",
              marginBottom: "7px",
            }}
          >
            {t("wallet.withdrawalAmount")}
          </span>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              borderRadius: "6px",
              padding: "0px 9px",
            }}
          >
            <RegularTextInput
              value={withdrawAmount}
              setValue={setWithdrawAmount}
              wrapperStyle={{
                backgroundColor: "transparent",
                flex: 1,
                border: "none",
              }}
              inputStyle={{ backgroundColor: "transparent", color: "black" }}
            />
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#48535B",
              }}
            >
              {currency}
            </span>
            <button style={{ border: "none", backgroundColor: "transparent" }}>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#0E73F6",
                }}
              >
                {t("wallet.all")}
              </span>
            </button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "400",
              fontSize: "12px",
              color: "#252C32",
              marginTop: "4px",
            }}
          >
            <span>
              {t("wallet.remainingBalance")} 100 {currency}
            </span>
            <span>
              {t("wallet.minimumInput")} 500 {currency}
            </span>
          </div>
          <span
            style={{
              fontSize: "16px",
              color: "#303940",
              marginTop: "16px",
              fontWeight: "500",
              marginBottom: "4px",
            }}
          >
            {t("wallet.receivingAmount")}
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              borderRadius: "6px",
              padding: "0px 9px",
            }}
          >
            <RegularTextInput
              value={receiveAmount}
              setValue={setReceiveAmount}
              wrapperStyle={{
                backgroundColor: "transparent",
                flex: 1,
                border: "none",
              }}
              inputStyle={{ backgroundColor: "transparent", color: "black" }}
            />
            <span
              style={{
                fontSize: "14px",
                color: "#48535B",
                fontWeight: "500",
              }}
            >
              {currency}
            </span>
          </div>
          <span
            style={{
              fontSize: "12px",
              color: "#1A2024",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            {t("wallet.withdrawalFee")}
            <img src={blueWarning} style={{ width: "16px", height: "16px" }} />
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "#1A2024",
              fontWeight: "500",
              marginTop: "9px",
            }}
          >
            {t("wallet.withdrawalArrive")}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 12px",
          position: "absolute",
          bottom: "80px",
          gap: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "#0E73F6",
            borderRadius: "100px",
            padding: "11px 0px",
            border: "none",
          }}
        >
          <span style={{ color: "white", fontWeight: "600", fontSize: "16px" }}>
            {t("wallet.withdraw2")}
          </span>
        </button>
      </div>
    </PageWrapper>
  )
}
const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
  },
}

const networkMapping = {
  BIANCE: "BNB Smart Chain (BEP20)",
  ETHEREUM: "Ethereum (ERC20)",
  TRON: "TRON (TRC20)",
}
