const BalanceBNBRefresh = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "839px",
          overflow: "hidden",
          textAlign: "left",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "-1px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "375px",
                display: "flex",
                flexDirection: "row",
                padding: "16px 20px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/左尖角.svg"
              />
              <b
                style={{
                  flex: "1",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  left: "-2px",
                }}
              >
                BNB
              </b>
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #eef0f2",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#fff",
              fontFamily: "Poppins",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                background: "linear-gradient(260.39deg, #f3ba2f, #ffd774)",
                width: "376px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: "28px 20px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontFamily: "Inter",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ position: "relative", fontWeight: "600" }}>
                      Your BNB Balance
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "6px",
                        textAlign: "center",
                        fontSize: "24px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          borderRadius: "137.04px",
                          backgroundColor: "#fff",
                          border: "0.7px solid #f3ba2f",
                          boxSizing: "border-box",
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            height: "70.37%",
                            width: "70.37%",
                            top: "14.82%",
                            right: "14.81%",
                            bottom: "14.81%",
                            left: "14.82%",
                            maxWidth: "100%",
                            overflow: "hidden",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                          alt=""
                          src="/BNB.png"
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          letterSpacing: "0.02em",
                        }}
                      >
                        <b>{`2,500 `}</b>
                        <span style={{ fontSize: "14px" }}>≈ 9 USDT</span>
                      </div>
                      <img
                        style={{
                          position: "relative",
                          width: "18px",
                          height: "18px",
                          flexShrink: "0",
                          top: "3px",
                          overflow: "hidden",
                        }}
                        alt=""
                        src="/刷新-白.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              padding: "11px 16px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "14px",
              color: "#1a2024",
              fontFamily: "Inter",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/提现.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                提现
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                borderRight: "1px solid #e5e9eb",
                boxSizing: "border-box",
                width: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/充值.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                充值
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                borderRight: "1px solid #e5e9eb",
                boxSizing: "border-box",
                width: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/闪兑.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                闪兑
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "241px",
            left: "0px",
            backgroundColor: "#fff",
            width: "375px",
            display: "flex",
            flexDirection: "column",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "8px",
            fontSize: "14px",
            color: "#252c32",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "25px",
              color: "#1a2024",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "4px",
                color: "#252c32",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                全部
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  borderTop: "2px solid #252c32",
                  boxSizing: "border-box",
                  height: "2px",
                  flexShrink: "0",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            >
              收入
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                fontWeight: "500",
              }}
            >
              支出
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "335px",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/充值-蓝.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        充值BNB
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +13,500 SP
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "335px",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/充值-蓝.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        充值BNB
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +13,500 SP
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "335px",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      borderRadius: "59.38px",
                      width: "37.5px",
                      height: "37.5px",
                      flexShrink: "0",
                    }}
                    alt=""
                    src="/提现-红.svg"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        提现BNB (失败)
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#f2271c",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    -13,500 SP
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "335px",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/充值-蓝.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        充值BNB
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +13,500 SP
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderBottom: "1px solid #eef0f2",
              display: "flex",
              flexDirection: "column",
              padding: "16px 0px",
              alignItems: "flex-end",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                height: "42px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    borderRadius: "59.38px",
                    backgroundColor: "#ebf7ff",
                    display: "flex",
                    flexDirection: "row",
                    padding: "4.75px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "28px",
                      height: "28px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/转换-蓝.svg"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "2px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "4px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      从 SP转换
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      letterSpacing: "-0.01em",
                      lineHeight: "20px",
                      color: "#828282",
                      textAlign: "right",
                    }}
                  >
                    2021-01-01 12:33
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "4px",
                  color: "#0e73f6",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  +13,500 SP
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "27px",
            left: "12px",
            borderRadius: "6px",
            backgroundColor: "#303940",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
            border: "1px solid #3c464e",
            boxSizing: "border-box",
            width: "351px",
            display: "flex",
            flexDirection: "row",
            padding: "12px 16px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "16px",
            color: "#f6f8f9",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="/对号-绿.svg"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0px 8px 0px 0px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "24px",
                fontWeight: "600",
              }}
            >
              刷新成功，您的钱包余额将在三分钟内刷新
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default BalanceBNBRefresh;
