import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getRankingToday, getRankingTotal } from "./service";

const InviteRanking = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const { todayRanking, totalRanking } = useSelector((state) => state.invite);
  const [dailyRank, setDailyRank] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    getRankingToday(dispatch);
    getRankingTotal(dispatch);
  }, []);

  return (
    <center>
      <div
        style={{
          position: "relative",
          background:
            "linear-gradient(180deg, #ff784e, #ff7d4d 11.61%, #ffa048)",
          width: "100%",
          height: "100vh",
          overflow: "auto",
          textAlign: "center",
          fontSize: "2.3vh",
          color: "#fff",
          fontFamily: "Inter",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "7.8vh",
            padding: "0 3.2vw",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "3.2vh",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: dailyRank ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2.4vh",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "6px",
                }}
              >
                <button
                  onClick={() => setDailyRank(true)}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                  }}
                >
                  <u
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      gap: "10px",
                      textUnderlineOffset: "10px",
                      fontWeight: "800",
                    }}
                  >
                    {t("invite.dailyRank")}
                  </u>
                </button>
              </div>
              <div
                style={{
                  flex: "1",

                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "6px",
                  fontWeight: "500",
                }}
              >
                <button
                  onClick={() => setDailyRank(false)}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      gap: "10px",
                    }}
                  >
                    {t("invite.totalRank")}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: dailyRank ? "none" : "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2.4vh",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "6px",
                  fontWeight: "500",
                }}
              >
                <button
                  onClick={() => setDailyRank(true)}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      gap: "10px",
                    }}
                  >
                    {t("invite.dailyRank")}
                  </div>
                </button>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "6px",
                }}
              >
                <button
                  onClick={() => setDailyRank(false)}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                  }}
                >
                  <u
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      gap: "10px",
                      textUnderlineOffset: "10px",
                      fontWeight: "800",
                    }}
                  >
                    {t("invite.totalRank")}
                  </u>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "6vh",
              fontSize: "1.8vh",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "20vh",
                flexShrink: "0",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1.2vh",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    borderRadius: "100px",
                    width: "10vh",
                    height: "10vh",
                    flexShrink: "0",
                    backgroundImage: dailyRank
                      ? todayRanking[0].user.avatarUrl
                        ? `url(${todayRanking[0].user.avatarUrl})`
                        : "../default-avatar.png"
                      : totalRanking[0].user.avatarUrl
                      ? `url(${totalRanking[0].user.avatarUrl})`
                      : "../default-avatar.png",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-.6vh",
                      left: "calc(50% - 1.4vh)",
                      borderRadius: "100px",
                      backgroundColor: "#ffd231",
                      width: "2.8vh",
                      height: "2.8vh",
                      overflow: "hidden",
                    }}
                  >
                    <b
                      style={{
                        position: "absolute",
                        left: "calc(50% - 1.4vh)",
                        letterSpacing: "-0.01em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "2.8vh",
                        height: "2.8vh",
                      }}
                    >
                      1
                    </b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: ".4vh",
                    fontSize: "1.4vh",
                  }}
                >
                  <b
                    style={{
                      position: "relative",
                      fontSize: "2vh",
                      letterSpacing: "-0.01em",
                    }}
                  >
                    {dailyRank
                      ? todayRanking[0].user.userName
                      : totalRanking[0].user.userName}
                  </b>
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    {dailyRank
                      ? t(`invite.member-${todayRanking[0].starCardLevel}`)
                      : t(`invite.member-${totalRanking[0].starCardLevel}`)}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontFamily: "Inter",
                      fontWeight: "400",
                    }}
                  >
                    {t("invite.shared")}{" "}
                    {dailyRank
                      ? Math.trunc(todayRanking[0].inviteIncome * 100) / 100
                      : Math.trunc(totalRanking[0].inviteIncome * 100) /
                        100}{" "}
                    {t("wallet.fscp")}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontFamily: "Inter",
                      fontWeight: "400",
                    }}
                  >
                    {t("invite.invited")}{" "}
                    {dailyRank
                      ? todayRanking[0].inviteCount
                      : totalRanking[0].inviteCount}{" "}
                    {(dailyRank
                      ? todayRanking[0].inviteCount
                      : totalRanking[0].inviteCount) > 1
                      ? t("invite.people")
                      : t("invite.person")}
                  </div>
                </div>
              </div>
              {dailyRank
                ? todayRanking.slice(1, 3).map((user, i) => {
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: "5.2vh",
                          left: i === 0 ? "10%" : "",
                          right: i === 1 ? "10%" : "",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "1.2vh",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            borderRadius: "100px",
                            width: "7.4vh",
                            height: "7.4vh",
                            flexShrink: "0",
                            backgroundImage: user.user.avatarUrl
                              ? `url(${user.user.avatarUrl})`
                              : "url(../default-avatar.png)",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "-.6vh",
                              left: "calc(50% - 1.15vh)",
                              borderRadius: "100px",
                              backgroundColor: i === 0 ? "#9aa6ac" : "#D4A80B",
                              width: "2.4vh",
                              height: "2.4vh",
                              overflow: "hidden",
                            }}
                          >
                            <b
                              style={{
                                position: "absolute",
                                top: "0px",
                                left: "calc(50% - 1.2vh)",
                                letterSpacing: "-0.01em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "2.4vh",
                                height: "2.4vh",
                              }}
                            >
                              {i + 2}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: ".4vh",
                            fontSize: "1.4vh",
                          }}
                        >
                          <b
                            style={{
                              position: "relative",
                              fontSize: "2vh",
                              letterSpacing: "-0.01em",
                            }}
                          >
                            {user.user.userName}
                          </b>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontWeight: "600",
                            }}
                          >
                            {t(`invite.member-${user.starCardLevel}`)}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.shared")}{" "}
                            {Math.trunc(user.inviteIncome * 100) / 100}{" "}
                            {t("wallet.fscp")}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.invited")} {user.inviteCount}{" "}
                            {user.inviteCount > 1
                              ? t("invite.people")
                              : t("invite.person")}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : totalRanking.slice(1, 3).map((user, i) => {
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: "5.2vh",
                          left: i === 0 ? "10%" : "",
                          right: i === 1 ? "10%" : "",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "1.2vh",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            borderRadius: "100px",
                            width: "7.4vh",
                            height: "7.4vh",
                            flexShrink: "0",
                            backgroundImage: user.user.avatarUrl
                              ? `url(${user.user.avatarUrl})`
                              : "url(../default-avatar.png)",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "-.6vh",
                              left: "calc(50% - 1.15vh)",
                              borderRadius: "100px",
                              backgroundColor: i === 0 ? "#9aa6ac" : "#D4A80B",
                              width: "2.4vh",
                              height: "2.4vh",
                              overflow: "hidden",
                            }}
                          >
                            <b
                              style={{
                                position: "absolute",
                                top: "0px",
                                left: "calc(50% - 1.2vh)",
                                letterSpacing: "-0.01em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "2.4vh",
                                height: "2.4vh",
                              }}
                            >
                              {i + 2}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: ".4vh",
                            fontSize: "1.4vh",
                          }}
                        >
                          <b
                            style={{
                              position: "relative",
                              fontSize: "2vh",
                              letterSpacing: "-0.01em",
                            }}
                          >
                            {user.user.userName}
                          </b>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontWeight: "600",
                            }}
                          >
                            {t(`invite.member-${user.starCardLevel}`)}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.shared")}{" "}
                            {Math.trunc(user.inviteIncome * 100) / 100}{" "}
                            {t("wallet.fscp")}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.invited")} {user.inviteCount}{" "}
                            {user.inviteCount > 1
                              ? t("invite.people")
                              : t("invite.person")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "12px",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                textAlign: "left",
                fontSize: "18px",
                color: "#48535b",
                padding: "0 2vh",
                overflow: "auto",
              }}
            >
              {dailyRank
                ? todayRanking.slice(3).map((user, i) => {
                    return (
                      <div
                        style={{
                          alignSelf: "stretch",
                          borderBottom: "1px solid #e5e9eb",
                          display: "flex",
                          flexDirection: "row",
                          padding: "1.6vh",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: ".8vh",
                          }}
                        >
                          <b
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              display: "flex",
                              alignItems: "center",
                              width: "21px",
                              flexShrink: "0",
                            }}
                          >
                            {i + 4}
                          </b>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: ".8vh",
                              textAlign: "center",
                              fontSize: "1.65vh",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "10000px",
                                width: "3.8vh",
                                height: "3.8vh",
                                flexShrink: "0",
                                objectFit: "cover",
                              }}
                              alt=""
                              src={
                                user.user.avatarUrl
                                  ? user.user.avatarUrl
                                  : "../default-avatar.png"
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: ".4vh",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  letterSpacing: "-0.01em",
                                  fontWeight: "600",
                                }}
                              >
                                {user.user.userName}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: "1.4vh",
                                  letterSpacing: "-0.01em",
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  color: "#5b6871",
                                }}
                              >
                                {t(`invite.member-${user.starCardLevel}`)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            gap: ".4vh",
                            textAlign: "center",
                            fontSize: "1.65vh",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.shared")}{" "}
                            {Math.trunc(user.inviteIncome * 100) / 100}
                            {t("wallet.fscp")}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              fontSize: "1.4vh",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.invited")} {user.inviteCount}{" "}
                            {t("invite.people")}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : totalRanking.slice(3).map((user, i) => {
                    return (
                      <div
                        style={{
                          alignSelf: "stretch",
                          borderBottom: "1px solid #e5e9eb",
                          display: "flex",
                          flexDirection: "row",
                          padding: "1.6vh",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: ".8vh",
                          }}
                        >
                          <b
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              display: "flex",
                              alignItems: "center",
                              width: "21px",
                              flexShrink: "0",
                            }}
                          >
                            {i + 4}
                          </b>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: ".8vh",
                              textAlign: "center",
                              fontSize: "1.65vh",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "10000px",
                                width: "3.8vh",
                                height: "3.8vh",
                                flexShrink: "0",
                                objectFit: "cover",
                              }}
                              alt=""
                              src={
                                user.user.avatarUrl
                                  ? user.user.avatarUrl
                                  : "../default-avatar.png"
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: ".4vh",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  letterSpacing: "-0.01em",
                                  fontWeight: "600",
                                }}
                              >
                                {user.user.userName}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: "1.4vh",
                                  letterSpacing: "-0.01em",
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  color: "#5b6871",
                                }}
                              >
                                {t(`invite.member-${user.starCardLevel}`)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            gap: ".4vh",
                            textAlign: "center",
                            fontSize: "1.65vh",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.shared")}{" "}
                            {Math.trunc(user.inviteIncome * 100) / 100}
                            {t("wallet.fscp")}
                          </div>
                          <div
                            style={{
                              position: "relative",
                              fontSize: "1.4vh",
                              letterSpacing: "-0.01em",
                              fontFamily: "Inter",
                              fontWeight: "400",
                            }}
                          >
                            {t("invite.invited")} {user.inviteCount}{" "}
                            {t("invite.people")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "1.6vh 2vh",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "2.3vh",
          }}
        >
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
            }}
          >
            {t("invite.invitationRanking")}
          </b>
        </div>
      </div>
    </center>
  );
};

export default InviteRanking;
