const ActivityCardRule = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "1041px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#000",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            color: "#303940",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../左箭头.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              规则说明
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "76px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              健步星卡
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "6px",
              }}
            >
              <b style={{ position: "relative", letterSpacing: "-0.01em" }}>
                活跃度星卡
              </b>
              <div
                style={{
                  position: "relative",
                  borderTop: "2px solid #000",
                  boxSizing: "border-box",
                  width: "72px",
                  height: "2px",
                  flexShrink: "0",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "82.93%",
            top: "calc(50% - 385.5px)",
            right: "8.53%",
            left: "8.53%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            textAlign: "left",
            fontSize: "12px",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                flexShrink: "0",
              }}
            >
              星级
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>新星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>一星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>二星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>三星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>四星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>五星</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>六星</div>
          </div>
          <div
            style={{
              flex: "1",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              padding: "8px 0px 0px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "17px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              兑换所需FSC
            </div>
            <div
              style={{
                position: "relative",
                width: "54px",
                height: "160px",
                flexShrink: "0",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "15px",
                  lineHeight: "16px",
                }}
              >
                免费
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "24px",
                  left: "10.5px",
                  lineHeight: "16px",
                }}
              >
                1000
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "48px",
                  left: "7.5px",
                  lineHeight: "16px",
                }}
              >
                2000
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "72px",
                  left: "8px",
                  lineHeight: "16px",
                }}
              >
                10000
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "96px",
                  left: "5px",
                  lineHeight: "16px",
                }}
              >
                20000
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "120px",
                  left: "3px",
                  lineHeight: "16px",
                }}
              >
                100000
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "144px",
                  left: "0px",
                  lineHeight: "16px",
                }}
              >
                200000
              </div>
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                flexShrink: "0",
              }}
            >
              总产量
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>360</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>1290</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>2700</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              13200
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              27000
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              135000
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              276000
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                flexShrink: "0",
              }}
            >
              有效期
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>30天</div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "82.93%",
            top: "calc(50% - 167.5px)",
            right: "8.53%",
            left: "8.53%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "12px",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                flexShrink: "0",
              }}
            >
              星级
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              新星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              一星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              二星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              三星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "36px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              四星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "36px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              五星
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "36px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              六星
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                width: "67px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              每日SP产量{" "}
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>12</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>43</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>90</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>440</div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "center",
                display: "inline-block",
                width: "42px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              900
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "center",
                display: "inline-block",
                width: "49px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              4500
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "center",
                display: "inline-block",
                width: "50px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              9200
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                SP 兑换FSC
              </p>
              <p style={{ margin: "0" }}>手续费</p>
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              50%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              9%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              9%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                textAlign: "left",
              }}
            >
              8%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "29px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              7%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "30px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              6%
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                display: "inline-block",
                width: "33px",
                height: "32px",
                flexShrink: "0",
              }}
            >
              6%
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
                flexShrink: "0",
              }}
            >
              晋级考核
            </div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div
              style={{ position: "relative", lineHeight: "16px" }}
            >{` `}</div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              团队有一名二星
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                团队有二名三星，
              </p>
              <p style={{ margin: "0" }}>分布在两条关系线</p>
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                团队有二名四星，
              </p>
              <p style={{ margin: "0" }}>分布在两条关系线</p>
            </div>
            <div style={{ position: "relative", lineHeight: "16px" }}>
              <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                团队有三名五星，
              </p>
              <p style={{ margin: "0" }}>分布在三条关系线</p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "82.93%",
            top: "60.46%",
            left: "8.53%",
            fontSize: "inherit",
            lineHeight: "16px",
            fontFamily: "inter",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "400px",
          }}
        >
          <ol style={{ margin: "0", paddingLeft: "16px" }}>
            <li style={{ marginBottom: "0px" }}>
              星卡分为健步星卡和活跃度星卡，领取星卡后完成相应任务后即可获得奖励。
            </li>
            <li style={{ marginBottom: "0px" }}>
              每等级星卡可无限领取，但每次只能购买并持有一张星卡。
            </li>
            <li style={{ marginBottom: "0px" }}>
              星卡做完30天任务后，可选择继续购买同级星卡或晋升为更高级星卡，但晋升后级别不能回降，只能保留现有的星级或继续晋升。所有星卡30天为一轮，都可无限轮充值领取。
            </li>
            <li style={{ marginBottom: "0px" }}>
              星卡分为不同等级，每个星卡领取后有效期皆为30天，从领取当天开始算起，30天结束后星卡失效，完成任务也无法获得对应奖励。
            </li>
            <li style={{ marginBottom: "0px" }}>
              一星至六星任一星级，当做完30天任务后未及时领取新卡时，即停止团队收益。但当任何时候再充值领取星卡时，当天起即恢复团队收益。
            </li>
            <li>
              领取新星、一星、二星星卡时消耗相应星币即可，领取三星及以上星卡时需要满足晋级考核
            </li>
          </ol>
        </div>
      </div>
    </center>
  );
};

export default ActivityCardRule;
