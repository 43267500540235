const initialState = {
  isLoggedIn: localStorage.getItem('isLoggedIn') || false,
  avatarUrl: null,
  username: localStorage.getItem('username'),
  starLevel: localStorage.getItem('starLevel'),
  confirmVisible: false,
  inviteCode: localStorage.getItem('inviteCode'),
  confirmInfo: {
    title: "",
    content: "",
    type: "normal",
    onConfirm: null,
    onCancel: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "user/SWITCH_CONFIRM":
      return {
        ...state,
        confirmVisible: action.payload.confirmVisible,
        confirmInfo: action.payload.confirmInfo,
      };
    case "user/SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
