import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSelected } from '../service';

import './index.css';
import moment from 'moment/moment';

const SelectItem = ({ title, _id }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`/article?id=${_id}`, _id);
  };

  return (
    <div className='info-select' onClick={handleClick}>
      <div className='info-select-item'>
        <div className='info-select-dot'></div>
        <div className='info-select-title'>{title}</div>
      </div>
    </div>
  );
};

const SelectList = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { selectArticles } = useSelector(state => state.info);

  useEffect(() => {
    getSelected(dispatch, { page: 1, size: 3, language: i18n.language });
  }, []);

  return (
    <div className='select-list'>
      <div className='article-list-header'>
        {t('home.starSelected')}
      </div>
      <div className='article-list-body'>
        {
          selectArticles.map((item, index) => {
            return <SelectItem {...item} key={index} />
          })
        }
      </div>
    </div>
  );
};

export default SelectList;
