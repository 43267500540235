import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  BrowserRouter,
  Navigate,
} from "react-router-dom"
import { getCookie } from "./cookie"
import ChangePaymentPasswordScreen from "./pages/Profile/pages/changePaymentPasswordScreen"
import ResetPasswordScreen from "./pages/Profile/pages/resetPasswordScreen"
import SettingScreen from "./pages/Profile/pages/settingScreen"
import TopupScreen from "./pages/Wallet/pages/TopupScreen"
import WalletScreen from "./pages/Wallet/pages/WalletScreen.js"
import CurrencyScreen from "./pages/Wallet/pages/CurrencyScreen"
import ExchangeCurrencyScreen from "./pages/Wallet/pages/ExchangeCurrencyScreen"
import CurrencyWithdrawScreen from "./pages/Wallet/pages/CurrencyWithdrawScreen"
import StarPointScreen from "./pages/Wallet/pages/StarPointScreen"
import Read from "./pages/read"
import AboutUsScreen from "./pages/Profile/pages/aboutUsScreen"
import Profile from "./pages/Profile"
import SetLanguage from "./pages/set-language"
import Cover from "./pages/cover"
import LoginInfo1 from "./pages/login-info1"
import LoginInfo2 from "./pages/login-info2"
import SetArea from "./pages/set-area"
import ForgetPassword1 from "./pages/forget-password1"
import ForgetPassword2 from "./pages/forget-password2"
import ForgetPassword3 from "./pages/forget-password3"
import Login1 from "./pages/login1"
import Login2 from "./pages/login2"
import PersonalInfo1 from "./pages/personal-info1"
import PersonalInfo2 from "./pages/personal-info2"
import NoStepCard from "./pages/no-step-card"
import NoActivityCard from "./pages/no-activity-card"
import EarnedPopup from "./pages/earned-popup"
import Developing from "./pages/developing"
import LoginPopup from "./pages/login-popup"
import NotLoggedIn from "./pages/not-logged-in"
import NoStarCard from "./pages/no-star-card"
import HomePage from "./pages/HomePage/index"
import Article from "./pages/Article/index"
import Daily from "./pages/daily"
import DailyInfo from "./pages/daily-info"
import TasksNoStarCard from "./pages/tasks-no-star-card"
import Tasks from "./pages/Task/index"
import IncomeInfo from "./pages/income-info"
import StepCardRule from "./pages/step-card-rule"
import ActivityCardRule from "./pages/activity-card-rule"
import MyStarCard from "./pages/mystarcard"
import Invite from "./pages/Invite/index"
import InviteToolTip from "./pages/invite-tooltip"
import InvitePoster from "./pages/invite-poster"
import InviteRule from "./pages/inviterule"
import InviteRanking from "./pages/Invite/ranking"
import MyMember from "./pages/my-member"
import MyPartner from "./pages/my-partner"
import FreeMember from "./pages/free-member"
import Income1 from "./pages/income1"
import Income2 from "./pages/income2"
import PersonalCenterSigned from "./pages/personal-center-signed"
import StarNews from "./pages/star-news"
// import Wallet from "./pages/wallet"
import SelectCryptoTopup from "./pages/select-crypto-topup"
import SelectNetwork from "./pages/select-network"
import TopupFSC from "./pages/topup-fsc"
import TopupUSDT from "./pages/topup-usdt"
import TopupBNB from "./pages/topup-bnb"
import SelectCryptoWithdraw from "./pages/select-crypto-withdraw"
import WithdrawUSDT from "./pages/withdraw-usdt"
import Convert from "./pages/convert"
import BalanceFSC from "./pages/balance-fsc"
import BalanceUSDT from "./pages/balance-usdt"
import BalanceBNB from "./pages/balance-bnb"
import BalanceBNBRefresh from "./pages/balance-bnb-refresh"
import TransferDetails from "./pages/transfer-details"
import ConvertDetails from "./pages/convert-details"
import StarPointConvert from "./pages/star-point-convert"
import StarPointConvertSubmitted from "./pages/star-point-convert-submitted"
import StarPointConvertSuccess from "./pages/star-point-convert-success"
import StarPointConvertRefused from "./pages/star-point-convert-refused"
import StarPointConvertUnderReview from "./pages/star-point-convert-success-under-review"
import StarPointStats from "./pages/star-point-stats"
import AccountBlocked from "./pages/account-blocked"
import IdUpload1 from "./pages/id-upload1"
import IdUpload2 from "./pages/id-upload2"
import IdUpload3 from "./pages/id-upload3"
import IdVerification1 from "./pages/id-verfication1"
import IdVerification2 from "./pages/id-verification2"
import IdVerification3 from "./pages/id-verification3"
import IdUsed from "./pages/id-used"
import IdVerificationUnderReview from "./pages/id-verification-under-review"
import IdVerificationSuccess from "./pages/id-verification-success"
import IdVerificationFailed from "./pages/id-verification-failed"
import IdVerificationFailedPopup from "./pages/id-verification-failed-popup"
import SetPaymentPassword from "./pages/set-payment-password"
import SetPaymentPasswordSuccess from "./pages/set-payment-password-success"
import CheckPaymentPassword from "./pages/check-payment-password"
import CheckPaymentPasswordSuccess from "./pages/check-payment-password-success"
import Settings from "./pages/settings"
import ForgetpaymentPassword from "./pages/forget-payment-password"
import AboutUs from "./pages/about-us"
import Announcement from "./pages/announcement"
import H5Login from "./pages/h5-login"
import H5Register from "./pages/h5-register"
import DownloadApp from "./pages/download-app"
import Card from "./pages/Card"
import MyCard from "./pages/Card/MyCard"
import Info from "./pages/Info"
import VerifyAccount from "./pages/verifyAccount"
import Activity from "./pages/Task/activity"
import Step from "./pages/Task/step"
import Video from "./pages/Video"
import ChangeLanguage from "./pages/Profile/changeLanguage"
import ChangePaymentPassword from "./pages/Profile/change-payment-password"
import ChangePassword from "./pages/Profile/change-password"

import { useEffect } from "react"
import Wallet from "./pages/wallet"
import Commission from "./pages/Profile/Commission"

function App() {
  document.documentElement.scrollTop = document.body.scrollTop = 0

  const ProtectedRoute = ({ children }) => {
    const userLogedIn = getCookie("sessionId").length > 0
    if (userLogedIn) {
      return children
    }
    return <Navigate to="/login" replace />
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cover" element={<Cover />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/setlanguage" element={<SetLanguage />} />
        <Route path="/read" element={<Read />} />
        <Route path="/login" element={<Login1 />} />
        <Route path="/signUp" element={<LoginInfo1 />} />
        <Route path="/onBoard" element={<PersonalInfo1 />} />
        <Route path="/verifyAccount" element={<VerifyAccount />} />
        <Route path="/topup" element={<TopupScreen />} />
        <Route path="/currencyExchange" element={<ExchangeCurrencyScreen />} />
        <Route path="/currencyWithdraw" element={<CurrencyWithdrawScreen />} />
        <Route path="/account-setting" element={<SettingScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route
          path="/change-payment-password"
          element={<ChangePaymentPasswordScreen />}
        />

        <Route
          path="/profile"
          element={<ProtectedRoute children={<Profile />} />}
        />
        <Route
          path="/currency"
          element={<ProtectedRoute children={<CurrencyScreen />} />}
        />
        <Route
          path="/starPoint"
          element={<ProtectedRoute children={<StarPointScreen />} />}
        />
        <Route
          path="/contactUs"
          component={() => {
            window.location.href = "https://chat.futurestargroup.com/"
            return null
          }}
        />
        <Route path="/Id-verification1" element={<IdVerification1 />}></Route>
        <Route path="/wallet" element={<WalletScreen />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/mymember" element={<MyMember />} />
        <Route path="/logininfo2" element={<LoginInfo2 />} />
        <Route path="/setarea" element={<SetArea />} />
        <Route path="/login2" element={<Login2 />} />
        <Route path="/personalinfo2" element={<PersonalInfo2 />} />
        <Route path="/forgetpassword1" element={<ForgetPassword1 />} />
        <Route path="/forgetpassword2" element={<ForgetPassword2 />} />
        <Route path="/forgetpassword3" element={<ForgetPassword3 />} />
        <Route path="/earnedpopup" element={<EarnedPopup />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/invite/ranking" element={<InviteRanking />} />
        <Route path="/mypartner" element={<MyPartner />} />
        <Route path="/freemember" element={<FreeMember />} />
        <Route path="/income1" element={<Income1 />} />
        <Route path="/income2" element={<Income2 />} />
        <Route
          path="/personal-center/signed"
          element={<PersonalCenterSigned />}
        />
        <Route path="/starnews" element={<StarNews />} />
        <Route path="/selectcrypto/topup" element={<SelectCryptoTopup />} />
        <Route path="/selectnetwork" element={<SelectNetwork />} />
        <Route path="/topup/fsc" element={<TopupFSC />} />
        <Route path="/topup/usdt" element={<TopupUSDT />} />
        <Route path="/topup/bnb" element={<TopupBNB />} />
        <Route
          path="/selectcrypto/withdraw"
          element={<SelectCryptoWithdraw />}
        />
        <Route path="/withdraw/usdt" element={<WithdrawUSDT />} />
        <Route path="/convert" element={<Convert />} />
        <Route path="/balance/fsc" element={<BalanceFSC />} />
        <Route path="/balance/usdt" element={<BalanceUSDT />} />
        <Route path="/balance/bnb" element={<BalanceBNB />} />
        <Route path="/balance/bnb/refresh" element={<BalanceBNBRefresh />} />
        <Route path="/transferdetails" element={<TransferDetails />} />
        <Route path="/convertdetails" element={<ConvertDetails />} />
        <Route path="/starpoint/convert" element={<StarPointConvert />}></Route>
        <Route
          path="/starpoint/convert/submitted"
          element={<StarPointConvertSubmitted />}
        ></Route>
        <Route
          path="/starpoint/convert/success"
          element={<StarPointConvertSuccess />}
        ></Route>
        <Route
          path="/starpoint/convert/refused"
          element={<StarPointConvertRefused />}
        ></Route>
        <Route
          path="/starpoint/convert/under-review"
          element={<StarPointConvertUnderReview />}
        ></Route>
        <Route path="/starpoint/stats" element={<StarPointStats />}></Route>
        <Route path="/account-blocked" element={<AccountBlocked />}></Route>
        <Route path="/Id-upload1" element={<IdUpload1 />}></Route>
        <Route path="/Id-upload2" element={<IdUpload2 />}></Route>
        <Route path="/Id-upload3" element={<IdUpload3 />}></Route>
        <Route path="/Id-verification2" element={<IdVerification2 />}></Route>
        <Route path="/Id-verification3" element={<IdVerification3 />}></Route>
        <Route path="/Id-used" element={<IdUsed />}></Route>
        <Route
          path="/id-verification/under-review"
          element={<IdVerificationUnderReview />}
        ></Route>
        <Route
          path="/id-verification/success"
          element={<IdVerificationSuccess />}
        ></Route>
        <Route
          path="/id-verification/failed"
          element={<IdVerificationFailed />}
        ></Route>
        <Route
          path="/id-verification/failed-popup"
          element={<IdVerificationFailedPopup />}
        ></Route>
        <Route
          path="/set-payment-password"
          element={<SetPaymentPassword />}
        ></Route>
        <Route
          path="/set-payment-password/success"
          element={<SetPaymentPasswordSuccess />}
        ></Route>
        <Route
          path="/check-payment-password"
          element={<CheckPaymentPassword />}
        ></Route>
        <Route
          path="/check-payment-password/success"
          element={<CheckPaymentPasswordSuccess />}
        ></Route>
        <Route
          path="/change-payment-password"
          element={<ChangePaymentPassword />}
        />
        <Route
          path="/forget-payment-password"
          element={<ForgetpaymentPassword />}
        />
        <Route path="/about-us" element={<AboutUsScreen />} />
        <Route path="/announcement" element={<Announcement />} />
        <Route path="/h5-login" element={<H5Login />} />
        <Route path="/h5-register" element={<H5Register />} />
        <Route path="/downloadapp" element={<DownloadApp />} />
        <Route
          path="/mycard"
          element={<ProtectedRoute children={<MyCard />} />}
        />
        <Route
          path="/video"
          element={<ProtectedRoute children={<Video />} />}
        />
        <Route path="/card" element={<ProtectedRoute children={<Card />} />} />
        <Route path="/info" element={<Info />} />
        <Route path="/article" element={<Article />} />
        <Route path="/stepcardrule" element={<StepCardRule />} />
        <Route path="/activitycardrule" element={<ActivityCardRule />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/step" element={<Step />} />
        <Route path="/changeLanguage" element={<ChangeLanguage />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/commission" element={<Commission />} />
      </Routes>
    </BrowserRouter>
  )
}
export default App
