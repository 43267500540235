const IdVerification3 = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "779px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              ID Verification
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "35px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#fff",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#0e73f6",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              关闭
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "0px 11px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/对号-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#4f46e5",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/对号-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#4f46e5",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/对号-紫.svg"
                />
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                基本信息
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传证件
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                提交认证
              </div>
            </div>
          </div>
          <div
            style={{
              width: "335px",
              height: "376px",
              flexShrink: "0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            <img
              style={{
                position: "relative",
                borderRadius: "1000px",
                width: "61px",
                height: "61px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/对号-绿-大.svg"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                  display: "inline-block",
                  width: "335px",
                  height: "24px",
                  flexShrink: "0",
                }}
              >
                实名认证已提交
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "14px",
                  lineHeight: "130%",
                  fontFamily: "Inter",
                  display: "inline-block",
                  width: "335px",
                }}
              >
                我们会在24小时内审核您的实名认证请求并及时告知您，清随时注意您的实名认证状态，如被驳回请重试认证或联系客服。
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdVerification3;
