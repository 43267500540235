import request from '../../utlis/request';
import { notification } from 'antd';

export const getMyCards = async (dispatch, type, includeCombo = 0) => {
  try {
    const URL = `/api/card/personal?type=${type}&includeCombo=${includeCombo}`;

    const { data, message } = await request.get(URL);

    if (type === 'step') {
      dispatch({
        type: 'card/PUT_MY_STEP_CARD_LIST',
        payload: data,
      });
    }

    if (type === 'task') {
      dispatch({
        type: 'card/PUT_MY_TASK_CARD_LIST',
        payload: data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getCardList = async (dispatch, type = 'step') => {
  try {
    const URL = `/api/card/default?type=${type}`;

    const { data, message } = await request.get(URL);

    dispatch({
      type: 'card/PUT_CARD_LIST',
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCardHistory = async (sort = 0) => {
  return await axios.get(`/api/card/history?sort=${sort}`);
};

export const checkPurchaseCard = async (level, type) => {
  return await axios.get(`/api/card/check?level=${level}&type=${type}`);
};

export const purchaseCard = async (dispatch, t, level, type) => {
  try {
    const URL = `/api/card`;

    const { data } = await request.get(`/api/card/check?level=${level}&type=${type}`);

    const { requirement, balance, cost } = data;

    if (balance < cost) {
      dispatch({
        type: 'user/SWITCH_CONFIRM',
        payload: {
          confirmVisible: true,
          confirmInfo: {
            title: t('card.insufficientFSC'),
            content: t('card.insufficientFSCDesc', { cost }),
            type: 'normal',
            onConfirm: null,
            onCancel: null,
          }
        },
      });

      return;
    }

    if (requirement && requirement.requirementMet === false) {
      dispatch({
        type: 'user/SWITCH_CONFIRM',
        payload: {
          confirmVisible: true,
          confirmInfo: {
            title: t('card.insufficientMember'),
            content: t('card.insufficientMemberDesc', { member: requirement.number }),
            type: 'normal',
            onConfirm: null,
            onCancel: null,
          }
        },
      });

      return;
    }

    const result = await request.post(URL, { level, type });

    if (result) {
      dispatch({
        type: 'user/SWITCH_CONFIRM',
        payload: {
          confirmVisible: true,
          confirmInfo: {
            title: t('card.obtainedSuccess'),
            content: t('card.purchasedDesc'),
            type: 'normal',
            onConfirm: null,
            onCancel: null,
          }
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getGuestCards = async () => {
  return await axios.get('/api/card/guest');
};

export const getCardSummary = async (dispatch) => {
  try {
    const URL = `/api/card/summary`;

    const { data, message } = await request.get(URL);

    dispatch({
      type: 'card/PUT_CARD_SUMMARY',
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNewCardSummary = async () => {
  return await axios.get('/api/card/newStarCheck');
};
