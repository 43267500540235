import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../../Wallet/components/PageHeader"
import { t } from "i18next"
const appIcon = require("../images/appLogo.png")
const certificateIcon = require("../images/certificate.png")
export default function AboutUsScreen() {
  return (
    <PageWrapper>
      <PageHeader title={t("profile.aboutUs")} />
      <div style={styles.bodyWrapper}>
        <img src={appIcon} style={{ height: "94px", width: "83px" }} />
        <span
          style={{
            fontSize: "14px",
            color: "#3C464E",
            marginTop: "30px",
            lineHeight: "24px",
          }}
        >
          {t("profile.companyIntro")}
        </span>
        <img
          src={certificateIcon}
          style={{ height: "600px", width: "100%", marginTop: "24px" }}
        />
      </div>
    </PageWrapper>
  )
}
const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
    alignItems: "center",
  },
}
