import { useEffect, useRef, useState } from "react"
import FunctionButton from "./FunctionButton"
import { t } from "i18next"
import BottomPopupMenu from "./BottomPopupMenu"
import { useNavigate } from "react-router-dom"
import FSCExchangePopup from "./FSCExchangePopup"
const widthdrowIcon = require("../asset/widthdrowIcon.png")
const topupIcon = require("../asset/topupIcon.png")
const exchangeIcon = require("../asset/exchangeIcon.png")
const fscIcon = require("../asset/fscIcon.png")
const usdtIcon = require("../asset/usdtIcon.png")
const bnbIcon = require("../asset/bnbIcon.png")

export default function CurrencyActionsBar({ initCurrency = null }) {
  const navigate = useNavigate()

  const [bottomMenuMode, setBottomMenuMode] = useState("")
  const [showChooseCurrencyMenu, setShowChooseCurrencyMenu] = useState(false)
  const [showChooseNetworkMenu, setShowChooseNetworkMenu] = useState(false)

  const [fscExchangePopup, setFscExchangePopup] = useState(false)
  const onCurrencySelected = (currency) => {
    setShowChooseCurrencyMenu(false)
    switch (currency) {
      case "FSC":
        if (bottomMenuMode === "topup") {
          navigate("/topup?currency=FSC&network=BIANCE")
        } else if (bottomMenuMode === "exchange") {
          setFscExchangePopup(true)
        } else if (bottomMenuMode === "withdraw") {
          navigate("/currencyWithdraw?currency=FSC&network=BIANCE")
        }
        break
      case "BNB":
        if (bottomMenuMode === "topup") {
          navigate("/topup?currency=BNB&network=BIANCE")
        } else if (bottomMenuMode === "exchange") {
          navigate("/currencyExchange?from=FSC&to=BNB")
        } else if (bottomMenuMode === "withdraw") {
          navigate("/currencyWithdraw?currency=BNB&network=BIANCE")
        }
        break
      case "USDT":
        if (bottomMenuMode === "topup") {
          setShowChooseNetworkMenu(true)
        } else if (bottomMenuMode === "exchange") {
          navigate("/currencyExchange?from=FSC&to=USDT")
        } else if (bottomMenuMode === "withdraw") {
          setShowChooseNetworkMenu(true)
        }
        break
    }
  }
  const onNetworkSelected = (network) => {
    setShowChooseNetworkMenu(false)
    switch (network) {
      case "BSC (BEP20)":
        if (bottomMenuMode === "topup") {
          navigate("/topup?currency=USDT&network=BIANCE")
        } else if (bottomMenuMode === "withdraw") {
          navigate("/currencyWithdraw?currency=USDT&network=BIANCE")
        }
        break
      case "Ethereum (ERC20)":
        if (bottomMenuMode === "topup") {
          navigate("/topup?currency=USDT&network=ETHEREUM")
        } else if (bottomMenuMode === "withdraw") {
          navigate("/currencyWithdraw?currency=USDT&network=ETHEREUM")
        }
        break
      case "TRON (TRC20)":
        if (bottomMenuMode === "topup") {
          navigate("/topup?currency=USDT&network=TRON")
        } else if (bottomMenuMode === "withdraw") {
          navigate("/currencyWithdraw?currency=USDT&network=TRON")
        }
        break
    }
  }
  return (
    <>
      <div style={styles.subButtonsWrapper}>
        <FunctionButton
          icon={widthdrowIcon}
          title={t("wallet.withdraw")}
          onClick={() => {
            setBottomMenuMode("withdraw")
            if (initCurrency) {
              switch (initCurrency) {
                case "FSC":
                  navigate("/currencyWithdraw?currency=FSC&network=BIANCE")
                  break
                case "USDT":
                  setShowChooseNetworkMenu(true)
                  break
                case "BNB":
                  navigate("/currencyWithdraw?currency=BNB&network=BIANCE")
                  break
              }
            } else {
              setShowChooseCurrencyMenu(true)
            }
          }}
        />
        <FunctionButton
          icon={topupIcon}
          title={t("wallet.topUp")}
          onClick={() => {
            setBottomMenuMode("topup")
            if (initCurrency) {
              switch (initCurrency) {
                case "FSC":
                  navigate("/topup?currency=FSC&network=BIANCE")
                  break
                case "USDT":
                  setShowChooseNetworkMenu(true)
                  break
                case "BNB":
                  navigate("/topup?currency=BNB&network=BIANCE")
                  break
              }
            } else {
              setShowChooseCurrencyMenu(true)
            }
          }}
        />
        <FunctionButton
          icon={exchangeIcon}
          title={t("wallet.convert")}
          onClick={() => {
            setBottomMenuMode("exchange")
            if (initCurrency) {
              switch (initCurrency) {
                case "FSC":
                  setShowChooseCurrencyMenu(true)
                  break
                case "USDT":
                  navigate("/currencyExchange?from=USDT&to=FSC")
                  break
                case "BNB":
                  navigate("/currencyExchange?from=BNB&to=FSC")
                  break
              }
            } else {
              setShowChooseCurrencyMenu(true)
            }
          }}
          style={{ borderRight: "0px" }}
        />
      </div>
      {showChooseNetworkMenu && (
        <BottomPopupMenu
          title={"选择网络"}
          selections={[
            {
              selectionIcon: null,
              selectionTitle: "BSC (BEP20)",
              clickAction: onNetworkSelected,
            },
            {
              selectionIcon: null,
              selectionTitle: "Ethereum (ERC20)",
              clickAction: onNetworkSelected,
            },
            {
              selectionIcon: null,
              selectionTitle: "TRON (TRC20)",
              clickAction: onNetworkSelected,
            },
          ]}
        />
      )}

      {showChooseCurrencyMenu && (
        <BottomPopupMenu
          title={"选择加密货币"}
          selections={[
            !initCurrency && {
              selectionIcon: fscIcon,
              selectionTitle: "FSC",
              clickAction: onCurrencySelected,
            },
            {
              selectionIcon: usdtIcon,
              selectionTitle: "USDT",
              clickAction: onCurrencySelected,
            },
            {
              selectionIcon: bnbIcon,
              selectionTitle: "BNB",
              clickAction: onCurrencySelected,
            },
          ]}
        />
      )}
      {fscExchangePopup && (
        <FSCExchangePopup
          onClose={() => {
            setFscExchangePopup(false)
          }}
        />
      )}
    </>
  )
}
const styles = {
  subButtonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: "15px 16px",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}
