import { t } from "i18next"
import { useEffect, useState } from "react"
import { getTransactionRecord } from "../../../apis/wallet"
import moment from "moment"
import { formatBalance } from "../../../utlis/numbers"
const transhistoryIcon = require("../asset/historyIcon.png")
const pageSize = 20
const spWithdraw = require("../asset/spWithdraw.png")
const incomeImage = require("../asset/sp-income.png")
const historyEarn = require("../asset/history-earn.png")
const historyPurchase = require("../asset/history-purchase.png")
const convertPendingImage = require("../asset/convert-pending.png")
export default function TransactionHistories() {
  const [history, setHistory] = useState([])

  useEffect(() => {
    getTransactionRecord({ page: 1, size: pageSize, currency: "SP" })
      .then((result) => {
        console.log("history: ", result.data.data)
        setHistory(result.data.data)
        //  if (result.data.data.length < pageSize) {
        //    setShowLoader(false)
        //  }
      })
      .catch((e) => console.log(e))
  }, [])
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "12px",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "18px",
          borderRadius: "12px",
        }}
      >
        <div>
          <img
            src={transhistoryIcon}
            style={{ width: "20px", height: "20px" }}
          />

          <span
            style={{ fontSize: "12px", fontWeight: "600", marginLeft: "4px" }}
          >
            {t("wallet.history")}
          </span>
        </div>
        <button style={{ border: "none", backgroundColor: "transparent" }}>
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
            {t("wallet.seeMore")}
          </span>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 18px 18px 18px",
        }}
      >
        {history.map((item) => {
          const typeExists = !!historyComponents[item.type]
          let icon = typeExists ? historyComponents[item.type].icon : null
          let title = typeExists
            ? t(historyComponents[item.type].title, {
                currency: t("wallet.fscp"),
                currencyTo:
                  historyComponents[item.type].convertTo === "SP"
                    ? t("wallet.fscp")
                    : historyComponents[item.type].convertTo,
                currencyFrom:
                  historyComponents[item.type].convertFrom === "SP"
                    ? t("wallet.fscp")
                    : historyComponents[item.type].convertFrom,
              })
            : ""
          let color = typeExists
            ? historyComponents[item.type].color
            : "#0E73F6"

          if (item.type === "SP convert FSC") {
            if (item.status === 0) {
              icon = convertSuccessImage
              title += t("wallet.spSuccess")
              color = "#119C2B"
            } else if (item.status !== 1) {
              icon = convertFailedImage
              title += t("wallet.spFailed")
              color = "#CC0905"
            } else {
              title += t("wallet.spPending")
            }
          }
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <img
                src={icon}
                style={{ height: "38px", width: "38px", borderRadius: "19px" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                  flex: 1,
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#252C32",
                  }}
                >
                  {title}
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#828282",
                  }}
                >
                  {moment(item.createTime).format("YYYY-MM-DD HH:mm")}
                </span>
              </div>
              <span
                style={{
                  color,
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "flex",
                }}
              >
                {typeExists && historyComponents[item.type].negative
                  ? "-"
                  : "+"}
                {formatBalance(item.amount, 10000)}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const historyComponents = {
  register: {
    icon: incomeImage,
    title: "wallet.registerBonus",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  invite: {
    icon: incomeImage,
    title: "wallet.invitationBonus",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  task_invite_income: {
    icon: incomeImage,
    title: "wallet.inviteIncome",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  task_income: {
    icon: incomeImage,
    title: "wallet.taskIncome",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  step_income: {
    icon: incomeImage,
    title: "wallet.stepIncome",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  step_invite_income: {
    icon: incomeImage,
    title: "wallet.inviteIncome",
    convertTo: "",
    convertFrom: "",
    color: "#0E73F6",
  },
  deposit: {
    icon: historyEarn,
    title: "wallet.topUpTitle",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  attend_income: {
    icon: incomeImage,
    title: "tasks.dailyCheckin",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  view_usermenu: {
    icon: incomeImage,
    title: "tasks.checkOut",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  watch_intro_video: {
    icon: incomeImage,
    title: "tasks.viewIntro",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  finish_verify: {
    icon: incomeImage,
    title: "tasks.completeVerification",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_get_card: {
    icon: incomeImage,
    title: "tasks.receiveStarCard",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  release_sp_store: {
    icon: spWithdraw,
    title: "tasks.commissionIncome",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_finish_daily_task: {
    icon: incomeImage,
    title: "tasks.completeActivity",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_finish_step_task: {
    icon: incomeImage,
    title: "tasks.completeWalk",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_deposit: {
    icon: incomeImage,
    title: "tasks.topUp",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_invite_user: {
    icon: incomeImage,
    title: "tasks.completeInvite",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_convert_FSC_to_USDT: {
    icon: incomeImage,
    title: "tasks.completeConvert",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  first_withdraw_USDT: {
    icon: incomeImage,
    title: "tasks.completeWithdraw",
    convertTo: "",
    convertFrom: "FSC",
    color: "#0E73F6",
  },
  "SP convert FSC": {
    icon: convertPendingImage,
    title: "wallet.currencyConvertTo",
    convertTo: "FSC",
    convertFrom: "",
    negative: true,
    color: "#D29404",
  },
  purchase_card: {
    icon: historyPurchase,
    title: "wallet.historyPurchase",
    negative: true,
    color: "#1A2024",
  },
}
