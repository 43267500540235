const eyeIcon = require("../assets/images/Auth/eye.png")
const crossEyeIcon = require("../assets/images/Auth/eye-cross.png")
import { useState } from "react"

export default function PasswordInput({
  placeHolder,
  value,
  setValue,
  errorMessage = "",
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        borderRadius: "6px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #dde2e4",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "2px 4px",
        borderColor: errorMessage.length > 0 ? "#f76659" : " #dde2e4",
      }}
    >
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        type={showPassword ? "text" : "password"}
        style={{
          flex: 1,
          backgroundColor: "transparent",
          border: "0px",
          padding: "10px 16px",
        }}
        placeholder={placeHolder}
      ></input>
      <div
        onClick={() => {
          setShowPassword((prev) => !prev)
        }}
      >
        <img
          style={{
            width: "24px",
            height: "24px",
            flexShrink: "0",
          }}
          alt=""
          src={showPassword ? crossEyeIcon : eyeIcon}
        />
      </div>
      {errorMessage.length > 0 && (
        <text
          style={{
            position: "absolute",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#f2271c",
            left: 0,
            bottom: -16,
          }}
        >
          {errorMessage}
        </text>
      )}
    </div>
  )
}
