const TopupUSDT = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#55B197",
          width: "375px",
          height: "724px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#fff",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "24px",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #55B197",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#55B197",
                borderBottom: "1px solid #55B197",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  backgroundColor: "#55B197",
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 20px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/左尖角-白.svg"
                />
                <b
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "120%",
                    left: "-6px",
                  }}
                >
                  Top-Up USDT
                </b>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: "#fff",
              width: "351px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
              textAlign: "left",
              fontSize: "14px",
              color: "#f2271c",
              fontFamily: "Inter",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                backgroundColor: "#f6f8f9",
                display: "flex",
                flexDirection: "column",
                padding: "32px",
                alignItems: "center",
                justifyContent: "center",
                gap: "32px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fed6cd",
                    width: "311px",
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                    boxSizing: "border-box",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/充值提醒惊叹号.svg"
                  />
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "16px",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          position: "relative",
                          letterSpacing: "-0.01em",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        该地址为币安智能链(BSC)，仅向该地址转入BSC/BEP20资产，因错误转账导致的资金损失将由您个人承担。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  width: "200px",
                  height: "200px",
                  flexShrink: "0",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0%",
                    right: "0%",
                    bottom: "0%",
                    left: "0%",
                    maxWidth: "100%",
                    overflow: "hidden",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                  src="/充值二维码.png"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                padding: "0px 16px 16px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
                fontSize: "12px",
                color: "#84919a",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f6f8f9",
                  border: "1px solid #eef0f2",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 16px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <div style={{ position: "relative", lineHeight: "16px" }}>
                    Address
                  </div>
                  <div
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #d7edff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      padding: "0px 4px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      color: "#0452c8",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      BSC
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "16px",
                    color: "#252c32",
                    textAlign: "center",
                    opacity: "0.7",
                  }}
                >
                  3FjcxDGZcfTzRGMGJ2VzVL66oSizJKrPpT
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  padding: "12px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  fontSize: "14px",
                  color: "#252c32",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/充值界面分享.svg"
                  />
                  <div style={{ position: "relative", lineHeight: "16px" }}>
                    Share
                  </div>
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/复制-灰.svg"
                  />
                  <div style={{ position: "relative", lineHeight: "16px" }}>
                    Copy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            top: "635px",
            left: "109px",
            width: "158px",
            height: "48px",
            objectFit: "cover",
          }}
          alt=""
          src="/充值界面logo.png"
        />
      </div>
    </center>
  );
};

export default TopupUSDT;
