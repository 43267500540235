export default function PageWrapper(props) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        gap: "30px",
        zIndex: 0,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}
