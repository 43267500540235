import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import defaultAva from '../assets/default-avatar.png';

const medal0Image = require('../assets/medal-0.png')
const medal1Image = require('../assets/medal-1.png')
const medal2Image = require('../assets/medal-2.png')
const medal3Image = require('../assets/medal-3.png')
const medal4Image = require('../assets/medal-4.png')
const medal5Image = require('../assets/medal-5.png')
const medal6Image = require('../assets/medal-6.png')

const medals = [
  medal0Image,
  medal1Image,
  medal2Image,
  medal3Image,
  medal4Image,
  medal5Image,
  medal6Image,
];

const User = () => {
  const { avatarUrl, username, starLevel } = useSelector(state => state.user);
  const { t } = useTranslation();

  return (
    <div className='card-user'>
      <div className='card-ava'>
        <img src={avatarUrl || defaultAva} alt="" className='card-ava-img' />
        <img src={medals[starLevel]} alt="" className='card-ava-medal' width={16} />
      </div>
      <div className='card-user-right'>
        <div className='card-username'>
          {username}
        </div>
        <div className='card-userlevel'>
          {t(`card.member-${starLevel}`)}
        </div>
      </div>
    </div>
  )
}

export default User