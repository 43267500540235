import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import right from '../assets/right.svg';

const Jump = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleClick = () => {
    navigation('/mycard');
  };

  return (
    <div className='card-jump' onClick={handleClick}>
      <div>{t('card.myCards2')}</div>
      <img src={right} alt="" />
    </div>
  )
}

export default Jump