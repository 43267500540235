const TransferDetails = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "726px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "14px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "-1px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontSize: "18px",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "20px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="/左尖角.svg"
          />
          <b
            style={{
              flex: "1",
              position: "relative",
              letterSpacing: "-0.01em",
              lineHeight: "120%",
              left: "-2px",
            }}
          >
            交易详情
          </b>
        </div>
        <div
          style={{
            position: "absolute",
            top: "112px",
            left: "12px",
            borderRadius: "12px",
            backgroundColor: "#fff",
            width: "351px",
            height: "516px",
            overflow: "hidden",
            color: "#5b6871",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "101px",
              left: "16px",
              width: "319px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                发款方
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  3FjcxDGZcfTzRGMGJ2VzVL66oSizJKrPpT
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                收款方
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  3FjcxDGZcfTzRGMGJ2VzVL66oSizJKrPpT
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                矿工费用
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  0.000014 BNB
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                备注
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  无
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                borderTop: "1px solid #e5e9eb",
                boxSizing: "border-box",
                height: "1px",
                flexShrink: "0",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                哈希值
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  3FjcxDGZc...SizJKrPpT
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                区块号
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "2px",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  3FjcxDGZc...SizJKrPpT
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/复制-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  fontWeight: "500",
                }}
              >
                交易时间
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  color: "#1a2024",
                  fontFamily: "Inter",
                }}
              >
                <div style={{ position: "relative", fontWeight: "500" }}>
                  2021-01-01 12:33
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "84px",
            left: "132px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "11px",
            textAlign: "left",
            color: "#22c348",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "7px",
            }}
          >
            <div
              style={{
                borderRadius: "10000px",
                backgroundColor: "#47d16c",
                display: "flex",
                flexDirection: "row",
                padding: "10px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "32px",
                  height: "32px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/转账兑换成功.svg"
              />
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              转账成功
            </div>
          </div>
          <div
            style={{
              position: "relative",
              fontSize: "18px",
              letterSpacing: "0.02em",
              fontWeight: "500",
              fontFamily: "Inter",
              color: "#303940",
              textAlign: "center",
            }}
          >
            +20.01 USDT
          </div>
        </div>
      </div>
    </center>
  );
};

export default TransferDetails;
