import PageWrapper from "../../../components/PageWrapper"
import { useEffect, useRef, useState } from "react"
import { getWalletSummary } from "../../../apis/user"
import { getWalletInfo } from "../../../apis/wallet"
import { getConversionRate } from "../../../apis/wallet"
import { formatBalance } from "../../../utlis/numbers"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import PageHeader from "../components/PageHeader"
import WalletButton from "../components/WalletButton"
import CurrencyActionsBar from "../components/CurrencyActionsBar"
const starPointIcon = require("../asset/starPointIcon.png")
const refreshIcon = require("../asset/refreshIcon.png")
const fscIcon = require("../asset/fscIcon.png")
const usdtIcon = require("../asset/usdtIcon.png")
const bnbIcon = require("../asset/bnbIcon.png")
export default function WalletScreen() {
  const navigate = useNavigate()
  const [totalBalance, setTotalBalance] = useState(0)
  const [spBalance, setSpBalance] = useState(0)
  const [fscBalance, setFscBalance] = useState(0)
  const [usdtBalance, setUsdtBalance] = useState(0)
  const [bnbBalance, setBnbBalance] = useState(0)
  const [FSCToUSDTRatio, setFSCToUSDTRatio] = useState(0)
  const [FSCToBNBRatio, setFSCToBNBRatio] = useState(0)
  const [SPToUSDTRatio, setFscpToUsdtRatio] = useState(0)

  const init = async () => {
    const result1 = await getWalletSummary()
    setTotalBalance(result1.data.data.totalBalanceUSDT)
    const result2 = await getWalletInfo()
    const walletInfo = result2.data.data
    setSpBalance(() => {
      let index = walletInfo.findIndex((w) => w.currency === "SP")
      if (index >= 0) {
        return walletInfo[index].balance
      } else {
        return 0
      }
    })
    setFscBalance(() => {
      let index = walletInfo.findIndex((w) => w.currency === "FSC")
      if (index >= 0) {
        return walletInfo[index].balance
      } else {
        return 0
      }
    })
    setUsdtBalance(() => {
      let index = walletInfo.findIndex((w) => w.currency === "USDT")
      if (index >= 0) {
        return walletInfo[index].balance
      } else {
        return 0
      }
    })
    setBnbBalance(() => {
      let index = walletInfo.findIndex((w) => w.currency === "BNB")
      if (index >= 0) {
        return walletInfo[index].balance
      } else {
        return 0
      }
    })
    getConversionRate()
      .then((result) => {
        setFSCToUSDTRatio(result.data.data.FSCtoUSDTRatio)
        setFSCToBNBRatio(result.data.data.FSCtoBNBRatio)
        setFscpToUsdtRatio(
          result.data.data.SPtoFSCRatio * result.data.data.FSCtoUSDTRatio
        )
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    init()
  }, [])
  return (
    <PageWrapper>
      <PageHeader title={t("wallet.title")} />
      <div style={styles.bodyWrapper}>
        <div style={styles.leadingPartWrapper}>
          <div style={styles.leadingFirstPart}>
            <span
              style={{ fontWeight: "400", fontSize: "12px", color: "#FFFFFF" }}
            >
              {t("wallet.totalAssets")}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#FFFFFF",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  ${" "}
                </span>
                {formatBalance(totalBalance, 100)}
              </span>
              {/* <button
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <img
                  src={refreshIcon}
                  style={{ width: "18px", height: "18px" }}
                />
              </button> */}
            </div>
          </div>
          <CurrencyActionsBar />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "24px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "18px",
              color: "#1A2024",
            }}
          >
            {t("wallet.assets")}
          </span>
          <WalletButton
            icon={starPointIcon}
            title={t("wallet.starPoints")}
            currencyBalance={formatBalance(spBalance)}
            usdtBalance={formatBalance(spBalance * SPToUSDTRatio)}
            onClick={() => {
              navigate("/starPoint")
            }}
          />
          <WalletButton
            icon={fscIcon}
            title={"FSC"}
            currencyBalance={formatBalance(fscBalance)}
            usdtBalance={formatBalance(fscBalance * FSCToUSDTRatio)}
            onClick={() => {
              navigate("/currency?type=FSC")
            }}
          />
          <WalletButton
            icon={usdtIcon}
            title={"USDT"}
            currencyBalance={formatBalance(usdtBalance)}
            usdtBalance={formatBalance(usdtBalance)}
            onClick={() => {
              navigate("/currency?type=USDT")
            }}
          />
          <WalletButton
            icon={bnbIcon}
            title={"BNB"}
            currencyBalance={formatBalance(bnbBalance)}
            usdtBalance={formatBalance(
              (bnbBalance * FSCToUSDTRatio) / FSCToBNBRatio
            )}
            onClick={() => {
              navigate("/currency?type=BNB")
            }}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
  },
  leadingPartWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
  },
  leadingFirstPart: {
    alignSelf: "stretch",
    background: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    padding: "20px 16px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
}
