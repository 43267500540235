import "bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react"
import { backendUserSignIn } from "../apis/auth"
import { setCookie } from "../cookie"
import { setToken } from "../utlis/axios"
import { useNavigate } from "react-router-dom"
import { userLogin } from "../cognito"
import Dropdown from "react-dropdown"
import LoginTypeSelector from "../components/LoginTypeSelector"
import "react-dropdown/style.css"
import { checkUserStatus, saveUserPassword } from "../apis/auth"
import PasswordInput from "../components/PasswordInput"
import RegularTextInput from "../components/RegularTextInput"
import PhoneNumberInput from "../components/PhoneNumberInput"
import { supportedLanguage, supportedAreaCode } from "../constant"
import { useDispatch } from "react-redux"
const Login1 = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate()
  const [selectedLanguage, setSelectedLanguage] = useState(supportedLanguage[0])
  const [selectedAreaCode, setSelectedAreaCode] = useState(supportedAreaCode[0])
  const [userNameErrorMessage, setUserNameErrorMessage] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

  const [mode, setMode] = useState(0)

  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const [password, setPassword] = useState("")
  const loginAndStoreSession = async (onSuccess) => {
    try {
      const { data } = await backendUserSignIn(
        mode === 0 ? null : email,
        mode === 1 ? null : selectedAreaCode + phoneNumber,
        password
      );

      dispatch({
        type: 'user/SET_USER',
        payload: {
          username: data.data.userName,
          starLevel: data.data.starLevel,
          avatarUrl: data.data.avatar,
          inviteCode: data.data.inviteCode,
          isLoggedIn: true,
        },
      });

      localStorage.setItem('username', data.data.userName);
      localStorage.setItem('starLevel', data.data.starLevel);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('avatarUrl', data.data.avatar);
      localStorage.setItem('inviteCode', data.data.inviteCode);

      setCookie("sessionId", data.data.sessionId, data.data.expireTime)
      setToken(data.data.sessionId)
      onSuccess()
    } catch (err) {
      setPasswordErrorMessage("Wrong Password")

      console.log(err)
    }
  }
  const migrateUser = async (onsuccess) => {
    await userLogin(
      mode === 0 ? selectedAreaCode + phoneNumber : email,
      password,
      async (data) => {
        console.log(selectedAreaCode + phoneNumber, email)
        const jwtToken = data.idToken.jwtToken
        await saveUserPassword(password, jwtToken)
        loginAndStoreSession(onsuccess)
      },
      (err) => {
        setPasswordErrorMessage("Wrong Password")
      }
    )
  }

  const onLoginClicked = async () => {
    try {
      const result = await checkUserStatus(
        mode === 0 ? selectedAreaCode + phoneNumber : email
      )
      console.log(result.data.data.userStatus, result.data.data.authExist)
      const userStatus = result.data.data.userStatus
      if (userStatus !== null) {
        if (userStatus === 0) {
          if (!result.data.data.authExist) {
            await migrateUser(() => {
              navigate("/")
            })
          } else {
            await loginAndStoreSession(() => {
              navigate("/")
            })
          }
        } else if (userStatus === 1) {
          if (!result.data.data.authExist) {
            await migrateUser(() => {
              navigate("/onBoard")
            })
          } else {
            await loginAndStoreSession(() => {
              navigate("/onBoard")
            })
          }
        } else if (userStatus === 4) {
          if (result.data.data.authExist) {
            navigate(
              `/verifyAccount/?user=${
                mode === 0 ? selectedAreaCode + phoneNumber : email
              }`
            )
          } else {
            setUserNameErrorMessage("Please go to register your account.")
          }
        }
      } else {
        setUserNameErrorMessage("Please go to register your account.")
      }
    } catch (err) {
      console.log("onLoginClicked: ", err)
    }
  }
  useEffect(() => {
    if (userNameErrorMessage.length > 0) {
      const timer = setTimeout(() => {
        setUserNameErrorMessage("")
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [userNameErrorMessage])
  useEffect(() => {
    if (passwordErrorMessage.length > 0) {
      const timer = setTimeout(() => {
        setPasswordErrorMessage("")
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [passwordErrorMessage])
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "779px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "Inter",
      }}
    >
      <center>
        <div
          style={{
            position: "relative",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontFamily: "'Work Sans'",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../关闭.svg"
          />
          <div
            style={{
              position: "relative",
              borderRadius: "6px",
              backgroundColor: "#ebf7ff",
              display: "flex",
              flexDirection: "row",
              padding: "4px 8px",
              alignItems: "center",
              justifyContent: "flex-start",
              left: "265px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "28px",
                height: "28px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../客服.svg"
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "28px",
            color: "#000",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Welcome Back
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "500",
              }}
            >
              Come back and restart your earning today!
            </div>
          </div>
          <div style={{ width: "335px", display: "flex" }}>
            <LoginTypeSelector mode={mode} setMode={setMode} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "14px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                {mode === 0 ? "Phone Number" : "Email"}
              </div>
              {mode === 0 ? (
                <PhoneNumberInput
                  areaCode={selectedAreaCode}
                  setAreaCode={setSelectedAreaCode}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  errorMessage={userNameErrorMessage}
                />
              ) : (
                <RegularTextInput
                  value={email}
                  setValue={setEmail}
                  placeHolder={"Email"}
                  errorMessage={userNameErrorMessage}
                />
              )}
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <PasswordInput
                placeHolder={"Password"}
                value={password}
                setValue={setPassword}
                errorMessage={passwordErrorMessage}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#1a2024",
              }}
            >
              <Dropdown
                options={supportedLanguage}
                onChange={setSelectedLanguage}
                value={selectedLanguage}
                placeholder="Select an option"
              />
              <div
                onClick={() => {
                  navigate("/forgetpassword1")
                }}
                style={{
                  position: "relative",
                  fontWeight: "500",
                  color: "#0e73f6",
                  textAlign: "right",
                }}
              >
                Forgot Password?
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            top: "225px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <button
              onClick={onLoginClicked}
              style={{
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                borderWidth: "0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                Log In
              </div>
            </button>
            <div
              onClick={() => {
                navigate("/signUp")
              }}
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "500",
                color: "#0e73f6",
                textAlign: "right",
              }}
            >
              I do not have an account
            </div>
          </div>
        </div>
      </center>
    </div>
  )
}

export default Login1
const styles = {
  modeSelectorOuter: {
    selected: {
      backgroundColor: "#0e73f6",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
    unselected: {
      backgroundColor: "transparent",
      width: "50%",
      display: "flex",
      justifyContent: "center",
      borderRadius: "20px",
      padding: "8px",
    },
  },
  modeText: {
    selected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "white",
    },
    unselected: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "#5B6871",
    },
  },
}
