const initialState = {
  inviteSummary: {
    code: "MGLSBEF7",
    today: {
      invite_loss: 0,
      member: 0,
      register_invite_income: 0,
      step_invite_income: 0,
      task_invite_income: 0,
      total_invite_income: 0,
    },

    total: {
      invite_loss: 0,
      member: 8,
      register_invite_income: 200,
      step_invite_income: 0,
      task_invite_income: 0,
      total_invite_income: 0,
    },
  },
  todayRanking: [
    {
      inviteCount: 17,
      inviteIncome: 33792.45,
      starCardLevel: 5,
      user: {
        avatarUrl:
          "https://future-star-prod-asset-us.s3.amazonaws.com/avatar/635ca000fe03d9003e8b4d9a.jpeg",

        userName: "启航",
      },
    },
  ],
  totalRanking: [
    {
      inviteCount: 17,
      inviteIncome: 33792.45,
      starCardLevel: 5,
      user: {
        avatarUrl:
          "https://future-star-prod-asset-us.s3.amazonaws.com/avatar/635ca000fe03d9003e8b4d9a.jpeg",

        userName: "启航",
      },
    },
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "invite/GET_INVITE_SUMMARY":
      return {
        ...state,
        inviteSummary: action.payload,
      };
    case "invite/GET_RANKING_TODAY":
      return {
        ...state,
        todayRanking: action.payload,
      };
    case "invite/GET_RANKING_TOTAL":
      return {
        ...state,
        totalRanking: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
