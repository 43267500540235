const SetPaymentPassword = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "667px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
              }}
            >
              我的钱包
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "72px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            fontSize: "12px",
            color: "#fff",
            fontFamily: "Inter",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(91, 104, 113, 0.14)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                background: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: "20px 16px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <div style={{ position: "relative", lineHeight: "16px" }}>
                    总资产
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  fontSize: "16px",
                }}
              >
                <div style={{ position: "relative", lineHeight: "24px" }}>
                  <span>{`$ `}</span>
                  <span style={{ fontSize: "24px", fontWeight: "600" }}>
                    123.02
                  </span>
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/刷新-白.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "11px 16px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
                textAlign: "left",
                fontSize: "14px",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/提现.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  提现
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  borderRight: "1px solid #e5e9eb",
                  boxSizing: "border-box",
                  width: "1px",
                  flexShrink: "0",
                }}
              />
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/充值.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  充值
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  borderRight: "1px solid #e5e9eb",
                  boxSizing: "border-box",
                  width: "1px",
                  flexShrink: "0",
                }}
              />
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/闪兑.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  闪兑
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "12px",
              textAlign: "left",
              fontSize: "18px",
              color: "#1a2024",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
                display: "inline-block",
                width: "133px",
              }}
            >
              资产列表
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
                fontSize: "14px",
                color: "#252c32",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 8px",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        borderRadius: "125.71px",
                        width: "44px",
                        height: "44px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/SP.svg"
                    />

                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      SP
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: "4px",
                      textAlign: "right",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      1.516132581
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      ≈ $3,020.75
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 8px",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "125.71px",
                        backgroundColor: "#fff",
                        border: "0.5px solid #4094f7",
                        boxSizing: "border-box",
                        width: "44px",
                        height: "44px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          height: "57.03%",
                          width: "62.06%",
                          top: "21.88%",
                          right: "22.32%",
                          bottom: "21.1%",
                          left: "15.63%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt=""
                        src="/FSC.png"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      FSC
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: "4px",
                      textAlign: "right",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      1.516132581
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      ≈ $3,020.75
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 8px",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "125.71px",
                        backgroundColor: "#fff",
                        border: "0.5px solid #50af95",
                        boxSizing: "border-box",
                        width: "44px",
                        height: "44px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          top: "7.54px",
                          left: "6.29px",
                          width: "31.43px",
                          height: "31.43px",
                          objectFit: "cover",
                        }}
                        alt=""
                        src="/USDT.png"
                      />
                    </div>

                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      USDT
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: "4px",
                      textAlign: "right",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      1.516132581
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      ≈ $3,020.75
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 8px",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "167.49px",
                        backgroundColor: "#fff",
                        border: "0.5px solid #f3ba2f",
                        boxSizing: "border-box",
                        width: "44px",
                        height: "44px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          height: "70.37%",
                          width: "70.37%",
                          top: "14.82%",
                          right: "14.82%",
                          bottom: "14.81%",
                          left: "14.81%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt=""
                        src="/BNB.png"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      BNB
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: "4px",
                      textAlign: "right",
                      fontSize: "16px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      1.516132581
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                      }}
                    >
                      ≈ $3,020.75
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "375px",
            height: "854px",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "287px",
            left: "0px",
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px -4px 8px rgba(186, 186, 186, 0.24)",
            width: "375px",
            height: "586px",
            overflow: "hidden",
            textAlign: "left",
            fontSize: "14px",
            color: "#1a2024",
            fontFamily: "Inter",
            fontWeight: "400",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "600",
                left: "110px",
              }}
            >
              请设置支付密码
            </div>
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/关闭.svg"
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "235px",
              left: "calc(50% - 175.5px)",
              width: "351px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "center",
              fontSize: "16px",
              color: "#fff",
            }}
          >
            <div
              style={{
                flex: "1",
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                确认
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 221px)",
              left: "calc(50% - 175.5px)",
              lineHeight: "130%",
              display: "inline-block",
              width: "351px",
            }}
          >
            为了更好的保护您的资产，请设用来支付,转账,提现时使用的支付密码。
          </div>
          <div
            style={{
              position: "absolute",
              top: "124px",
              left: "12px",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "4px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "24px",
              }}
            >
              密码
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "6px",
                backgroundColor: "#f5f5f5",
                border: "1px solid #dde2e4",
                display: "flex",
                flexDirection: "row",
                padding: "8px 16px",
                alignItems: "flex-start",
                justifyContent: "space-between",
                color: "#9aa6ac",
              }}
            >
              <input
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  width: "100%",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                placeholder="输入密码"
              ></input>
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/可见.svg"
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "208px",
              left: "12px",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "4px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "24px",
              }}
            >
              重复
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "6px",
                backgroundColor: "#f5f5f5",
                border: "1px solid #dde2e4",
                display: "flex",
                flexDirection: "row",
                padding: "8px 16px",
                alignItems: "flex-start",
                justifyContent: "space-between",
                color: "#9aa6ac",
              }}
            >
              <input
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                  width: "100%",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                placeholder="重复密码"
              ></input>
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="/不可见.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default SetPaymentPassword;
