import { useEffect, useState } from "react"
import RegularTextInput from "../../../components/RegularTextInput"
import { formatBalance } from "../../../utlis/numbers"
import { useNavigate } from "react-router-dom"

import { t } from "i18next"
import { getConversionRate } from "../../../apis/wallet"
import { getWalletInfo } from "../../../apis/wallet"
import { convertCurrency } from "../../../apis/wallet"
const blueWarning = require("../asset/blueWarning.png")
export default function FSCExchangePopup({ onClose }) {
  const navigate = useNavigate()

  const [SPAmount, setSPAmount] = useState("")
  const [mode, setMode] = useState(0)
  const [balance, setBalance] = useState(0)
  const [convertRatio, setConvertRatio] = useState(0)
  const [fee, setFee] = useState(0)
  const [starLevel, setStarLevel] = useState(-1)
  const init = async () => {
    const rate = await getConversionRate()
    setConvertRatio(rate.data.data.SPtoFSCRatio)
    setFee(rate.data.data.SPtoFSCFee)
    setStarLevel(rate.data.data.starLevel)
    const wallet = await getWalletInfo()
    setBalance(() => {
      let index = wallet.data.data.findIndex((w) => w.currency === "SP")
      if (index >= 0) {
        return wallet.data.data[index].balance
      } else {
        return 0
      }
    })
  }
  const tryConfirm = async () => {
    try {
      if (Number(SPAmount) >= 500) {
        const result = await convertCurrency("SP", "FSC", Number(SPAmount))
        setMode(1)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    init()
  }, [])
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgb(26, 32, 36, 0.32)",
        display: "flex",
        padding: "20px",
      }}
    >
      {mode === 0 && (
        <div
          style={{
            position: "relative",
            width: "100%",
            backgroundColor: "white",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "6px",
            padding: "16px",
          }}
        >
          <span
            style={{ fontWeight: "600", fontSize: "18px", color: "#252C32" }}
          >
            {t("wallet.starPointRedeem")}
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <span
              style={{ fontWeight: "500", fontSize: "14px", color: "#303940" }}
            >
              {t("wallet.redeemAmount")}
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
                backgroundColor: "#F5F5F5",
                alignItems: "center",
                marginTop: "4px",
                borderRadius: "6px",
              }}
            >
              <RegularTextInput
                value={SPAmount}
                setValue={setSPAmount}
                placeHolder={t("wallet.inputStarPoint")}
                wrapperStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                  flex: 1,
                }}
                inputStyle={{ backgroundColor: "transparent" }}
              />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#303940",
                }}
              >
                {t("wallet.fscp")}
              </span>
              <button
                onClick={() => {
                  setSPAmount(formatBalance(balance, 10000).toString())
                }}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#0E73F6",
                  }}
                >
                  {t("wallet.all")}
                </span>
              </button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontWeight: "400",
                fontSize: "12px",
                color: "#252C32",
                marginTop: "4px",
              }}
            >
              <span>
                {t("wallet.availableStarPoint")} {formatBalance(balance, 10000)}
                {t("wallet.fscp")}
              </span>
              <span>{t("wallet.minStarPointRequest")}</span>
            </div>
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#303940",
                marginTop: "12px",
              }}
            >
              {t("wallet.fscObtained")}
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F5F5F5",
                borderRadius: "6px",
                paddingRight: "5px",
              }}
            >
              <RegularTextInput
                value={formatBalance(
                  convertRatio * SPAmount * (1 - fee),
                  10000
                )}
                wrapperStyle={{
                  backgroundColor: "transparent",
                  flex: 1,
                  border: "none",
                  padding: "0px",
                }}
                disabled={true}
                inputStyle={{ backgroundColor: "transparent", color: "black" }}
              />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#48535B",
                }}
              >
                FSC
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "5px",
                fontWeight: "500",
                fontSize: "12px",
                color: "#1A2024",
              }}
            >
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "#1A2024",
                }}
              >
                <img
                  src={blueWarning}
                  style={{ width: "16px", height: "16px" }}
                />
                {t("wallet.fscpConvertStatus", {
                  member: starLevel < 7 ? t(memberNames[starLevel]) : "",
                  fee: fee * 100,
                })}
              </span>
              <span>
                <img
                  src={blueWarning}
                  style={{ width: "16px", height: "16px" }}
                />
                {t("wallet.feeRules1")}

                {t("wallet.feeRules2")}
              </span>
              <span>
                <img
                  src={blueWarning}
                  style={{ width: "16px", height: "16px" }}
                />
                {t("wallet.spConversionRule")}
              </span>
            </div>

            <button
              style={{
                backgroundColor: "#0E73F6",
                borderRadius: "100px",
                padding: "11px 0px",
                border: "none",
                marginTop: "20px",
              }}
              onClick={tryConfirm}
            >
              <span
                style={{ color: "white", fontWeight: "600", fontSize: "16px" }}
              >
                {t("wallet.confirmExchange")}
              </span>
            </button>
            <button
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                borderRadius: "100px",
                padding: "11px 0px",
                border: "none",
              }}
            >
              <span
                style={{
                  color: "#0E73F6",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                {t("wallet.cancel")}
              </span>
            </button>
          </div>
        </div>
      )}
      {mode === 1 && (
        <div
          style={{
            position: "relative",
            width: "100%",
            backgroundColor: "white",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "6px",
            padding: "16px",
          }}
        >
          <span
            style={{ fontWeight: "600", fontSize: "18px", color: "#252C32" }}
          >
            {t("wallet.exchangeComplete")}
          </span>
          <span
            style={{ fontWeight: "500", fontSize: "16px", color: "#5B6871" }}
          >
            {t("wallet.exchangeCompleteDesc1")}
            <span
              style={{ fontWeight: "600", fontSize: "14px", color: "#252C32" }}
            >
              {formatBalance(convertRatio * SPAmount * (1 - fee))} FSC
            </span>
            {t("wallet.exchangeCompleteDesc2")}
          </span>
          <button
            style={{
              backgroundColor: "#0E73F6",
              borderRadius: "100px",
              padding: "11px 0px",
              border: "none",
              marginTop: "20px",
              width: "100%",
            }}
            onClick={() => {
              onClose()
              navigate("/currency?type=FSC")
            }}
          >
            <span
              style={{ color: "white", fontWeight: "600", fontSize: "16px" }}
            >
              {t("wallet.goToWallet")}
            </span>
          </button>
          <button
            onClick={onClose}
            style={{
              backgroundColor: "transparent",
              borderRadius: "100px",
              padding: "11px 0px",
              border: "none",
              width: "100%",
            }}
          >
            <span
              style={{
                color: "#0E73F6",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              {t("wallet.understood")}
            </span>
          </button>
        </div>
      )}
    </div>
  )
}
const memberNames = {
  "-1": "card.member-new",
  0: "card.member-0",
  1: "card.member-1",
  2: "card.member-2",
  3: "card.member-3",
  4: "card.member-4",
  5: "card.member-5",
  6: "card.member-6",
}
