import axios from "axios";
// import i18n from "i18next";
// import * as RootNavigator from "../RootNavigation";
import configs from "../configs";
const reLoginMessage = ["session.not.found", "session.timeout"];
const instance = axios.create({
  baseURL: configs.API_BASE_URL,
  timeout: configs.API_TIMEOUT,
});

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.status === 500) {
      // Toast.show({ type: "error", text1: i18n.t("axios.error3") });
    }
    if (reLoginMessage.includes(error.response.data.message)) {
      removeToken();
    }

    if (error.message === `timeout of ${configs.API_TIMEOUT}ms exceeded`) {
      // Toast.show({ type: "error", text1: i18n.t("axios.error1") });
      error.isTimeout = true;
    }
    return Promise.reject(error);
  }
);

export default instance;

export const setToken = async (token) => {
  try {
    instance.defaults.headers.common.sessionid = token;

    localStorage.setItem('sessionid', token);
  } catch (e) {
    console.log(e);
  }
};

export const removeToken = async () => {
  delete instance.defaults.headers.common.authorization;
  localStorage.removeItem('sessionid');
  localStorage.setItem('username', '');
  localStorage.setItem('starLevel', -1);
  localStorage.setItem('isLoggedIn', false);
  localStorage.setItem('avatarUrl', null);
};
