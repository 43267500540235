import { configureStore } from "@reduxjs/toolkit";
import cardReducer from "../pages/Card/model";
import userReducer from "../pages/User/model";
import homePageReducer from "../pages/HomePage/model";
import infoReducer from "../pages/Info/model";
import taskReducer from "../pages/Task/model";
import inviteReducer from "../pages/Invite/model";
import articleReducer from "../pages/Article/model";
import videoReducer from "../pages/Video/model";

export default configureStore({
  reducer: {
    card: cardReducer,
    user: userReducer,
    homePage: homePageReducer,
    info: infoReducer,
    task: taskReducer,
    invite: inviteReducer,
    article: articleReducer,
    video: videoReducer,
  },
});
