import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getTaskProgress } from "../HomePage/service";

const Activity = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const { progress } = useSelector((state) => state.homePage);

  const dispatch = useDispatch();
  useEffect(() => {
    getTaskProgress(dispatch);
  }, []);

  return (
    <center>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "2vh",
          color: "#000",
          fontFamily: "'Work Sans'",
        }}
      >
        <div
          style={{
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#303940",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "1.6vh 2vh",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                fontSize: "2.4vh",
              }}
            >
              {t("activity.title")}
            </b>
          </div>
        </div>
        <div style={{ padding: "6vh 3vh 4vh 3vh" }}>
          <ProgressBar
            now={(progress.total / progress.potentialEarn) * 100}
            label={`${progress.total}`}
          />
        </div>
        <div
          style={{
            alignSelf: "stretch",
            position: "relative",
            borderRadius: "12px",
            backgroundColor: "#0e73f6",
            flexShrink: "0",
            overflow: "hidden",
            display: "flex",
            left: "3vh",
            width: "calc(100% - 6vh)",
            padding: "2vh",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "0px",
              left: "0px",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.37vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.46vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "0.06em",
                    lineHeight: "140%",
                    fontWeight: "500",
                  }}
                >
                  {t("activity.youCanEarn")}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    fontSize: "2.8vh",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "500",
                      display: "inline-block",
                      width: "100%",
                      flexShrink: "0",
                    }}
                  >
                    <b>
                      {progress.potentialEarn} {t("wallet.fscp")}
                    </b>
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "0.06em",
                    lineHeight: "140%",
                    fontWeight: "500",
                  }}
                >
                  {t("activity.yourStarCard")}
                  <button
                    onClick={() => navigation("/card")}
                    style={{
                      border: "0",
                      backgroundColor: "transparent",
                      color: "inherit",
                    }}
                  >
                    <u>{t("activity.viewCard")}</u>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            top: "3vh",
            position: "relative",
            alignSelf: "stretch",
            borderRadius: "12px",
            backgroundColor: "#fff",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: "1.6vh 0px 0px",
            alignItems: "center",
            justifyContent: "flex-start",
            left: "3vh",
            width: "calc(100% - 6vh)",
          }}
        >
          <div
            style={{
              position: "relative",
              lineHeight: "130%",
              fontWeight: "600",
              display: "inline-block",
              width: "100%",
            }}
          >
            {t("activity.calculateionMethod")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "2.8vh",
              color: "#b0babf",
              width: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.2vh",
              }}
            >
              <b
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "4vh",
                  flexShrink: "0",
                }}
              >
                01
              </b>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "1.65vh",
                  color: "#48535b",
                }}
              >
                {t("activity.method1")}
              </div>
            </div>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.2vh",
              }}
            >
              <b
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "4vh",
                  flexShrink: "0",
                }}
              >
                02
              </b>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "1.65vh",
                  color: "#48535b",
                }}
              >
                {t("activity.method2")}
              </div>
            </div>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.2vh",
              }}
            >
              <b
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "4vh",
                  flexShrink: "0",
                }}
              >
                03
              </b>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "1.65vh",
                  color: "#48535b",
                }}
              >
                {t("activity.method3")}
              </div>
            </div>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #eef0f2",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "20px 12px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1.2vh",
              }}
            >
              <b
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "4vh",
                  flexShrink: "0",
                }}
              >
                04
              </b>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  fontSize: "1.65vh",
                  color: "#48535b",
                }}
              >
                {t("activity.method4")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Activity;
