const IdUpload3 = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "779px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "18px",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              实名认证
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "35px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "#fff",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#9bcffd",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              Next
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            textAlign: "left",
            fontSize: "14px",
            color: "#f2271c",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
              color: "#303940",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "0px 11px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆-灰.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                基本信息
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传证件
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                提交认证
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            请确保使用的是真实身份，否则认证将无法通过。您提交的信息仅用于Future
            Star认证使用，我们将会对您的信息进行加密处理。
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                名字
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder="Input First Name"
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                姓氏
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                  placeholder="Input Last Name"
                ></input>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                出生日期
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  color: "#9aa6ac",
                }}
              >
                <input
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    display: "inline-block",
                    width: "303px",
                    flexShrink: "0",
                    border: "0",
                    backgroundColor: "transparent",
                  }}
                  placeholder="YYYY-MM-DD"
                ></input>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
            }}
          >
            *信息需与证件上的信息一致
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "375px",
            height: "854px",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "193px",
            left: "0px",
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px -4px 8px rgba(186, 186, 186, 0.24)",
            width: "375px",
            height: "586px",
            overflow: "hidden",
            color: "#1a2024",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "600",
                left: "120px",
              }}
            >
              上传证件照
            </div>
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/关闭.svg"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "54px",
              left: "0px",
              backgroundColor: "#4094f7",
              width: "375px",
              height: "233px",
              overflow: "hidden",
              textAlign: "left",
              fontSize: "14px",
              color: "#fff",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "375px",
                height: "233px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "417px",
                  height: "290px",
                  objectFit: "cover",
                }}
                alt=""
                src="/登陆领取背景图.png"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "28px",
                left: "35px",
                borderRadius: "16.14px",
                backgroundColor: "#fff",
                width: "305px",
                height: "176.71px",
                overflow: "hidden",
                fontSize: "10.06px",
                color: "#87929b",
                fontFamily: "'Yuppy SC'",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "86px",
                  left: "92px",
                  width: "120px",
                  height: "65px",
                  overflow: "hidden",
                }}
                alt=""
                src="/灰实心半圆.svg"
              />
              <img
                style={{
                  position: "absolute",
                  top: "1px",
                  left: "108px",
                  width: "88px",
                  height: "88px",
                  overflow: "hidden",
                }}
                alt=""
                src="/证件照头像-无框.svg"
              />
              <div
                style={{
                  position: "absolute",
                  top: "99px",
                  left: "38px",
                  width: "229px",
                  height: "63.62px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "9px",
                    borderRadius: "5.81px",
                    backgroundColor: "#efefef",
                    width: "109.81px",
                    height: "63.62px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "10.46px",
                      left: "6.68px",
                      width: "49.09px",
                      height: "42.7px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#9ba7b2",
                          width: "12.49px",
                          height: "3.49px",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "19.46px",
                          backgroundColor: "#c4cad0",
                          width: "16.56px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "7.84px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#9ba7b2",
                          width: "12.49px",
                          height: "3.49px",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "19.46px",
                          backgroundColor: "#c4cad0",
                          width: "38.06px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "23.53px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#9ba7b2",
                          width: "12.49px",
                          height: "3.49px",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "19.46px",
                          backgroundColor: "#c4cad0",
                          width: "26.14px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "15.69px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#9ba7b2",
                          width: "12.49px",
                          height: "3.49px",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "19.46px",
                          backgroundColor: "#c4cad0",
                          width: "38.06px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "31.37px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#9ba7b2",
                          width: "12.49px",
                          height: "3.49px",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "19.46px",
                          backgroundColor: "#c4cad0",
                          width: "32.83px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "39.22px",
                        left: "0px",
                        width: "49.09px",
                        height: "3.49px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          backgroundColor: "#c4cad0",
                          width: "57.52px",
                          height: "3.49px",
                        }}
                      />
                    </div>
                  </div>
                  <img
                    style={{
                      position: "absolute",
                      top: "17.72px",
                      left: "73.5px",
                      width: "28.47px",
                      height: "28.47px",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/证件照缩略头像.svg"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "119.19px",
                    borderRadius: "5.81px",
                    backgroundColor: "#efefef",
                    width: "109.81px",
                    height: "63.62px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "11.74px",
                      left: "9.22px",
                      letterSpacing: "-0.01em",
                      lineHeight: "120%",
                    }}
                  >
                    Future Star
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "26.82px",
                      left: "9.22px",
                      letterSpacing: "-0.01em",
                      lineHeight: "120%",
                    }}
                  >
                    John Doe
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "41.91px",
                      left: "9.22px",
                      letterSpacing: "-0.01em",
                      lineHeight: "120%",
                    }}
                  >
                    20xx xx xx
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "23px",
                      left: "117.81px",
                      width: "12px",
                      height: "20.5px",
                      transform: " rotate(180deg)",
                      transformOrigin: "0 0",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "-1px",
                        borderRadius: "100px",
                        backgroundColor: "#9ba7b2",
                        width: "11px",
                        height: "4px",
                        transform: " rotate(180deg)",
                        transformOrigin: "0 0",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "5.5px",
                        left: "0px",
                        borderRadius: "100px",
                        backgroundColor: "#9ba7b2",
                        width: "11px",
                        height: "4px",
                        transform: " rotate(180deg)",
                        transformOrigin: "0 0",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "11px",
                        left: "-1px",
                        borderRadius: "100px",
                        backgroundColor: "#9ba7b2",
                        width: "11px",
                        height: "4px",
                        transform: " rotate(180deg)",
                        transformOrigin: "0 0",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "16.5px",
                        left: "-4px",
                        borderRadius: "100px",
                        backgroundColor: "#9ba7b2",
                        width: "8px",
                        height: "4px",
                        transform: " rotate(180deg)",
                        transformOrigin: "0 0",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "19px",
                    left: "0px",
                    width: "12px",
                    height: "20.5px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "1px",
                      borderRadius: "100px",
                      backgroundColor: "#9ba7b2",
                      width: "11px",
                      height: "4px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "5.5px",
                      left: "0px",
                      borderRadius: "100px",
                      backgroundColor: "#9ba7b2",
                      width: "11px",
                      height: "4px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "11px",
                      left: "1px",
                      borderRadius: "100px",
                      backgroundColor: "#9ba7b2",
                      width: "11px",
                      height: "4px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "16.5px",
                      left: "4px",
                      borderRadius: "100px",
                      backgroundColor: "#9ba7b2",
                      width: "8px",
                      height: "4px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "309px",
              left: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              textAlign: "left",
              fontSize: "18px",
              color: "#303940",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                width: "351px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传个人签字和证件正面照片
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontSize: "24px",
                  color: "#0e73f6",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }}
              >
                <span>3</span>
                <span style={{ fontSize: "18px", color: "#303940" }}>/3</span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                fontSize: "14px",
                lineHeight: "130%",
                fontFamily: "Inter",
                color: "#1a2024",
                display: "inline-block",
                width: "351px",
              }}
            >
              请上传一张本人持有“证件正面”和“个人签字”的照片，个人签字内容需要含
              “Future Star”和当日日期，请确保照片内容清晰可见。
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "31px",
              left: "calc(50% - 175.5px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#0e73f6",
            }}
          >
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#ebf7ff",
                width: "167px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "none",
                }}
                alt=""
                src="/group-add-fill0-wght400-grad0-opsz48-1-21.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                上一步
              </div>
            </div>
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                width: "167px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                color: "#fff",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "none",
                }}
                alt=""
                src="/group-add-fill0-wght400-grad0-opsz48-1-22.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                知道了
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdUpload3;
