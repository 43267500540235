export default {
  release: "dev",

  API_BASE_URL: "http://18.204.14.82",
  API_TIMEOUT: 6000,

  AWS_USER_POOL_ID: "us-east-1_HVxcwMCyv",
  AWS_CLIENT_ID: "752jf7c1osqpus56ock0e7hjmf",

  ARTICLE_READ_KEY: "FINTELICS_SECRET",
  SPECIAL_INVITATION_CODE: "",
  APP_VERSION: 24,
}
