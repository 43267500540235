// expire param is in second timestemp
export function setCookie(key, value, expire) {
  const expiredate = new Date(expire).toUTCString();
  window.document.cookie =
    key + "=" + value + ";path=/; " + "expires=" + expiredate + ";";
}

export function getCookie(key) {
  var result = "";
  if (document.cookie.length > 0) {
    var arr = document.cookie.split("; ");
    for (var i = 0; i < arr.length; i++) {
      var arr2 = arr[i].split("=");
      if (arr2[0] === key) {
        result = arr2[1];
      }
    }
  }
  return result;
}

export function userLogedIn() {
  const result = getCookie("sessionId");
  if (result) {
    return true;
  }
  return false;
}
export function eraseCookie() {
  window.document.cookie = "sessionId=" + "; path=/" + "; max-age=0";
}
