const DownloadApp = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#527efd",
          width: "375px",
          height: "680px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "30px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "46px",
            left: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                letterSpacing: "0.02em",
                fontWeight: "800",
                display: "inline-block",
                width: "344px",
              }}
            >
              Download Future Star to start your earning
            </div>
            <div
              style={{
                position: "relative",
                fontSize: "16px",
                fontWeight: "500",
                display: "inline-block",
                width: "301px",
              }}
            >
              Download and log in to the Future Star App to see your rewards!
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "535px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
            fontSize: "16px",
            color: "#0e73f6",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "1000px",
              backgroundColor: "#fff",
              border: "1px solid #fff",
              display: "flex",
              flexDirection: "row",
              padding: "12px 16px",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
              }}
              alt=""
              src="/苹果.svg"
            />
            <div
              style={{ position: "relative", fontWeight: "500" }}
            >{`Download For Iphone `}</div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "1000px",
              backgroundColor: "#fff",
              border: "1px solid #fff",
              display: "flex",
              flexDirection: "row",
              padding: "12px 16px",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/安卓.svg"
            />
            <div style={{ position: "relative", fontWeight: "500" }}>
              Download For Android
            </div>
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            top: "117px",
            left: "0px",
            width: "416px",
            height: "409px",
            objectFit: "cover",
          }}
          alt=""
          src="/下载app背景图.png"
        />
      </div>
    </center>
  );
};

export default DownloadApp;
