import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultLanguage } from "../../locale";
import { ALL_LANGUAGE } from "../../locale";

import Menu from "../../components/Menu";

const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#f5f5f5",
        width: "100%",
        height: "100vh",
        overflow: "auto",
        textAlign: "center",
        fontSize: "2vh",
        color: "#fff",
        fontFamily: "Inter",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          borderBottom: "1px solid #eef0f2",
          boxSizing: "border-box",
          width: "100%",
          height: "5.8vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          fontSize: "2vh",
          lineHeight: "130%",
          fontWeight: "600",
          color: "#48535b",
        }}
      >
        {t("profile.changeLanguage")}
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1.2vh",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "2vh",
            color: "#1a2024",
            width: "100%",
            padding: "2vh 2.7vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "2.4vh",
              textAlign: "left",
              fontSize: "1.8vh",
              color: "#3c464e",
            }}
          >
            {Object.keys(ALL_LANGUAGE).map((lang) => {
              return (
                <button
                  onClick={() => {
                    navigation("/profile");
                    localStorage.setItem("lang", lang);
                    window.location.reload();
                  }}
                  style={{
                    border: "0",
                    backgroundColor: "transparent",
                    color: "inherit",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.8vh",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          letterSpacing: "0.02em",
                          fontSize: "1.65vh",
                          textTransform: "uppercase",
                          fontWeight: "500",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        {ALL_LANGUAGE[lang]}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          lang === getDefaultLanguage() ? "flex" : "none",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{
                          position: "relative",
                          height: "100%",
                          flexShrink: "0",
                          overflow: "hidden",
                        }}
                        alt=""
                        src="/对号-绿.svg"
                      />
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeLanguage;
