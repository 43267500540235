const EarnedPopup = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "854px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "12px",
          color: "#48535b",
          fontFamily: "Inter",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "12px 20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "26.5px",
              height: "33.86px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../奖牌-金.svg"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <div
              style={{
                position: "relative",
                textDecoration: "underline",
                lineHeight: "130%",
              }}
            >
              升级星卡获得更高奖励
            </div>
            <img
              style={{
                position: "relative",
                width: "16px",
                height: "16px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../右箭头.svg"
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "20px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
              display: "flex",
              flexDirection: "row",
              padding: "20px 16px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "228px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                活跃度任务
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "130%",
                }}
              >
                <span>达成100活跃度领取</span>
                <b>900 SP</b>
                <span>≈9 USDT</span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                width: "56px",
                height: "56px",
                flexShrink: "0",
                fontSize: "12px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "-3.5px",
                  left: "-3.5px",
                  width: "63px",
                  height: "63px",
                }}
                alt=""
                src="../蓝色进度条.svg"
              />
              <img
                style={{
                  position: "absolute",
                  top: "-3.5px",
                  left: "-3.5px",
                  width: "63px",
                  height: "63px",
                }}
                alt=""
                src="../front.svg"
              />
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "8px",
                  fontWeight: "900",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40.28px",
                  height: "16.7px",
                }}
              >
                78.6
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "linear-gradient(266.42deg, #03d4aa, #0ade6e)",
              display: "flex",
              flexDirection: "row",
              padding: "20px 16px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "228px",
                flexShrink: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                健步任务
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "130%",
                }}
              >
                <span>完成4000步领取</span>
                <b>900 SP</b>
                <span>≈9 USDT</span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                width: "56px",
                height: "56px",
                flexShrink: "0",
                fontSize: "12px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "-3.5px",
                  left: "-3.5px",
                  width: "63px",
                  height: "63px",
                }}
                alt=""
                src="../绿色进度条.svg"
              />
              <img
                style={{
                  position: "absolute",
                  top: "-3.5px",
                  left: "-3.5px",
                  width: "63px",
                  height: "63px",
                }}
                alt=""
                src="../front.svg"
              />
              <div
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "8px",
                  fontWeight: "900",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "28px",
                }}
              >
                3209步
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              padding: "16px 8px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              fontSize: "12px",
              color: "#303940",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 2px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "48px",
                  height: "48px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../每日签到.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                每日签到
              </div>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 2px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "48px",
                  height: "48px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../每日头条.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                每日头条
              </div>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 2px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "48px",
                  height: "48px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../幸运转盘.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                幸运转盘
              </div>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 2px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "10000px",
                  width: "48px",
                  height: "48px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../星星彩票.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                星星彩票
              </div>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                padding: "0px 2px",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  borderRadius: "1000000px",
                  width: "48px",
                  height: "48px",
                  flexShrink: "0",
                  overflow: "hidden",
                }}
                alt=""
                src="../星星宠物.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "150%",
                  fontWeight: "600",
                }}
              >
                星星宠物
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "12px",
              fontSize: "14px",
              color: "#1a2024",
            }}
          >
            <div
              style={{
                position: "relative",
                fontSize: "16px",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              为你推荐
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                borderRadius: "12px",
                backgroundColor: "#fff",
                height: "95.28px",
                flexShrink: "0",
                overflow: "hidden",
                textAlign: "left",
                fontSize: "18px",
                color: "#fff",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "12px",
                  width: "351px",
                  height: "95px",
                  objectFit: "cover",
                }}
                alt=""
                src="../邀好友送现金背景图.png"
              />
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "155px",
                  width: "190px",
                  height: "95px",
                  objectFit: "cover",
                }}
                alt=""
                src="../礼盒.png"
              />
              <div
                style={{
                  position: "relative",
                  top: "26px",
                  left: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "0.21em",
                    lineHeight: "16px",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    display: "inline-block",
                    width: "221px",
                  }}
                >
                  邀好友 送现金
                </div>
                <div
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row",
                    padding: "2px 8px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "12px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "0.02em",
                      lineHeight: "16px",
                      textTransform: "uppercase",
                      fontWeight: "500",
                    }}
                  >
                    邀请新用户必得usdt
                  </div>
                </div>
              </div>
              <img
                style={{
                  position: "absolute",
                  top: "49px",
                  left: "292px",
                  width: "43px",
                  height: "40px",
                  objectFit: "cover",
                }}
                alt=""
                src="../绿色钱币.png"
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(68.87deg, #cce1ff, #b0c5ff)",
                  height: "93px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "9px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    看资讯，赚星币
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "130%",
                    }}
                  >
                    快速了解世界大事
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "102px",
                    width: "58px",
                    height: "103px",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      top: "0%",
                      right: "-12.07%",
                      bottom: "0%",
                      left: "12.07%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../看资讯赚星币.png"
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(248.34deg, #ffd689, #ffc03e)",
                  height: "93px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    top: "30px",
                    left: "12px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    健步赚星币
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "130%",
                    }}
                  >
                    运动永不止步
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "2px",
                    left: "74.5px",
                    width: "91px",
                    height: "91px",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      top: "0%",
                      right: "-7.69%",
                      bottom: "0%",
                      left: "7.69%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../健步赚星币.png"
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(248.16deg, #ffaea9, #ffdad8)",
                  height: "93px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    top: "30px",
                    left: "12px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    看广告赚星币
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "130%",
                    }}
                  >
                    你爱看的都在这
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "18px",
                    left: "86px",
                    width: "76px",
                    height: "76px",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      top: "0%",
                      right: "-10.53%",
                      bottom: "0%",
                      left: "10.53%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../看广告赚星币.png"
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  borderRadius: "12px",
                  background: "linear-gradient(249deg, #c891ff, #e7ceff)",
                  height: "93px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "3px",
                    left: "72.5px",
                    width: "92px",
                    height: "92px",
                    opacity: "0.54",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      top: "0%",
                      right: "-9.78%",
                      bottom: "0%",
                      left: "9.78%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                    src="../星星宠物赚星币.png"
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "30px",
                    left: "12px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "600",
                    }}
                  >
                    星星宠物
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "12px",
                      lineHeight: "130%",
                    }}
                  >
                    领养你的NFT宠物
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "50px",
            left: "0px",
            backgroundColor: "#fff",
            borderTop: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "10px 12px",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            fontSize: "10px",
            color: "#9aa6ac",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
              color: "#0e73f6",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../首页-蓝.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>首页</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../任务.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>任务</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../星卡.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>星卡</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../头条.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>头条</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../我的.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "120%",
                fontWeight: "600",
              }}
            >
              我的
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "-802px",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "375px",
            height: "854px",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "326px",
            left: "20px",
            borderRadius: "6px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
            width: "335px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            color: "#252c32",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    lineHeight: "140%",
                    fontWeight: "600",
                  }}
                >
                  Congrats! You had earned!
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "20px",
                  color: "#5b6871",
                }}
              >
                You had reached 100 activity points, your earning is:
              </div>
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "36px",
                letterSpacing: "-0.02em",
                lineHeight: "48px",
                color: "#0e73f6",
              }}
            >
              2,000 SP
            </b>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "12px",
                fontSize: "14px",
                color: "#f6f8f9",
              }}
            >
              <div
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0e73f6",
                  width: "303px",
                  height: "39px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Got It
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "12px",
                  letterSpacing: "-0.01em",
                  lineHeight: "20px",
                  color: "#5b6871",
                }}
              >
                <span>{`Your earning will be sent to your wallet within `}</span>
                <span style={{ fontWeight: "600" }}>10 mins</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default EarnedPopup;
