import request from '../../utlis/request';
import { notification } from 'antd';
import axios from 'axios';

export const getFeed = async (dispatch, { page, size, keyword, language }) => {
  try {
    const URL = '/api/feed?' +
      (page ? `page=${page}&` : '') +
      (size ? `size=${size}&` : '') +
      (keyword ? `keyword=${encodeURI(keyword)}&` : '') +
      (language ? `language=${language}` : '');

    const { data } = await request.get(URL);

    dispatch({
      type: 'info/PUT_ARTICLES',
      payload: data.articles,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getBanner = async (dispatch, { language }) => {
  try {
    const URL = '/api/banner?' + (language ? `language=${language}` : '');

    const { data } = await request.get(URL);

    dispatch({
      type: 'info/PUT_BANNERS',
      payload: data,
    });
  } catch (e) {

  }
};

export const getSelected = async (dispatch, { page, size, keyword, language }) => {
  try {
    const URL = '/api/feed/select?' +
      (page ? `page=${page}&` : '') +
      (size ? `size=${size}&` : '') +
      (keyword ? `keyword=${encodeURI(keyword)}&` : '') +
      (language ? `language=${language}` : '');

    const { data } = await request.get(URL);

    dispatch({
      type: 'info/PUT_SELECT_ARTICLES',
      payload: data.articles,
    });
  } catch (e) {

  }
};

export const getSystemNotice = async language => {
  const dbLanguageMapping = {
    en: 'English',
    zhch: 'Chinese',
    es: 'Spanish',
    pt: 'Portuguese',
  };
  return await axios.get(`/api/notice?language=${dbLanguageMapping[language]}`);
};
