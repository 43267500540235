import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getBeginnerTasks,
  getDailySignIn,
  getSummary,
  todaySignIn,
  getArticle,
} from "./service";
import { getDefaultLanguage } from "../../locale";
import { beginnerTaskLabels } from "../../utlis/tasks";

import Menu from "../../components/Menu";

const Tasks = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  const { isLoggedIn, starLevel } = useSelector((state) => state.user);
  const { summary, dailySignIn, beginnerTasks } = useSelector(
    (state) => state.task
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getSummary(dispatch);
    getDailySignIn(dispatch);
    getBeginnerTasks(dispatch);
  }, []);
  const [signinSuccess, setSigninSuccess] = useState(false);
  const [cardRuleTooltip, setCardRuleTooltip] = useState(false);
  const [dailyRuleTooltip, setDailyRuleTooltip] = useState(false);

  const actions = {
    view_usermenu: () => {
      const article = beginnerTasks.articleId.find(
        (a) => a.language === getDefaultLanguage()
      );
      navigation(`/article?id=${article.id}`);
    },
    watch_intro_video: () => {
      const video = beginnerTasks.videoId.find(
        (a) => a.language === getDefaultLanguage()
      );
      navigation(`/video?id=${video.id}`);
    },
    finish_verify: () => {
      navigation("/verifyAccount");
    },
    first_get_card: () => {
      navigation("/card");
    },
    first_finish_daily_task: () => {
      navigation("/activity");
    },
    first_finish_step_task: () => {
      navigation("/step");
    },
    first_invite_user: () => {
      navigation("/invite");
    },
    first_convert_FSC_to_USDT: () => {
      navigation("/convert");
    },
    first_withdraw_USDT: () => {
      navigation("/withdraw/usdt");
    },
    first_deposit: () => {
      navigation("/selectcrypto/topup");
    },
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#f5f5f5",
        width: "100%",
        height: "100vh",
        overflow: "auto",
        textAlign: "center",
        fontSize: "2vh",
        color: "#fff",
        fontFamily: "Inter",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          borderBottom: "1px solid #eef0f2",
          boxSizing: "border-box",
          width: "100%",
          height: "5.8vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          fontSize: "2vh",
          lineHeight: "130%",
          fontWeight: "600",
          color: "#48535b",
        }}
      >
        {t("tasks.myTasks")}
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1.2vh",
          padding: "1.3vh 1.3vh",
        }}
      >
        <div
          style={{
            position: "relative",
            borderRadius: "12px",
            backgroundColor: "#4094f7",
            width: "100%",
            height: "11.9vh",
            flexShrink: "0",
            overflow: "hidden",
            textAlign: "left",
            fontSize: "1.65vh",
            alignItems: "center",
            justifyContent: "cemter",
            display: starLevel >= 0 ? "flex" : "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              borderRadius: "12px",
              backgroundColor: "#0e73f6",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt=""
              src="../登陆领取背景图.png"
            />
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              fontSize: "1.4vh",
              padding: "3vh 3vh",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "4.8vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.8vh",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    lineHeight: "140%",
                    fontWeight: "500",
                    display: "inline-block",
                    width: "315px",
                  }}
                >
                  {t("tasks.totalCurrentIncome")}
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.02em",
                  lineHeight: "17px",
                  fontWeight: "500",
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                  width: "151.5px",
                  flexShrink: "0",
                  fontSize: "3vh",
                }}
              >
                <span style={{ lineBreak: "anywhere", width: "100%" }}>
                  <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                    <span>{summary.taskIncome + summary.stepIncome}</span>
                    <span style={{ fontSize: "1.8vh" }}>
                      {" "}
                      {t("wallet.fscp")}
                    </span>
                  </p>
                  <p style={{ margin: "0", fontSize: "1.4vh" }}>
                    <span>
                      ≈{" "}
                      {Math.trunc(
                        100 *
                          (summary.taskIncome + summary.stepIncome) *
                          summary.SPtoUSDTRatio
                      ) / 100}{" "}
                      {t("wallet.usdt")}
                    </span>
                  </p>
                </span>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "6vh",
                left: "3vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  lineHeight: "140%",
                  fontWeight: "500",
                }}
              >
                {t("tasks.totalEarning", { FSC: summary.potentialEarn })}
              </div>
              <button
                onClick={() => {
                  setCardRuleTooltip(!cardRuleTooltip);
                }}
                style={{ border: "0", background: "none" }}
                display="flex"
                flexDirection="column"
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.6vh",
                    height: "1.6vh",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../info-fill0-wght400-grad0-opsz48-12.svg"
                />
                <div style={{ display: cardRuleTooltip ? "flex" : "none" }}>
                  <img
                    style={{
                      position: "relative",
                      top: "0vh",
                      left: "0.3vh",
                      height: "0.6vh",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../上尖角.svg"
                  />
                </div>
              </button>
            </div>

            <button
              onClick={() => {
                navigation("/card");
              }}
              style={{
                position: "absolute",
                bottom: "1vh",
                right: "2vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                textAlign: "center",
                border: "0",
                backgroundColor: "transparent",
                color: "white",
              }}
            >
              <div
                style={{
                  position: "relative",
                  textDecoration: "underline",
                  lineHeight: "130%",
                }}
              >
                {t("tasks.upgradeStar")}
                <img
                  style={{
                    position: "relative",
                    width: "1.6vh",
                    height: "1.6vh",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../白色右箭头.svg"
                />
              </div>
            </button>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            display: starLevel >= 0 ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.8vh",
            height: "11vh",
            width: "100%",
            gap: "0 2vh",
          }}
        >
          <button
            onClick={() => {
              navigation("/activity");
            }}
            style={{
              border: "0",
              padding: "0",
              flex: "1",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              backdropFilter: "blur(4px)",
              height: "100%",
              overflow: "hidden",
              alignItems: "center",
              color: "inherit",
            }}
          >
            <img
              style={{
                position: "relative",

                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt=""
              src="../活跃度任务收益背景图.png"
            />
            <div
              style={{
                position: "absolute",
                top: "2.3vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "140%",
                  fontWeight: "500",
                }}
              >
                {t("tasks.activityTaskIncome")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "32px",
                  fontWeight: "500",
                  fontSize: "2.6vh",
                }}
              >
                <span>{summary.taskIncome}</span>
                <span style={{ fontSize: "1.8vh" }}> {t("wallet.fscp")}</span>
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              navigation("/step");
            }}
            style={{
              border: "0",
              padding: "0",
              color: "inherit",
              flex: "1",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              backdropFilter: "blur(4px)",
              height: "100%",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <img
              style={{
                position: "relative",

                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt=""
              src="../活跃度任务收益背景图.png"
            />
            <div
              style={{
                position: "absolute",
                top: "2.3vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "140%",
                  fontWeight: "500",
                }}
              >
                {t("tasks.walkToEarnIncome")}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  lineHeight: "32px",
                  fontWeight: "500",
                  fontSize: "2.6vh",
                }}
              >
                <span>{summary.stepIncome}</span>
                <span style={{ fontSize: "1.8vh" }}> {t("wallet.fscp")}</span>
              </div>
            </div>
          </button>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            position: "relative",
            borderRadius: "12px",
            backgroundColor: "#4094f7",
            height: "14.06vh",
            flexShrink: "0",
            overflow: "hidden",
            display: starLevel < 0 ? "flex" : "none",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "0px",
              left: "0px",
              borderRadius: "12px",
              backgroundColor: "#0e73f6",
              boxShadow:
                "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt=""
              src="登陆领取背景图.png"
            />
            <div
              style={{
                position: "relative",
                top: "2.17vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1.37vh",
                paddingBottom: "2.17vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.46vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "0.06em",
                    lineHeight: "140%",
                    fontWeight: "600",
                  }}
                >
                  {t("tasks.getStarCard")}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    fontSize: "1.4vh",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "500",
                      display: "inline-block",
                      width: "100%",
                      flexShrink: "0",
                    }}
                  >
                    {t("tasks.getStarCardDesc")}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigation("/card");
                }}
                style={{
                  width: "23.31vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: "1.65vh",
                  color: "#0e73f6",
                  borderRadius: "100px",
                  border: "0",
                  backgroundColor: "#FFF",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "100px",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.46vh 2.4vw",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                    }}
                  >
                    {t("tasks.getItNow")}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "12px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            padding: "1.2vh 2.7vw",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "2vh 2.7vw",
            color: "#1a2024",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              height: "2.3vh",
              flexShrink: "0",
              textAlign: "left",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "1.6vh",
                left: "0px",
                borderRadius: "100px",
                backgroundColor: "#97ceff",
                width: "3.6vh",
                height: "1vh",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                lineHeight: "130%",
                fontWeight: "600",
                display: "inline-block",
              }}
            >
              {t("tasks.dailyCheckin")}
            </div>
            <div
              style={{
                position: "absolute",
                top: "0.4vh",
                right: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "2px",
                textAlign: "center",
                fontSize: "1.4vh",
                color: "#48535b",
                border: "0",
                backgroundColor: "transparent",
              }}
            >
              <button
                onClick={() => {
                  setDailyRuleTooltip(!dailyRuleTooltip);
                }}
                style={{ border: "0", background: "none" }}
                display="flex"
                flexDirection="column"
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.6vh",
                    height: "1.6vh",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../info-fill0-wght400-grad0-opsz48-17.svg"
                />
                <div style={{ display: dailyRuleTooltip ? "flex" : "none" }}>
                  <img
                    style={{
                      position: "relative",
                      top: "0vh",
                      left: "0.3vh",
                      height: "0.6vh",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../上尖角.svg"
                  />
                </div>
              </button>
              <div style={{ position: "relative", lineHeight: "130%" }}>
                {t("tasks.checkInRules")}
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.2vh",
              fontSize: "1.2vh",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2.7vw",
              }}
            >
              {dailySignIn.ATTEND_INCOME.map((d, i) => {
                return (
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "0.2vh",
                      color:
                        dailySignIn.attends.length >= i + 1
                          ? "#fff"
                          : "#1a2024",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        borderRadius: "8px",
                        backgroundColor:
                          dailySignIn.attends.length >= i + 1
                            ? "#1573ff"
                            : "#eaeaea",
                        height: "5.8vh",
                        flexShrink: "0",
                        display: "flex",
                        flexDirection: "column",
                        padding: "1vh 0.4vh 0.9vh",
                        boxSizing: "border-box",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "2px",
                      }}
                    >
                      <img
                        style={{
                          position: "relative",
                          borderRadius: "10000px",
                          width: "2vh",
                          height: "2vh",
                          flexShrink: "0",
                          overflow: "hidden",
                        }}
                        alt=""
                        src={
                          dailySignIn.attends.length >= i + 1
                            ? "../已签到.svg"
                            : "../未来签到.svg"
                        }
                      />
                      <b
                        style={{
                          position: "relative",
                          lineHeight: "130%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "1.5vh",
                          flexShrink: "0",
                        }}
                      >
                        +{d}
                      </b>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        lineHeight: "150%",
                        fontWeight: "500",
                        color: "#303940",
                      }}
                    >
                      {t(`tasks.day${i + 1}`)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                fontSize: "1.4vh",
                color: "#303940",
              }}
            >
              <div style={{ position: "relative", lineHeight: "150%" }}>
                <span style={{ fontWeight: "500" }}>
                  {t("tasks.consecutiveDays1")}
                </span>
                <b style={{ color: "#ff5d15" }}>{dailySignIn.continuousDays}</b>
                <span style={{ fontWeight: "500" }}>
                  {t("tasks.consecutiveDays2", {
                    plural: dailySignIn.continuousDays > 1 ? "s" : "",
                  })}
                </span>
              </div>
              <div style={{ position: "relative", lineHeight: "150%" }}>
                <span style={{ fontWeight: "500" }}>
                  {t("tasks.cumulativeEarning")}
                </span>
                <b style={{ color: "#ff5d15" }}>{dailySignIn.total}</b>
                <span style={{ fontWeight: "500" }}> {t("wallet.fscp")}</span>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              todaySignIn(dispatch);
              setSigninSuccess(true);
              getDailySignIn(dispatch);
            }}
            style={{
              border: "0",
              borderRadius: "100px",
              background: "linear-gradient(-90deg, #1472ff, #53b5ff)",
              width: "70%",
              height: "4vh",
              flexShrink: "0",
              overflow: "hidden",
              display: dailySignIn.checkedInToday ? "none" : "flex",
              flexDirection: "column",
              padding: "0.4vh 0.4vh",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.65vh",
              color: "#fff",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "2.4vh",
                  fontWeight: "600",
                }}
              >
                {t("tasks.signInNow")}
              </div>
            </div>
          </button>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "12px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            justifyContent: "flex-start",
            gap: "2vh",
            color: "#1a2024",
            width: "100%",
            padding: "2vh 2.7vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "23px",
                flexShrink: "0",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "1.6vh",
                  left: "0px",
                  borderRadius: "100px",
                  backgroundColor: "#97ceff",
                  width: "3.6vh",
                  height: "1vh",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                {t("tasks.beginnerTask")}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                fontSize: "13px",
                letterSpacing: "0.02em",
                lineHeight: "16px",
                fontWeight: "600",
                color: "#0e73f6",
                textAlign: "right",
                display: "flex",
                flexShrink: "0",
                right: "4vh",
              }}
            >
              {t("tasks.earn", {
                FSC: beginnerTasks.remaining,
              })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "2.4vh",
              textAlign: "left",
              fontSize: "1.8vh",
              color: "#3c464e",
            }}
          >
            {beginnerTasks.tasks.map((task, i) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "0.8vh",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          letterSpacing: "0.02em",
                          lineHeight: "16px",
                          textTransform: "uppercase",
                          fontWeight: "500",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        {t(beginnerTaskLabels[task.type])}
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          gap: "0.8vh",
                          fontSize: "1.65vh",
                          color: "#0e73f6",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            letterSpacing: "0.02em",
                            lineHeight: "16px",
                            textTransform: "uppercase",
                          }}
                        >
                          {t("tasks.reward")}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <img
                            style={{
                              position: "relative",
                              borderRadius: "10000px",
                              width: "1.7vh",
                              height: "1.7vh",
                              flexShrink: "0",
                              overflow: "hidden",
                            }}
                            alt=""
                            src="../奖励.svg"
                          />
                          <div
                            style={{
                              position: "relative",
                              letterSpacing: "0.02em",
                              lineHeight: "16px",
                              textTransform: "uppercase",
                              fontWeight: "600",
                            }}
                          >
                            {task.bonus} {t("wallet.fscp")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {task.finished ? (
                      <div
                        style={{
                          borderRadius: "100px",
                          backgroundColor: "#fff",
                          border: "1px solid #0e73f6",
                          color: "#0e73f6",

                          display: "flex",
                          flexDirection: "row",
                          padding: "0.8vh 1.6vh",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "right",
                          fontSize: "1.4vh",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            letterSpacing: "0.02em",
                            lineHeight: "16px",
                            textTransform: "uppercase",
                            fontWeight: "500",
                          }}
                        >
                          {t("tasks.completed")}
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={actions[task.type]}
                        disabled={task.finished}
                        style={{
                          borderRadius: "100px",
                          background:
                            "linear-gradient(-90deg, #1472ff, #53b5ff)",
                          color: "#fff",
                          border: "0",
                          display: "flex",
                          flexDirection: "row",
                          padding: "0.8vh 1.6vh",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          textAlign: "right",
                          fontSize: "1.4vh",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            letterSpacing: "0.02em",
                            lineHeight: "16px",
                            textTransform: "uppercase",
                            fontWeight: "500",
                          }}
                        >
                          {t("tasks.toFinish")}
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button
          onClick={() => {
            navigation("/invite");
          }}
          style={{
            border: "0",
            alignSelf: "stretch",
            position: "relative",
            borderRadius: "12px",
            backgroundColor: "#fff",
            height: "11vh",
            flexShrink: "0",
            overflow: "hidden",
            textAlign: "left",
            fontSize: "2.6vh",
            color: "#fff",
            marginBottom: 80,
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              borderRadius: "12px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
            src="../邀好友送现金背景图.png"
          />
          <img
            style={{
              position: "absolute",
              top: "0px",
              right: "4.2vw",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
            src="../礼盒.png"
          />
          <div
            style={{
              position: "relative",
              left: "2.2vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "1vh",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "relative",
                letterSpacing: "0.21em",
                lineHeight: "16px",
                textTransform: "uppercase",
                fontWeight: "600",
                display: "inline-block",
                fontSize: "2.2vh",
              }}
            >
              {t("homeV1.inviteFriends")}
            </div>
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "row",
                padding: "0.43vh 0.92vh",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                color: "#0e73f6",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "0.02em",
                  lineHeight: "16px",
                  textTransform: "uppercase",
                  fontWeight: "300",
                  fontSize: "1.4vh",
                }}
              >
                {t("homeV1.inviteFriendsDesc")}
              </div>
            </div>
          </div>
          <img
            style={{
              position: "absolute",
              bottom: "0px",
              right: "4.2vw",
              height: "42.7%",
              objectFit: "cover",
            }}
            alt=""
            src="../绿色钱币.png"
          />
        </button>
      </div>
      <Menu select={"task"} />
      <div
        style={{
          position: "absolute",
          top: "15.5vh",
          right: "10vh",
          width: "75%",
          display: cardRuleTooltip ? "flex" : "none",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          textAlign: "left",
          color: "#f6f8f9",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "4px",
            backgroundColor: "#303940",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "12px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              flex: "1",
              position: "relative",
              lineHeight: "130%",
              fontSize: "1.4vh",
            }}
          >
            {t("tasks.cardRule")}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "36.5vh",
          width: "75%",
          right: "3vh",
          alignSelf: "stretch",
          borderRadius: "4px",
          backgroundColor: "#303940",
          overflow: "hidden",
          display: dailyRuleTooltip ? "flex" : "none",
          flexDirection: "row",
          padding: "1.2vh",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            flex: "1",
            position: "relative",
            lineHeight: "130%",
            whiteSpace: "pre-wrap",
            fontSize: "1.4vh",
          }}
        >
          <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
            {t("tasks.dailyRule1")}
          </p>
          <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
            {t("tasks.dailyRule2")}
          </p>
          <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
            {t("tasks.dailyRule3")}
          </p>
          <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>&nbsp;</p>
          <p style={{ margin: "0" }}>
            {t("tasks.dailyRule4", {
              0: dailySignIn.ATTEND_INCOME[0],
              1: dailySignIn.ATTEND_INCOME[1],
              2: dailySignIn.ATTEND_INCOME[2],
              3: dailySignIn.ATTEND_INCOME[3],
              4: dailySignIn.ATTEND_INCOME[4],
              5: dailySignIn.ATTEND_INCOME[5],
              6: dailySignIn.ATTEND_INCOME[6],
            })}
          </p>
        </div>
      </div>
      <div style={{ display: signinSuccess ? "flex" : "none" }}>
        <div
          style={{
            position: "fixed",
            top: "0",
            backgroundColor: "rgba(26, 32, 36, 0.32)",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            position: "fixed",
            top: "calc(40% - 75px)",
            left: "calc(50% - 165px)",
            borderRadius: "6px",
            background: "linear-gradient(180deg, #53acfc, #0560e5)",
            boxShadow:
              "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
            width: "335px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            boxSizing: "border-box",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            color: "#ffffff",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "173.79px",
                height: "150px",
                flexShrink: "0",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  height: "93.41%",
                  width: "73.78%",
                  top: "0%",
                  right: "13.16%",
                  bottom: "6.59%",
                  left: "13.06%",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                }}
                alt=""
                src="../恭喜6.svg"
              />
              <div
                style={{
                  position: "absolute",
                  height: "30.51%",
                  width: "100.01%",
                  top: "69.45%",
                  right: "-0.01%",
                  bottom: "0.04%",
                  left: "0%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0%",
                    right: "0%",
                    bottom: "0%",
                    left: "0%",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      height: "78.57%",
                      width: "26.33%",
                      top: "21.43%",
                      right: "73.67%",
                      bottom: "0%",
                      left: "0%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                    }}
                    alt=""
                    src="../恭喜1.svg"
                  />
                  <img
                    style={{
                      position: "absolute",
                      height: "21.43%",
                      width: "16.93%",
                      top: "78.57%",
                      right: "73.67%",
                      bottom: "0%",
                      left: "9.41%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                    }}
                    alt=""
                    src="../恭喜2.svg"
                  />
                  <img
                    style={{
                      position: "absolute",
                      height: "78.57%",
                      width: "26.33%",
                      top: "21.43%",
                      right: "0%",
                      bottom: "0%",
                      left: "73.66%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                    }}
                    alt=""
                    src="../恭喜3.svg"
                  />
                  <img
                    style={{
                      position: "absolute",
                      height: "21.43%",
                      width: "16.93%",
                      top: "78.57%",
                      right: "9.41%",
                      bottom: "0%",
                      left: "73.66%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                    }}
                    alt=""
                    src="../恭喜4.svg"
                  />
                  <img
                    style={{
                      position: "absolute",
                      height: "78.57%",
                      width: "81.24%",
                      top: "0%",
                      right: "9.35%",
                      bottom: "21.43%",
                      left: "9.41%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      maxHeight: "100%",
                    }}
                    alt=""
                    src="../恭喜5.svg"
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "81.12%",
                      top: "14.91%",
                      left: "9.43%",
                      lineHeight: "140%",
                      fontWeight: "600",
                      display: "inline-block",
                      fontSize: "18px",
                    }}
                  >
                    {t("tasks.congratulations")}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    lineHeight: "140%",
                    fontWeight: "600",
                  }}
                >
                  {t("tasks.signinSuccess")}
                </div>
              </div>
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "36px",
                letterSpacing: "-0.02em",
                lineHeight: "48px",
              }}
            >
              +100 {t("wallet.fscp")}
            </b>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                fontSize: "14px",
                color: "#000",
              }}
            >
              <button
                onClick={() => {
                  setSigninSuccess(false);
                }}
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#fff",
                  width: "303px",
                  height: "39px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "4px 24px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "0",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    {t("wallet.gotIt")}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
