import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../../Wallet/components/PageHeader"
import SettingRow from "../components/settingRow"
import { t } from "i18next"
import { getUserProfile } from "../../../apis/user"
import { getUserWalletPasswordSetted } from "../../../apis/user"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function SettingScreen() {
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [userName, setUserName] = useState(null)
  const [walletPasswordExists, setWalletPasswordExists] = useState(false)

  const init = async () => {
    const result1 = await getUserProfile()
    const result2 = await getUserWalletPasswordSetted()

    setWalletPasswordExists(result2.data.data.exist)
    setPhoneNumber(result1.data.data.phoneNumber)
    setUserName(result1.data.data.userName)
    setEmail(result1.data.data.email)
  }
  const navigate = useNavigate()

  useEffect(() => {
    init()
  }, [])
  return (
    <PageWrapper>
      <PageHeader title={t("profile.account")} />
      <div style={styles.bodyWrapper}>
        {email && <SettingRow title={t("profile.email")} content={email} />}
        {phoneNumber && (
          <SettingRow
            title={t("profile.phoneNumber")}
            content={phoneNumber}
            onClick={() => {
              console.log("profile")
            }}
          />
        )}

        <SettingRow
          title={t("profile.name")}
          content={userName}
          onClick={() => {
            console.log("profile")
          }}
        />

        <SettingRow
          title={t("profile.password")}
          content={"********"}
          onClick={() => {
            navigate("/reset-password")
          }}
        />
        {walletPasswordExists && (
          <SettingRow
            title={t("profile.paymentPassword")}
            content={"********"}
            onClick={() => {
              navigate("/change-payment-password")
            }}
          />
        )}
      </div>
    </PageWrapper>
  )
}
const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
}
