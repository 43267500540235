import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

const IdUsed = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          width: "375px",
          height: "962px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "18px",
          color: "#303940",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              实名认证
            </b>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "35px",
            left: "calc(50% - 167.5px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              borderRadius: "100px",
              backgroundColor: "#9bcffd",
              width: "335px",
              display: "flex",
              flexDirection: "row",
              padding: "11px 32px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                position: "relative",
                lineHeight: "130%",
                fontWeight: "600",
              }}
            >
              提交认证
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
            textAlign: "left",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "0px 11px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/对号-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#4f46e5",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-紫.svg"
                />
                <div
                  style={{
                    flex: "1",
                    position: "relative",
                    backgroundColor: "#d1d5db",
                    height: "2px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "32px",
                    height: "32px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/圆点-灰.svg"
                />
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                基本信息
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                上传证件
              </div>
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "120%",
                  fontWeight: "600",
                }}
              >
                提交认证
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              lineHeight: "130%",
              color: "#f2271c",
            }}
          >
            请确保使用的是真实身份，否则认证将无法通过。您提交的信息仅用于Future
            Star认证使用，我们将会对您的信息进行加密处理。
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                证件签发国家
              </div>
              <Form.Select
                style={{ alignSelf: "stretch", backgroundColor: "#F5F5F5" }}
              >
                <option>Canada</option>
              </Form.Select>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                选择政府签发的有效证件
              </div>
              <Form.Select
                style={{ alignSelf: "stretch", backgroundColor: "#F5F5F5" }}
              >
                <option>身份证</option>
                <option value="ID Card">ID Card</option>
                <option value="Passport">Passport</option>
                <option value="Driver's Licence">Driver's Licence</option>
              </Form.Select>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                输入证件号码
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #f76659",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    border: "0",
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  placeholder="N4DSDFS"
                ></input>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#f2271c",
                }}
              >
                此证件号码已被使用，请换另外的证件
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "130%",
                color: "#f2271c",
              }}
            >
              *需与上传的证件号码保持一致，否则将被驳回。
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                上传证件照片
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "4px",
                  textAlign: "center",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    borderRadius: "6px",
                    border: "1px dashed #d5dadd",
                    boxSizing: "border-box",
                    height: "89px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/上传.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                      }}
                    >
                      上传证件正面
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: "1",
                    borderRadius: "6px",
                    border: "1px dashed #d5dadd",
                    boxSizing: "border-box",
                    height: "89px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/上传.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                      }}
                    >
                      上传证件反面
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                上传个人签字和证件正面照片
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  height: "132px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    borderRadius: "6px",
                    border: "1px dashed #d5dadd",
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "303px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/上传.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                      }}
                    >
                      上传照片
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IdUsed;
