// import axios from "../utlis/axios"
import axios from "axios"
import { getCookie } from "../cookie"
import configs from "../configs"

export const getWalletInfo = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/wallet`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const getWithdrawFee = async () => {
  return await axios.get("/api/wallet/withdraw")
}

export const getConversionRate = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/wallet/convert`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const getTransactionRecord = async ({
  page,
  size,
  currency,
  filter,
  date,
}) => {
  return await axios.get(
    `${configs.API_BASE_URL}/api/wallet/transaction?` +
      (page ? `page=${page}&` : "") +
      (size ? `size=${size}&` : "") +
      (currency ? `currency=${currency}&` : "") +
      (date ? `date=${encodeURIComponent(date)}&` : "") +
      (filter ? `type=${filter}&` : ""),
    {
      headers: { sessionid: getCookie("sessionId") },
    }
  )
}

export const getTopUpAddress = async (currency = "BNB", network = "BIANCE") => {
  return await axios.get(
    `${configs.API_BASE_URL}/api/metamask/${network}/${currency}`,
    {
      headers: { sessionid: getCookie("sessionId") },
    }
  )
}

export const withdrawBNB = async (amount, metamask, network = "BIANCE") => {
  return await axios.post("/api/wallet/withdraw/bnb", {
    amount,
    metamask,
    network,
  })
}

export const withdrawUSDT = async (amount, metamask, network = "BIANCE") => {
  return await axios.post("/api/wallet/withdraw/usdt", {
    amount,
    metamask,
    network,
  })
}

export const withdrawFSC = async (amount, metamask, network = "BIANCE") => {
  return await axios.post("/api/wallet/withdraw/fsc", {
    amount,
    metamask,
    network,
  })
}

export const convertCurrency = async (from, to, amount) => {
  return await axios.post(
    `${configs.API_BASE_URL}/api/wallet/convert`,
    {
      from,
      to,
      amount,
    },
    {
      headers: { sessionid: getCookie("sessionId") },
    }
  )
}

export const getFSCPSummary = async () => {
  return await axios.get(`${configs.API_BASE_URL}/api/wallet/summary/sp`, {
    headers: { sessionid: getCookie("sessionId") },
  })
}

export const getWithdrawDetails = async (withdrawId) => {
  return await axios.get("/api/wallet/withdrawDetails/" + withdrawId)
}
