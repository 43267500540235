import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

const LoginInfo2 = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "797px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "Inter",
      }}
    >
      <center>
        <div
          style={{
            position: "relative",
            top: "0px",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontFamily: "'Work Sans'",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../关闭.svg"
          />
          <div
            style={{
              position: "relative",
              borderRadius: "6px",
              backgroundColor: "#ebf7ff",
              display: "flex",
              flexDirection: "row",
              padding: "4px 8px",
              alignItems: "center",
              justifyContent: "flex-start",
              left: "265px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "28px",
                height: "28px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../客服.svg"
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "14px",
            color: "#000",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "600",
                color: "#0e73f6",
              }}
            >
              Sign Up 1/2
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "28px",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Set Your Login Info
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
              }}
            >
              Please give us your login info first
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Phone Number
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../加拿大.svg"
                  />
                  <div
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                    }}
                  >
                    +1
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../展开选项.svg"
                  />
                </div>
                <input
                  style={{
                    alignSelf: "stretch",
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    flexDirection: "row",
                    padding: "0px 0px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    color: "#1a2024",
                    border: "0",
                  }}
                  placeholder="6476091868"
                ></input>
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  placeholder="futurestar123456"
                ></input>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../可见.svg"
                />
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Password
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  placeholder="***********"
                ></input>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../不可见.svg"
                />
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Verification Code
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  boxSizing: "border-box",
                  height: "40px",
                  flexShrink: "0",
                  overflow: "hidden",
                  color: "#1a2024",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0",
                    boxSizing: "border-box",
                    height: "40px",
                    flexShrink: "0",
                    overflow: "hidden",
                    color: "#1a2024",
                    padding: "16px",
                  }}
                  placeholder="8485"
                ></input>
                <div
                  style={{
                    position: "relative",
                    width: "calc(100% - 196px)",
                    letterSpacing: "-0.01em",
                    lineHeight: "24px",
                    fontWeight: "500",
                    color: "#0e73f6",
                    textAlign: "right",
                    display: "inline-block",
                  }}
                >
                  60s
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                padding: "4px 0px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "8px",
              }}
            >
              <Form.Check />
              <div style={{ flex: "1", position: "relative" }}>
                <p style={{ marginBlockStart: "0", marginBlockEnd: "0px" }}>
                  <span
                    style={{ lineHeight: "140%" }}
                  >{`I have read carefully and agree with `}</span>
                </p>
                <p style={{ margin: "0", color: "#0e73f6" }}>
                  <span
                    style={{
                      textDecoration: "underline",
                      lineHeight: "130%",
                      fontWeight: "600",
                      fontFamily: "Inter",
                      color: "#0e73f6",
                    }}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            top: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "24px",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "none",
                }}
                alt=""
                src="../group-add-fill0-wght400-grad0-opsz48-1-2.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                Sign Up
              </div>
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "500",
                color: "#0e73f6",
                textAlign: "right",
              }}
            >
              I already had an account
            </div>
          </div>
          <div
            style={{
              borderRadius: "6px",
              backgroundColor: "#d7edff",
              display: "flex",
              flexDirection: "row",
              padding: "4px 8px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
              textAlign: "left",
              color: "#0e73f6",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "28px",
                height: "28px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../客服.svg"
            />
            <div style={{ position: "relative", fontWeight: "600" }}>
              Chat With Us
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default LoginInfo2;
