import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
const Login1 = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "779px",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "18px",
        color: "#303940",
        fontFamily: "Inter",
      }}
    >
      <center>
        <div
          style={{
            position: "relative",
            width: "375px",
            display: "flex",
            flexDirection: "row",
            padding: "16px 20px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
            fontFamily: "'Work Sans'",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
            }}
            alt=""
            src="../关闭.svg"
          />
        </div>
        <div
          style={{
            position: "relative",
            top: "32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "32px",
            textAlign: "left",
            fontSize: "28px",
            color: "#000",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "335px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "32px",
                textTransform: "capitalize",
              }}
            >
              Welcome Back
            </b>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "20px",
                fontWeight: "500",
              }}
            >
              Come back and restart your earning today!
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "14px",
              color: "#252c32",
            }}
          >
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Phone Number or User Name
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "2px 4px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  color: "#1a2024",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #dde2e4",
                    display: "flex",
                    flexDirection: "row",
                    padding: "9px 12px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    color: "#1a2024",
                    border: "0",
                    width: "100%",
                  }}
                  placeholder="Input Your Name"
                ></input>
              </div>
            </div>
            <div
              style={{
                width: "335px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  letterSpacing: "-0.01em",
                  lineHeight: "24px",
                }}
              >
                Repeat Password
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #dde2e4",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 16px",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    backgroundColor: "#f5f5f5",
                    border: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  placeholder="Input Password"
                ></input>
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../可见.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "500",
                  color: "#0e73f6",
                  textAlign: "right",
                  left: "210px",
                }}
              >
                Forgot Password?
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            top: "225px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                borderRadius: "100px",
                backgroundColor: "#0e73f6",
                width: "335px",
                display: "flex",
                flexDirection: "row",
                padding: "11px 32px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "24px",
                  height: "24px",
                  flexShrink: "0",
                  overflow: "hidden",
                  display: "none",
                }}
                alt=""
                src="../group-add-fill0-wght400-grad0-opsz48-1-2.svg"
              />
              <div
                style={{
                  position: "relative",
                  lineHeight: "130%",
                  fontWeight: "600",
                }}
              >
                Log In
              </div>
            </div>
            <div
              style={{
                position: "relative",
                lineHeight: "140%",
                fontWeight: "500",
                color: "#0e73f6",
                textAlign: "right",
              }}
            >
              I do not have an account
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default Login1;
