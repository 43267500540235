import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DefaultCard from './DefaultCard';
import { getCardList } from '../service';

const ObtainCard = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { selectedType, cardList } = useSelector(state => state.card);

  useEffect(() => {
    getCardList(dispatch, selectedType);
  }, [selectedType]);

  return (
    <div className='card-obtain'>
      <div className='obtain-header'>
        <div className='obtain-header-title'>
          {t('card.get')}
          <div className='obtain-header-shadow'></div>
        </div>
        <div className='obtain-header-right'>
          <div style={{ marginRight: 16 }}
            className={selectedType === 'task' ? 'obtain-select' : 'pointer'}
            onClick={() => {
              dispatch({
                type: 'card/SWTICH_SELECT_TYPE',
                payload: 'task',
              });
            }}
          >
            {t('card.taskCard')}
          </div>
          <div className={selectedType === 'step' ? 'obtain-select' : 'pointer'} onClick={() => {
            dispatch({
              type: 'card/SWTICH_SELECT_TYPE',
              payload: 'step',
            });
          }}>
            {t('card.stepCard')}
          </div>
        </div>
      </div>
      {
        cardList.map((item, index) => {
          return <DefaultCard
            key={index}
            {...item}
          />
        })
      }
    </div>
  );
};

export default ObtainCard;