const IdVerificationFailed = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "958px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "16px",
          color: "#9aa6ac",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            backgroundColor: "#fff",
            borderTop: "1px solid #eef0f2",
            boxSizing: "border-box",
            width: "375px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            padding: "10px 12px",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            fontSize: "10px",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../首页.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>首页</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../任务.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>任务</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../星卡.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>星卡</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../头条.svg"
            />
            <div style={{ position: "relative", lineHeight: "120%" }}>头条</div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              padding: "0px 2px",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
              color: "#0e73f6",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="../我的-蓝.svg"
            />
            <div
              style={{
                position: "relative",
                lineHeight: "120%",
                fontWeight: "600",
              }}
            >
              我的
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
            color: "#1a2024",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "375px",
              height: "191px",
              flexShrink: "0",
              overflow: "hidden",
              fontSize: "18px",
              color: "#fff",
              fontFamily: "'Work Sans'",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-1367px",
                left: "-571px",
                borderRadius: "999999986991104px",
                background: "linear-gradient(180.14deg, #3a77f9, #3b97fa)",
                width: "1517px",
                height: "1517px",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  height: "39.82%",
                  width: "65.59%",
                  top: "90.11%",
                  right: "-3.23%",
                  bottom: "-29.93%",
                  left: "37.64%",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="../个人中心背景图.png"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                backgroundColor: "rgba(255, 255, 255, 0)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0)",
                  width: "375px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "16px 20px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <b
                  style={{
                    flex: "1",
                    position: "relative",
                    letterSpacing: "-0.01em",
                    lineHeight: "120%",
                  }}
                >
                  个人中心
                </b>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../信息.svg"
                  />
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "500",
                    }}
                  >
                    消息
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "73px",
                left: "12px",
                borderRadius: "12px",
                width: "351px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px 12px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        borderRadius: "124px",
                        width: "47px",
                        height: "47px",
                        flexShrink: "0",
                        objectFit: "cover",
                      }}
                      alt=""
                      src="../示例头像2.png"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        gap: "4px",
                      }}
                    >
                      <a
                        style={{
                          position: "relative",
                          letterSpacing: "-0.01em",
                          fontWeight: "600",
                          color: "inherit",
                          textDecoration: "none",
                        }}
                        href="mailto:example@email.com"
                        target="_blank"
                      >
                        Amy Woden
                      </a>
                      <div
                        style={{
                          position: "relative",
                          fontSize: "12px",
                          letterSpacing: "-0.01em",
                          color: "#000",
                          textAlign: "left",
                          fontFamily: "Inter",
                          fontWeight: "400",
                        }}
                      >
                        example@email.com
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "1000px",
                      backgroundColor: "#4094f7",
                      display: "flex",
                      flexDirection: "row",
                      padding: "8px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#fff",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "16px",
                        height: "16px",
                        flexShrink: "0",
                        overflow: "hidden",
                        top: "1.5px",
                      }}
                      alt=""
                      src="../签到-白.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        fontWeight: "500",
                      }}
                    >
                      已签到
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#0e73f6",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "23px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../奖牌-金.svg"
                />
                <div
                  style={{
                    position: "relative",
                    letterSpacing: "-0.01em",
                    fontFamily: "Inter",
                    fontWeight: "400",
                  }}
                >
                  当前星级：三星合伙人
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textAlign: "left",
                    fontFamily: "Inter",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontFamily: "Inter",
                      fontWeight: "400",
                    }}
                  >
                    查看星卡
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "18px",
                      height: "18px",
                      top: "-0.5px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="../右尖角-白.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "#fff",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../钱包.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "600",
                  }}
                >
                  我的钱包
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  textAlign: "left",
                  fontSize: "14px",
                  color: "#5b6871",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "400",
                  }}
                >
                  查看详情
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
                textAlign: "left",
                fontSize: "12px",
                color: "#6e7c87",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  总收益
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    color: "#1a2024",
                    fontSize: "18px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>
                    <span style={{ fontSize: "16px" }}>{`≈ `}</span>
                    <span>803,129.14</span>
                  </span>
                  <span style={{ fontSize: "12px" }}>USDT</span>
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  总资产
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    color: "#1a2024",
                    fontSize: "18px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>
                    <span style={{ fontSize: "16px" }}>{`≈ `}</span>
                    <span>803,129.14</span>
                  </span>
                  <span style={{ fontSize: "12px" }}>USDT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "#fff",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../我的团队.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "600",
                  }}
                >
                  我的团队
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  textAlign: "left",
                  fontSize: "14px",
                  color: "#5b6871",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "400",
                  }}
                >
                  查看详情
                </div>
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "16px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
                textAlign: "left",
                fontSize: "12px",
                color: "#6e7c87",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  总人数
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "18px",
                    lineHeight: "130%",
                    fontWeight: "500",
                    color: "#1a2024",
                  }}
                >
                  80
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  今日新增
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "18px",
                    lineHeight: "130%",
                    fontWeight: "500",
                    color: "#1a2024",
                  }}
                >
                  3
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "#fff",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              padding: "12px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "21px",
                    height: "21px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../申请提现.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  申请提现
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "21px",
                    height: "21px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../联系客服.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  联系客服
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "21px",
                    height: "21px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../语言切换.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  语言切换
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "#fff",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              padding: "12px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "230px",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "21px",
                      height: "21px",
                      flexShrink: "0",
                    }}
                    alt=""
                    src="/实名认证.svg"
                  />
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "130%",
                      fontWeight: "500",
                    }}
                  >
                    实名认证
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textAlign: "right",
                    fontSize: "14px",
                    color: "#CC0905",
                  }}
                >
                  <div style={{ position: "relative", fontWeight: "400" }}>
                    审核失败
                  </div>
                  <img
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/i-红.svg"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "21px",
                    height: "21px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="../设置.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  设置
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="../右尖角.svg"
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "21px",
                    height: "21px",
                    flexShrink: "0",
                  }}
                  alt=""
                  src="/关于我们.svg"
                />
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "500",
                  }}
                >
                  关于我们
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "18px",
                    height: "18px",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                  alt=""
                  src="/右尖角.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "789px",
            left: "12px",
            borderRadius: "100px",
            backgroundColor: "#f2271c",
            width: "351px",
            display: "flex",
            flexDirection: "row",
            padding: "11px 32px",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            color: "#fff",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              flexShrink: "0",
              overflow: "hidden",
              display: "none",
            }}
            alt=""
            src="../group-add-fill0-wght400-grad0-opsz48-1-2.svg"
          />
          <div
            style={{
              position: "relative",
              lineHeight: "130%",
              fontWeight: "600",
            }}
          >
            Log Out
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "846px",
            left: "83px",
            color: "#84919A",
            display: "inline-block",
            width: "209px",
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "16px",
          }}
        >
          version 1.0.0
        </div>
      </div>
    </center>
  );
};

export default IdVerificationFailed;
