const StarPointStats = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#f5f5f5",
          width: "375px",
          height: "683px",
          overflow: "hidden",
          textAlign: "left",
          fontSize: "14px",
          color: "#000",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "76px",
            left: "12px",
            width: "351px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            <div style={{ position: "relative", fontWeight: "500" }}>
              11月(本月)星值数据
            </div>
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "24px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/展开选项.svg"
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderRadius: "12px",
              backgroundColor: "#4094f7",
              height: "79px",
              flexShrink: "0",
              overflow: "hidden",
              color: "#fff",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "12px",
                backgroundColor: "#0e73f6",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
                width: "351px",
                height: "139px",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src="/登陆领取背景图.png"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 35.5px)",
                left: "calc(50% - 175.5px)",
                borderRadius: "12px",
                width: "351px",
                display: "flex",
                flexDirection: "column",
                padding: "16px 8px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "400",
                    }}
                  >
                    累计收益星值
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    212121
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    borderRight: "1px solid #e5e9eb",
                    boxSizing: "border-box",
                    width: "1px",
                    height: "40px",
                    flexShrink: "0",
                  }}
                />
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      lineHeight: "140%",
                      fontWeight: "400",
                    }}
                  >
                    累计兑换SP数量
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontSize: "16px",
                    }}
                  >
                    <b>12238.132</b>
                    <span style={{ fontSize: "10px", fontWeight: "500" }}>
                      ≈2136USDT
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#252c32",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "25px",
                color: "#1a2024",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "4px",
                  color: "#252c32",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  全部
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "2px solid #252c32",
                    boxSizing: "border-box",
                    height: "2px",
                    flexShrink: "0",
                  }}
                />
              </div>
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              >
                收入
              </div>
              <div
                style={{
                  position: "relative",
                  lineHeight: "16px",
                  fontWeight: "500",
                }}
              >
                支出
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebf7ff",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/完成活跃度任务.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        完成活跃度任务
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#0e73f6",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    +213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#ebfff1",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/兑换sp-成功.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        兑换 SP(成功)
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#22c348",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    -213
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                borderBottom: "1px solid #eef0f2",
                display: "flex",
                flexDirection: "column",
                padding: "16px 0px",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: "42px",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "59.38px",
                      backgroundColor: "#fffeeb",
                      display: "flex",
                      flexDirection: "row",
                      padding: "4.75px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "28.5px",
                        height: "28.5px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/兑换sp-审核中.svg"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        兑换 SP(审核中)
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontSize: "12px",
                        letterSpacing: "-0.01em",
                        lineHeight: "20px",
                        color: "#828282",
                        textAlign: "right",
                      }}
                    >
                      2021-01-01 12:33
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "4px",
                    color: "#d29404",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "16px",
                      fontWeight: "600",
                    }}
                  >
                    -200
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "1px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #eef0f2",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "center",
            fontSize: "18px",
            color: "#303940",
            fontFamily: "'Work Sans'",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eef0f2",
              boxSizing: "border-box",
              width: "375px",
              display: "flex",
              flexDirection: "row",
              padding: "16px 20px",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "24px",
                height: "20px",
                flexShrink: "0",
                overflow: "hidden",
              }}
              alt=""
              src="/左尖角.svg"
            />
            <b
              style={{
                flex: "1",
                position: "relative",
                letterSpacing: "-0.01em",
                lineHeight: "120%",
                left: "-2px",
              }}
            >
              星值记录
            </b>
          </div>
        </div>
      </div>
    </center>
  );
};

export default StarPointStats;
