const initialState = {
  summary: {
    SPtoUSDTRatio: 0.01,
    USDT_TO_FSC_RATIO: "100",
    potentialEarn: 48,
    stepIncome: 0,
    taskIncome: 0,
    total: 0,
  },
  dailySignIn: {
    ATTEND_INCOME: [0, 0, 0, 0, 0, 0, 0],
    attends: [],
    checkedInToday: false,
    continuousDays: 0,
    total: 0,
  },
  beginnerTasks: {
    articleId: [
      { id: "637db23d550013003e898d40", language: "Chinese" },
      { id: "637db33c550013003e898d7b", language: "English" },
      { id: "637db535eeb81a7fbc8af12b", language: "Spanish" },
      { id: "637db5feeeb81a7fbc8af12f", language: "Portuguese" },
    ],
    remaining: 500,
    tasks: [
      { type: "view_usermenu", bonus: 100, finished: 8 },
      { type: "watch_intro_video", bonus: 100, finished: 0 },
      { type: "finish_verify", bonus: 100, finished: 0 },
      { type: "first_get_card", bonus: 100, finished: 0 },
      { type: "first_finish_daily_task", bonus: 100, finished: 1 },
      { type: "first_finish_step_task", bonus: 100, finished: 0 },
      { type: "first_invite_user", bonus: 100, finished: 0 },
      { type: "first_convert_FSC_to_USDT", bonus: 100, finished: 1 },
      { type: "first_withdraw_USDT", bonus: 100, finished: 1 },
      { type: "first_deposit", bonus: 100, finished: 0 },
    ],

    total: 0,
    videoId: [
      {
        id: "637dc08d3f2802843c7dcdd1",
        language: "English",
        link: "https://future-star-prod-asset-us.s3.amazonaws.com/video/a408f9f8-0d99-45e1-bc5f-EN.mp4",
      },
      {
        id: "637dc0ba3f2802843c7dcdd3",
        language: "Chinese",
        link: "https://future-star-prod-asset-us.s3.amazonaws.com/video/a408f9f8-0d99-45e1-bc5f-ZH.mp4",
      },
      {
        id: "637dfd7e550013003e899e44",
        language: "Spanish",
        link: "https://future-star-prod-asset-us.s3.amazonaws.com/video/a408f9f8-0d99-45e1-bc5f-SP.mp4",
      },
    ],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "task/GET_SUMMARY":
      return {
        ...state,
        summary: action.payload,
      };

    case "task/GET_DAILY_SIGN_IN":
      return {
        ...state,
        dailySignIn: action.payload,
      };

    case "task/POST_DAILY_SIGN_IN":
      return state;

    case "task/GET_BEGINNER_TASKS":
      return {
        ...state,
        beginnerTasks: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
