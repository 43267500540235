import axios from "../utlis/axios"
import _ from "lodash"
export const callSignup = async ({ email, phoneNumber, token }) => {
  return await axios.post(
    "/api/user/register",
    {
      email,
      phoneNumber,
    },
    {
      headers: {
        authorization: token,
      },
    }
  )
}

export const callOnboard = async (userName, inviteCode, jwtToken) => {
  if (jwtToken) {
    return await axios.post(
      "/api/invite/onBoard",
      {
        userName: _.trim(userName),
        inviteCode: _.trim(inviteCode),
      },
      {
        headers: {
          authorization: jwtToken,
        },
      }
    )
  } else {
    return await axios.post("/api/invite/onBoard", {
      userName,
      inviteCode,
    })
  }
}

export const callLogin = async (token) => {
  return await axios.post(
    "/api/user/login",
    {},
    {
      headers: {
        authorization: token,
      },
    }
  )
}

export const preRegisterUser = async (
  userName,
  type,
  challengeId,
  challengeAnswer
) => {
  return await axios.post("/api/user/preRegister", {
    type,
    userName,
    challengeId,
    challengeAnswer,
  })
}

export const getCaptcha = async () => {
  return await axios.get("/api/user/registerChallenge")
}

export const checkCaptcha = async (user, challengeId, challengeAnswer) => {
  return await axios.get(
    `/api/user/checkAnswer?user=${encodeURIComponent(
      user
    )}&challengeId=${challengeId}&challengeAnswer=${challengeAnswer}`
  )
}

export const checkUserExists = async (user) => {
  return await axios.get(
    `/api/user/userExists/${encodeURIComponent(_.trim(user))}`
  )
}

export const checkUserStatus = async (user) => {
  return await axios.get(
    `/api/user/userStatus/${encodeURIComponent(_.trim(user))}`
  )
}

export const saveUserPassword = async (password, token) => {
  return await axios.post(
    "/api/auth/store",
    { password: _.trim(password) },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  )
}

export const backendUserSignIn = async (email, phoneNumber, password) => {
  return await axios.post("/api/auth/signin", {
    email: email ? _.trim(email).toLowerCase() : null,
    phoneNumber: _.trim(phoneNumber),
    password: _.trim(password),
  })
}

export const backendUserSignUp = async (
  email,
  phoneNumber,
  password,
  passwordConfirm
) => {
  return await axios.post("/api/auth/signup", {
    email: email ? _.trim(email).toLowerCase() : null,
    phoneNumber: _.trim(phoneNumber),
    password: _.trim(password),
    passwordConfirm: _.trim(passwordConfirm),
  })
}

export const backendSendCode = async (email, phoneNumber, type) => {
  return await axios.post("/api/auth/sms", {
    email: email ? _.trim(email).toLowerCase() : null,
    phoneNumber: _.trim(phoneNumber),
    type: _.trim(type),
  })
}

export const backendVerifyCode = async (email, phoneNumber, code) => {
  return await axios.post("/api/auth/verifyUser", {
    email: email ? _.trim(email).toLowerCase() : null,
    phoneNumber: _.trim(phoneNumber),
    code: _.trim(code),
  })
}

export const backendResetPassword = async (
  email,
  phoneNumber,
  code,
  password,
  passwordConfirm
) => {
  return await axios.post("/api/auth/resetPassword", {
    email: email ? _.trim(email).toLowerCase() : null,
    phoneNumber: _.trim(phoneNumber),
    code: _.trim(code),
    password: _.trim(password),
    passwordConfirm: _.trim(passwordConfirm),
  })
}

export const backendChangePassword = async (
  original,
  password,
  passwordConfirm
) => {
  return await axios.post("/api/auth/changePassword", {
    original: _.trim(original),
    password: _.trim(password),
    passwordConfirm: _.trim(passwordConfirm),
  })
}

export const backendLogout = async () => {
  return await axios.get(`/api/auth/signout`)
}
