import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMyCards } from './service';

import left from './assets/left.svg';

import './mycard.css';
import MyStarCard from './components/MyStarCard';

import Menu from '../../components/Menu';

const MyCard = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const { myCardType, myStepCardList, myTaskCardList } = useSelector(state => state.card);

  const handleBack = () => {
    navigation('/card');
  };

  useEffect(() => {
    getMyCards(dispatch, 'step');
    getMyCards(dispatch, 'task');
  }, []);

  return (
    <div className='mycard-home'>
      <div className='mycard-header'>
        <div className='mycard-back' onClick={handleBack}>
          <img src={left} alt="" />
        </div>
        <div>{t('card.myCards')}</div>
      </div>
      <div className='mycard-body'>
        <div className='mycard-body-header'>
          <div
            onClick={() => {
              dispatch({
                type: 'card/SWTICH_MYCARD_SELECT_TYPE',
                payload: 'step',
              });
            }}
            className={myCardType === 'step' ? 'mycard-body-header-select' : ''}
          >
            {t('card.stepCard')} {`(${myStepCardList.length})`}
          </div>
          <div
            onClick={() => {
              dispatch({
                type: 'card/SWTICH_MYCARD_SELECT_TYPE',
                payload: 'task',
              });
            }}
            className={myCardType === 'task' ? 'mycard-body-header-select' : ''}
          >
            {t('card.taskCard')} {`(${myTaskCardList.length})`}
          </div>
        </div>
        <div>
          {
            myCardType === 'step'
            &&
            myStepCardList.map((item, index) => {
              return <MyStarCard {...item} index={index} />
            })
          }
          {
            myCardType === 'task'
            &&
            myTaskCardList.map((item, index) => {
              return <MyStarCard {...item} index={index} />
            })
          }
        </div>
      </div>
      <Menu select={'card'} />
    </div >
  )
}

export default MyCard;
