const H5Register = () => {
  return (
    <center>
      <div
        style={{
          position: "relative",
          backgroundColor: "#527efd",
          width: "375px",
          height: "850px",
          overflow: "hidden",
          textAlign: "center",
          fontSize: "40px",
          color: "#fff",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: "#527efd",
            width: "375px",
            height: "832px",
            overflow: "hidden",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "51px",
              left: "0px",
              width: "375px",
              height: "808px",
              objectFit: "cover",
            }}
            alt=""
            src="/h5注册背景图.png"
          />
          <div
            style={{
              position: "absolute",
              top: "35px",
              left: "44px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div style={{ position: "relative", fontWeight: "900" }}>
              <p style={{ margin: "0" }}>{`Register to `}</p>
              <p style={{ margin: "0" }}>receive USDT</p>
            </div>
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#3e62e9",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24)",
                display: "flex",
                flexDirection: "row",
                padding: "4px 24px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
              }}
            >
              <div style={{ position: "relative", fontWeight: "500" }}>
                Get good rewards when signup
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "363px",
              left: "12px",
              borderRadius: "12px",
              backgroundColor: "#fff",
              width: "351px",
              display: "flex",
              flexDirection: "column",
              padding: "32px 20px 16px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "24px",
              textAlign: "left",
              fontSize: "14px",
              color: "#000",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "10000px",
                backgroundColor: "#eef0f2",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                padding: "3px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  flex: "1",
                  borderRadius: "10000px",
                  backgroundColor: "#fff",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 21px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    fontWeight: "600",
                    opacity: "0.9",
                  }}
                >
                  手机注册
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px 41px",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#252c32",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    fontWeight: "500",
                    opacity: "0.9",
                  }}
                >
                  邮箱注册
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "20px",
                color: "#9aa6ac",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                  color: "#1a2024",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "4px",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/加拿大.svg"
                    />
                    <div
                      style={{
                        position: "relative",
                        letterSpacing: "-0.01em",
                        lineHeight: "24px",
                      }}
                    >
                      +1
                    </div>
                    <img
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        overflow: "hidden",
                      }}
                      alt=""
                      src="/展开选项.svg"
                    />
                  </div>
                  <input
                    style={{
                      flex: "1",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      color: "#9aa6ac",
                      border: "0",
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                    placeholder="Input Phone Number"
                  ></input>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "0.5px solid #4094f7",
                    boxSizing: "border-box",
                    height: "0.5px",
                    flexShrink: "0",
                  }}
                />
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <input
                    style={{
                      flex: "1",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      color: "#9aa6ac",
                      border: "0",
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                    placeholder="Input Password"
                  ></input>
                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/可见.svg"
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "0.5px solid #4094f7",
                    boxSizing: "border-box",
                    height: "0.5px",
                    flexShrink: "0",
                  }}
                />
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <input
                    style={{
                      flex: "1",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      color: "#9aa6ac",
                      border: "0",
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                    placeholder="Confirm Password"
                  ></input>

                  <img
                    style={{
                      position: "relative",
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      overflow: "hidden",
                    }}
                    alt=""
                    src="/可见.svg"
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "0.5px solid #4094f7",
                    boxSizing: "border-box",
                    height: "0.5px",
                    flexShrink: "0",
                  }}
                />
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      display: "inline-block",
                      width: "188px",
                      flexShrink: "0",
                      border: "0",
                      width: "220px",
                      backgroundColor: "transparent",
                    }}
                    placeholder="Input Verification Code"
                  ></input>

                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      fontWeight: "500",
                      color: "#0e73f6",
                      textAlign: "right",
                    }}
                  >
                    获取验证码
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "0.5px solid #4094f7",
                    boxSizing: "border-box",
                    height: "0.5px",
                    flexShrink: "0",
                  }}
                />
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <input
                    style={{
                      position: "relative",
                      letterSpacing: "-0.01em",
                      lineHeight: "24px",
                      display: "inline-block",
                      width: "188px",
                      flexShrink: "0",
                      border: "0",
                      width: "220px",
                      backgroundColor: "transparent",
                    }}
                    placeholder="Input Verification Code"
                  ></input>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "0.5px solid #4094f7",
                    boxSizing: "border-box",
                    height: "0.5px",
                    flexShrink: "0",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
                textAlign: "center",
                fontSize: "16px",
                color: "#fff",
              }}
            >
              <div
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0e73f6",
                  width: "256px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "11px 32px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    lineHeight: "130%",
                    fontWeight: "600",
                  }}
                >
                  Get Rewards Now
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "130%",
                  color: "#0e73f6",
                }}
              >
                Already Had Account? Download Now!
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default H5Register;
