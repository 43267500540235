import { useEffect, useState } from "react"

export default function VerifyCodeInput({
  value,
  setValue,
  onSendCodeClicked,
  errorMessage = "",
}) {
  const [countdown, setCountdown] = useState(0)
  const onCLicked = async () => {
    await onSendCodeClicked()
    setCountdown(5)
  }
  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [countdown])
  return (
    <div
      style={{
        width: "100%",
        borderRadius: "6px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #dde2e4",
        boxSizing: "border-box",
        color: "#1a2024",
        display: "flex",
        alignItems: "center",
        paddingRight: "16px",
        borderColor: errorMessage.length > 0 ? "#f76659" : " #dde2e4",
      }}
    >
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        style={{
          borderRadius: "6px",
          backgroundColor: "#f5f5f5",
          border: "0",
          boxSizing: "border-box",
          color: "#1a2024",
          flex: 1,
          padding: "12px 16px",
        }}
        type="text"
        placeholder="Code"
      ></input>
      <div
        style={{
          display: "flex",
          letterSpacing: "-0.01em",
          display: "inline-block",
          lineHeight: "24px",
          fontWeight: "500",
          color: "#0e73f6",
        }}
      >
        {countdown > 0 ? (
          <span>{countdown}</span>
        ) : (
          <div onClick={onCLicked}>
            <span>Send Code</span>
          </div>
        )}
      </div>
      {errorMessage.length > 0 && (
        <text
          style={{
            position: "absolute",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#f2271c",
            left: 0,
            bottom: -16,
          }}
        >
          {errorMessage}
        </text>
      )}
    </div>
  )
}
