import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFeed } from '../service';

import './index.css';
import moment from 'moment/moment';

const Article = ({ title, author, readTime, createTime, containsImage, content, _id }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`/article?id=${_id}`, _id);
  };

  return (
    <div className='info-article' onClick={handleClick}>
      <div className='info-article-left'>
        <div className='info-article-author'>
          <img src={''} alt="" />
          <div>{author}</div>
        </div>
        <div className='info-article-title'>
          {title}
        </div>
        <div className='info-article-detail'>
          <span>{moment(createTime).format('MM DD, YYYY')}</span>
          <span>{` · ${t('read.duration', { duration: readTime })}`}</span>
        </div>
      </div>
      {
        containsImage
        &&
        <div className='info-article-right'>
          <img src={content.find(item => item.type === 'image').content} alt="" />
        </div>
      }
    </div>
  );
};

const Ad = ({ title, containsImage, content, _id }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(`/article?id=${_id}`, _id);
  };

  return (
    <div className='info-ad' onClick={handleClick}>
      <div className='info-ad-title'>
        {title}
      </div>
      {
        containsImage
        &&
        <div className='info-ad-body'>
          <img src={content.find(item => item.type === 'image').content} alt="" />
        </div>
      }
      <div className='info-ad-label'>{t('read.ads')}</div>
    </div>
  );
};

const ArticleList = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { articles } = useSelector(state => state.info);

  useEffect(() => {
    getFeed(dispatch, { page: 1, size: 100, language: i18n.language });
  }, []);

  return (
    <div className='article-list'>
      <div className='article-list-header'>
        {t('home.starNews')}
      </div>
      <div className='article-list-body'>
        {
          articles.map((item, index) => {
            if (item.isAd === true) {
              return <Ad {...item} key={index} />
            }

            return <Article {...item} key={index} />
          })
        }
      </div>
    </div>
  );
};

export default ArticleList;
