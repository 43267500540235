import { t } from "i18next"
import { useEffect, useState, useRef } from "react"
import { getTransactionRecord } from "../../../apis/wallet"
import moment from "moment"
import { formatBalance } from "../../../utlis/numbers"
const pageSize = 20
const historyEarn = require("../asset/history-earn.png")
const historyTopUp = require("../asset/history-money.png")
const historyConvert = require("../asset/history-convert.png")
const historyPurchase = require("../asset/history-purchase.png")
const historyWithdraw = require("../asset/history-withdraw-2.png")
const convertPendingImage = require("../asset/convert-pending.png")
const convertSuccessImage = require("../asset/convert-success.png")
const convertFailedImage = require("../asset/convert-failed.png")
export default function CurrencyTransactions({ currency }) {
  const [filter, setFilter] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [history, setHistory] = useState([])
  const domRef = useRef(null)
  // useEffect(() => {
  //   console.log("new page fetched")
  //   getTransactionRecord({
  //     page: currentPage,
  //     size: pageSize,
  //     currency,
  //     filter,
  //   })
  //     .then((result) => {
  //       setHistory([...history, result.data.data])
  //       // if (result.data.data.length < pageSize) {
  //       //   setShowLoader(false)
  //       // }
  //     })
  //     .catch((e) => console.log(e))
  // }, [currentPage])

  useEffect(() => {
    setHistory([])

    getTransactionRecord({
      page: currentPage,
      size: pageSize,
      currency,
      filter,
    })
      .then((result) => {
        setHistory(result.data.data)
        // if (result.data.data.length < pageSize) {
        //   setShowLoader(false)
        // }
      })
      .catch((e) => console.log(e))
  }, [filter, currency])
  const handleOnScroll = () => {
    if (domRef.current) {
      const contentScrollTop = domRef.current.scrollTop //滚动条距离顶部
      const clientHeight = domRef.current.clientHeight //可视区域
      const scrollHeight = domRef.current.scrollHeight //滚动条内容的总高度
      if (contentScrollTop + clientHeight >= scrollHeight) {
        setCurrentPage((prev) => {
          return prev + 1
        })
      }
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "white",
        marginTop: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "white",
          padding: "16px 20px",
        }}
      >
        <button
          onClick={() => {
            setFilter(0)
          }}
          style={
            filter === 0
              ? styles.choosedTransactionType
              : styles.unChoosedTransactionType
          }
        >
          <span>{t("wallet.all")}</span>
        </button>
        <button
          onClick={() => {
            setFilter(1)
          }}
          style={
            filter === 1
              ? styles.choosedTransactionType
              : styles.unChoosedTransactionType
          }
        >
          <span>{t("wallet.income")}</span>
        </button>
        <button
          onClick={() => {
            setFilter(2)
          }}
          style={
            filter === 2
              ? styles.choosedTransactionType
              : styles.unChoosedTransactionType
          }
        >
          <span>{t("wallet.expense")}</span>
        </button>
      </div>
      <div
        // ref={domRef}
        // onScroll={handleOnScroll}
        style={{
          width: "100%",
          height: "65vh",
          display: "flex",
          flexDirection: "column",
          padding: "0px 18px",
          overflowY: "scroll",
        }}
      >
        {history.map((item) => {
          if (item) {
            const typeExists = !!historyComponents[currency][item.type]
            let icon = historyComponents[currency][item.type].icon
            let title = typeExists
              ? t(historyComponents[currency][item.type].title, {
                  currency: currency,
                  currencyTo:
                    historyComponents[currency][item.type].convertTo === "SP"
                      ? t("wallet.fscp")
                      : historyComponents[currency][item.type].convertTo,
                  currencyFrom:
                    historyComponents[currency][item.type].convertFrom === "SP"
                      ? t("wallet.fscp")
                      : historyComponents[currency][item.type].convertFrom,
                  status: status[item.status],
                })
              : ""
            let color = typeExists
              ? historyComponents[currency][item.type].color
              : "#0E73F6"
            if (item.type === "SP convert FSC") {
              if (item.status === 0) {
                icon = convertSuccessImage
                title += t("wallet.spSuccess")
                color = "#119C2B"
              } else if (item.status !== 1) {
                icon = convertFailedImage
                title += t("wallet.spFailed")
                color = "#CC0905"
              } else {
                title += t("wallet.spPending")
              }
            }
            return (
              <div
                key={item._id}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <img
                  src={icon}
                  style={{
                    height: "38px",
                    width: "38px",
                    borderRadius: "19px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    flex: 1,
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#252C32",
                    }}
                  >
                    {title}
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#828282",
                    }}
                  >
                    {moment(item.createTime).format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
                <span
                  style={{
                    color,
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "flex",
                  }}
                >
                  {typeExists && historyComponents[currency][item.type].negative
                    ? "-"
                    : "+"}
                  {formatBalance(item.amount, 10000)} {currency}
                </span>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}
const styles = {
  choosedTransactionType: {
    border: "none",
    backgroundColor: "transparent",
    borderBottom: "2px solid black",
    marginRight: "18px",
  },
  unChoosedTransactionType: {
    border: "none",
    backgroundColor: "transparent",
    borderBottom: "0px",
    marginRight: "18px",
  },
}
const status = {
  "-1": "",
  0: "",
  1: "(处理中)",
  2: "",
  3: "",
}
const historyComponents = {
  FSC: {
    register: {
      icon: historyEarn,
      title: "wallet.registerBonus",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    invite: {
      icon: historyEarn,
      title: "wallet.invitationBonus",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    task_invite_income: {
      icon: historyEarn,
      title: "wallet.inviteIncome",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    task_income: {
      icon: historyEarn,
      title: "wallet.taskIncome",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    step_income: {
      icon: historyEarn,
      title: "wallet.stepIncome",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    step_invite_income: {
      icon: historyEarn,
      title: "wallet.inviteIncome",
      convertTo: "",
      convertFrom: "",
      color: "#0E73F6",
    },
    deposit: {
      icon: historyEarn,
      title: "wallet.topUpTitle",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    attend_income: {
      icon: historyEarn,
      title: "tasks.dailyCheckin",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    view_usermenu: {
      icon: historyEarn,
      title: "tasks.checkOut",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    watch_intro_video: {
      icon: historyEarn,
      title: "tasks.viewIntro",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    finish_verify: {
      icon: historyEarn,
      title: "tasks.completeVerification",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_get_card: {
      icon: historyEarn,
      title: "tasks.receiveStarCard",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_finish_daily_task: {
      icon: historyEarn,
      title: "tasks.completeActivity",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_finish_step_task: {
      icon: historyEarn,
      title: "tasks.completeWalk",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_invite_user: {
      icon: historyEarn,
      title: "tasks.completeInvite",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_convert_FSC_to_USDT: {
      icon: historyEarn,
      title: "tasks.completeConvert",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_withdraw_USDT: {
      icon: historyEarn,
      title: "tasks.completeWithdraw",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    first_deposit: {
      icon: historyTopUp,
      title: "tasks.topUp",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    "FSC convert USDT": {
      icon: historyConvert,
      title: "wallet.currencyConvertTo",
      convertTo: "USDT",
      convertFrom: "",
      negative: true,
      color: "#1A2024",
    },
    "USDT convert FSC": {
      icon: historyConvert,
      title: "wallet.currencyConvertFrom",
      convertTo: "",
      convertFrom: "USDT",
      color: "#119C2B",
    },
    "FSC convert BNB": {
      icon: historyConvert,
      title: "wallet.currencyConvertTo",
      convertTo: "BNB",
      convertFrom: "",
      negative: true,
      color: "#1A2024",
    },
    "BNB convert FSC": {
      icon: historyConvert,
      title: "wallet.currencyConvertFrom",
      convertTo: "",
      convertFrom: "BNB",
      color: "#119C2B",
    },
    "SP convert FSC": {
      icon: convertPendingImage,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      color: "#D29404",
    },
    purchase_card: {
      icon: historyPurchase,
      title: "wallet.historyPurchase",
      negative: true,
      color: "#1A2024",
    },
    "FSC Withdraw": {
      icon: historyWithdraw,
      title: "wallet.currencyWithdraw",
      convertTo: "",
      negative: true,
      color: "#F2271C",
    },
  },
  BNB: {
    deposit: {
      icon: historyTopUp,
      title: "wallet.topUpTitle",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    "FSC convert BNB": {
      icon: historyConvert,
      title: "wallet.currencyConvertFrom",
      convertTo: "",
      convertFrom: "FSC",
      color: "#119C2B",
    },
    "BNB convert FSC": {
      icon: historyConvert,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      negative: true,
      color: "#1A2024",
    },
    "BNB Withdraw": {
      icon: historyWithdraw,
      title: "wallet.currencyWithdraw",
      convertTo: "",
      convertFrom: "",
      negative: true,
      color: "#F2271C",
    },
    "SP convert FSC": {
      icon: convertPendingImage,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      color: "#D29404",
    },
    purchase_card: {
      icon: historyPurchase,
      title: "wallet.historyPurchase",
      negative: true,
      color: "#1A2024",
    },
  },
  USDT: {
    deposit: {
      icon: historyTopUp,
      title: "wallet.topUpTitle",
      convertTo: "",
      convertFrom: "FSC",
      color: "#0E73F6",
    },
    "FSC convert USDT": {
      icon: historyConvert,
      title: "wallet.currencyConvertFrom",
      convertTo: "",
      convertFrom: "FSC",
      color: "#119C2B",
    },
    "USDT convert FSC": {
      icon: historyConvert,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      negative: true,
      color: "#1A2024",
    },
    "USDT Withdraw": {
      icon: historyWithdraw,
      title: "wallet.currencyWithdraw",
      convertTo: "",
      convertFrom: "",
      negative: true,
      color: "#F2271C",
    },
    "BNB convert FSC": {
      icon: historyConvert,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      negative: true,
      color: "#1A2024",
    },
    "FSC convert BNB": {
      icon: historyConvert,
      title: "wallet.currencyConvertFrom",
      convertTo: "",
      convertFrom: "FSC",
      color: "#119C2B",
    },
    "SP convert FSC": {
      icon: convertPendingImage,
      title: "wallet.currencyConvertTo",
      convertTo: "FSC",
      convertFrom: "",
      color: "#D29404",
    },
    purchase_card: {
      icon: historyPurchase,
      title: "wallet.historyPurchase",
      negative: true,
      color: "#1A2024",
    },
  },
}
