import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import PageWrapper from "../../../components/PageWrapper"
import { useNavigate } from "react-router-dom"
import PageHeader from "../components/PageHeader"
import CurrencyActionsBar from "../components/CurrencyActionsBar"
import { t } from "i18next"
import { getWalletInfo, getConversionRate } from "../../../apis/wallet"
import CurrencyTransactions from "../components/CurrencyTransactions"
import { formatBalance } from "../../../utlis/numbers"
import { getTopUpAddress } from "../../../apis/wallet"
const SUPPORT_CURRENCY_TYPE = ["FSC", "USDT", "BNB"]
const fscIcon = require("../asset/fscIcon.png")
const usdtIcon = require("../asset/usdtIcon.png")
const refreshIcon = require("../asset/refreshIcon.png")
const bnbIcon = require("../asset/bnbIcon.png")
export default function CurrencyScreen() {
  const [currencyType, setCurrencyType] = useState("FSC")
  const [fscBalance, setFscBalance] = useState(0)
  const [usdtBalance, setUsdtBalance] = useState(0)
  const [bnbBalance, setBnbBalance] = useState(0)
  const [FSCToUSDTRatio, setFSCToUSDTRatio] = useState(0)
  const [FSCToBNBRatio, setFSCToBNBRatio] = useState(0)
  const [fscConvertFee, setFSCConvertFee] = useState(0)
  const currencyConfigs = {
    FSC: {
      title: "wallet.fscBalance",
      icon: fscIcon,
      backGroundColor: "linear-gradient(265.88deg, #1371ff, #55b8fe)",
      balance: fscBalance,
      usdValue: formatBalance(FSCToUSDTRatio * fscBalance),
    },
    USDT: {
      title: "wallet.usdtBalance",
      icon: usdtIcon,
      backGroundColor: "linear-gradient(260.39deg, #53ae94, #6cd0b4)",
      balance: usdtBalance,
      usdValue: formatBalance(usdtBalance),
    },
    BNB: {
      title: "wallet.bnbBalance",
      icon: bnbIcon,
      backGroundColor: "linear-gradient(260.39deg, #f3ba2f, #ffd774)",
      balance: bnbBalance,
      usdValue: formatBalance((FSCToUSDTRatio / FSCToBNBRatio) * bnbBalance),
    },
  }
  const location = useLocation()
  const navigate = useNavigate()
  const getMetamaskAddress = async () => {
    try {
      if (currencyType === "USDT") {
        await getTopUpAddress(currencyType, "TRON")
        await getTopUpAddress(currencyType, "ETHEREUM")
      }
      await getTopUpAddress(currencyType, "BNB")

      console.log("success")

      //  Toast.show({
      //    type: "info",
      //    text1: t("card.refreshSuccess"),
      //  })
    } catch (err) {
      console.log(err)
      //  Toast.show({
      //    type: "error",
      //    text1: t("card.error1"),
      //  })
    }
  }
  useEffect(() => {
    const type = location.search.split("?type=")[1]
    if (!type) {
      navigate(-1)
    }
    if (!SUPPORT_CURRENCY_TYPE.includes(type)) {
      navigate(-1)
    }
    setCurrencyType(type)
  }, [])
  useEffect(() => {
    getWalletInfo()
      .then((result) => {
        let wallet = result.data.data
        setFscBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "FSC")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
        setUsdtBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "USDT")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
        setBnbBalance(() => {
          let index = wallet.findIndex((w) => w.currency === "BNB")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
      })
      .catch((e) => console.log(e))

    getConversionRate()
      .then((result) => {
        setFSCToUSDTRatio(result.data.data.FSCtoUSDTRatio)
        setFSCToBNBRatio(result.data.data.FSCtoBNBRatio)
        setFSCConvertFee(result.data.data.FSCtoUSDTFee)
      })
      .catch((e) => console.log(e))
  }, [])
  return (
    <PageWrapper style={{ gap: "0px" }}>
      <PageHeader title={currencyType} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            background: currencyConfigs[currencyType].backGroundColor,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: "20px 16px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <span style={{ fontSize: "18px", fontWeight: "600", color: "white" }}>
            {t(currencyConfigs[currencyType].title)}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            <img
              src={currencyConfigs[currencyType].icon}
              style={{ width: "24px", height: "24px", marginRight: "6px" }}
            />
            <span
              style={{ fontSize: "24px", fontWeight: "700", color: "white" }}
            >
              {formatBalance(currencyConfigs[currencyType].balance)}
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                alignSelf: "end",
                color: "white",
                marginRight: "10px",
                marginLeft: "3px",
              }}
            >
              ≈ {formatBalance(currencyConfigs[currencyType].usdValue, 1000)}{" "}
              USDT
            </span>
            <button
              onClick={getMetamaskAddress}
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <img
                src={refreshIcon}
                style={{ width: "18px", height: "18px" }}
              />
            </button>
          </div>
        </div>
        <CurrencyActionsBar initCurrency={currencyType} />
        <CurrencyTransactions currency={currencyType} />
      </div>
    </PageWrapper>
  )
}

const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
  },
  subButtonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: "15px 16px",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}
