import { useEffect, useState } from "react"
import { t } from "i18next"
import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../../Wallet/components/PageHeader"
export default function ChangePaymentPasswordScreen() {
  return (
    <PageWrapper>
      <PageHeader title={"修改支付密码"} />
    </PageWrapper>
  )
}
