import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import './index.css';

const Confirm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { confirmVisible, confirmInfo } = useSelector(state => state.user);

  const { title, content, onConfirm, onCancel, type = 'normal' } = confirmInfo;

  const handleOkay = () => {
    if (onConfirm) {
      onConfirm();
    }

    dispatch({
      type: 'user/SWITCH_CONFIRM',
      payload: {
        confirmVisible: false,
        confirmInfo: {
          title: '',
          content: '',
          type: 'normal',
          onConfirm: null,
          onCancel: null,
        }
      },
    });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    dispatch({
      type: 'user/SWITCH_CONFIRM',
      payload: {
        confirmVisible: false,
        confirmInfo: {
          title: '',
          content: '',
          type: 'normal',
          onConfirm: null,
          onCancel: null,
        }
      },
    });
  };

  return (
    <Modal
      open={confirmVisible}
      onCancel={handleCancel}
      centered
      closable={false}
      footer={null}
      style={{
        width: 335,
      }}
    >
      <div className='confirm-title'>
        {title}
      </div>
      <div className='confirm-content'>
        {content}
      </div>
      {
        type === 'confirm'
        &&
        <div>
          <div
            className='confirm-button'
            onClick={handleOkay}
          >
            {t('card.yes')}
          </div>
          <div
            className='cancel-button'
            onClick={handleCancel}
          >
            {t('card.cancel')}
          </div>
        </div>
      }
      {
        type === 'normal'
        &&
        <div>
          <div
            className='ok-button'
            onClick={handleOkay}
          >
            {t('card.okay')}
          </div>
        </div>
      }
    </Modal>
  )
}

export default Confirm;