import PageWrapper from "../../../components/PageWrapper"
import PageHeader from "../components/PageHeader"
import TransactionHistories from "../components/TransactionHistories"
import { t } from "i18next"
import BackgroundedCard from "../components/BackgroundedCard"
import { formatBalance } from "../../../utlis/numbers"
import { useEffect, useState } from "react"
import {
  getFSCPSummary,
  getConversionRate,
  getWalletInfo,
  getTransactionRecord,
} from "../../../apis/wallet"
import FSCExchangePopup from "../components/FSCExchangePopup"
const pageSize = 20

export default function StarPointScreen() {
  const [spToUSDT, setSpToUSDT] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [todayIncome, setTodayIncome] = useState(0)
  const [fscExchangePopup, setFscExchangePopup] = useState(false)

  const [history, setHistory] = useState([])
  useEffect(() => {
    getConversionRate().then((result) => {
      setSpToUSDT(result.data.data.FSCtoUSDTRatio)
    })
    getFSCPSummary()
      .then((result) => {
        setTodayIncome(result.data.data.totalToday)
        // setTotalIncome(result.data.data.totalIncome)
      })
      .catch((e) => console.log(e))
    getWalletInfo()
      .then((result) => {
        let wallet = result.data.data
        setTotalIncome(() => {
          let index = wallet.findIndex((w) => w.currency === "SP")
          if (index >= 0) {
            return wallet[index].balance
          } else {
            return 0
          }
        })
      })
      .catch((e) => console.log(e))
    // getTransactionRecord({ page: 1, size: pageSize, currency: "SP" })
    //   .then((result) => {
    //     console.log("history: ", result.data.data)
    //     setHistory(result.data.data)
    //     //  if (result.data.data.length < pageSize) {
    //     //    setShowLoader(false)
    //     //  }
    //   })
    //   .catch((e) => console.log(e))
  }, [])
  return (
    <PageWrapper style={{ gap: "0px" }}>
      <PageHeader title={t("wallet.fscp")} />
      <div style={styles.bodyWrapper}>
        <BackgroundedCard>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 20,
              width: "100%",
              height: "100%",
              color: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {t("wallet.myStarPoints")}
            </span>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "4px",
              }}
            >
              {formatBalance(totalIncome, 10000)}
            </span>
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              {t("wallet.todayTotalIncome")}
              {formatBalance(todayIncome, 10000)} ≈{" "}
              {formatBalance(todayIncome * spToUSDT, 10000)} USDT
            </span>
            <button
              onClick={() => {
                setFscExchangePopup(true)
              }}
              style={{
                backgroundColor: "white",
                padding: "4px 65px",
                borderRadius: "100px",
                marginTop: "12px",
                border: "none",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#0E73F6",
                }}
              >
                {t("wallet.exchangeFSCP")}
              </span>
            </button>
          </div>
        </BackgroundedCard>
        <TransactionHistories />
      </div>
      {fscExchangePopup && (
        <FSCExchangePopup
          onClose={() => {
            setFscExchangePopup(false)
          }}
        />
      )}
    </PageWrapper>
  )
}

const styles = {
  bodyWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "16px 12px",
  },
}
