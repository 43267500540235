const backgroundImage = require("../asset/backgroundImage.png")

export default function BackgroundedCard(props) {
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "12px",
        backgroundColor: "#4094f7",
        width: "100%",
        overflow: "hidden",
        zIndex: 0,
        padding: "16px",
      }}
    >
      <img
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 10,
        }}
        alt=""
        src={backgroundImage}
      />
      {props.children}
    </div>
  )
}
